import Flux from '@cobuildlab/flux-state';

/**
 * Super admin Events.
 *
 * @type {string}
 */

export const SUPER_ADMIN_USERS_EVENT = 'SUPER_ADMIN_USERS_EVENT';
export const SUPER_ADMIN_USERS_ERROR = 'SUPER_ADMIN_USERS_ERROR ';
export const SUPER_ADMIN_SCHOOLS_EVENT = 'SUPER_ADMIN_SCHOOLS_EVENT';
export const SUPER_ADMIN_SCHOOLS_ERROR = 'SUPER_ADMIN_SCHOOLS_ERROR';
export const SUPER_ADMIN_ALL_SCHOOLS_EVENT = 'SUPER_ADMIN_ALL_SCHOOLS_EVENT';
export const ADD_USER_EVENT = 'ADD_USER_EVENT';
export const ADD_USER_ERROR_EVENT = 'ADD_USER_ERROR_EVENT';
export const UPDATE_USER_EVENT = 'UPDATE_USER_EVENT';
export const UPDATE_USER_ERROR_EVENT = 'UPDATE_USER_ERROR_EVENT';
export const SUPER_ADMIN_REGIONS = 'SUPER_ADMIN_REGIONS';
export const SUPER_ADMIN_REGIONS_ERROR = 'SUPER_ADMIN_REGIONS_ERROR';
export const SUPER_ADMIN_ADD_SCHOOL_EVENT = 'SUPER_ADMIN_ADD_SCHOOL_EVENT';
export const SUPER_ADMIN_ADD_SCHOOL_ERROR = 'SUPER_ADMIN_ADD_SCHOOL_ERROR';
export const SUPER_ADMIN_EDIT_SCHOOL_EVENT = 'SUPER_ADMIN_EDIT_SCHOOL_EVENT';
export const SUPER_ADMIN_EDIT_SCHOOL_ERROR = 'SUPER_ADMIN_EDIT_SCHOOL_ERROR';
export const SUPER_ADMIN_ALL_DISTRICTS_EVENT = 'SUPER_ADMIN_ALL_DISTRICTS_EVENT';
export const CLEAR_INVENTORY_ERROR_EVENT = 'CLEAR_INVENTORY_ERROR_EVENT';
export const CLEAR_INVENTORY_EVENT = 'CLEAR_INVENTORY_EVENT';
export const GET_OPEN_REGISTRATION_EVENT = 'GET_OPEN_REGISTRATION_EVENT';
export const GET_OPEN_REGISTRATION_ERROR = 'GET_OPEN_REGISTRATION_ERROR';
export const ADD_OPEN_REGISTRATION_EVENT = 'ADD_OPEN_REGISTRATION_EVENT';
export const ADD_OPEN_REGISTRATION_ERROR = 'ADD_OPEN_REGISTRATION_ERROR';
export const EXTEND_OPEN_REGISTRATION_EVENT = 'EXTEND_OPEN_REGISTRATION_EVENT';
export const EXTEND_OPEN_REGISTRATION_ERROR = 'EXTEND_OPEN_REGISTRATION_ERROR';
export const STOP_OPEN_REGISTRATION_EVENT = 'STOP_OPEN_REGISTRATION_EVENT';
export const STOP_OPEN_REGISTRATION_ERROR = 'STOP_OPEN_REGISTRATION_ERROR';
export const SUPER_ADMIN_USERS_CLF_EVENT = 'SUPER_ADMIN_USERS_CLF_EVENT';
export const SUPER_ADMIN_USERS_CLF_ERROR = 'SUPER_ADMIN_USERS_CLF_ERROR';
export const SUPER_ADMIN_DELETE_USER_EVENT = 'SUPER_ADMIN_DELETE_USER_EVENT';
export const SUPER_ADMIN_DELETE_USER_ERROR = 'SUPER_ADMIN_DELETE_USER_ERROR';
export const SUPER_ADMIN_DISTRICTS_EVENT = 'SUPER_ADMIN_DISTRICTS_EVENT';
export const SUPER_ADMIN_DISTRICTS_VIEW_ERROR = 'SUPER_ADMIN_DISTRICTS_VIEW_ERROR';
export const SUPER_ADMIN_DISTRICTS_VIEW_EVENT = 'SUPER_ADMIN_DISTRICTS_VIEW_EVENT';
export const SUPER_ADMIN_ADD_DISTRICT_ERROR = 'SUPER_ADMIN_ADD_DISTRICT_ERROR';
export const SUPER_ADMIN_ADD_DISTRICT_EVENT = 'SUPER_ADMIN_ADD_DISTRICT_EVENT';
export const SUPER_ADMIN_ADD_REGION_ERROR = 'SUPER_ADMIN_ADD_REGION_ERROR';
export const SUPER_ADMIN_ADD_REGION_EVENT = 'SUPER_ADMIN_ADD_REGION_EVENT';
export const SUPER_ADMIN_EXCEL_REPORTS_EVENT = 'SUPER_ADMIN_EXCEL_REPORTS_EVENT';
export const SUPER_ADMIN_EXCEL_REPORTS_ERROR = 'SUPER_ADMIN_EXCEL_REPORTS_ERROR';
export const CLEAR_DISTRICT_INVENTORY_EVENT = 'CLEAR_DISTRICT_INVENTORY_EVENT';
export const CLEAR_DISTRICT_INVENTORY_ERROR_EVENT = 'CLEAR_DISTRICT_INVENTORY_ERROR_EVENT';

class SuperAdminStore extends Flux.Store {
  constructor() {
    super();
    this.addEvent(SUPER_ADMIN_USERS_EVENT);
    this.addEvent(SUPER_ADMIN_USERS_ERROR);
    this.addEvent(SUPER_ADMIN_SCHOOLS_EVENT);
    this.addEvent(SUPER_ADMIN_SCHOOLS_ERROR);
    this.addEvent(SUPER_ADMIN_ALL_SCHOOLS_EVENT);
    this.addEvent(ADD_USER_EVENT);
    this.addEvent(ADD_USER_ERROR_EVENT);
    this.addEvent(UPDATE_USER_EVENT);
    this.addEvent(UPDATE_USER_ERROR_EVENT);
    this.addEvent(SUPER_ADMIN_REGIONS);
    this.addEvent(SUPER_ADMIN_REGIONS_ERROR);
    this.addEvent(SUPER_ADMIN_ADD_SCHOOL_EVENT);
    this.addEvent(SUPER_ADMIN_ADD_SCHOOL_ERROR);
    this.addEvent(SUPER_ADMIN_EDIT_SCHOOL_EVENT);
    this.addEvent(SUPER_ADMIN_EDIT_SCHOOL_ERROR);
    this.addEvent(SUPER_ADMIN_ALL_DISTRICTS_EVENT);
    this.addEvent(CLEAR_INVENTORY_ERROR_EVENT);
    this.addEvent(CLEAR_INVENTORY_EVENT);
    this.addEvent(GET_OPEN_REGISTRATION_EVENT);
    this.addEvent(GET_OPEN_REGISTRATION_ERROR);
    this.addEvent(ADD_OPEN_REGISTRATION_EVENT);
    this.addEvent(ADD_OPEN_REGISTRATION_ERROR);
    this.addEvent(EXTEND_OPEN_REGISTRATION_EVENT);
    this.addEvent(EXTEND_OPEN_REGISTRATION_ERROR);
    this.addEvent(STOP_OPEN_REGISTRATION_EVENT);
    this.addEvent(STOP_OPEN_REGISTRATION_ERROR);
    this.addEvent(SUPER_ADMIN_USERS_CLF_EVENT);
    this.addEvent(SUPER_ADMIN_USERS_CLF_ERROR);
    this.addEvent(SUPER_ADMIN_DELETE_USER_EVENT);
    this.addEvent(SUPER_ADMIN_DELETE_USER_ERROR);
    this.addEvent(SUPER_ADMIN_DISTRICTS_EVENT);
    this.addEvent(SUPER_ADMIN_DISTRICTS_VIEW_ERROR);
    this.addEvent(SUPER_ADMIN_DISTRICTS_VIEW_EVENT);
    this.addEvent(SUPER_ADMIN_ADD_DISTRICT_ERROR);
    this.addEvent(SUPER_ADMIN_ADD_DISTRICT_EVENT);
    this.addEvent(SUPER_ADMIN_ADD_REGION_ERROR);
    this.addEvent(SUPER_ADMIN_ADD_REGION_EVENT);
    this.addEvent(SUPER_ADMIN_EXCEL_REPORTS_EVENT);
    this.addEvent(SUPER_ADMIN_EXCEL_REPORTS_ERROR);
    this.addEvent(CLEAR_DISTRICT_INVENTORY_EVENT);
    this.addEvent(CLEAR_DISTRICT_INVENTORY_ERROR_EVENT);
  }
}

const superAdminStore = new SuperAdminStore();
export { superAdminStore };
