import firebase from 'firebase';
import Flux from '@cobuildlab/flux-state';
import {
  COA_SCHOOLS_ERROR,
  COA_SCHOOLS_EVENT,
  MY_DASHBOARD_ERROR_EVENT,
  MY_DASHBOARD_TEACHERS_EVENT,
  SCHOOL_AVERAGE_DASH_EVENT,
  SCHOOL_AVERAGE_SCHOOLID_EVENT,
  SCHOOL_MONTHLY_AVERAGE_EVENT,
} from './dashboard-coa-store';
import { authStore, USER_EVENT } from '../auth/auth-store';
import moment from 'moment';
import { USER_TYPE_TEACHER } from '../../shared/userTypes';

/**
 * Return the School average from monthlySchoolAverages.
 *
 * @returns {Promise<>}
 */

export const getSchoolsByDistrictId = async (districtId, isActive = true) => {
  const getSchoolsByDistrictId = firebase
    .functions()
    .httpsCallable('getDistrictSchoolsByDistrictId');
  const result = await getSchoolsByDistrictId(districtId, isActive);
  return result;
};

export const fetchCoaSchoolsAction = async () => {
  const user = authStore.getState(USER_EVENT);

  try {
    const result = await getSchoolsByDistrictId(user.districtId, false);
    Flux.dispatchEvent(COA_SCHOOLS_EVENT, { schools: result.data });
  } catch (err) {
    Flux.dispatchEvent(COA_SCHOOLS_ERROR, err);
  }
};

/**
 * Return the School average from monthlySchoolAverages.
 *
 * @returns {Promise<>}
 */

export const getTeachersByDistrictId = async (districtId) => {
  const getTeachersByDistrictId = firebase
    .functions()
    .httpsCallable('getDistrictTeachersByDistrictId');
  const result = await getTeachersByDistrictId(districtId);
  return result;
};

/**
 * Return the School average.
 *
 * @returns {Promise<>}
 */

export const allUserMyDashboardAction = async () => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');

  const user = authStore.getState(USER_EVENT);

  let teacherQuery;
  try {
    teacherQuery = await usersCollection
      .where('userType', '==', USER_TYPE_TEACHER)
      .where('active', '==', true)
      .where('districtId', '==', user.districtId)
      .get();
  } catch (err) {
    Flux.dispatchEvent(MY_DASHBOARD_ERROR_EVENT, err.message);
    return;
  }
  const teachers = teacherQuery.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

  try {
    const result = await getDashboardGraphData(teachers);
    Flux.dispatchEvent(MY_DASHBOARD_TEACHERS_EVENT, result);
  } catch (err) {
    console.error({ err });
    Flux.dispatchEvent(MY_DASHBOARD_ERROR_EVENT, err.message);
  }
};

/**
 *
 * @param {string} schoolId - School ID.
 * @returns {Promise<any>} -
 */
export const schoolUserMyDashboardAction = async (schoolId) => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');

  let teacherQuery;
  try {
    teacherQuery = await usersCollection
      .where('userType', '==', USER_TYPE_TEACHER)
      .where('active', '==', true)
      .where('schoolId', '==', schoolId)
      .get();
  } catch (err) {
    Flux.dispatchEvent(MY_DASHBOARD_ERROR_EVENT, err.message);
    return;
  }

  const teachers = teacherQuery.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

  try {
    const result = await getDashboardGraphData(teachers);
    Flux.dispatchEvent(MY_DASHBOARD_TEACHERS_EVENT, result);
  } catch (err) {
    console.error({ err });
    Flux.dispatchEvent(MY_DASHBOARD_ERROR_EVENT, err.message);
  }
};

/**
 * @param teachers
 */
export async function getDashboardGraphData(teachers) {
  const DB = firebase.firestore();
  const logInLogsCollection = DB.collection('logInLogs');

  const teachersDriversCompleted = teachers.filter((teacher) => !teacher.needsProfile);
  // GET LOGIN LOGS
  let loginQuery;
  try {
    const schoolLogInQueries = teachers.map((teacher) =>
      logInLogsCollection
        .where('email', '==', teacher.email)
        .orderBy('date', 'desc')
        .limit(1)
        .get(),
    );
    loginQuery = await Promise.all(schoolLogInQueries);
  } catch (err) {
    throw new Error('Error getting logging data');
  }

  const teachersLogInList = loginQuery.filter((ref) => ref.size);

  const oneMonthAgo = moment().subtract(1, 'month');
  const oneMonthAgoSeconds = parseInt(oneMonthAgo.format('X'));
  const oneMonthAgoMilliseconds = parseInt(oneMonthAgo.format('x'));

  const teachersLogIn30Days = teachersLogInList.filter((ref) => {
    const { date } = ref.docs[0].data();
    return date.seconds > oneMonthAgoSeconds;
  });

  const driversQueryList = teachers.map((teacher) => {
    return DB.collection('driversTeacherLog')
      .orderBy('dateCompleted', 'desc')
      .where('dateCompleted', '>=', oneMonthAgoMilliseconds)
      .where('email', '==', teacher.email)
      .get();
  });

  let driversList;
  try {
    driversList = await Promise.all(driversQueryList);
  } catch (err) {
    throw new Error('Error getting drivers log');
  }

  const numTeachers = teachers.length;
  const haveLoggedIn = teachersLogInList.length;
  const haveLogged30 = teachersLogIn30Days.length;
  const numTeachersInitial = teachersDriversCompleted.length;
  const numUpdatedDrivers = driversList.filter((ref) => ref.size).length;

  return {
    numTeachers,
    haveLoggedIn,
    haveLogged30,
    numTeachersInitial,
    numUpdatedDrivers,
  };
}

/**
 * Return the School average from monthlySchoolAverages.
 *
 * @returns {Promise<>}
 */

export const getMySchoolMonthlyAveragesCoaAction = async () => {
  const DB = firebase.firestore();
  const user = authStore.getState(USER_EVENT);

  let schoolQuery = null;
  let monthlySchoolAveragesCollection = null;
  let monthlySchoolAveragesQuery = [];
  let monthlySchoolList = [];
  let montlyList = [];
  let list = [];
  let listDate = [];
  let docDateX = {
    year: null,
    month: null,
  };

  try {
    schoolQuery = await getSchoolsByDistrictId(user.districtId);
  } catch (err) {
    return Flux.dispatchEvent(MY_DASHBOARD_ERROR_EVENT, err);
  }

  schoolQuery.data.forEach((school) => {
    monthlySchoolAveragesQuery.push(
      DB.collection('monthlySchoolAverages')
        .where('schoolId', '==', school.id)
        .get(),
    );
  });

  try {
    monthlySchoolAveragesCollection = await Promise.all(monthlySchoolAveragesQuery);
  } catch (err) {
    return Flux.dispatchEvent(MY_DASHBOARD_ERROR_EVENT, err);
  }

  monthlySchoolAveragesCollection.forEach((doc) => {
    if (doc.docs.length > 0) {
      monthlySchoolAveragesCollection.forEach((document) => {
        const drivers = document.docs;
        monthlySchoolList.push(drivers);
      });
    }
  });

  monthlySchoolList.forEach((doc) => {
    if (doc.length > 0) {
      doc.forEach((document) => {
        montlyList.push(document.data());
      });
    }
  });
  montlyList = montlyList.sort((b, a) => a.year - b.year);
  montlyList = montlyList.sort((b, a) => a.month - b.month);

  let driversAux = {
    advancement: 0,
    clearUnifiedDirection: 0,
    collaboration: 0,
    empowerment: 0,
    feedbackReflection: 0,
    instructionalAutonomy: 0,
    professionalEngagement: 0,
    resourcePriorities: 0,
    senseOfBelonging: 0,
    supportCare: 0,
  };

  montlyList.forEach((doc) => {
    const docDateAux = {
      year: doc.year,
      month: doc.month,
    };
    if (docDateAux.year !== docDateX.year || docDateAux.month !== docDateX.month) {
      docDateX = {
        year: doc.year,
        month: doc.month,
      };
      listDate = montlyList.filter((data) => data.year === docDateX.year);
      listDate = montlyList.filter((data) => data.month === docDateX.month);

      driversAux = {
        advancement: 0,
        clearUnifiedDirection: 0,
        collaboration: 0,
        empowerment: 0,
        feedbackReflection: 0,
        instructionalAutonomy: 0,
        professionalEngagement: 0,
        resourcePriorities: 0,
        senseOfBelonging: 0,
        supportCare: 0,
      };

      listDate.forEach((doc) => {
        if (
          doc.drivers !== null &&
          doc.drivers !== undefined &&
          Object.keys(doc.drivers).length !== 0
        ) {
          driversAux.advancement = driversAux.advancement + parseInt(doc.drivers.advancement);
          driversAux.clearUnifiedDirection =
            driversAux.clearUnifiedDirection + parseInt(doc.drivers.clearUnifiedDirection);
          driversAux.collaboration = driversAux.collaboration + parseInt(doc.drivers.collaboration);
          driversAux.empowerment = driversAux.empowerment + parseInt(doc.drivers.empowerment);
          driversAux.feedbackReflection =
            driversAux.feedbackReflection + parseInt(doc.drivers.feedbackReflection);
          driversAux.instructionalAutonomy =
            driversAux.instructionalAutonomy + parseInt(doc.drivers.instructionalAutonomy);
          driversAux.professionalEngagement =
            driversAux.professionalEngagement + parseInt(doc.drivers.professionalEngagement);
          driversAux.resourcePriorities =
            driversAux.resourcePriorities + parseInt(doc.drivers.resourcePriorities);
          driversAux.senseOfBelonging =
            driversAux.senseOfBelonging + parseInt(doc.drivers.senseOfBelonging);
          driversAux.supportCare = driversAux.supportCare + parseInt(doc.drivers.supportCare);
        }
      });

      const prom = listDate.length;
      const drivers = {
        advancement: driversAux.advancement / prom,
        clearUnifiedDirection: driversAux.clearUnifiedDirection / prom,
        collaboration: driversAux.collaboration / prom,
        empowerment: driversAux.empowerment / prom,
        feedbackReflection: driversAux.feedbackReflection / prom,
        instructionalAutonomy: driversAux.instructionalAutonomy / prom,
        professionalEngagement: driversAux.professionalEngagement / prom,
        resourcePriorities: driversAux.resourcePriorities / prom,
        senseOfBelonging: driversAux.senseOfBelonging / prom,
        supportCare: driversAux.supportCare / prom,
      };
      const { year, month } = docDateX;

      list.push({
        drivers,
        year,
        month,
      });
    }
  });

  Flux.dispatchEvent(SCHOOL_MONTHLY_AVERAGE_EVENT, list);
  return list;
};

/**
 * Gets the Teachers School Average of an specific school.
 *
 * @param schoolId
 * @returns {Promise<void>}
 */
export const getTeachersDriversAvg = async () => {
  const fetchTodayAverageCoa = firebase.functions().httpsCallable('fetchTodayAverageCoa');
  const user = authStore.getState(USER_EVENT);
  try {
    const { data } = await fetchTodayAverageCoa(user.districtId);
    Flux.dispatchEvent(SCHOOL_AVERAGE_DASH_EVENT, data);
    return data;
  } catch (error) {
    console.log('Error to fetch today average:', error);
    return Flux.dispatchEvent(MY_DASHBOARD_ERROR_EVENT, error);
  }
};

export const getSchoolTeachersDriversAvg = async (schoolId) => {
  const fetchTodayAverageSchool = firebase.functions().httpsCallable('fetchTodayAverageSchool');
  try {
    const { data } = await fetchTodayAverageSchool(schoolId);
    Flux.dispatchEvent(SCHOOL_AVERAGE_DASH_EVENT, data);
    return data;
  } catch (error) {
    console.log('Error to fetch today average:', error);
    return Flux.dispatchEvent(MY_DASHBOARD_ERROR_EVENT, error);
  }
};

/**
 * Gets the Teachers School Average of an specific school.
 *
 * @param schoolId
 * @returns {Promise<void>}
 */
export const getSchoolTeachersDriversAvgSchoolId = async (schoolId) => {
  const DB = firebase.firestore();
  const teachersCollection = DB.collection('users')
    .where('userType', '==', USER_TYPE_TEACHER)
    .where('schoolId', '==', schoolId);

  let teacherQuery = null;
  let emails = [];

  try {
    teacherQuery = await teachersCollection.get();
  } catch (err) {
    return Flux.dispatchEvent(MY_DASHBOARD_ERROR_EVENT, err);
  }

  teacherQuery.forEach((doc) => {
    const teacher = doc.data();
    emails.push(teacher.email);
  });

  const promises = emails.map((email, i) => getTeacherDriversAvg(email, i));
  const results = await Promise.all(promises);
  const filteredResults = results.filter((result) => result !== null);

  const avgMap = {};
  filteredResults.forEach((result) => {
    const driverAvgs = result[Object.keys(result)[0]];
    const indicatorsIds = Object.keys(driverAvgs);
    indicatorsIds.forEach((indicatorId) => {
      const indicatorAvg = driverAvgs[indicatorId];
      if (avgMap[indicatorId] === undefined) {
        avgMap[indicatorId] = [];
      }
      avgMap[indicatorId].push(parseFloat(indicatorAvg));
    });
  });

  // Reducing
  const indicatorsIds = Object.keys(avgMap);
  indicatorsIds.forEach((indicatorId) => {
    const valueList = avgMap[indicatorId];
    const size = valueList.length;
    avgMap[indicatorId] = (avgMap[indicatorId].reduce((total, num) => total + num) / size).toFixed(
      2,
    );
  });

  Flux.dispatchEvent(SCHOOL_AVERAGE_SCHOOLID_EVENT, avgMap);
  return avgMap;
};

/**
 * Get a Teacher Drivers Avg.
 *
 * @param userEmail
 * @param i
 * @returns {Promise<{}|null>}
 */
export const getTeacherDriversAvg = async (userEmail, i) => {
  const DB = firebase.firestore();
  const driversCollection = DB.collection('driversTeacher');
  const queryRef = driversCollection.doc(userEmail);
  let driversData;
  try {
    const ref = await queryRef.get();
    driversData = ref.data();
  } catch (e) {
    return null;
  }
  if (driversData === null || driversData === undefined) return null;
  const driversIds = Object.keys(driversData);
  const userAvgs = {};
  driversIds.forEach((driverId) => {
    const indicators = driversData[driverId];
    const indicatorsIds = Object.keys(indicators);
    let acc = 0,
      i = 0,
      driverAvg = 0;
    indicatorsIds.forEach((indicatorId) => {
      acc += indicators[indicatorId];
      i++;
    });
    if (i !== 0) driverAvg = (acc / i).toFixed(2);
    userAvgs[driverId] = driverAvg;
  });
  return { [userEmail]: userAvgs };
};

export const fetchMonthlyAverageCoaAction = async () => {
  const fetchMonthlyAverageCoa = firebase.functions().httpsCallable('fetchMonthlyAverageCoa');
  const user = authStore.getState(USER_EVENT);
  try {
    const { data } = await fetchMonthlyAverageCoa(user.districtId);
    Flux.dispatchEvent(SCHOOL_MONTHLY_AVERAGE_EVENT, data);
    return data;
  } catch (err) {
    console.log('Error to fetch monthly average:', err);
    return Flux.dispatchEvent(MY_DASHBOARD_ERROR_EVENT, err);
  }
};

export const fetchMonthlyAverageSchoolAction = async (schoolId) => {
  const fetchMonthlyAverageSchool = firebase.functions().httpsCallable('fetchMonthlyAverageSchool');
  try {
    const { data } = await fetchMonthlyAverageSchool(schoolId);
    Flux.dispatchEvent(SCHOOL_MONTHLY_AVERAGE_EVENT, data);
    return data;
  } catch (err) {
    console.log('Error to fetch monthly average:', err);
    return Flux.dispatchEvent(MY_DASHBOARD_ERROR_EVENT, err);
  }
};
