import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import myVoiceLogo from '../assets/images/myvoice-cognia-logo.png';
import cogniaWhiteLogo from '../assets/images/cognia_white.png';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const AuthContent = ({ children }) => (
  <Content>
    <div style={{ width: 370 }}>{children}</div>
  </Content>
);

AuthContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthLayout = ({ children }) => {
  return (
    <Container fluid className="h-100vh">
      <Row className="h-100">
        <Col xs={12} md={6} className="h-100 bg-primary">
          <Content style={{ marginTop: '-5%' }}>
            <img src={cogniaWhiteLogo} alt="cognia logo" width="330px" />
            <br /> <br /> <br />
            <div style={{ maxWidth: 500 }} className="text-center">
              <img src={myVoiceLogo} alt="my voice logo" width="280px" />
              <p style={{ fontSize: 24, letterSpacing: '1.25px' }} className="text-white mt-2">
                Improving Schools Through Teacher Voice and Agency
              </p>
            </div>
          </Content>
        </Col>
        <Col xs={12} md={6} className="h-100">
          {children}
        </Col>
      </Row>
    </Container>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
