import React, { useRef, useState } from 'react';
import ConfirmModal from '../modal/ConfirmModal';
import { clearSchoolInventory } from '../../modules/super-admin/super-admin-actions';
import { useSubscription } from '@cobuildlab/react-flux-state';
import {
  CLEAR_INVENTORY_ERROR_EVENT,
  CLEAR_INVENTORY_EVENT,
  superAdminStore,
} from '../../modules/super-admin/super-admin-store';
import { customToast } from '../../shared/toast';
import LoadingButton from '../button/LoadingButton';
import PropTypes from 'prop-types';

const ClearInventoryButton = ({ school }) => {
  const [isLoading, setLoading] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const resolve = useRef(() => {});

  const toggle = () => setConfirmModalOpen(!isConfirmModalOpen);
  const handleConfirmationResponse = (response) => resolve.current(response);

  const waitConfirmation = () => {
    toggle();
    return new Promise((r) => {
      resolve.current = r;
    });
  };

  const handleResetInventory = async () => {
    const confirmation = await waitConfirmation();
    if (confirmation) {
      setLoading(true);
      await clearSchoolInventory(school.id);
    }
  };

  useSubscription(superAdminStore, CLEAR_INVENTORY_ERROR_EVENT, (err) => {
    setLoading(false);
    customToast.error(err);
  });

  useSubscription(superAdminStore, CLEAR_INVENTORY_EVENT, () => {
    setLoading(false);
    customToast.success('Inventory Cleared');
  });

  return (
    <>
      <LoadingButton
        size="sm"
        disabled={isLoading}
        loading={isLoading}
        onClick={handleResetInventory}>
        Clear Inventory
      </LoadingButton>

      <ConfirmModal
        active={isConfirmModalOpen}
        toggle={toggle}
        onResponse={handleConfirmationResponse}
        title="Please Confirm"
        body={`Are you sure you want to require again the Initial Profile and Initial Drivers for the users with the school ID ${school.id}?`}
      />
    </>
  );
};

ClearInventoryButton.propTypes = {
  school: PropTypes.object.isRequired,
};

export default ClearInventoryButton;
