import React, { Component } from 'react';
import { NavigationBar } from '../../../shared/layouts/NavigationBar';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
//icons
import { Icon } from 'react-icons-kit';
import { radioChecked } from 'react-icons-kit/icomoon/radioChecked';
import PrimaryButton from '../../../components/button/PrimaryButton';

class ProfileResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: '',
    };
  }

  render() {
    const personInfo = [
      { content: 'Full Name', className: 'item-textgray' },
      { content: 'Lorem Impsum', className: 'item-textback' },
      { content: 'Teacher #', className: 'item-textgray' },
      { content: '999999', className: 'item-textback' },
    ];

    const schoolInfo = [
      { content: 'School', className: 'item-textgray' },
      { content: 'Lorem Ipsum', className: 'item-textback' },
      { content: 'District', className: 'item-textgray' },
      { content: 'Lorem Ipsum', className: 'item-textback' },
    ];

    return (
      <NavigationBar currentRoute={'/my-profile'}>
        <Container fluid className="mt-3">
          <Row className="m-4 item-content">
            <div className="item-my-profile" />
            <Col className="pl-5">
              <div className="pt-4 pr-5">
                {personInfo.map((value, index) => (
                  <h5 className={value.className} key={index}>
                    {value.content}
                  </h5>
                ))}
              </div>
            </Col>
            <Col>
              <div className="pt-4 pl-5">
                {schoolInfo.map((value, index) => (
                  <h5 className={value.className} key={index}>
                    {value.content}
                  </h5>
                ))}
              </div>
            </Col>
            <Col>
              <PrimaryButton onClick={() => {}} text={'Edit'} />
            </Col>
          </Row>
          <Row className="m-4 item-content">
            <Col>
              <h4 className="d-inline">MySchool Culture Inventory</h4>
              <div className="d-flex pb-4 float-right">
                <Link to="/profile-query" className="ml-4 btn btn-primary btn-block pl-4 pr-4">
                  Update
                </Link>
              </div>
              <Row className="mt-5">
                <Col className="profile-content">
                  <strong className="item-textgray-small">Gender</strong>
                  <p>
                    <Icon className="mr-2" icon={radioChecked} />
                    <small>Male</small>
                  </p>
                  <strong className="item-textgray-small">Race</strong>
                  <p>
                    <Icon className="mr-2" icon={radioChecked} />
                    <small>White</small>
                  </p>
                  <strong className="item-textgray-small">Age</strong>
                  <p>
                    <Icon className="mr-2" icon={radioChecked} />
                    <small>Under 30</small>
                  </p>
                  <strong className="item-textgray-small">Number of years in education</strong>
                  <p>
                    <Icon className="mr-2" icon={radioChecked} />
                    <small>0 to 5</small>
                  </p>
                  <strong className="item-textgray-small">School level</strong>
                  <p>
                    <Icon className="mr-2" icon={radioChecked} />
                    <small>Elemental school</small>
                  </p>
                  <strong className="item-textgray-small">Number of years in current school</strong>
                  <p>
                    <Icon className="mr-2" icon={radioChecked} />
                    <small>0 to 5</small>
                  </p>
                </Col>
                <Col className="profile-content">
                  <strong className="item-textgray-small">
                    Number of years I have worked with
                    <br />
                    the principal in this school.
                  </strong>
                  <p>
                    <Icon className="mr-2" icon={radioChecked} />
                    <small>0 to 2</small>
                  </p>
                  <strong className="item-textgray-small">
                    In your tenure in this building, how many
                    <br />
                    principals have you worked with.
                  </strong>
                  <p>
                    <Icon className="mr-2" icon={radioChecked} />
                    <small>0 to 2</small>
                  </p>
                  <strong className="item-textgray-small">
                    How many schools have you worked in
                    <br />
                    during your career.
                  </strong>
                  <p>
                    <Icon className="mr-2" icon={radioChecked} />
                    <small>0 to 5</small>
                  </p>
                  <strong className="item-textgray-small">
                    radioUnchecked Higher degree Attained.
                  </strong>
                  <p>
                    <Icon className="mr-2" icon={radioChecked} />
                    <small>0 to 5</small>
                  </p>
                </Col>
                <Col className="profile-content">
                  <strong className="item-textgray-small">Certification type</strong>
                  <p>
                    <Icon className="mr-2" icon={radioChecked} />
                    <small>Traditional certification</small>
                  </p>
                  <strong className="item-textgray-small">
                    Are you considering leaving your
                    <br />
                    current school
                  </strong>
                  <p>
                    <Icon className="mr-2" icon={radioChecked} />
                    <small>Yes</small>
                  </p>
                  <strong className="item-textgray-small">
                    Are you considering leaving your teaching
                    <br />
                    profession for reasons other than retirement
                  </strong>
                  <p>
                    <Icon className="mr-2" icon={radioChecked} />
                    <small>Yes</small>
                  </p>
                  <strong className="item-textgray-small">
                    Do you have an interest in a leadership type position
                    <br />
                    [e.g., principal. instructional coach, dept chair, lead teacher, etc]
                  </strong>
                  <p>
                    <Icon className="mr-2" icon={radioChecked} />
                    <small>Yes</small>
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </NavigationBar>
    );
  }
}

export default ProfileResult;
