import validate from 'validate.js';

import { IntegrityError } from '../../shared/errors';

const PROFILE_CONSTRAINTS = {
  gender: {
    presence: {
      allowEmpty: false,
      message: `Gender can't be blank`,
    },
  },
  race: {
    presence: {
      allowEmpty: false,
      message: `Race can't be blank`,
    },
  },
  age: {
    presence: {
      allowEmpty: false,
      message: `Age can't be blank`,
    },
  },
  yearsInEducation: {
    presence: {
      allowEmpty: false,
      message: `Years in Education can't be blank`,
    },
  },
  schoolLevel: {
    presence: {
      allowEmpty: false,
      message: `School Level can't be blank`,
    },
  },
  yearsInCurrentSchool: {
    presence: {
      allowEmpty: false,
      message: `Years in Current School can't be blank`,
    },
  },
  yearsWithPrincipal: {
    presence: {
      allowEmpty: false,
      message: `Years with Principal can't be blank`,
    },
  },
  howManyPrincipals: {
    presence: {
      allowEmpty: false,
      message: `How many Principals can't be blank`,
    },
  },
  howManySchools: {
    presence: {
      allowEmpty: false,
      message: `How Many Schools can't be blank`,
    },
  },
  certificationType: {
    presence: {
      allowEmpty: false,
      message: `Certification Type can't be blank`,
    },
  },
  consideringLivingSchool: {
    presence: {
      allowEmpty: false,
      message: `Considering Living School can't be blank`,
    },
  },
  consideringLivingProfession: {
    presence: {
      allowEmpty: false,
      message: `Considering Living Profession can't be blank`,
    },
  },
  interestedOnLeadership: {
    presence: {
      allowEmpty: false,
      message: `Interest on Leadership can't be blank`,
    },
  },
  highestDegree: {
    presence: {
      allowEmpty: false,
      message: `Highest Degree can't be blank`,
    },
  },
};

const PROFILE_CONSTRAINTS_LEADER = {
  currentPosition: {
    presence: {
      allowEmpty: false,
      message: `Current position can't be blank`,
    },
  },
  schoolLevel: {
    presence: {
      allowEmpty: false,
      message: `School Level can't be blank`,
    },
  },
  gender: {
    presence: {
      allowEmpty: false,
      message: `Gender can't be blank`,
    },
  },
  race: {
    presence: {
      allowEmpty: false,
      message: `Race can't be blank`,
    },
  },
  age: {
    presence: {
      allowEmpty: false,
      message: `Age can't be blank`,
    },
  },
  yearsInEducation: {
    presence: {
      allowEmpty: false,
      message: `Years in Education can't be blank`,
    },
  },
  certificationType: {
    presence: {
      allowEmpty: false,
      message: `Certification type can't be blank`,
    },
  },
  buildingAdministrator: {
    presence: {
      allowEmpty: false,
      message: `Building Administrator can't be blank`,
    },
  },
  numberAdministrator: {
    presence: {
      allowEmpty: false,
      message: `Number Administrator can't be blank`,
    },
  },
  timeAssistant: {
    presence: {
      allowEmpty: false,
      message: `Time Assistant can't be blank`,
    },
  },
  howManyTimePrincipal: {
    presence: {
      allowEmpty: false,
      message: `How many time Principal can't be blank`,
    },
  },
  roleBefore: {
    presence: {
      allowEmpty: false,
      message: `Role before can't be blank`,
    },
  },
  howManyWorked: {
    presence: {
      allowEmpty: false,
      message: `How Many Worked can't be blank`,
    },
  },
  consideringLivingSchool: {
    presence: {
      allowEmpty: false,
      message: `Considering Living School can't be blank`,
    },
  },
  highestDegree: {
    presence: {
      allowEmpty: false,
      message: `Highest Degree can't be blank`,
    },
  },
  interestedOnLeadership: {
    presence: {
      allowEmpty: false,
      message: `Interested On Leadership can't be blank`,
    },
  },
  leavingProfession: {
    presence: {
      allowEmpty: false,
      message: `Leaving Profession can't be blank`,
    },
  },
  leadership: {
    presence: {
      allowEmpty: false,
      message: `Leadership can't be blank`,
    },
  },
};

export const profileValidator = (profileData, user) => {
  const result = validate(
    profileData,
    user.userType === `LEADER` ? PROFILE_CONSTRAINTS_LEADER : PROFILE_CONSTRAINTS,
    { format: 'detailed' },
  );
  if (result !== undefined) throw new IntegrityError(result[0]);
};
