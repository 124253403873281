import React from 'react';
import PropTypes from 'prop-types';
import TableSortable from '../../../components/table/TableSortable';
import { StatusBadge } from '../../../shared/components/badge/StatusBadge';
import { DropdownActions } from '../../../shared/components/inputs/DropdownActions';

export const TableUsersClf = ({ onEdit, onDelete, ...props }) => {
  const statusColumn = (val) => <StatusBadge active={val} />;

  const districtsColumn = (val) => (
    <div style={{ maxWidth: 200 }}>{val.map((district) => district.name).join(', ')}</div>
  );

  const schoolsColumn = (val) => (
    <div style={{ maxWidth: 200 }}>{val.map((school) => school.name).join(', ')}</div>
  );

  const actionsColumn = (val, user) => {
    const isCogniaUser = Boolean(user.userGUID);
    if (isCogniaUser) return null;
    return <DropdownActions onDelete={() => onDelete(user)} onEdit={() => onEdit(user)} />;
  };

  return (
    <TableSortable
      columns={[
        {
          title: 'Email',
          field: 'email',
        },
        {
          title: 'Name',
          field: 'firstName',
          render: (val, user) => `${val} ${user.lastName}`,
        },
        {
          title: 'Status',
          field: 'active',
          render: statusColumn,
        },
        {
          title: 'Districts',
          field: 'districts',
          render: districtsColumn,
        },
        {
          title: 'Schools',
          field: 'schools',
          render: schoolsColumn,
        },
        {
          title: 'Last Log In',
          field: 'lastLoggedIn',
          render: (value) => value || <span style={{ color: 'lightgrey' }}>N/A</span>,
        },
        {
          title: 'Actions',
          field: ' ',
          render: actionsColumn,
        },
      ]}
      {...props}
    />
  );
};

TableUsersClf.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
