import React from 'react';
import SidebarComponent from 'react-sidebar';

const Sidebar = ({ children, ...rest }) => {
  return (
    <SidebarComponent
      {...rest}
      styles={{
        sidebar: { width: 243 },
        content: { overflowY: 'hidden', overflowX: 'hidden' },
      }}
      sidebarClassName="bg-primary"
      transitions={true}
      defaultSidebarWidth={267}>
      {children}
    </SidebarComponent>
  );
};

export default Sidebar;
