import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Form, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import LoadingButton from '../../../components/button/LoadingButton';
import ModalHeader from '../../../components/modal/ModalHeader';
import FormGroup from 'reactstrap/lib/FormGroup';
import { RequiredInputLabel } from '../../../shared/components/typography/RequiredInputLabel';

export const initialMeesageData = { title: '', message: '' };

export const MessageFormModal = ({
  isOpen,
  onToggle,
  onSubmit,
  isLoading,
  initialData,
  editing,
}) => {
  const [mesasgeData, setMessageData] = useState({ ...initialMeesageData });

  const handleOnSubmit = (e) => {
    e.preventDefault();
    onSubmit(mesasgeData);
  };

  useEffect(() => {
    if (initialData) setMessageData({ ...initialData });
  }, [initialData]);

  const onChange = (values) => {
    const auxMesasgeData = { ...mesasgeData };
    Object.assign(auxMesasgeData, values);
    setMessageData(auxMesasgeData);
  };

  const isIncompleteData = !mesasgeData.title.trim() || !mesasgeData.message.trim();
  const disabledSubmit = isLoading || isIncompleteData;

  const titleModal = editing ? 'Edit message' : 'Add Message';

  return (
    <Modal isOpen={isOpen} onToggle={onToggle} backdrop="static" centered>
      <Form onSubmit={handleOnSubmit}>
        <ModalHeader title={titleModal} onToggle={onToggle} />
        <ModalBody>
          <FormGroup>
            <RequiredInputLabel>Title</RequiredInputLabel>
            <Input
              type="text"
              onChange={(e) => onChange({ title: e.target.value })}
              value={mesasgeData.title}
            />
          </FormGroup>
          <FormGroup>
            <RequiredInputLabel>Description</RequiredInputLabel>
            <Input
              type="textarea"
              rows="5"
              onChange={(e) => onChange({ message: e.target.value })}
              value={mesasgeData.message}
            />
          </FormGroup>
          <div className="d-flex justify-content-end mt-2">
            <LoadingButton
              loading={isLoading}
              className="d-block ml-auto btn-input-size"
              disabled={disabledSubmit}>
              {titleModal}
            </LoadingButton>
          </div>
        </ModalBody>
      </Form>
    </Modal>
  );
};

MessageFormModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  initialData: PropTypes.object.isRequired,
};
