import firebase from 'firebase';
import Flux from '@cobuildlab/flux-state';
import { log } from 'pure-logger';
import { authStore, USER_EVENT } from '../auth/auth-store';
import {
  AVG_QUESTIONS_TEACHER_EVENT,
  AVG_QUESTIONS_TEACHER_EVENT_CLF,
  AVG_QUESTIONS_TEACHER_EVENT_COA,
  MY_VOICE_ERROR_EVENT,
  MY_VOICE_EVENT,
  MY_VOICE_COA_EVENT,
  MY_VOICE_UPDATE_EVENT,
  SCHOOL_AVERAGE_EVENT,
  SCHOOL_INITIAL_AVERAGE_EVENT,
  MY_VOICE_INITIAL_EVENT,
} from './my-voice-store';
import { getCoaSchoolsUsers } from '../my-district/my-district-actions';
import { USER_TYPE_TEACHER } from '../../shared/userTypes';
import { isNumber, mapValues, mergeWith, reduce, sum, toPairs } from 'lodash';

/**
 * Update a Driver data for the Sign in Teacher.
 *
 * @param driverId
 * @param indicators
 * @returns {Promise<{driverId: *}>}
 */
export const updateMyVoiceAction = async (driverId, indicators) => {
  log('updateMyVoiceAction', driverId, indicators);
  const DB = firebase.firestore();
  const driversCollection = DB.collection('driversTeacher');
  const { email, schoolId } = authStore.getState(USER_EVENT);
  log('updateMyVoiceAction:user', email, schoolId);
  const driversRef = driversCollection.doc(email);
  // logMyVoice(user.email, driverId, indicators, new Date().getTime());
  const driver = { [driverId]: indicators };
  driversTeacherLog(email, driver, new Date().getTime(), schoolId);
  await driversRef.set(driver, { merge: true });
  Flux.dispatchEvent(MY_VOICE_UPDATE_EVENT, driver);
  return driver;
};

/**
 * Create a new Log entry on the driver teacher Log.
 *
 * @param email
 * @param driver
 * @param timestamp
 * @param schoolId
 */
const driversTeacherLog = async (email, driver, timestamp, schoolId) => {
  const DB = firebase.firestore();
  const driversLogsCollection = DB.collection('driversTeacherLog');
  await driversLogsCollection.add({ dateCompleted: timestamp, email, driver, schoolId });
};

//Update Driver Leader
export const updateMyVoiceActionLeader = async (driverId, indicators) => {
  const DB = firebase.firestore();
  const driversCollection = DB.collection('driversLeader');
  const user = authStore.getState(USER_EVENT);
  const driversRef = driversCollection.doc(user.email);
  const driver = { [driverId]: indicators };
  driversLeaderLog(user.email, driver, new Date().getTime());
  await driversRef.set(driver, { merge: true });
  Flux.dispatchEvent(MY_VOICE_UPDATE_EVENT, driver);
  return driver;
};

/**
 * Create a new Log entry on the driver leader Log.
 *
 * @param email
 * @param driver
 * @param timestamp
 * @param date
 */
const driversLeaderLog = async (email, driver, timestamp) => {
  const DB = firebase.firestore();
  const driversLogsCollection = DB.collection('driversLeaderLog');
  await driversLogsCollection.add({ dateCompleted: timestamp, email, driver });
};

/**
 * Update all Drivers data for the Sign in Teacher.
 *
 * @param drivers - The Drivers Object.
 * @returns {Promise<{driverId: *}>}
 */
export const bulkUpdateMyVoiceAction = async (drivers) => {
  log('bulkUpdateMyVoiceAction:', drivers);
  const DB = firebase.firestore();
  const driversCollection = DB.collection('driversTeacher');
  const user = authStore.getState(USER_EVENT);
  log('bulkUpdateMyVoiceAction:user', user);
  const driversRef = driversCollection.doc(user.email);
  await driversRef.set(drivers, { merge: true });
  Flux.dispatchEvent(MY_VOICE_UPDATE_EVENT, drivers);
  // // Update User as no need to update Profile
  const usersCollection = DB.collection('users');
  const userRef = usersCollection.doc(user.email);
  await userRef.set({ needsProfile: false }, { merge: true });
  Flux.dispatchEvent(USER_EVENT, { ...user, ...{ needsProfile: false } });
  return drivers;
};

/**
 * Adds a Record for the Initial Drivers for a Teacher.
 *
 * @param drivers
 * @returns {Promise<*>}
 */
export const bulkUpdateMyVoiceInitialAction = async (drivers) => {
  const DB = firebase.firestore();
  const driversCollection = DB.collection('initialDriversTeacher');
  const user = authStore.getState(USER_EVENT);
  const dateCompleted = new Date().getTime();
  await driversCollection.add({ dateCompleted, email: user.email, drivers });
  Flux.dispatchEvent(MY_VOICE_INITIAL_EVENT, drivers);
  return drivers;
};

/**
 * Update all Drivers data for the Sign in User Leader in driversLeader.
 *
 * @param drivers - The Drivers Object.
 */
export const bulkUpdateMyVoiceActionLeader = async (drivers) => {
  const DB = firebase.firestore();
  const driversCollection = DB.collection('driversLeader');
  const user = authStore.getState(USER_EVENT);
  const driversRef = driversCollection.doc(user.email);
  await driversRef.set(drivers, { merge: true });
  Flux.dispatchEvent(MY_VOICE_UPDATE_EVENT, drivers);
  // // Update User as no need to update Profile
  const usersCollection = DB.collection('users');
  const userRef = usersCollection.doc(user.email);
  await userRef.set({ needsProfile: false }, { merge: true });
  Flux.dispatchEvent(USER_EVENT, { ...user, ...{ needsProfile: false } });
  return drivers;
};

/**
 * Create all initial Drivers data for the Sign in User Leader in initialDriversLeader.
 *
 * @param drivers - The Drivers Object.
 */
export const bulkUpdateMyVoiceInitialActionLeader = async (drivers) => {
  const DB = firebase.firestore();
  const driversCollection = DB.collection('initialDriversLeader');
  const user = authStore.getState(USER_EVENT);
  const dateCompleted = new Date().getTime();
  await driversCollection.add({ dateCompleted, email: user.email, drivers });
  Flux.dispatchEvent(MY_VOICE_INITIAL_EVENT, drivers);
  return drivers;
};

/**
 * Update all Drivers data for the Sign in User Leader in driversLeader.
 *
 * @param drivers - The Drivers Object.
 */
export const bulkUpdateMyVoiceActionCoa = async (drivers) => {
  const DB = firebase.firestore();
  const driversCollection = DB.collection('driversCoa');
  const user = authStore.getState(USER_EVENT);
  const driversRef = driversCollection.doc(user.email);
  await driversRef.set(drivers, { merge: true });
  Flux.dispatchEvent(MY_VOICE_UPDATE_EVENT, drivers);
  // // Update User as no need to update Profile
  const usersCollection = DB.collection('users');
  const userRef = usersCollection.doc(user.email);
  await userRef.set({ needsProfile: false }, { merge: true });
  Flux.dispatchEvent(USER_EVENT, { ...user, ...{ needsProfile: false } });
  return drivers;
};

/**
 * Create all initial Drivers data for the Sign in User Leader in initialDriversLeader.
 *
 * @param drivers - The Drivers Object.
 */
export const bulkUpdateMyVoiceInitialActionCoa = async (drivers) => {
  const DB = firebase.firestore();
  const driversCollection = DB.collection('initialDriversCoa');
  const user = authStore.getState(USER_EVENT);
  const dateCompleted = new Date().getTime();
  await driversCollection.add({ dateCompleted, email: user.email, drivers });
  Flux.dispatchEvent(MY_VOICE_INITIAL_EVENT, drivers);
  return drivers;
};

/**
 * Create a new Log entry on the MyVoice Log.
 *
 * @param email
 * @param driverId
 * @param indicators
 * @param timestamp
 * @returns {Promise<void>}
 */
// const logMyVoice = async (email, driverId, indicators, timestamp) => {
//   const DB = firebase.firestore();
//   const driversLogsCollection = DB.collection('driversLogs');
//   const ref = driversLogsCollection.doc(`${email}:${timestamp}`);
//   await ref.set({ [driverId]: indicators });
// };

// const logInitialMyVoice = async (email, drivers, timestamp) => {
//   const DB = firebase.firestore();
//   const driversLogsCollection = DB.collection('initialDriversLogs');
//   await driversLogsCollection.add({ dateCompleted : timestamp, email, drivers } );
// };

/**
 * Return the School average.
 *
 * @returns {Promise<firebase.functions.HttpsCallableResult>}
 */
export const fetchMySchoolAverage = async () => {
  const getSchoolTeacherDriversAvg = firebase
    .functions()
    .httpsCallable('getSchoolTeacherDriversAvg');
  const result = await getSchoolTeacherDriversAvg();
  Flux.dispatchEvent(SCHOOL_AVERAGE_EVENT, result);
  return result;
};

/**
 * Return the School Initial average.
 *
 * @returns {Promise<firebase.functions.HttpsCallableResult>}
 */
export const getSchoolTeacherInitialDriversAvg = async () => {
  const getSchoolTeacherInitialDriversAvg = firebase
    .functions()
    .httpsCallable('getSchoolTeacherInitialDriversAvg');
  let result = {};
  try {
    result = await getSchoolTeacherInitialDriversAvg();
  } catch (e) {
    console.log(e);
    Flux.dispatchEvent(MY_VOICE_ERROR_EVENT, result);
    return result;
  }
  Flux.dispatchEvent(SCHOOL_INITIAL_AVERAGE_EVENT, result);
  return result;
};

/**
 * Fetches a User My Voice Data Teacher.
 *
 * @param email - The email of the User.
 * @returns {Promise<MyVoiceModel>}
 */
export const fetchMyVoiceAction = async (email = null) => {
  const DB = firebase.firestore();
  const driversCollection = DB.collection('driversTeacher');

  if (email === null) {
    const user = authStore.getState(USER_EVENT);
    email = user.email;
  }
  log('fetchMyVoiceAction:email', email);
  const driversRef = driversCollection.doc(email.toLowerCase());
  const query = await driversRef.get();
  log('fetchMyVoiceAction:query', query);
  let driversData = {};
  if (query.exists) {
    driversData = query.data();
  }
  Flux.dispatchEvent(MY_VOICE_EVENT, { driversData });
  return driversData;
};

/**
 * Fetches a User My Voice Data Leader.
 *
 * @param email - The email of the User.
 */
export const fetchMyVoiceActionLeader = async (email = null) => {
  const DB = firebase.firestore();
  const driversCollection = DB.collection('driversLeader');
  if (email === null) {
    const user = authStore.getState(USER_EVENT);
    email = user.email;
  }

  const driversRef = driversCollection.doc(email.toLowerCase());
  const query = await driversRef.get();
  let driversData = {};
  if (query.exists) {
    driversData = query.data();
  }
  Flux.dispatchEvent(MY_VOICE_EVENT, { driversData });
  return driversData;
};

/**
 * Fetches a User My Voice Data Leader.
 *
 * @param email - The email of the User.
 */
export const fetchMyVoiceActionCoa = async (email = null) => {
  const DB = firebase.firestore();
  const driversCollection = DB.collection('driversCoa');
  if (email === null) {
    const user = authStore.getState(USER_EVENT);
    email = user.email;
  }

  const driversRef = driversCollection.doc(email.toLowerCase());
  const query = await driversRef.get();
  let driversData = {};
  let driversAverages = {};
  if (query.exists) {
    driversData = query.data();
  }

  Object.keys(driversData).forEach((driver) => {
    let driverAvg = 0;
    const driverIndicators = driversData[driver];
    const indicatorsCount = Object.keys(driverIndicators).length;
    Object.keys(driverIndicators).forEach((indicator) => {
      const indicatorVal = driverIndicators[indicator];
      driverAvg += indicatorVal / indicatorsCount;
    });
    driversAverages[driver] = driverAvg.toFixed(2);
  });

  console.log(`driversAverage`, driversAverages);

  Flux.dispatchEvent(MY_VOICE_COA_EVENT, { driversData, driversAverages });
  return driversData;
};

/**
 * Update indicators from a data object.
 *
 * @param indicators
 * @param data
 * @returns {Array}
 */
export const updateIndicators = (indicators, data) => {
  const newIndicators = [];
  indicators.forEach((indicator) => {
    if (data !== undefined) {
      const value = data[indicator.id];
      if (value !== undefined) indicator.data = value;
    }
    newIndicators.push(indicator);
  });
  return newIndicators;
};

/**
 * Updates the Indicators List with the new Value.
 *
 * @param indicators
 * @param indicatorId
 * @param indicatorValue
 * @returns {Array}
 */
export const updateIndicator = (indicators, indicatorId, indicatorValue) => {
  const newIndicators = [];
  indicators.forEach((indicator) => {
    if (indicator.id === indicatorId) indicator.data = indicatorValue;
    newIndicators.push(indicator);
  });
  return newIndicators;
};

/**
 * Return the School Questions Teacher Average.
 */
export const getSchoolAverageQuestionsTeacher = async () => {
  let result = {};
  try {
    result = await averageQuestionDetailsTeacher();
  } catch (e) {
    console.log(e);
    Flux.dispatchEvent(MY_VOICE_ERROR_EVENT, result);
    return result;
  }
  Flux.dispatchEvent(AVG_QUESTIONS_TEACHER_EVENT, result);
  return result;
};

export const averageQuestionDetailsTeacher = async () => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');

  const { schoolId } = authStore.getState(USER_EVENT);

  if (!schoolId) return {};

  const usersRef = await usersCollection
    .where('schoolId', '==', schoolId)
    .where('userType', '==', USER_TYPE_TEACHER)
    .where('active', '==', true)
    .get();

  const users = usersRef.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

  return await getDriversAverage(users);
};

/**
 * Get drivers for a teacher.
 *
 * @param email - Teacher Email.
 * @returns {Promise<null|object>}
 */
async function getTeachersDrivers(email) {
  if (!email) {
    return null;
  }

  const DB = firebase.firestore();
  const driversCollection = DB.collection('driversTeacher');

  const queryRef = await driversCollection.doc(email).get();
  const driversData = queryRef.data();

  if (!driversData) {
    return null;
  }

  return driversData;
}

/**
 * Get Initial drivers for a teacher.
 *
 * @param email - Teacher Email.
 * @returns {Promise<null|object>}
 */
async function getTeachersInitialDrivers(email) {
  if (!email) {
    return null;
  }

  const DB = firebase.firestore();
  const driversCollection = DB.collection('initialDriversTeacher');

  const queryRef = await driversCollection
    .where('email', '==', email)
    .orderBy('dateCompleted', 'desc')
    .limit(1)
    .get();

  if (!queryRef.size) {
    return null;
  }
  const driversData = queryRef.docs.lastItem.data().drivers;
  return driversData;
}

/**
 * Return the School Questions Teacher Average.
 *
 * @param schoolId
 */
export const getSchoolAverageQuestionsTeacherCoa = async (schoolId) => {
  let result = {};
  try {
    result = await averageQuestionDetailsTeacherCoa(schoolId);
  } catch (e) {
    console.log(e);
    Flux.dispatchEvent(MY_VOICE_ERROR_EVENT, result);
    return result;
  }

  Flux.dispatchEvent(AVG_QUESTIONS_TEACHER_EVENT_COA, result);
  return result;
};

/**
 * Return the Schools Questions Teacher Average.
 *
 * @param {Array<string>} schoolIds - School Ids.
 */
export const getSchoolsAverageQuestionsTeacherClf = async (schoolIds) => {
  const call = firebase.functions().httpsCallable('getSchoolQuestionsAvgClf');

  let result = {};
  try {
    result = await call({ schoolIds });
  } catch (err) {
    console.log(err);
    Flux.dispatchEvent(MY_VOICE_ERROR_EVENT, result);
    return result;
  }

  Flux.dispatchEvent(AVG_QUESTIONS_TEACHER_EVENT_CLF, result.data);
  return result;
};

export const averageQuestionDetailsTeacherCoa = async (schoolId) => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');

  let usersRef;
  if (schoolId) {
    usersRef = await usersCollection
      .where('schoolId', '==', schoolId)
      .where('active', '==', true)
      .get();
  } else {
    usersRef = await getCoaSchoolsUsers();
  }

  const users = usersRef.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

  return await getDriversAverage(users);
};

/**
 * @param users
 */
async function getDriversAverage(users) {
  // Filter By Teachers
  const teachers = users.filter((user) => {
    const isTeacher = user.userType === USER_TYPE_TEACHER;
    return isTeacher;
  });

  const teachersFilledDrivers = teachers.filter((teacher) => {
    const hasFilledDrivers = !teacher.needsProfile;
    return hasFilledDrivers;
  });

  // Drivers
  const promises = teachersFilledDrivers.map((teacher) => getTeachersDrivers(teacher.email));
  const usersDriversResult = await Promise.all(promises);

  const teachersDrivers = usersDriversResult.filter((result) => result);

  let driversIndicatorsCount = {};
  let driversAverage = {};

  if (teachersDrivers.length) {
    ({ driversIndicatorsCount, driversAverage } = getDriversAverages(teachersDrivers));
  }

  // Initial Drivers
  const initialDriversPromises = teachersFilledDrivers.map((teacher) =>
    getTeachersInitialDrivers(teacher.email),
  );
  const usersInitialDriversResult = await Promise.all(initialDriversPromises);

  const teachersInitialDrivers = usersInitialDriversResult.filter((result) => result);

  let initialDriversAverage = {};

  if (teachersInitialDrivers.length) {
    ({ driversAverage: initialDriversAverage } = getDriversAverages(teachersInitialDrivers));
  }

  const totalTeachers = teachers.length;
  const numberTeachersResponded = teachersDrivers.length;

  return {
    driversAverage,
    driversIndicatorsCount,
    initialDriversAverage,
    totalTeachers,
    numberTeachersResponded,
  };
}

/**
 * @param drivers
 */
function getDriversAverages(drivers) {
  const teachersDriversContainers = drivers.map((drivers) => {
    return mapValues(drivers, (driver) => {
      return mapValues(driver, (indicator) => {
        return { [indicator]: 1 };
      });
    });
  });

  const driversIndicatorsCount = reduce(teachersDriversContainers, (result, teacherDrivers) => {
    if (!result) return teacherDrivers;
    return mergeWith(result, teacherDrivers, (resultValue, teacherDriversValue) => {
      if (isNumber(resultValue)) {
        return resultValue + teacherDriversValue;
      }
    });
  });

  const driversAverage = mapValues(driversIndicatorsCount, (indicators) => {
    const indicatorsValues = Object.values(indicators).map((indicatorValues) => {
      let total = 0;
      const indicatorSum = sum(
        toPairs(indicatorValues).map(([value, count]) => {
          total += count;
          return parseInt(value) * count;
        }),
      );
      return indicatorSum / total;
    });
    const indicatorsSum = sum(indicatorsValues);
    return (indicatorsSum / indicatorsValues.length).toFixed(2);
  });

  return {
    driversIndicatorsCount,
    driversAverage,
  };
}
