import { Row } from 'reactstrap';
import React from 'react';
import { RESOURCE_INDICATORS, RESOURCE_MESSAGE, RESOURCE_QUESTIONS } from '../../my-voice-models';
import TitleQuestions from './TitleQuestions';
import PropTypes from 'prop-types';
import IndicatorDetailCards from './indicatorsCards/IndicatorDetailCards';
import Icon from '../../../../shared/assets/icons/resource-priorities.svg';
import { getSeries } from '../../../../shared/driversGraphs';

/**
 * My Voice Leader Questions resourcePriorities View.
 *
 * @param questionsTeacher
 * @param numberTeachers
 * @returns {*}
 */
const DriverResourceLeaderView = ({
  questionsTeacher,
  numberTeachers,
  numberTeachersResponded,
  name,
  type,
  height,
}) => {
  const seriesGeneral = getSeries(RESOURCE_QUESTIONS, questionsTeacher['resourcePriorities']);

  const content = (
    <IndicatorDetailCards
      seriesGeneral={seriesGeneral}
      questions={RESOURCE_INDICATORS}
      type={type}
      height={height}
    />
  );
  return (
    <div>
      <TitleQuestions
        numberTeachers={numberTeachers}
        numberTeachersResponded={numberTeachersResponded}
        message={RESOURCE_MESSAGE}
        icon={Icon}
        name={name}
      />
      <Row className="p-4 ">{content}</Row>
    </div>
  );
};

DriverResourceLeaderView.propTypes = {
  questionsTeacher: PropTypes.object.isRequired,
  numberTeachers: PropTypes.number.isRequired,
  numberTeachersResponded: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
};

export default DriverResourceLeaderView;
