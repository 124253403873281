import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const RenderInMap = (props) => {
  const { map, google, children } = props;
  let { position = null } = props;

  const [isRendered, setRendered] = useState(false);

  if (!React.Children.count(children)) {
    throw new Error('RenderInMap cannot be and empty tag.');
  }

  useEffect(() => {
    if (map && !isRendered) {
      setRendered(true);
      if (position === null) {
        position = google.maps.ControlPosition.TOP_CENTER;
      }

      const elements = React.Children.map(children, (element) => {
        if (!element) return;
        return React.cloneElement(element, { map, google });
      });

      const container = document.createElement('div');
      container.index = 1;

      ReactDOM.render(elements, container);

      map.controls[position].push(container);
    }
  }, [map]);

  return null;
};

export default RenderInMap;
