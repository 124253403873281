import React, { useEffect, useState } from 'react';
import { useSubscription } from '@cobuildlab/react-flux-state';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import { NavigationBar } from '../../shared/layouts/NavigationBar';
import { MY_REPORTS_GET_REPORTS, myReportsStore } from './my-reports-store';
import { getUserReports } from './my-reports-actions';
import ReportList from './components/ReportList';
import BuildMyReports from './components/BuildMyReports';
import Accordion from '../../components/Accordeon';
import { ClipLoader } from '../../shared/components/progress/ClipLoader';

/**
 * My Reports View.
 *
 * @returns {*} - Reports main view.
 * @class
 */
function MyReportsView() {
  const [reports, setReports] = useState([]);

  useSubscription(myReportsStore, MY_REPORTS_GET_REPORTS, setReports);

  useEffect(() => {
    getUserReports();
  }, []);

  const savedReportsContent = reports ? (
    <ReportList reports={reports} />
  ) : (
    <div className="d-flex justify-content-center" style={{ padding: 20 }}>
      <ClipLoader />
    </div>
  );

  return (
    <NavigationBar currentRoute={'/my-reports'}>
      <Container fluid className="mt-4">
        <Accordion initialOpened={false} title={'Saved Reports'}>
          {savedReportsContent}
        </Accordion>
        <Accordion title={'Build My Report'}>
          <BuildMyReports />
        </Accordion>
      </Container>
    </NavigationBar>
  );
}

export default withRouter(MyReportsView);
