import React from 'react';
import ManageUserModal from './ManageUserModal';
import PropTypes from 'prop-types';

/**
 * Creates a modal to edit a user.
 *
 * @param isOpen - Sets if the modal is opened.
 * @param schools - Schools to list in dropdown.
 * @param onSubmit - Function triggered to edit a user.
 * @param onToggle - Function to toggle modal.
 * @param user - User object to be edit.
 * @param admin - Add admin features to modal.
 * @returns {*}
 * @class
 */
const EditUserModal = ({
  isOpen,
  loading,
  schools,
  districts,
  onSubmit,
  onToggle,
  user,
  admin = false,
  CLF = false,
}) => {
  return (
    <ManageUserModal
      edit
      CLF={CLF}
      loading={loading}
      schools={schools}
      districts={districts}
      onSubmit={onSubmit}
      isOpen={isOpen}
      onToggle={onToggle}
      user={user}
      admin={admin}
    />
  );
};

EditUserModal.defaultProps = {
  admin: false,
  CLF: false,
  loading: false,
};

EditUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  schools: PropTypes.array.isRequired,
  districts: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  admin: PropTypes.bool,
  CLF: PropTypes.bool,
  loading: PropTypes.bool,
};

export default EditUserModal;
