import React from 'react';
import PropTypes from 'prop-types';
import { NavItem, Button, Collapse, ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ic_menu } from 'react-icons-kit/md';
import { Icon } from 'react-icons-kit';

const ToggleMenu = ({ menuUrls, onLogout, currentRoute }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <NavItem className="toggle" onClick={() => setIsOpen(!isOpen)} style={{ marginBottom: '1rem' }}>
      <>
        <Icon icon={ic_menu} className="iconToggleMenu" />
        <Collapse isOpen={isOpen} className="menuToggle">
          <ListGroup flush>
            {menuUrls.map((url, index) => {
              return url.href === null ? (
                <Button
                  key={index}
                  active={false}
                  className="list-group-item text-left"
                  onClick={onLogout}>
                  {url.icon} {url.name}
                </Button>
              ) : (
                <Link to={url.href} key={index}>
                  <ListGroupItem disabled={url.disabled} active={url.href === currentRoute}>
                    {url.icon} {url.name}
                  </ListGroupItem>
                </Link>
              );
            })}
          </ListGroup>
        </Collapse>
      </>
    </NavItem>
  );
};

ToggleMenu.propTypes = {
  menuUrls: PropTypes.array.isRequired,
  currentRoute: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default ToggleMenu;
