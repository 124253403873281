import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a fetch on the Superintendent Message.
 *
 * @type {string}
 */
export const HOME_EVENT = 'onHomeEvent';
export const HOME_ERROR = 'onHomeErrorEvent';

class HomeStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(HOME_EVENT);
    this.addEvent(HOME_ERROR);
  }
}

const homeStore = new HomeStore();

export { homeStore };
