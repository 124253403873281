import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';
import { SOLUTIONS_STAGES, DRIVERS_AND_DOMAINS } from '../solutions-models';
import { theme } from '../../../shared/theme';

export const Card = styled.div`
  width: 340px;
  max-width: 350px;
  height: ${(props) => (props.small ? '100px' : '300px')};
  max-height: 300px;
  cursor: pointer;
  background-color: #ffff !important;
  color: ${theme.palette.primary};
  border: 1px solid ${(props) => (props.selected ? theme.palette.primaryLight : 'transparent')};
  border-radius: 0.8rem !important;
  transition: box-shadow ease 0.5s;
  box-shadow: 1px 1px 5px 1px #0000002e;
  margin: 15px;
  opacity: ${(props) => (props.status === 'completed' ? '0.3' : '1')}
  &:hover {
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.24);
    border-color: ${theme.palette.primaryLight};
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2 !important;
  padding: 0.75rem 1.25rem;
  height: 60px;
  position: relative;
`;

export const CardBody = styled.div`
  height: calc(100% - 60px);
  overflow: auto;
  padding: 1.25rem;
  padding-bottom: 0.7rem;
`;

export const CardIndicator = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.active ? theme.palette.primary : theme.palette.primaryLight};
  color: #fff;
  padding: 1
  border-radius: 50%;
  margin-right: .5rem;
  width: 22px;
  height: 22px;

`;

export const CardSubtitle = styled.span`
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

export const IndicatorBadge = styled.div`
  font-size: 12px;
  background-color: ${theme.palette.primary};
  color: #fff;
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 10px;
`;

const RedIndicator = styled.span`
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: red;
  position: absolute;
  top: 10px;
  left: 10px;
`;

const DriverDomainIconContainer = styled.span`
  position: relative;
  display: inline-block;
  margin-left: auto;
`;

const needsIdentifiedMaxLength = 100;

const CustomSolutionCard = ({ solution, selected, onClick, isNew, showSchool }) => {
  const driverDomainIconRef = useRef();
  const solutionStage = SOLUTIONS_STAGES.find((s) => s.id === solution.stage);
  const solutionDriverDomain =
    DRIVERS_AND_DOMAINS.find((d) => d.id === solution.driverDomain) || {};

  const driverDomainIcon = solutionDriverDomain.icon ? (
    <DriverDomainIconContainer>
      <img
        src={solutionDriverDomain.icon}
        alt="icon"
        height={32}
        width={35}
        ref={driverDomainIconRef}
      />
      <UncontrolledTooltip placement="top" target={driverDomainIconRef}>
        {solutionDriverDomain.name}
      </UncontrolledTooltip>
    </DriverDomainIconContainer>
  ) : null;

  const stagesIndicators = SOLUTIONS_STAGES.map((stg) => (
    <CardIndicator key={stg.id} active={stg.id === solutionStage.id} {...theme}>
      {stg.number}
    </CardIndicator>
  ));

  const needsIdentified =
    solution.needsIdentified && solution.needsIdentified.length > needsIdentifiedMaxLength
      ? solution.needsIdentified.substring(0, needsIdentifiedMaxLength) + '...'
      : solution.needsIdentified;

  const headerRedIndicator = isNew ? <RedIndicator /> : null;

  const solutionSchool = showSchool ? (
    <div className="mb-2">
      <CardSubtitle>School</CardSubtitle>
      <p className="pl-2 mb-0">{solution.schoolId}</p>
    </div>
  ) : null;

  return (
    <Card {...theme} selected={selected} status={solution.status} onClick={onClick}>
      <CardHeader>
        {headerRedIndicator}
        <h6>{solution.name}</h6>
        {driverDomainIcon}
      </CardHeader>

      <CardBody>
        <div className="mb-2">
          {stagesIndicators}
          {solutionStage ? solutionStage.name : null}
        </div>
        {solutionSchool}
        <CardSubtitle>Existing Needs Identified</CardSubtitle>
        <p className="pl-2 mb-0">{needsIdentified}</p>
      </CardBody>
    </Card>
  );
};

CustomSolutionCard.defaultProps = {
  selected: false,
  isNew: false,
  showSchool: false,
};

CustomSolutionCard.propTypes = {
  solution: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
  showSchool: PropTypes.bool,
};

export default CustomSolutionCard;
