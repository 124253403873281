import React from 'react';
import styled from 'styled-components';

const Badge = styled.span`
  border-radius: 15px;
  padding: 0px 10px;
  background-color: ${(props) => props.color};
  font-weight: bold;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  display: inline-block;
  align-items: center;
`;

const badgeColors = {
  success: '#b8e0bc',
  danger: '#ecbcbc',
  warning: '#f4f3ae',
  dafault: '#ececec',
};

const CustomBadge = ({ color, children, ...rest }) => {
  const badgeColor = badgeColors[color] || badgeColors.dafault;

  return (
    <Badge {...rest} color={badgeColor}>
      {children}
    </Badge>
  );
};

export default CustomBadge;
