import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import TopBar from './TopBar';

const mql = window.matchMedia(`(min-width: 800px)`);

const MainLayout = ({ sidebar, sidebarProps, topBarProps, children }) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [sidebarDocked, setSidebarDocked] = useState(mql.matches);

  useEffect(() => {
    const onChangeMediaQuery = () => {
      setSidebarIsOpen(false);
      setSidebarDocked(mql.matches);
    };
    mql.addListener(onChangeMediaQuery);
    return () => {
      mql.removeListener(onChangeMediaQuery);
    };
  }, []);

  const sidebarComponentProps = sidebarProps || {};
  const topBarComponentProps = topBarProps || {};

  return (
    <Sidebar
      {...sidebarComponentProps}
      open={sidebarIsOpen}
      docked={sidebarDocked}
      onSetOpen={(isOpen) => setSidebarIsOpen(isOpen)}
      sidebar={sidebar}>
      <div className="d-flex flex-column h-100vh">
        <div className="w-100">
          <TopBar {...topBarComponentProps} />
        </div>
        <div className="overflow-auto flex-grow-1 w-100">{children}</div>
      </div>
    </Sidebar>
  );
};

export default MainLayout;
