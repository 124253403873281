import React from 'react';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import mapStyles from './mapStyles';
import { getIconMarker } from '../my-distict-model';
import View from '@cobuildlab/react-flux-state';
import { getSchoolsByDistrict, getTeacherForSchool } from '../my-district-actions';
import { customToast } from '../../../shared/toast';
import {
  MY_DISTRICT_ERROR_EVENT,
  myDistrictStore,
  SCHOOLS_BY_DISTRCIT_EVENT,
  TEACHERS_BY_SCHOOLS_EVENT,
} from '../my-district-store';
import { default as firebaseConfig } from '../../../config/firebase.json';
import RenderInMap from '../../../components/google-maps/RenderInMap';
import { Button } from 'reactstrap';
import Icon from 'react-icons-kit';
import { ic_location_searching as locationIcon } from 'react-icons-kit/md/ic_location_searching';
import SchoolWindow from './SchoolWindow';

class MapSchool extends View {
  state = {
    schools: [],
    showingInfoWindow: false,
    selectedMarker: {},
    selectedSchool: {},
  };

  googleMap = null;

  componentDidMount() {
    const { maps } = this.props.google;
    this.bounds = new maps.LatLngBounds();

    this.subscribe(myDistrictStore, SCHOOLS_BY_DISTRCIT_EVENT, (resp) => {
      const schools = resp.data;
      const filteredSchools = schools.filter((school) => {
        if (school.active === false) return false;
        return true;
      });
      getTeacherForSchool(filteredSchools);
    });
    this.subscribe(myDistrictStore, TEACHERS_BY_SCHOOLS_EVENT, (schools) => {
      const { maps } = this.props.google;
      this.bounds = new maps.LatLngBounds();

      schools.forEach((school) => {
        if (school.latLon) {
          const { _latitude: lat, _longitude: lng } = school.latLon;
          this.bounds.extend({ lat, lng });
        }
      });

      this.setState({ schools });
    });
    this.subscribe(myDistrictStore, MY_DISTRICT_ERROR_EVENT, () => {
      customToast.error('Error From Data Base');
    });
    getSchoolsByDistrict();
  }

  componentDidUpdate() {
    this.controlMapZoom();
  }

  onMarkerClick = (school, marker) => {
    this.setState({
      selectedSchool: school,
      selectedMarker: marker,
      showingInfoWindow: true,
    });
  };

  onMapClicked = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        selectedMarker: {},
      });
    }
  };

  summaryClick = (school) => {
    this.props.selectTarget(school);
    this.onMapClicked();
  };

  getSchoolsMarkers() {
    const { maps } = this.props.google;
    const { schools } = this.state;

    const scaledSize = new maps.Size(35, 60);

    return schools.map((school, key) => {
      if (school.latLon && school.latLon._latitude && school.latLon._longitude) {
        const { latLon, schoolType = 'OTHER' } = school;
        const { _latitude: lat, _longitude: lng } = latLon;

        return (
          <Marker
            key={key}
            onClick={(props, marker) => this.onMarkerClick(school, marker)}
            school={school}
            position={{ lat, lng }}
            icon={{
              url: getIconMarker(schoolType),
              scaledSize,
            }}
          />
        );
      }
      return null;
    });
  }

  controlMapZoom = () => {
    const maxZoom = 17;
    if (this.googleMap) {
      const zoom = this.googleMap.getZoom();
      if (zoom > maxZoom) {
        this.googleMap.setZoom(maxZoom);
      }
    }
  };

  handleRecenter = () => {
    this.googleMap.fitBounds(this.bounds);
  };

  handleMapReady = (mapProps, map) => {
    this.googleMap = map;
  };

  render() {
    const { google } = this.props;
    const { selectedSchool, selectedMarker, showingInfoWindow } = this.state;

    const markers = this.getSchoolsMarkers();

    return (
      <Map
        streetViewControl={false}
        onReady={this.handleMapReady}
        google={google}
        style={{ width: '90%', height: '75%' }}
        styles={mapStyles}
        bounds={this.bounds}
        onClick={this.onMapClicked}>
        <RenderInMap position={google.maps.ControlPosition.TOP_RIGHT}>
          <div style={{ marginTop: 10, marginRight: 10 }}>
            <Button onClick={() => this.handleRecenter()}>
              <Icon icon={locationIcon} size="20px" />
            </Button>
          </div>
        </RenderInMap>

        {markers}

        <SchoolWindow
          school={selectedSchool}
          marker={selectedMarker}
          visible={showingInfoWindow}
          onSummary={() => this.summaryClick(selectedSchool)}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: firebaseConfig.apiKey,
})(MapSchool);
