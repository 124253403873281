import React from 'react';
import { Row, Col, Media, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import IndicatorStepper from './IndicatorStepper';
import H2Blue from '../../../components/H2Blue';
import DriverIcon from '../../../components/DriverIcon';

export const DriverDetailsForm = ({
  icon,
  title,
  message,
  indicators,
  onChangeIndicator,
  submitText,
  onSubmit,
}) => (
  <Row className="driver">
    <Col md={12} className="driver-sub-bar-col">
      <Media className="mb-2">
        {icon ? (
          <Media className="mr-3" left href="#">
            <DriverIcon icon={icon} />
          </Media>
        ) : null}
        <Media body>
          <H2Blue>{title}</H2Blue>
        </Media>
      </Media>
      <p className="mb-3">{message}</p>
      <Media className="mb-3">
        <Media left href="#" />
        <Media body className="pr-5" />
        <Media right className="updateButtonTwo">
          <Button color="danger" block onClick={onSubmit}>
            {submitText}
          </Button>
        </Media>
      </Media>
      <React.Fragment>
        {indicators.map(({ name, id, data }, index) => {
          return (
            <Col className="mb-5" md={12} key={index}>
              <IndicatorStepper
                title={name}
                active={data === 0 ? null : data}
                onClick={(stepNumber) => onChangeIndicator(id, stepNumber)}
                index={index}
              />
            </Col>
          );
        })}
      </React.Fragment>
      <Media className="mb-3">
        <Media left href="#" />
        <Media body className={'pr-5'} />
        <Media right className="updateButtonTwo">
          <Button color="danger" block onClick={onSubmit}>
            {submitText}
          </Button>
        </Media>
      </Media>
    </Col>
  </Row>
);

DriverDetailsForm.defaultProps = {
  title: 'Driver title',
  message: '',
  submitText: 'Save',
  icon: null,
};

DriverDetailsForm.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  indicators: PropTypes.func.isRequired,
  onChangeIndicator: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};
