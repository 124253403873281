import { useEffect } from 'react';

export const useDebounce = (callback, dependence, delay = 1500) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      callback();
    }, delay);
    return () => {
      clearTimeout(timer);
    };
  }, [dependence]);
};
