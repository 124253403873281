import React from 'react';
import { Icon } from 'react-icons-kit';
// import { building } from 'react-icons-kit/fa/building';
import {
  // ic_home,
  // ic_keyboard_voice,
  // ic_person,
  ic_sd_storage,
  // ic_exit_to_app,
  ic_lightbulb_outline,
} from 'react-icons-kit/md';
import { document } from 'react-icons-kit/iconic/document';
import { gear as ic_gear, users as ic_users, dashboard as ic_dashboard } from 'react-icons-kit/fa/';
import iconHome from '../shared/assets/icons/home.svg';
import iconMyVoice from '../shared/assets/icons/microphone.svg';
import iconMySchool from '../shared/assets/icons/school.svg';
import iconMyProfile from '../shared/assets/icons/single.svg';
import iconLogout from '../shared/assets/icons/logout.svg';
// import iconDomains from '../shared/assets/images/domain-icon.svg';

const HomeIcon = () => <img src={iconHome} alt="home icon" width="18px" height="18px" />;
const MyVoiceIcon = () => <img src={iconMyVoice} alt="my voice icon" width="18px" height="18px" />;
const MySchoolIcon = () => (
  <img src={iconMySchool} alt="my school icon" width="18px" height="18px" />
);
const MyProfileIcon = () => (
  <img src={iconMyProfile} alt="my profile icon" width="18" height="18px" />
);
const LogoutIcon = () => <img src={iconLogout} alt="logout icon" width="18" height="18px" />;

export const urls = [
  {
    href: '/home',
    name: 'Home',
    disabled: false,
    icon: <HomeIcon />,
  },
  {
    href: '/my-voice',
    name: 'MyVoice',
    disabled: false,
    icon: <MyVoiceIcon />,
    redIndicator: false,
  },
  {
    href: '/my-school',
    name: 'MySchool',
    disabled: false,
    icon: <MySchoolIcon />,
  },
  // {
  //   href: '/covid-19-domains',
  //   name: 'COVID-19',
  //   title: 'COVID-19 Support Domains',
  //   disabled: false,
  //   icon: <img src={iconDomains} alt="cv-19-domain" width="16px" height="24px" />,
  // },
  {
    href: '/solutions',
    name: 'Solutions',
    disabled: false,
    icon: <Icon size={18} icon={ic_lightbulb_outline} />,
  },
];

export const urlsLeader = [
  {
    href: '/home',
    name: 'Home',
    disabled: false,
    icon: <HomeIcon />,
  },
  {
    href: '/my-school',
    name: 'MySchool',
    disabled: false,
    icon: <MySchoolIcon />,
  },
  {
    href: '/solutions',
    name: 'Solutions',
    disabled: false,
    icon: <Icon size={18} icon={ic_lightbulb_outline} />,
  },
  {
    href: '/my-dashboard',
    name: 'MyDashboard',
    disabled: false,
    icon: <Icon size={18} icon={ic_dashboard} />,
  },
  // {
  //   href: '/covid-19-domains',
  //   name: 'COVID-19',
  //   title: 'COVID-19 Support Domains',
  //   disabled: false,
  //   icon: <img src={iconDomains} alt="cv-19-domain" width="16px" height="24px" />,
  // },
];

export const urlsCoa = [
  {
    href: '/my-district',
    name: 'MyDistrict',
    disabled: false,
    icon: <HomeIcon />,
  },
  {
    href: '/solutions',
    name: 'Solutions',
    disabled: false,
    icon: <Icon size={18} icon={ic_lightbulb_outline} />,
  },
  {
    href: '/my-dashboard',
    name: 'MyDashboard',
    disabled: false,
    icon: <Icon icon={ic_dashboard} />,
  },
  // {
  //   href: '/covid-19-domains',
  //   name: 'COVID-19',
  //   title: 'COVID-19 Support Domains',
  //   disabled: false,
  //   icon: <img src={iconDomains} alt="cv-19-domain" width="16px" height="24px" />,
  // },
  {
    href: '/my-reports',
    name: 'MyReports',
    disabled: false,
    icon: <Icon size={18} icon={document} />,
  },
];

export const urlsClf = [
  {
    href: '/my-district',
    name: 'MyDistricts',
    disabled: false,
    icon: <HomeIcon />,
  },
  {
    href: '/solutions',
    name: 'Solutions',
    disabled: false,
    icon: <Icon size={18} icon={ic_lightbulb_outline} />,
  },
  {
    href: '/my-dashboard',
    name: 'MyDashboard',
    disabled: false,
    icon: <Icon size={18} icon={ic_dashboard} />,
  },
  {
    href: '/my-reports',
    name: 'MyReports',
    disabled: false,
    icon: <Icon size={18} icon={document} />,
  },
];

export const adminUrls = [
  {
    href: '/data-import',
    name: 'Import Data',
    disabled: false,
    icon: <Icon size={18} icon={ic_sd_storage} />,
    render: true,
  },
  {
    href: '/super-admin',
    name: 'Super Admin',
    disabled: false,
    icon: <Icon size={18} icon={ic_gear} />,
  },
];

export const urlsRosterManager = [
  {
    href: '/roster-manager',
    name: 'Roster Manager',
    disabled: false,
    icon: <Icon size={18} icon={ic_users} />,
  },
];

export const myProfileUrl = {
  href: '/my-profile',
  name: 'MyProfile',
  disabled: false,
  icon: <MyProfileIcon />,
};

export const logoutUrl = {
  href: null,
  name: 'Log Out',
  disabled: false,
  icon: <LogoutIcon />,
};
