import React from 'react';
import PropTypes from 'prop-types';
import TableSortable from '../../../components/table/TableSortable';

const schoolsColumn = (val) => (
  <div style={{ maxWidth: 250 }}>{val.map((school) => school.name).join(', ')}</div>
);

export const DashboardClfTable = ({ districts, schools, teachers, solutions }) => (
  <TableSortable
    onLoadMore={() => {}}
    columns={[
      {
        title: 'Districts',
        field: 'name',
      },
      {
        title: 'Schools',
        field: 'schools',
        render: schoolsColumn,
      },
      {
        title: 'Number of Teachers',
        field: 'teachers',
        render: (val) => val.length,
      },
      {
        title: 'Number of Solutions',
        field: 'solutions',
        render: (val) => val.length,
      },
    ]}
    rows={districts.map((district) => {
      const districtTeachers = [];

      const districtSchools = schools
        .filter((school) => school.regionDistrictId === district.id)
        .map((school) => {
          const schoolTeachers = teachers.filter((teacher) => teacher.schoolId === school.id);
          districtTeachers.push(...schoolTeachers);
          return school;
        });

      const districtSolutions = solutions.filter((solution) => solution.districtId === district.id);

      return {
        ...district,
        schools: districtSchools,
        teachers: districtTeachers,
        solutions: districtSolutions,
      };
    })}
  />
);

DashboardClfTable.propTypes = {
  districts: PropTypes.array.isRequired,
  schools: PropTypes.array.isRequired,
  teachers: PropTypes.array.isRequired,
  solutions: PropTypes.array.isRequired,
};
