import * as moment from 'moment';
import { getMonth } from '../../shared/date';

const generatePreviousMonths = (length) => {
  const result = [];
  for (let i = 0, j = length; i < j; i++) {
    const _date = moment().subtract(i + 1, 'months');
    result.push({
      month: _date.month(),
      year: _date.year(),
    });
  }
  return result;
};

const schoolDataFind = (schoolAvgData, month, year) => {
  for (let i = 0, j = schoolAvgData.length; i < j; i++) {
    const monthData = schoolAvgData[i];
    if (monthData.month === month && monthData.year === year) return monthData.drivers;
  }
  return null;
};
/**
 * Generate the Data for the Graph Trend.
 *
 * @param schoolAvg - The data from the monthly Avg for the school:
 * Example:
 *
 * [
 *  {
      drivers: {
                advancement: "3.00"
                clearUnifiedDirection: "3.00"
                 collaboration: "2.75"
                 empowerment: "2.70"
                 feedbackReflection: "3.25"
                 instructionalAutonomy: "3.00"
                 professionalEngagement: "3.00"
                 resourcePriorities: "3.00"
                 senseOfBelonging: "2.90"
                 supportCare: "2.90"
                 },
      month: 0,
      schoolId: "kademHS",
      year: 2020
    }
 ].
 * @param length - The number of values to generate.
 */
export const generateMonthsData = (schoolAvg, length = 12) => {
  // console.log(`DEBUG:generateMonthsData:schoolAvg:`, schoolAvg);
  const months = generatePreviousMonths(length);
  const keys = [];
  const resultMap = {};
  for (let i = 0, j = length; i < j; i++) {
    const { month, year } = months[i];
    const key = getMonth(month) + " '" + String(year).substr(-2);
    resultMap[key] = schoolDataFind(schoolAvg, month, year);
    keys.push(key);
  }
  return [keys.reverse(), resultMap];
};
