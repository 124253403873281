import React from 'react';
import { Input } from 'reactstrap';
import Icon from 'react-icons-kit';
import styled from 'styled-components';

const InputContainer = styled.div`
  position: relative;
  & input {
    padding-left: 35px;
  }
  & i {
    position: absolute;
    top: 5px;
    left: 10px;
  }
`;

export const InputWithIcon = ({ icon, ...rest }) => (
  <InputContainer>
    <Input {...rest} />
    <Icon icon={icon} size="20px" />
  </InputContainer>
);
