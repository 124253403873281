import Circle from '../../../components/Circle';
import IcoMyVoice from '../../../shared/assets/images/IcoMyVoice.png';
import React from 'react';
import { getStyleCircle } from '../my-distict-model';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import InfoWindowEx from './InfoWindowEx';
import { useTheme } from '../../../shared/theme';

/**
 * School Window for Google Maps.
 *
 * @param {*} school - School To Show.
 * @param {*} marker - Selected Marker.
 * @param {boolean} visible - Is Window visible.
 * @param {Function} onSummary - Function handler to view school summary.
 * @returns {*} - School Window Component.
 * @class
 */
const SchoolWindow = ({ school, onSummary, marker, visible: isVisible, ...googleMapPros }) => {
  const theme = useTheme();
  const {
    id,
    name,
    schoolType,
    regionId,
    logoUrl,
    numTeacher,
    address1,
    address2,
    city,
    state,
    zipCode,
  } = school;

  const schoolTitle = name || id || <em style={{ opacity: '0.5' }}>Name not available</em>;
  const regionText = regionId || <em style={{ opacity: '0.5' }}>Region not available</em>;

  const styleCircle = getStyleCircle(schoolType);

  return (
    <InfoWindowEx
      marker={marker}
      visible={isVisible}
      style={{ minWidth: '250px' }}
      {...googleMapPros}>
      <div style={{ minWidth: 240, minHeight: 170 }}>
        <div
          className="info-header"
          style={{
            padding: '10px 10px',
            backgroundColor: theme.palette.primary,
            color: 'white',
            minHeight: 50,
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <div>
            <h1 style={{ fontSize: 17, color: 'white', margin: 0, padding: 0 }}>{schoolTitle}</h1>
            <h2 style={{ fontSize: 13, color: 'white', margin: 0, padding: 0 }}>
              Region: {regionText}
            </h2>
          </div>
          <Circle
            letter={styleCircle.letter}
            background={styleCircle.background}
            border={styleCircle.border}
            letterColor={styleCircle.letterColor}
          />
        </div>
        <div className="info-body" style={{ padding: 10, width: 300 }}>
          <div
            className="info-left"
            style={{ display: 'inline-block', width: '50%', textAlign: 'center' }}>
            <img style={{ maxWidth: 100 }} alt={'School Logo'} src={logoUrl || IcoMyVoice} />
            <div
              style={{
                height: '15%',
                paddingTop: 10,
                fontSize: 15,
                fontWeight: 600,
                textAlign: 'center',
              }}>
              {numTeacher} Teachers
            </div>
          </div>
          <div
            className="info-right"
            style={{ paddingLeft: 10, display: 'inline-block', width: '50%' }}>
            <div style={{ fontSize: 13 }} className="address-block">
              <div>{address1 || ''}</div>
              <div>{address2 || ''}</div>
              <div>
                {city ? city + ',' : ''} {state ? state + ',' : ''} {zipCode ? zipCode : ''}{' '}
              </div>
            </div>
            <Button
              onClick={onSummary}
              color="secondary"
              style={{
                width: '100%',
                padding: 5,
                marginTop: 10,
              }}>
              View Summary
            </Button>
          </div>
        </div>
      </div>
    </InfoWindowEx>
  );
};

SchoolWindow.propTypes = {
  visible: PropTypes.bool.isRequired,
  marker: PropTypes.object.isRequired,
  school: PropTypes.object.isRequired,
  onSummary: PropTypes.func.isRequired,
};

export default SchoolWindow;
