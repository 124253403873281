import { Row } from 'reactstrap';
import React from 'react';
import { SUPPORT_INDICATORS, SUPPORT_MESSAGE, SUPPORT_QUESTIONS } from '../../my-voice-models';
import TitleQuestions from './TitleQuestions';
import PropTypes from 'prop-types';
import IndicatorDetailCards from './indicatorsCards/IndicatorDetailCards';
import Icon from '../../../../shared/assets/icons/support-and-care.svg';
import { getSeries } from '../../../../shared/driversGraphs';

/**
 * My Voice Leader Questions Support View.
 *
 * @param questionsTeacher
 * @param numberTeachers
 * @returns {*}
 */
const DriverSupportLeaderView = ({
  questionsTeacher,
  numberTeachers,
  numberTeachersResponded,
  name,
  type,
  height,
}) => {
  const seriesGeneral = getSeries(SUPPORT_QUESTIONS, questionsTeacher['supportCare']);

  const content = (
    <IndicatorDetailCards
      seriesGeneral={seriesGeneral}
      questions={SUPPORT_INDICATORS}
      type={type}
      height={height}
    />
  );
  return (
    <div>
      <TitleQuestions
        numberTeachers={numberTeachers}
        numberTeachersResponded={numberTeachersResponded}
        message={SUPPORT_MESSAGE}
        icon={Icon}
        name={name}
      />
      <Row className="p-4 ">{content}</Row>
    </div>
  );
};

DriverSupportLeaderView.propTypes = {
  questionsTeacher: PropTypes.object.isRequired,
  numberTeachers: PropTypes.number.isRequired,
  numberTeachersResponded: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
};

export default DriverSupportLeaderView;
