import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a My VOICE error.
 *
 * @type {string}
 */
export const MY_DASHBOARD_ERROR_EVENT = 'MY_DASHBOARD_ERROR_EVENT';
export const MY_DASHBOARD_TEACHERS_EVENT = 'MY_DASHBOARD_TEACHERS_EVENT';
export const SCHOOL_MONTHLY_AVERAGE_EVENT = 'SCHOOL_MONTHLY_AVERAGE_EVENT';
export const SCHOOL_AVERAGE_UPDATE_EVENT = 'SCHOOL_AVERAGE_UPDATE_EVENT';

export const SCHOOLS_BY_DISTRCIT_EVENT = 'SCHOOLS_BY_DISTRCIT_EVENT';
export const MY_DISTRICT_ERROR_EVENT = 'MY_DISTRICT_ERROR_EVENT';
export const SCHOOL_INITIAL_AVERAGE_EVENT = 'SCHOOL_INITIAL_AVERAGE_EVENT';
export const SCHOOL_AVERAGE_EVENT = 'SCHOOL_AVERAGE_EVENT';
export const SCHOOL_AVERAGE_SCHOOLID_EVENT = 'SCHOOL_AVERAGE_SCHOOLID_EVENT';
export const TEACHERS_BY_SCHOOLS_EVENT = 'TEACHERS_BY_SCHOOLS_EVENT';

export const GET_MESSAGES_EVENT = 'GET_MESSAGES_EVENT';
export const GET_MESSAGES_ERROR = 'GET_MESSAGES_ERROR';

export const SEND_MESSAGE_EVENT = 'SEND_MESSAGE_EVENT';
export const SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR';
export const UPDATE_MESSAGE_EVENT = 'UPDATE_MESSAGE_EVENT';
export const UPDATE_MESSAGE_ERROR = 'UPDATE_MESSAGE_ERROR';
export const DELETE_MESSAGE_EVENT = 'DELETE_MESSAGE_EVENT';
export const DELETE_MESSAGE_ERROR = 'DELETE_MESSAGE_ERROR';

class MyDistrictStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(SCHOOLS_BY_DISTRCIT_EVENT);
    this.addEvent(MY_DISTRICT_ERROR_EVENT);
    this.addEvent(SCHOOL_INITIAL_AVERAGE_EVENT);
    this.addEvent(SCHOOL_AVERAGE_EVENT);
    this.addEvent(SCHOOL_AVERAGE_SCHOOLID_EVENT);
    this.addEvent(TEACHERS_BY_SCHOOLS_EVENT);
    this.addEvent(GET_MESSAGES_EVENT);
    this.addEvent(GET_MESSAGES_ERROR);
    this.addEvent(SEND_MESSAGE_EVENT);
    this.addEvent(SEND_MESSAGE_ERROR);
    this.addEvent(UPDATE_MESSAGE_EVENT);
    this.addEvent(UPDATE_MESSAGE_ERROR);
    this.addEvent(DELETE_MESSAGE_EVENT);
    this.addEvent(DELETE_MESSAGE_ERROR);
  }
}

const myDistrictStore = new MyDistrictStore();

export { myDistrictStore };
