import React from 'react';
import { NavigationBar } from '../../shared/layouts/NavigationBar';
import { Container, Row, Col, Button } from 'reactstrap';
//Icons
import { QUESTIONS_LEADER } from './profile-models';
import * as R from 'ramda';
import Radio from '../../components/form/Radio';
import {
  fetchProfileActionLeader,
  updateProfileActionLeader,
  initialProfilesLeader,
} from './profile-actions';
import View from '@cobuildlab/react-flux-state';
import {
  PROFILE_ERROR_EVENT,
  PROFILE_EVENT,
  profileStore,
  UPDATE_PROFILE_EVENT,
} from './profile-store';
import { onErrorMixin } from '../../shared/mixins';
import { customToast } from '../../shared/toast';
import { authStore, USER_EVENT } from '../auth/auth-store';
// import { USER_TYPE_LEADER } from '../../shared/userTypes';

/**
 * Edit Profile View.
 *
 * @param valueIndex
 * @param optionsIndex
 * @param questionIndex
 */
class LeaderProfileEditView extends View {
  constructor(props) {
    super(props);
    // const user = authStore.getState(USER_EVENT);
    this.state = {
      loading: false,
      data: R.clone(QUESTIONS_LEADER),
    };
    this.onError = onErrorMixin.bind(this);
    // if (user.userType !== USER_TYPE_LEADER) throw new Error('This View is Just for Leaders');
  }

  componentDidMount() {
    this.subscribe(profileStore, PROFILE_ERROR_EVENT, this.onError);
    this.subscribe(profileStore, UPDATE_PROFILE_EVENT, () => {
      const user = authStore.getState(USER_EVENT);
      customToast.success('Profile successfully Updated!');
      if (user.needsProfile === true) {
        this.props.history.push('/initial-assessment');
      } else this.props.history.push('/my-profile');
    });
    this.subscribe(profileStore, PROFILE_EVENT, (state) => {
      const { data } = this.state;
      data.forEach((optionSet) => {
        const questions = optionSet.options;
        questions.forEach((question) => {
          const currentValue = state[question.id];
          question.values.forEach((value) => {
            if (value.name === currentValue) value.checked = true;
          });
        });
      });
      this.setState({ data });
    });
    fetchProfileActionLeader();
  }

  toggleQuestion = (valueIndex, optionsIndex, questionIndex) => {
    const { data } = this.state;
    const question = data[optionsIndex].options[questionIndex];
    question.values.forEach((value, i) => (value.checked = i === valueIndex));
    data[optionsIndex][questionIndex] = question;
    this.setState({ data });
  };

  onSubmit = () => {
    const { data } = this.state;
    const profileData = {};
    data.forEach(({ options }) => {
      options.forEach((question) => {
        const checked = question.values.find((val) => val.checked === true);
        if (checked) profileData[question.id] = checked.name;
      });
    });
    updateProfileActionLeader(profileData);
    initialProfilesLeader(profileData);
  };

  onCancel = () => this.props.history.goBack();

  render() {
    const { data } = this.state;
    return (
      <NavigationBar currentRoute={'/my-profile'}>
        <Container fluid className="mt-3">
          <Row className="m-4 item-content">
            <Col>
              <h4 className="d-inline">
                Update {this.props.location.state ? `` : `Initial `}Assessment
              </h4>
              <div className="d-flex pb-4 float-right">
                <Button className="ml-4 btn btn-primary btn-block pl-4pr-4" onClick={this.onSubmit}>
                  Save
                </Button>
                <Button className="ml-4 btn btn-primary btn-block pl-4pr-4" onClick={this.onCancel}>
                  Cancel
                </Button>
              </div>
              <Row className="mt-5">
                {data.map(({ options }, optionsIndex) => {
                  return (
                    <Col className="profile-content" key={optionsIndex}>
                      {options.map((option, questionIndex) => {
                        return (
                          <React.Fragment key={questionIndex}>
                            <strong className="item-textgray-small">{option.title}</strong>
                            <div>
                              {option.values.map((value, valueIndex) => {
                                return (
                                  <React.Fragment key={valueIndex}>
                                    <Radio
                                      checked={value.checked}
                                      onClick={() =>
                                        this.toggleQuestion(valueIndex, optionsIndex, questionIndex)
                                      }
                                    />
                                    <small key={valueIndex} style={{ opacity: '1' }}>
                                      {value.name}
                                    </small>
                                    <br />
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Container>
      </NavigationBar>
    );
  }
}

export default LeaderProfileEditView;
