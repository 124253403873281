import PropTypes from 'prop-types';

export const actionsPropsType = PropTypes.arrayOf(
  PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.node,
    loading: PropTypes.bool,
  }),
).isRequired;

export const buttomPropsType = {
  actions: actionsPropsType,
};
