import React from 'react';
import { PropTypes } from 'prop-types';
// import styled from 'styled-components';
import { DRIVERS } from '../my-voice-models';
// import { CUSTOM_DOMAINS } from '../../covid-19-domains/covid-19-domains-models';
import gridInterfaceIcon from '../../../shared/assets/icons/grid-interface.svg';
import {
  SubNavigationBar,
  CollapsibleSubNavigationList,
} from '../../../shared/components/menus/SubNavigationBar';

// const RedIndicator = styled.span`
//   display: inline-block;
//   width: 0.5rem;
//   height: 0.5rem;
//   border-radius: 50%;
//   background: red;
//   margin-left: 7px;
// `;

const DriversNavigationBar = ({ avg, customDomainsData, customDomainAvg }) => (
  <SubNavigationBar>
    <CollapsibleSubNavigationList
      className="mb-4 mt-3"
      title="Culture"
      defaultOpen={true}
      items={[
        {
          icon: (
            <img
              src={gridInterfaceIcon}
              alt="drivers icon"
              className="ml-1 mr-1"
              height={15}
              width={15}
            />
          ),
          href: '/my-voice',
          title: 'All Drivers',
          className: 'font-weight-bold',
          style: { fontSize: '1.1rem' },
        },
        ...DRIVERS.map((item) => {
          item.data = avg[item.id] || 0;
          return {
            icon: <img src={item.icon} alt="driver icon" height={24} width={24} />,
            href: item.href,
            title: item.name,
            value: item.data,
          };
        }),
      ]}
    />

    {/* <CollapsibleSubNavigationList
      title="Covid-19"
      items={CUSTOM_DOMAINS.map((item) => {
        let requireIndicator = false;
        if (!customDomainsData[item.id]) requireIndicator = true;
        else {
          for (const indicator of item.indicators) {
            if (!customDomainsData[item.id][indicator.id]) {
              requireIndicator = true;
              break;
            }
          }
        }
        return {
          // icon: <img src={item.icon} alt="domain icon" height={24} width={24} />,
          href: item.href,
          title: (
            <>
              {item.name}
              {requireIndicator ? <RedIndicator /> : null}
            </>
          ),
          value: customDomainAvg[item.id] || 0,
        };
      })}
    /> */}
  </SubNavigationBar>
);

DriversNavigationBar.propTypes = {
  avg: PropTypes.object.isRequired,
  customDomainsData: PropTypes.object.isRequired,
  customDomainAvg: PropTypes.object.isRequired,
};

export { DriversNavigationBar };
