import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSubscription } from '@cobuildlab/react-flux-state';
import { customToast } from '../../../shared/toast';
import {
  createUserSuperAdmin,
  updateUser,
  getAllDistricts,
  fetchAllSchools,
} from '../super-admin-actions';
import {
  ADD_USER_ERROR_EVENT,
  ADD_USER_EVENT,
  SUPER_ADMIN_ALL_DISTRICTS_EVENT,
  SUPER_ADMIN_ALL_SCHOOLS_EVENT,
  superAdminStore,
  UPDATE_USER_EVENT,
  UPDATE_USER_ERROR_EVENT,
} from '../super-admin-store';
import AddUserModal from '../../../components/user/AddUserModal';
import EditUserModal from '../../../components/user/EditUserModal';

export const UserClfModalContainer = ({ isOpen, onToggle, user, edit, submitCallback }) => {
  const [loading, setLoading] = useState(false);
  const [schools, setSchools] = useState([]);
  const [districts, setDistricts] = useState([]);

  const onSubmit = (user) => {
    setLoading(true);
    if (edit) updateUser(user);
    else createUserSuperAdmin(user);
  };

  useEffect(() => {
    if (isOpen) {
      fetchAllSchools();
      getAllDistricts();
    }
  }, [isOpen]);

  useSubscription(superAdminStore, ADD_USER_EVENT, (user) => {
    customToast.success('User created successfully');
    setLoading(false);
    onToggle();
    submitCallback(user);
  });

  useSubscription(superAdminStore, ADD_USER_ERROR_EVENT, (err) => {
    setLoading(false);
    customToast.error(err);
  });

  useSubscription(superAdminStore, UPDATE_USER_EVENT, (updatedUser) => {
    customToast.success('User updated successfully');
    setLoading(false);
    onToggle();
    submitCallback(updatedUser);
  });

  useSubscription(superAdminStore, UPDATE_USER_ERROR_EVENT, (err) => {
    setLoading(false);
    customToast.error(err);
  });

  useSubscription(superAdminStore, SUPER_ADMIN_ALL_DISTRICTS_EVENT, (data) => {
    setDistricts(data);
  });

  useSubscription(superAdminStore, SUPER_ADMIN_ALL_SCHOOLS_EVENT, (data) => {
    setSchools(data);
  });

  const Component = edit ? EditUserModal : AddUserModal;

  return (
    <Component
      CLF
      user={user}
      isOpen={isOpen}
      loading={loading}
      onSubmit={onSubmit}
      schools={schools}
      districts={districts}
      onToggle={onToggle}
    />
  );
};

UserClfModalContainer.defaultProps = {
  isOpen: false,
  edit: false,
  user: null,
};

UserClfModalContainer.propTypes = {
  isOpen: PropTypes.bool,
  edit: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  user: PropTypes.object,
  submitCallback: PropTypes.func.isRequired,
};
