import React from 'react';
import View from '@cobuildlab/react-flux-state';
import { PropTypes } from 'prop-types';
import { ListGroup, ListGroupItem as BootsListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';

import MainLayout from './MainLayout';
import icoMyVoice from '../assets/images/myvoice-cognia-logo-small.png';
import cogniaWhiteLogo from '../assets/images/cognia_white.png';

import { logOutAction } from '../../modules/auth/auth-actions';
import {
  authStore,
  LOGOUT_EVENT,
  UPDATE_USER_EVENT,
  USER_EVENT,
} from '../../modules/auth/auth-store';
import covid19Store, {
  COVID_19_TEACHER_CUSTOM_DOMAINS_EVENT,
} from '../../modules/covid-19-domains/covid-19-domains-store';
import { CUSTOM_DOMAINS } from '../../modules/covid-19-domains/covid-19-domains-models';
import solutionsStore, {
  NOTIFICATIONS_SOLUTIONS_NEW_EVENT,
} from '../../modules/solutions/solutions-store';
import {
  urls,
  urlsLeader,
  urlsCoa,
  urlsClf,
  urlsRosterManager,
  adminUrls,
  myProfileUrl,
  logoutUrl,
} from '../../routes/navigation-routes';
import { USER_TYPE_CLF, USER_TYPE_LEADER, USER_TYPE_TEACHER, USER_TYPE_COA } from '../userTypes';

const RedIndicator = styled.span`
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: red;
  margin-left: 7px;
`;

const SidebarContaier = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ListGroupItem = styled(BootsListGroupItem)`
  cursor: pointer;
  background: transparent;
  color: #fff;
  border: 0px;
  border-left: 2px solid transparent;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.75rem 0rem 0.75rem 2.25rem;
  margin-bottom: -1px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  &.active {
    border-left-color: #fff;
    background-color: rgb(45 25 50);
  }
  &:hover {
    background-color: rgb(45 25 50);
    border-left-color: #fff;
  }
`;

class NavigationBar extends View {
  constructor(props) {
    super(props);
    this.state = {
      requireDomains: false,
      newsSolutions: false,
    };
  }

  componentDidMount() {
    this.subscribe(authStore, LOGOUT_EVENT, () => {
      window.location.href = '/login';
    });
    this.subscribe(authStore, USER_EVENT, (user) => {
      this.setState({ user });
    });
    this.subscribe(authStore, UPDATE_USER_EVENT, (user) => {
      this.setState({ user });
    });
    this.subscribe(covid19Store, COVID_19_TEACHER_CUSTOM_DOMAINS_EVENT, (data) => {
      let requireDomains = false;
      if (data) {
        const { domains } = data;
        for (const domain of CUSTOM_DOMAINS) {
          if (!domains[domain.id]) {
            requireDomains = true;
            break;
          } else {
            for (const indicator of domain.indicators) {
              if (!domains[domain.id][indicator.id]) {
                requireDomains = true;
                break;
              }
            }
          }
        }
      } else requireDomains = true;
      this.setState({ requireDomains });
    });
    this.subscribe(solutionsStore, NOTIFICATIONS_SOLUTIONS_NEW_EVENT, (notifications) => {
      const notificationsNotViewed = notifications.filter((n) => !n.viewed);
      if (notificationsNotViewed.length) this.setState({ newsSolutions: true });
      else this.setState({ newsSolutions: false });
    });
  }

  onLogout = (e) => {
    logOutAction();
    localStorage.removeItem('email');
  };

  render() {
    const { children, currentRoute } = this.props;
    const user = authStore.getState(USER_EVENT);

    let menuUrls = [];
    if (user.userType === USER_TYPE_TEACHER) menuUrls = [...urls];
    if (user.userType === USER_TYPE_LEADER) menuUrls = [...urlsLeader];
    if (user.userType === USER_TYPE_COA) menuUrls = [...urlsCoa];
    if (user.userType === USER_TYPE_CLF) menuUrls = [...urlsClf];

    if (user.needsProfile && user.userType !== USER_TYPE_COA) menuUrls = [];
    if (user.isRosterManager === true) menuUrls.push(...urlsRosterManager);
    if (user.isAdmin === true) menuUrls.push(...adminUrls);

    if (user.userType !== USER_TYPE_COA && user.userType !== USER_TYPE_CLF)
      menuUrls.push(myProfileUrl);

    menuUrls.push(logoutUrl);

    const currentUrl = menuUrls.find((url) => url.href === currentRoute);
    let schoolName = '';
    if (user.userType === USER_TYPE_COA) {
      schoolName = user.district ? user.district.name : '';
    } else {
      schoolName = user.school ? user.school.name : '';
    }

    if (user.userType === 'TEACHER') {
      const myVoiceUrlIndex = menuUrls.findIndex((url) => url.href === '/my-voice');
      if (myVoiceUrlIndex !== -1) {
        if (this.state.requireDomains) {
          menuUrls[myVoiceUrlIndex].redIndicator = true;
        } else {
          menuUrls[myVoiceUrlIndex].redIndicator = false;
        }
      }
    }

    const solutionsIndex = menuUrls.findIndex((url) => url.href === '/solutions');
    if (solutionsIndex > -1) {
      menuUrls[solutionsIndex].redIndicator = this.state.newsSolutions;
    }

    const sidebarComponent = (
      <SidebarContaier>
        <div className="d-flex flex-wrap justify-content-center py-3 px-4">
          <img src={icoMyVoice} alt="MyVoice" width="90px" height="80px" />
          <h5 style={{ width: '100%' }} className="text-white mt-3 mb-3">
            {schoolName}
          </h5>
        </div>
        <div
          style={{ height: '100%' }}
          className="d-flex flex-column justify-content-between flex-grow-1 overflow-auto">
          <ListGroup flush>
            {menuUrls.map((url, index) => {
              return url.href === null ? (
                <ListGroupItem key={index} active={false} onClick={this.onLogout}>
                  <span className="mr-3">{url.icon}</span> {url.name}
                </ListGroupItem>
              ) : (
                <Link to={url.href} key={index}>
                  <ListGroupItem disabled={url.disabled} active={url.href === currentRoute}>
                    <span className="mr-3">{url.icon}</span> {url.name}
                    {url.redIndicator ? <RedIndicator /> : null}
                  </ListGroupItem>
                </Link>
              );
            })}
          </ListGroup>
          <div className="d-flex flex-wrap justify-content-center py-3 px-4">
            <img src={cogniaWhiteLogo} alt="cognia logo" width="220px" />
            {/* <p className="text-white">
              ©2021 Cognia, Inc. <br />
              <span style={{ textDecoration: 'underline' }} className="text-white">
                cognia.org Terms and Conditions
              </span>
            </p> */}
          </div>
        </div>
      </SidebarContaier>
    );

    return (
      <MainLayout
        sidebar={sidebarComponent}
        topBarProps={{
          title: currentUrl ? currentUrl.title || currentUrl.name : null,
          menuUrls: menuUrls,
          currentRoute: currentRoute,
          onLogout: this.onLogout,
        }}>
        {children}
      </MainLayout>
    );
  }
}

NavigationBar.propTypes = {
  history: PropTypes.object,
};

const NavigationBarWithTheme = withTheme(NavigationBar);

export { NavigationBarWithTheme as NavigationBar };
