import React from 'react';
import PropTypes from 'prop-types';
import TableSortable from '../../../components/table/TableSortable';
import { StatusBadge } from '../../../shared/components/badge/StatusBadge';
import Icon from 'react-icons-kit';
import { edit as EditIcon } from 'react-icons-kit/fa/edit';
import { formatStringToId, QtyRow, truncateString, validateValue } from '../../../shared/utils';
import { TooltipClipboard } from '../../../shared/components/TooltipClipboard';
import Checkbox from '../../../components/form/Checkbox';
import { isActiveOpenRegistration } from '../super-admin-actions';

export const TableSchools = ({ onEdit, handleOnSchoolActiveRegistration, ...props }) => {
  const statusColumn = (val) => {
    const active = typeof val === 'boolean' ? val : true;
    return <StatusBadge active={active} />;
  };

  const qtyRow = (qty = 0) => <QtyRow>{qty}</QtyRow>;

  const registrationColumn = (val, school) => {
    let isActiveOpeReg = false;

    if (school.openRegistrationExpireDate) {
      isActiveOpeReg = isActiveOpenRegistration(school.openRegistrationExpireDate.toDate());
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Checkbox
          checked={isActiveOpeReg}
          onChange={() => handleOnSchoolActiveRegistration(school)}
        />
      </div>
    );
  };

  const tooltipRow = (val) => {
    if (val) {
      return (
        <TooltipClipboard value={val} id={formatStringToId(val)}>
          {truncateString(val, 0, 15)}
        </TooltipClipboard>
      );
    }
    return validateValue(val);
  };

  const regionRow = (val, school) => {
    if (val) {
      return (
        <TooltipClipboard
          value={school.regionId}
          id={formatStringToId(`${school.id}${school.regionId}`)}>
          {truncateString(school.regionId, 0, 15)}
        </TooltipClipboard>
      );
    }
    return validateValue(val);
  };

  const districtRow = (val, school) => {
    if (val) {
      return (
        <TooltipClipboard
          value={school.regionDistrictId}
          id={formatStringToId(`${school.id}${school.regionDistrictId}`)}>
          {truncateString(school.regionDistrictId, 0, 15)}
        </TooltipClipboard>
      );
    }
    return validateValue(val);
  };

  const addressRow = (val, school) => {
    if (val) {
      return <div style={{ color: 'lightgrey ', maxWidth: 200 }}>{val}</div>;
    }
    return validateValue(val);
  };

  const originColumn = (val, school) => {
    return (
      <div className="d-flex justify-content-center">
        {val ? 'Cognia' : 'MyVoice'}
        <span
          onClick={() => onEdit(school)}
          style={{ width: '10px' }}
          className="table-button-edit ml-3">
          <Icon icon={EditIcon} size="25px" />
        </span>
      </div>
    );
  };

  return (
    <TableSortable
      columns={[
        {
          title: 'Registration',
          field: 'registration',
          render: registrationColumn,
        },
        {
          title: 'School Name',
          field: 'name',
          transform: (value) => value || 'N/A',
        },
        {
          title: 'Active',
          field: 'active',
          render: statusColumn,
        },
        {
          title: 'School ID',
          field: 'id',
          render: tooltipRow,
          transform: (value) => value || 'N/A',
        },
        {
          title: 'Region ID',
          field: 'regionId',
          render: regionRow,
          transform: (value) => value || 'N/A',
        },
        {
          title: 'District ID',
          field: 'regionDistrictId',
          render: districtRow,
          transform: (value) => value || 'N/A',
        },
        {
          title: 'School Type',
          field: 'schoolType',
          render: tooltipRow,
          transform: (value) => value || 'N/A',
        },
        {
          title: 'Address',
          field: 'address1',
          render: addressRow,
          transform: (value) => value || 'N/A',
        },
        {
          title: 'Teachers',
          field: 'teachersNumbers',
          render: qtyRow,
          transform: (value) => value || 'N/A',
        },
        {
          title: 'Origin',
          field: 'organizationGUID',
          render: originColumn,
        },
      ]}
      {...props}
    />
  );
};

TableSchools.propTypes = {
  onEdit: PropTypes.func.isRequired,
};
