import React, { useEffect, useState } from 'react';
import {
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal as ReactModal,
  ModalBody,
  Row,
  Button,
  Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { edit as ic_edit } from 'react-icons-kit/fa/edit';
import { Icon } from 'react-icons-kit';
import ModalHeader from '../../../components/modal/ModalHeader';
import { RequiredInputLabel } from '../../../shared/components/typography/RequiredInputLabel';

const Modal = styled(ReactModal)`
  @media (min-width: 768px) {
    width: 700px !important;
    max-width: 700px !important;
    min-width: 700px !important;
  }
`;

const initialRegion = {
  id: '',
  name: '',
};

export const RegionModal = ({ isOpen, onToggle, onSubmit, loading }) => {
  const [id, setId] = useState(initialRegion.id);
  const [name, setName] = useState(initialRegion.name);

  const [isValid, setValid] = useState(false);

  const [readOnlyRegionId, setReadOnlyRegionId] = useState(true);

  useEffect(() => {
    const regionId = name.replace(/\b\w/g, (l) => l.toUpperCase()).replace(/ /g, '');
    setId(regionId);
  }, [name]);

  useEffect(() => {
    setValid(id && name);
  }, [id, name]);

  useEffect(() => {
    setName('');
    setId('');
  }, [isOpen]);

  const handleSubmit = () => {
    const userData = {
      name,
      id,
    };
    onSubmit(userData);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={onToggle}
        backdrop="static"
        centered
        className="add-district-modal">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}>
          <ModalHeader onToggle={onToggle} title={'Add Region'} />
          <ModalBody>
            <Row>
              <Col md={12}>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <RequiredInputLabel>Region</RequiredInputLabel>
                      <Input
                        type="text"
                        name="regionName"
                        onChange={(evt) => setName(evt.target.value)}
                        value={name}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <RequiredInputLabel>Region ID</RequiredInputLabel>
                      <InputGroup className="input-editable">
                        <Input
                          type="text"
                          name="regionId"
                          value={id}
                          onChange={(evt) => setId(evt.target.value)}
                          onDoubleClick={() => setReadOnlyRegionId(!readOnlyRegionId)}
                          readOnly={readOnlyRegionId}
                          required
                        />
                        {readOnlyRegionId ? (
                          <InputGroupAddon
                            className="disk-icon"
                            addonType="append"
                            onClick={() => setReadOnlyRegionId(false)}>
                            <InputGroupText>
                              <Icon icon={ic_edit} size={20} />
                            </InputGroupText>
                          </InputGroupAddon>
                        ) : null}
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Button
                  block
                  type="submit"
                  color="secondary"
                  className="btn-input-size"
                  disabled={loading || !isValid}>
                  {loading ? <Spinner size="sm" color="primary" /> : 'Add Region'}
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Form>
      </Modal>
    </>
  );
};

RegionModal.defaultProps = {
  loading: false,
  isOpen: false,
};

RegionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
