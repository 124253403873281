import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { NavigationBar } from '../../shared/layouts/NavigationBar';
import { ClipLoader } from '../../shared/components/progress/ClipLoader';
import { GraphTeacherActivity } from './components/GraphTeacherActivity';
import { DashboardClfTable } from './components/DashboardClfTable';
import { getMyClfDashboardAction } from './my-dashboard-actions';
import { useSubscription } from '@cobuildlab/react-flux-state';
import {
  myDashboardStore,
  MY_CLF_DASHBOARD_TEACHERS_ERROR,
  MY_CLF_DASHBOARD_TEACHERS_EVENT,
} from './my-dashboard-store';

const TotalTitle = ({ title, value }) => (
  <h6>
    <span style={{ color: 'darkgray' }}>{title}:</span> {value}
  </h6>
);

TotalTitle.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};

const initialTeachersStats = {
  numTeachers: 0,
  haveLoggedIn: 0,
  haveLogged30: 0,
  numTeachersInitial: 0,
  numUpdatedDrivers: 0,
};

export const MyClfDashboardView = () => {
  const [teachers, setTeachers] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [schools, setSchools] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const [teachersStats, setTeachersStats] = useState(initialTeachersStats);
  const [isLoading, setIsLoading] = useState(false);

  const getDashboard = useCallback(() => {
    getMyClfDashboardAction();
    setIsLoading(true);
  }, []);

  useEffect(() => {
    getDashboard();
  }, [getDashboard]);

  useSubscription(myDashboardStore, MY_CLF_DASHBOARD_TEACHERS_EVENT, (data) => {
    const {
      teachers: auxTeachers,
      districts: auxDistricts,
      schools: auxSchools,
      solutions: auxSolutions,
      ...stats
    } = data;
    setDistricts(auxDistricts);
    setSchools(auxSchools);
    setSolutions(auxSolutions);
    setTeachers(auxTeachers);
    setTeachersStats(stats);
    setIsLoading(false);
  });

  useSubscription(myDashboardStore, MY_CLF_DASHBOARD_TEACHERS_ERROR, (data) => {
    setIsLoading(false);
  });

  let content = (
    <div className="d-flex justify-content-center">
      <ClipLoader size={150} />
    </div>
  );

  if (!isLoading)
    content = (
      <>
        <Row className="m-5" style={{ paddingTop: '0px !important' }}>
          <GraphTeacherActivity
            haveLoggedIn={teachersStats.haveLoggedIn}
            haveLogged30={teachersStats.haveLogged30}
            numTeachers={teachersStats.numTeachers}
            numTeachersInitial={teachersStats.numTeachersInitial}
            schoolUpdateData={teachersStats.numUpdatedDrivers}
          />
        </Row>
        <h6 className="font-weight-bold mb-4">My Districts</h6>
        <Container fluid>
          <DashboardClfTable
            districts={districts}
            schools={schools}
            solutions={solutions}
            teachers={teachers}
          />
        </Container>
      </>
    );

  return (
    <NavigationBar currentRoute="/my-dashboard">
      <Container fluid className="p-4">
        <Row className="mb-3">
          <Col md={2}>
            <TotalTitle title="Total Districts" value={districts.length} />
          </Col>
          <Col md={2}>
            <TotalTitle title="Total Schools" value={schools.length} />
          </Col>
          <Col md={2}>
            <TotalTitle title="Total Teachers" value={teachers.length} />
          </Col>
        </Row>
        {content}
      </Container>
    </NavigationBar>
  );
};
