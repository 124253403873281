import React from 'react';
import PropTypes from 'prop-types';
import TableSortable from '../../../components/table/TableSortable';
import { StatusBadge } from '../../../shared/components/badge/StatusBadge';
import Icon from 'react-icons-kit';
import { textUpperCase, truncateString } from '../../../shared/utils';
import { TooltipClipboard } from '../../../shared/components/TooltipClipboard';
import { edit as ic_edit } from 'react-icons-kit/fa/edit';
import { USER_TYPE_COA } from '../../../shared/userTypes';

export const TableUsers = ({ onEdit, ...props }) => {
  const statusColumn = (val) => {
    const active = typeof val === 'boolean' ? val : true;
    return <StatusBadge active={active} />;
  };

  const columns = [
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: 'Status',
      field: 'active',
      render: statusColumn,
    },
    {
      title: 'School ID',
      field: 'schoolId',
      render: (value, user) => {
        if (user.userType === USER_TYPE_COA) return '-';
        else if (value) {
          return (
            <TooltipClipboard value={value} id={`${user.id}${value}`}>
              {truncateString(value, 0, 15)}
            </TooltipClipboard>
          );
        }
        return value;
      },
    },
    {
      title: 'Role',
      field: 'userType',
      render: (value) => (value !== USER_TYPE_COA ? textUpperCase(value.toLowerCase()) : 'COA'),
    },
    {
      title: 'Last Log In',
      field: 'lastLoggedIn',
      render: (value) => value || <span style={{ color: 'lightgrey' }}>N/A</span>,
    },
    {
      sort: false,
      onClick: (user) => {
        const isCogniaUser = Boolean(user.userGUID);
        if (!isCogniaUser) onEdit(user);
      },
      render: function renderButton(v, row) {
        const isCogniaUser = Boolean(row.userGUID);
        if (isCogniaUser) return null;
        return (
          <span className="table-button-edit">
            <Icon icon={ic_edit} size="25px" />
          </span>
        );
      },
    },
  ];

  return <TableSortable columns={columns} {...props} />;
};

TableUsers.propTypes = {
  onEdit: PropTypes.func.isRequired,
};
