import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import Icon from 'react-icons-kit';
import { ic_content_copy } from 'react-icons-kit/md/ic_content_copy';
import styled from 'styled-components';

const IconCopy = styled(Icon)`
  cursor: pointer;
`;

export const TooltipClipboard = ({ id, value, children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const idReplaced = id.replace(/[^a-zA-Z ]/g, '-');

  const copyOnClipboard = () => {
    navigator.clipboard.writeText(value);
  };

  return (
    <div>
      <div style={{ display: 'inline-block' }} id={idReplaced}>
        {children}
      </div>
      {value && (
        <Tooltip
          isOpen={isOpen}
          autohide={false}
          flip
          target={idReplaced}
          toggle={() => setIsOpen(!isOpen)}>
          {value} <IconCopy onClick={copyOnClipboard} icon={ic_content_copy} size="14px" />
        </Tooltip>
      )}
    </div>
  );
};

TooltipClipboard.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
