import React, { useCallback, useEffect, useState } from 'react';
import { NavigationBar } from '../../shared/layouts/NavigationBar';
import { Col, Container, Row } from 'reactstrap';
import { authStore, UPDATE_USER_EVENT, USER_EVENT } from '../auth/auth-store';
import { updateUser } from '../auth/auth-actions';
import { useSubscription } from '@cobuildlab/react-flux-state';
import { customToast } from '../../shared/toast';
import { fetchProfileAction, fetchProfileActionLeader } from '../profile/profile-actions';
import { PROFILE_EVENT, profileStore } from '../profile/profile-store';
import {
  ProfileModel,
  QUESTIONS,
  ProfileModelLeader,
  QUESTIONS_LEADER,
} from '../profile/profile-models';
import Radio from '../../components/form/Radio';
import { withRouter } from 'react-router-dom';
import { UserModel } from '../auth/auth-models';
import { USER_TYPE_LEADER } from '../../shared/userTypes';
import { UserProfileHeader } from './components/UserProfileHeader';

/**
 * My Profile View.
 *
 * @returns {Node} - ProfileView component.
 */
export const ProfileView = withRouter(({ history }) => {
  const userSession = authStore.getState(USER_EVENT);
  const { userType } = userSession;
  const questions = userType === USER_TYPE_LEADER ? QUESTIONS_LEADER : QUESTIONS;
  const [user, setUser] = useState({ ...UserModel, ...userSession });
  const [loadingUser, setLoadingUser] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [profile, setProfile] = useState(
    userType === USER_TYPE_LEADER ? ProfileModelLeader : ProfileModel,
  );

  const onEdit = () => {
    setEditProfile(!editProfile);
  };

  const onUpdateUser = (data) => {
    setLoadingUser(true);
    updateUser(data);
  };

  const fetchProfile = useCallback(() => {
    userType === USER_TYPE_LEADER ? fetchProfileActionLeader() : fetchProfileAction();
  }, []);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  useSubscription(authStore, UPDATE_USER_EVENT, (user) => {
    customToast.success('User successfully updated!');
    setLoadingUser(false);
    setEditProfile(false);
    setUser(user);
  });

  useSubscription(profileStore, PROFILE_EVENT, (profile) => {
    setProfile((prevState) => ({ ...prevState, ...profile }));
  });

  return (
    <NavigationBar currentRoute={'/my-profile'}>
      <Container fluid className="mt-3 E-margin">
        <Row className="m-5 item-content">
          <UserProfileHeader
            user={user}
            editUser={editProfile}
            onCancel={onEdit}
            onEditUser={onEdit}
            onEditProfile={() => history.push('/edit-profile')}
            onUpdateUser={onUpdateUser}
            loading={loadingUser}
          />
        </Row>
        <div className="m-4 ">
          <Row className="m-5">
            {questions.map(({ options }, optionsIndex) => {
              return (
                <Col className="profile-content mb-4" key={optionsIndex} lg={4} md={6}>
                  {options.map((option, questionIndex) => {
                    const currentValue = profile[option.id];
                    return (
                      <React.Fragment key={questionIndex}>
                        <strong className="item-textgray-small">{option.title}</strong>
                        <div>
                          {option.values.map((value, valueIndex) => {
                            return (
                              <React.Fragment key={valueIndex}>
                                <Radio
                                  clickable={false}
                                  checked={value.name === currentValue ? true : false}
                                  onClick={() => {}}
                                />
                                {value.name === currentValue ? (
                                  <small key={valueIndex}>{value.name}</small>
                                ) : (
                                  <small style={{ opacity: '0.5' }} key={valueIndex}>
                                    {value.name}
                                  </small>
                                )}
                                <br />
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </React.Fragment>
                    );
                  })}
                </Col>
              );
            })}
          </Row>
        </div>
      </Container>
    </NavigationBar>
  );
});
