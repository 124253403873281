import Flux from '@cobuildlab/flux-state';

/**
 * My Reports Events.
 */

export const MY_REPORTS_GET_REPORTS = 'MY_REPORTS_GET_REPORTS';

class MyReportsStore extends Flux.Store {
  constructor() {
    super();

    this.addEvent(MY_REPORTS_GET_REPORTS);
  }
}

const myReportsStore = new MyReportsStore();
export { myReportsStore };
