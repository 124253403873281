import { error } from 'pure-logger';
import { customToast } from '../shared/toast';
/**
 * Mixin for the on Change HTML inputs.
 *
 * @param {Event} e - Event.
 */
export function onChangeInputMixin(e) {
  e.preventDefault();
  const { data } = this.state;
  data[e.target.name] = e.target.value;
  this.setState({
    data,
  });
}

/**
 * The onChange method for forms.
 *
 * @param {string} name - Name.
 * @param {*} value - Value.
 */
export function onChangeMixin(name, value) {
  const data = this.state.data;
  data[name] = value;
  this.setState({ data });
}

/**
 * The onError method for View, shows the error on a Toast.
 *
 * @param {Error} err - Error Object.
 */
export function onErrorMixin(err) {
  error(err);
  customToast.error(err.message);
  this.setState({ loading: false });
}
