import { isValidString } from './strings';

/**
 * Previews the image on the file to the <Img> tag identify by imgIdSelector.
 *
 * @param {string} imgIdSelector - Image Id.
 * @param {File} file - File to preview.
 * @returns {void}
 */
export const previewFile = (imgIdSelector, file) => {
  const preview = document.getElementById(imgIdSelector);
  const reader = new FileReader();

  reader.addEventListener(
    'load',
    function() {
      preview.src = reader.result;
    },
    false,
  );

  if (file) {
    reader.readAsDataURL(file);
  }
};

/**
 * Extracts the extension of a FileName.
 *
 * @param {string} fileName - File Name.
 * @returns {string} - Returns the ext.
 */
export const getExtension = (fileName) => {
  if (!isValidString(fileName)) throw new Error(`Invalid File Name: ${fileName}`);

  return fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
};
