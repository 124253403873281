import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSubscription } from '@cobuildlab/react-flux-state';
import { NavigationBar } from '../../shared/layouts/NavigationBar';
import {
  allUserMyDashboardAction,
  fetchCoaSchoolsAction,
  fetchMonthlyAverageCoaAction,
  getTeachersDriversAvg,
  getSchoolTeachersDriversAvg,
  schoolUserMyDashboardAction,
  fetchMonthlyAverageSchoolAction,
} from './dashboard-coa-actions';
import {
  MY_DASHBOARD_ERROR_EVENT,
  MY_DASHBOARD_TEACHERS_EVENT,
  myDashboardStore,
  SCHOOL_AVERAGE_DASH_EVENT,
  SCHOOL_MONTHLY_AVERAGE_EVENT,
  COA_SCHOOLS_EVENT,
  COA_SCHOOLS_ERROR,
} from './dashboard-coa-store';
import ProtectedRoute from '../../routes/ProtectedRoute';
import { Redirect, Route, Switch } from 'react-router-dom';
import { GraphTeacherActivity } from './components/GraphTeacherActivity';
import { GraphDriveTrend } from './components/GraphDriveTrend';
import { customToast } from '../../shared/toast';
import { Col, Input, Row } from 'reactstrap';
import { authStore, USER_EVENT } from '../auth/auth-store';
import DropDownDriver from './components/DropDownDriver';
import { ClipLoader } from '../../shared/components/progress/ClipLoader';

export const DashboardCoaView = () => {
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState('all');
  const [loadingSchools, setLoadingSchools] = useState(false);

  const [schoolAvgData, setSchoolAvgData] = useState(null);
  const [numTeachers, setNumTeachers] = useState(0);
  const [numTeachersInitial, setNumTeachersInitial] = useState(0);
  const [numUpdatedDrivers, setNumUpdatedDrivers] = useState(0);
  const [haveLoggedIn, setHaveLoggedIn] = useState(0);
  const [haveLogged30, setHaveLogged30] = useState(0);
  const [schoolAvgMonthlyData, setSchoolAvgMonthlyData] = useState([]);
  const [typeGraph, setTypeGraph] = useState('clearUnifiedDirection');
  const [loading, setLoading] = useState(true);

  const getDashboard = useCallback(() => {
    if (selectedSchool === 'all') {
      allUserMyDashboardAction();
      getTeachersDriversAvg();
      fetchMonthlyAverageCoaAction();
    } else {
      schoolUserMyDashboardAction(selectedSchool);
      getSchoolTeachersDriversAvg(selectedSchool);
      fetchMonthlyAverageSchoolAction(selectedSchool);
    }

    setLoading(true);
  }, [selectedSchool]);

  useEffect(() => {
    getDashboard();
  }, [getDashboard]);

  useEffect(() => {
    fetchCoaSchoolsAction();
    setLoadingSchools(true);
  }, []);

  useSubscription(myDashboardStore, MY_DASHBOARD_TEACHERS_EVENT, (data) => {
    setNumTeachers(data.numTeachers);
    setNumTeachersInitial(data.numTeachersInitial);
    setNumUpdatedDrivers(data.numUpdatedDrivers);
    setHaveLoggedIn(data.haveLoggedIn);
    setHaveLogged30(data.haveLogged30);
  });

  useSubscription(myDashboardStore, SCHOOL_AVERAGE_DASH_EVENT, (state) => {
    setSchoolAvgData(state);
  });

  useSubscription(myDashboardStore, SCHOOL_MONTHLY_AVERAGE_EVENT, (state) => {
    setSchoolAvgMonthlyData(state);
    setLoading(false);
  });

  useSubscription(myDashboardStore, MY_DASHBOARD_ERROR_EVENT, (err) => {
    customToast.error('Error From Data Base.', err.message);
    setLoading(false);
  });

  useSubscription(myDashboardStore, COA_SCHOOLS_EVENT, (data) => {
    setSchools(data.schools.filter((s) => s.active !== false));
    setLoadingSchools(false);
  });

  useSubscription(myDashboardStore, COA_SCHOOLS_ERROR, (err) => {
    customToast.error('error loading schools', err.message);
    setLoadingSchools(false);
  });

  const schoolOptions = useMemo(() => {
    const sortedSchools = schools.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return [{ id: 'all', name: 'All Schools' }].concat(sortedSchools);
  }, [schools]);

  const dropSelect = (type) => {
    setTypeGraph(type);
  };

  let contentTeacherActivity = (
    <Col md={12} className="content-sub-bar justify-content-center h-100vh d-flex">
      <ClipLoader />
    </Col>
  );

  let contentDriveTrend = (
    <Col md={12} className="content-sub-bar justify-content-center h-100vh d-flex">
      <ClipLoader />
    </Col>
  );

  if (!loading) {
    const user = authStore.getState(USER_EVENT);

    contentTeacherActivity = (
      <Switch>
        <ProtectedRoute
          path="/my-dashboard"
          render={() => (
            <GraphTeacherActivity
              haveLoggedIn={haveLoggedIn}
              haveLogged30={haveLogged30}
              numTeachers={numTeachers}
              numTeachersInitial={numTeachersInitial}
              schoolUpdateData={numUpdatedDrivers}
            />
          )}
        />
        <Route render={() => <Redirect to={'/my-dashboard'} />} />
      </Switch>
    );

    contentDriveTrend = (
      <Switch>
        <ProtectedRoute
          path="/my-dashboard"
          render={() => (
            <GraphDriveTrend
              user={user}
              schoolAvgData={schoolAvgData}
              driverId={typeGraph}
              schoolAvgMonthlyData={schoolAvgMonthlyData}
            />
          )}
        />
        <Route render={() => <Redirect to={'/my-dashboard'} />} />
      </Switch>
    );
  }

  return (
    <NavigationBar currentRoute={'/my-dashboard'}>
      <div className="m-4 ">
        <h6 className="d-inline" style={{ color: 'darkgray' }}>
          Schools
        </h6>
        <Input
          style={{ width: '250px' }}
          type="select"
          value={selectedSchool}
          disabled={loading || loadingSchools}
          onChange={(e) => {
            setSelectedSchool(e.target.value);
          }}>
          {!loadingSchools ? (
            schoolOptions.map((school) => {
              return (
                <option key={school.id} value={school.id}>
                  {school.name}
                </option>
              );
            })
          ) : (
            <option value="loading">Loading...</option>
          )}
        </Input>
        <br />
        <h6 className="d-inline" style={{ color: 'darkgray', paddingLeft: '2px' }}>
          {`Total Teachers: ` + numTeachers}
        </h6>
        <Row className="m-5" style={{ paddingTop: 0 + 'px !important' }}>
          {contentTeacherActivity}
        </Row>
        <div style={{ display: 'flex' }}>
          <h4 className="d-inline">{`Driver Trend`} </h4>
          <div style={{ marginLeft: '10px' }}>
            <DropDownDriver dropSelect={dropSelect} />
          </div>
        </div>
        <Row className="m-5" style={{ paddingTop: 0 + 'px !important' }}>
          {contentDriveTrend}
        </Row>
      </div>
    </NavigationBar>
  );
};
