import Flux from '@cobuildlab/flux-state';
import firebase from 'firebase';
import {
  SOLUTIONS_EVENT,
  SOLUTIONS_ERROR,
  CREATE_UPDATE_SOLUTION_EVENT,
  CREATE_UPDATE_SOLUTION_ERROR,
  DRIVERS_AND_AVERAGES,
  DRIVERS_AND_AVERAGES_ERROR,
  DELETE_SOLUTION_EVENT,
  DELETE_SOLUTION_ERROR,
  NOTIFICATIONS_SOLUTIONS_NEW_EVENT,
  NOTIFICATIONS_SOLUTIONS_NEW_ERROR,
  UPDATE_NOTIFICATION_SOLUTION_NEW_EVENT,
  UPDATE_NOTIFICATION_SOLUTION_NEW_ERROR,
} from './solutions-store';
import { authStore, USER_EVENT } from '../auth/auth-store';

/**
 * Get solutions by Driver or Domain.
 *
 * @param {string} driverDomain - Driver or Domain ID.
 * @param {string} schoolId - School ID.
 * @param {string} districtId - District ID.
 */
export const getSolutions = async (driverDomain, schoolId, districtId) => {
  const DB = firebase.firestore();
  const solutionsCollection = DB.collection('driversDomainsSolutions');
  const { schoolId: userSchoolId } = authStore.getState(USER_EVENT);

  let promise;

  if (!driverDomain) promise = solutionsCollection.orderBy('date', 'desc');
  else promise = solutionsCollection.where('driverDomain', '==', driverDomain);

  if (districtId) promise = promise.where('districtId', '==', districtId);
  else promise = promise.where('schoolId', '==', schoolId || userSchoolId);

  try {
    const response = await promise.get();
    const solutions = response.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    Flux.dispatchEvent(SOLUTIONS_EVENT, solutions);
  } catch (err) {
    console.log('err :>> ', err);
    Flux.dispatchEvent(SOLUTIONS_ERROR);
  }
};

/**
 * Create new solution or update solution.
 *
 * @param {object} solution - Solution data.
 * @param {string} solutionId - Solution firebase id.
 */
export const createUpdateSolution = async (solution, solutionId) => {
  const DB = firebase.firestore();
  const solutionsCollection = DB.collection('driversDomainsSolutions');
  const { email, schoolId, districtId, schoolRegionId } = authStore.getState(USER_EVENT);

  const solutionData = {
    ...solution,
    schoolId,
    districtId,
    schoolRegionId,
    userEmail: email,
  };

  try {
    if (!solutionId) {
      solutionData.date = firebase.firestore.FieldValue.serverTimestamp();
      const response = await solutionsCollection.add(solutionData);
      solutionData.id = response.id;
    } else {
      delete solutionData.date;
      await solutionsCollection.doc(solutionId).set(solutionData, { merge: true });
    }

    Flux.dispatchEvent(CREATE_UPDATE_SOLUTION_EVENT, solutionData);
  } catch (err) {
    console.log('err :>> ', err);
    return Flux.dispatchEvent(CREATE_UPDATE_SOLUTION_ERROR, err);
  }
};

export const getSchoolDriversAndDomainsAverage = async (schoolId) => {
  const fetchSchoolDriversAndDomainsAverages = firebase
    .functions()
    .httpsCallable('fetchSchoolDriversAndDomainsAverages');

  try {
    const response = await fetchSchoolDriversAndDomainsAverages(schoolId);
    Flux.dispatchEvent(DRIVERS_AND_AVERAGES, response.data);
  } catch (err) {
    return Flux.dispatchEvent(DRIVERS_AND_AVERAGES_ERROR, err);
  }
};

/**
 * Delete solution.
 *
 * @param {string} solutionId - Solution firebase id.
 */
export const deleteSolution = async (solutionId) => {
  const DB = firebase.firestore();
  const solutionsCollection = DB.collection('driversDomainsSolutions');

  try {
    await solutionsCollection.doc(solutionId).delete();
    Flux.dispatchEvent(DELETE_SOLUTION_EVENT, { solutionId });
  } catch (err) {
    return Flux.dispatchEvent(DELETE_SOLUTION_ERROR, err);
  }
};

/**
 * Get user notification of solutions new.
 */
export const getUserNotificationsSolutionsNew = async () => {
  const DB = firebase.firestore();
  const user = authStore.getState(USER_EVENT);
  const userNotificationsCollection = DB.collection('usersNotifications');

  if (!user && !user.email) return;

  try {
    const notificationsNewSolutionsDocs = await userNotificationsCollection
      .where('email', '==', user.email)
      .where('type', '==', 'SOLUTION_NEW')
      .where('viewed', '==', false)
      .get();

    const notificationsNewSolutions = notificationsNewSolutionsDocs.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    Flux.dispatchEvent(NOTIFICATIONS_SOLUTIONS_NEW_EVENT, notificationsNewSolutions);
  } catch (err) {
    Flux.dispatchEvent(NOTIFICATIONS_SOLUTIONS_NEW_ERROR, err);
  }
};

/**
 * Update user notification.
 *
 * @param {string} notificationId - Notification database ID.
 * @param {object} notificationData - Notification data to update.
 */
export const updateUserNotification = async (notificationId, notificationData) => {
  const DB = firebase.firestore();
  const userNotificationsCollection = DB.collection('usersNotifications');
  try {
    await userNotificationsCollection.doc(notificationId).set(notificationData, { merge: true });
    Flux.dispatchEvent(UPDATE_NOTIFICATION_SOLUTION_NEW_EVENT);
  } catch (err) {
    Flux.dispatchEvent(UPDATE_NOTIFICATION_SOLUTION_NEW_ERROR);
  }
};

export const getUserClfSolutions = async (
  driverDomain = null,
  schoolId = null,
  districtId = null,
) => {
  const call = firebase.functions().httpsCallable('getUserClfSolutions');
  try {
    const response = await call({ driverDomain, schoolId, districtId });
    Flux.dispatchEvent(SOLUTIONS_EVENT, response.data.solutions);
  } catch (err) {
    console.log('err :>> ', err);
    Flux.dispatchEvent(SOLUTIONS_ERROR);
  }
};
