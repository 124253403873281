import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, CardText, Col, Row } from 'reactstrap';
import moment from 'moment';
import DownloadReportButton from './DownloadReportButton';

/**
 * Return card of report.
 *
 * @returns {*} - Report component.
 * @class
 *
 */
const Report = ({ report }) => {
  const { description, type, date, url } = report;

  const dateFormat = moment(date.toDate()).format('ddd DD, YYYY');
  const descriptionText = description.split('\n').map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));

  return (
    <Card>
      <CardHeader className="rounded-top">
        <Row>
          <Col xs={10}>
            <CardText className="mb-0 font-weight-bold">{type}</CardText>
            <CardText className="mb-0">{dateFormat}</CardText>
          </Col>
          <Col
            style={{ padding: 0 }}
            xs={2}
            className="d-flex align-items-center justify-content-center">
            <DownloadReportButton url={url} />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <CardText>{descriptionText}</CardText>
      </CardBody>
    </Card>
  );
};

Report.propTypes = {
  report: PropTypes.object.isRequired,
};

export default Report;
