import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'reactstrap';
import { ic_search } from 'react-icons-kit/md/ic_search';
import { download2 as ic_download } from 'react-icons-kit/icomoon/download2';
import { plus as ic_plus } from 'react-icons-kit/fa/plus';
import { useSubscription } from '@cobuildlab/react-flux-state';
import AddSchoolModalContainer from './AddSchoolModalContainer';
import {
  SUPER_ADMIN_SCHOOLS_EVENT,
  superAdminStore,
  SUPER_ADMIN_EXCEL_REPORTS_ERROR,
  SUPER_ADMIN_EXCEL_REPORTS_EVENT,
} from '../super-admin-store';
import { InputWithIcon } from '../../../shared/components/inputs/InputWithIcon';
import { ButtonSchoolFilter } from './ButtonFilterSchool';
import { ButtomActionsTabs } from './ButtomActionsTabs';
import { customToast } from '../../../shared/toast';
import { createAndDownloadSuperAdminReport } from '../super-admin-actions';

export const initialOtherSearch = {
  district: null,
  schoolType: null,
  onlyActive: 'active',
  onlyRegistrations: false,
  minTeachersNumbers: 0,
  maxTeachersNumbers: 0,
  school: '',
};

/**
 * Top panel to add and search schools.
 *
 * @param {Function} onSearch - Search data.
 * @class
 */
function SchoolSearchForm({ onSearch, searchFilters, isLoading }) {
  const [modal, setModal] = useState(false);
  const [school, setSchool] = useState('');
  const [otherSearch, setOtherSearch] = useState(initialOtherSearch);
  const [loadingReport, setLoadingReport] = useState(false);

  const toggle = () => setModal(!modal);

  const handleCreateReport = () => {
    setLoadingReport(true);
    createAndDownloadSuperAdminReport(searchFilters, 'schools', 'Schools Report');
  };

  useEffect(() => {
    const {
      district,
      onlyActive,
      schoolType,
      minTeachersNumbers,
      maxTeachersNumbers,
      onlyRegistrations,
    } = otherSearch;

    onSearch({
      school,
      onlyActive,
      districtId: district && district.id,
      schoolType: schoolType && schoolType.value,
      minTeachersNumbers: minTeachersNumbers || 0,
      maxTeachersNumbers: maxTeachersNumbers || 0,
      onlyRegistrations,
    });
  }, [school, otherSearch]);

  const [countResults, setCount] = useState({ count: 0, isMore: false });

  useSubscription(superAdminStore, SUPER_ADMIN_SCHOOLS_EVENT, (schoolsList) => {
    const { schools, isMore, clear } = schoolsList;
    const prevCount = countResults.count;
    const count = clear ? schools.length : prevCount + schools.length;
    setCount({ count, isMore });
  });

  useSubscription(superAdminStore, SUPER_ADMIN_EXCEL_REPORTS_ERROR, (err) => {
    setLoadingReport(false);
    customToast.error(err);
  });

  useSubscription(superAdminStore, SUPER_ADMIN_EXCEL_REPORTS_EVENT, () => {
    setLoadingReport(false);
  });

  const { count: schoolsCount, isMore: areMoreSchools } = countResults;
  const countText = (
    <span className="result-count">
      {schoolsCount}
      {areMoreSchools ? '+' : ''} Results
    </span>
  );

  return (
    <Form className="form-tabs" onSubmit={(e) => e.preventDefault()}>
      <h5 className="my-3">Search {countText}</h5>
      <AddSchoolModalContainer onToggle={toggle} isOpen={modal} />
      <Row className="justify-content-between align-items-center">
        <Col xs={6} md={3}>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <InputWithIcon
                icon={ic_search}
                onChange={(evt) => setSchool(evt.target.value)}
                type="text"
                placeholder="Search"
                disabled={isLoading}
              />
            </div>
            <div className="ml-2">
              <ButtonSchoolFilter
                data={otherSearch}
                onChange={(val) => setOtherSearch(val)}
                isLoading={isLoading}
              />
            </div>
          </div>
        </Col>

        <Col md={6} xs={6} className="d-flex justify-content-end">
          <ButtomActionsTabs
            actions={[
              {
                onClick: handleCreateReport,
                title: 'Download File',
                icon: ic_download,
                loading: loadingReport,
              },
              {
                onClick: toggle,
                title: 'Add School',
                icon: ic_plus,
              },
            ]}
          />
        </Col>
      </Row>
    </Form>
  );
}

SchoolSearchForm.defaultProps = {
  isLoading: false,
};

SchoolSearchForm.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchFilters: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

export default SchoolSearchForm;
