/**
 *
 * @param {Array} questions - Questions IDs.
 * @param {object} questionsAnswers - Questions Answers.
 * @returns {object} - Object to present data in graph.
 */
export function getSeries(questions, questionsAnswers) {
  const labels = [1, 2, 3, 4, 5];
  const seriesGeneral = {};
  //get questions from section advancement
  questions.forEach((question) => {
    //take a question with possibles responses
    if (!seriesGeneral[question]) {
      seriesGeneral[question] = {};
    }

    //take every question and check how many times the response {1,2,3,4 or 5} has been chosen by the users
    labels.forEach((label) => {
      if (!seriesGeneral[question].series) {
        seriesGeneral[question].series = [];
      }

      if (questionsAnswers && questionsAnswers[question]) {
        seriesGeneral[question].series.push(questionsAnswers[question][label] || 0);
      }
    });
  });
  return seriesGeneral;
}
