import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import ProfessionalIcon from '../../../shared/assets/icons/professional-engagement.svg';
import { updateIndicator, updateIndicators } from '../my-voice-actions';
import { INITIAL_PROFESSIONAL_INDICATORS, PROFESSIONAL_INDICATORS } from '../my-voice-models';
import { DriverDetailsForm } from '../components/DriversDetailsForm';

class DriverProfessionalView extends Component {
  constructor(props) {
    super(props);
    const data = this.props.data;
    const indicators = updateIndicators(
      R.clone(this.props.isInitial ? INITIAL_PROFESSIONAL_INDICATORS : PROFESSIONAL_INDICATORS),
      data,
    );
    this.state = {
      indicators,
    };
  }

  onClickStep = (indicatorId, indicatorValue) => {
    const indicators = updateIndicator(this.state.indicators, indicatorId, indicatorValue);
    this.setState({ indicators });
  };

  onSubmit = () => {
    this.props.onSubmit(this.state.indicators);
  };

  render() {
    const { indicators } = this.state;
    const { submitText } = this.props;
    return (
      <DriverDetailsForm
        icon={ProfessionalIcon}
        title="Professional Engagement"
        message="Teachers are lifelong learners necessitating multiple opportunities for them to
        engage in professional activities both collectively and individually. Conversations
        are created throughout the system to improve practice while modeling the attributes
        of a learner."
        indicators={indicators}
        onChangeIndicator={(id, stepNumber) => this.onClickStep(id, stepNumber)}
        submitText={submitText}
        onSubmit={this.onSubmit}
      />
    );
  }
}

DriverProfessionalView.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  isInitial: PropTypes.bool,
};

DriverProfessionalView.defaultProps = {
  submitText: 'Update',
  isInitial: false,
};

export default DriverProfessionalView;
