import Flux from '@cobuildlab/flux-state';

export const UPLOAD_DATA = 'UPLOAD_DATA';
export const UPLOAD_DATA_ERROR = 'UPLOAD_DATA_ERROR';
export const REVIEW_UPLOAD_DATA = 'REVIEW_UPLOAD_DATA';

class DataImportStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(UPLOAD_DATA);
    this.addEvent(UPLOAD_DATA_ERROR);
    this.addEvent(REVIEW_UPLOAD_DATA);
  }
}

const dataImportStore = new DataImportStore();

export default dataImportStore;
