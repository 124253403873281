import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
//auth
import LoginView from './modules/auth/LoginView';
import ForgotPasswordView from './modules/auth/ForgotPasswordView';
import ChangePasswordView from './modules/auth/ChangePasswordView';
import RegistrationView from './modules/auth/RegistrationView';
//Cooming Soon
// import ComingSoon from './modules/landing/ComingSoonView';
import 'react-toastify/dist/ReactToastify.css';
//main
//profile views
import ComponentsView from './modules/landing/ComponentsView';
import { toast, ToastContainer } from 'react-toastify';
import Session from './components/Session';
import { Router } from './routes/Router';
import { ThemeProvider } from 'styled-components';
import { theme } from './shared/theme';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './shared/utils';

/**
 * App.
 */
export default class App extends Component {
  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
          <BrowserRouter>
            <Switch>
              {/*<Route exact path="/" component={ComingSoon} />*/}
              <Route exact path="/" component={LoginView} />
              <Route exact path="/components" component={ComponentsView} />
              <Route exact path="/login" component={LoginView} />
              <Route path="/forgot-password" component={ForgotPasswordView} />
              <Route path="/change-password" component={ChangePasswordView} />
              <Route path="/registration/:token" component={RegistrationView} />
              <Session>
                <Router />
              </Session>
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </QueryClientProvider>
    );
  }
}
