import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Row, Input } from 'reactstrap';
import { Select } from './Select';
import { CheckButton } from './CheckButton';
import schoolTypes from '../../schoolTypes';
import ActiveSelect from './ActiveSelect';

const schoolOptions = [
  { label: 'Elementary', value: schoolTypes.ELEM },
  { label: 'High', value: schoolTypes.HIGH },
  { label: 'Middle', value: schoolTypes.MIDDLE },
  { label: 'Other', value: schoolTypes.OTHER },
];

export const SchoolsFilter = ({
  data,
  districts,
  isLoadingDistricts,
  onChange,
  isMulti,
  disabled,
}) => {
  const handleOnChange = (values) => {
    const auxData = { ...data };
    Object.assign(auxData, values);
    onChange(auxData);
  };

  let districtInput = null;
  if (disabled) {
    const val = data.district ? data.district.id : '';
    districtInput = <Input value={val} disabled />;
  } else {
    districtInput = (
      <Select
        placeholder="Search District"
        getOptionValue={(opt) => opt.id}
        getOptionLabel={(opt) => opt.name || opt.id}
        options={districts}
        isSearchable
        isClearable
        isLoading={isLoadingDistricts}
        isMulti={isMulti}
        onChange={(district, evt) => {
          if (evt.action === 'select-option' || evt.action === 'remove-value') {
            handleOnChange({ district });
          } else if (evt.action === 'clear') {
            handleOnChange({
              district: isMulti ? [] : null,
            });
          }
        }}
        value={data.district}
      />
    );
  }

  let districtIdInput = null;
  if (disabled) {
    const val = data.district ? data.district.id : '';
    districtIdInput = <Input value={val} disabled />;
  } else {
    districtIdInput = (
      <Select
        placeholder="Search District Id"
        getOptionValue={(opt) => opt.id}
        getOptionLabel={(opt) => opt.id}
        options={districts}
        isSearchable
        isClearable
        isLoading={isLoadingDistricts}
        isMulti={isMulti}
        onChange={(district, evt) => {
          if (evt.action === 'select-option' || evt.action === 'remove-value') {
            handleOnChange({ district });
          } else if (evt.action === 'clear') {
            handleOnChange({
              district: isMulti ? [] : null,
            });
          }
        }}
        value={data.district}
      />
    );
  }

  let schoolTypeInput = null;
  if (disabled) {
    const val = data.schoolType ? data.schoolType.value : '';
    schoolTypeInput = <Input value={val} disabled />;
  } else {
    schoolTypeInput = (
      <Select
        placeholder="Select School Type"
        options={schoolOptions}
        isClearable
        onChange={(schoolType, evt) => {
          if (evt.action === 'select-option') {
            handleOnChange({ schoolType });
          } else if (evt.action === 'clear') {
            handleOnChange({ schoolType: null });
          }
        }}
        value={data.schoolType}
      />
    );
  }

  return (
    <FormGroup>
      <Row>
        <Col md={12} className="my-2">
          <label>District</label>
          {districtInput}
        </Col>
        <Col md={12} className="my-2">
          <label>District Id</label>
          {districtIdInput}
        </Col>
        <Col md={12} className="mb-2">
          <label>School Type</label>
          {schoolTypeInput}
        </Col>
        <Col md={12} className="mb-4">
          <label>Status</label>
          <ActiveSelect
            value={data.onlyActive}
            onChange={(val) => handleOnChange({ onlyActive: val })}
            disabled={disabled}
          />
        </Col>
        <Col md={12} className="mb-4">
          <CheckButton
            style={{ width: '100%' }}
            active={data.onlyRegistrations}
            onChange={(val) => handleOnChange({ onlyRegistrations: val })}
            disabled={disabled}>
            Only Open Registration
          </CheckButton>
        </Col>
        <Col md={12}>
          <label>Teachers Numbers</label>
          <Row className="justify-content-between">
            <Col sm={6}>
              <label>Minimum</label>
              <Input
                type="number"
                placeholder="min"
                min={0}
                value={data.minTeachersNumbers}
                onChange={(e) => handleOnChange({ minTeachersNumbers: e.target.value })}
                disabled={disabled}
              />
            </Col>
            <Col sm={6}>
              <label>Maximum</label>
              <Input
                type="number"
                placeholder="max"
                min={0}
                value={data.maxTeachersNumbers}
                onChange={(e) => handleOnChange({ maxTeachersNumbers: e.target.value })}
                disabled={disabled}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </FormGroup>
  );
};

SchoolsFilter.defaultProps = {
  isMulti: false,
  isLoadingDistricts: false,
  disabled: false,
};

SchoolsFilter.propTypes = {
  data: PropTypes.object.isRequired,
  districts: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  isLoadingDistricts: PropTypes.bool,
  disabled: PropTypes.bool,
};
