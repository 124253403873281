import React from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'reactstrap';

const LoadMoreButton = ({ loading, onClick }) => {
  return !loading ? (
    <Button type="button" className="mx-auto d-block" color="light" onClick={onClick}>
      Load More
    </Button>
  ) : (
    <Spinner color="primary" />
  );
};

LoadMoreButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LoadMoreButton;
