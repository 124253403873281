import React, { useEffect, useState } from 'react';
import {
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal as ReactModal,
  ModalBody,
  Row,
  Button,
  Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { edit as ic_edit } from 'react-icons-kit/fa/edit';
import { plus as ic_plus } from 'react-icons-kit/fa/plus';
import { Icon } from 'react-icons-kit';
import ModalHeader from '../../../components/modal/ModalHeader';
import SwitchCheckbox from '../../../shared/components/inputs/SwitchCheckbox';
import { RequiredInputLabel } from '../../../shared/components/typography/RequiredInputLabel';
import ConfirmModal from '../../../components/modal/ConfirmModal';
import RegionMultiSelect from './RegionMultiSelect';
import { ClearDistrictInventoryButton } from './ClearDistrictInventoryButton';

const Modal = styled(ReactModal)`
  @media (min-width: 768px) {
    width: 700px !important;
    max-width: 700px !important;
    min-width: 700px !important;
  }
`;

const initialDistrict = {
  active: true,
  id: '',
  name: '',
  message: '',
  regions: [],
  logoUrl: '',
  address1: '',
};

const initialInputsStatus = {
  readOnlyDistrictId: true,
};

export const DistrictModal = ({
  isOpen,
  onToggle,
  onSubmit,
  district,
  loading,
  openRegionModal,
}) => {
  const [message, setMessage] = useState(initialDistrict.message);
  const [address1, setAddress1] = useState(initialDistrict.address1);
  const [regions, setRegions] = useState(initialDistrict.regions);

  const [active, setActive] = useState(initialDistrict.active);
  const [districtId, setDistrictId] = useState(initialDistrict.id);
  const [districtName, setDistrictName] = useState(initialDistrict.name);

  const [readOnlyDistrictId, setReadOnlyDistrictId] = useState(
    initialInputsStatus.readOnlyDistrictId,
  );

  const [confirmModal, setConfirmModal] = useState(false);

  const [isValid, setValid] = useState(false);

  const setState = (state) => {
    setActive(() => {
      if (typeof state.active === 'boolean') return state.active;
      return initialDistrict.active;
    });
    setMessage(state.message || initialDistrict.message);
    setDistrictId(state.id);
    setDistrictName(state.name);
    setAddress1(state.address1);
    setRegions(state.regions);
  };

  useEffect(() => {
    district && setState(district);
  }, [district]);

  useEffect(() => {
    if (!district) {
      setState(initialDistrict);
      setValid(false);
    }
    setReadOnlyDistrictId(initialInputsStatus.readOnlyDistrictId);
  }, [isOpen]);

  useEffect(() => {
    if (!district) {
      const districtId = districtName.replace(/\b\w/g, (l) => l.toUpperCase()).replace(/ /g, '');
      setDistrictId(districtId);
    }
  }, [districtName]);

  useEffect(() => {
    setValid(districtId && districtName && regions.length);
  }, [districtId, districtName, regions]);

  const handleSubmit = () => {
    const districtData = {
      name: districtName,
      active,
      message,
      districtId,
      regions,
      address1,
    };
    onSubmit(districtData);
  };

  const handleDistrictIdDoubleClick = () => setReadOnlyDistrictId(Boolean(district)); // Can't Edit districtId if district exists

  const title = district ? 'Edit District' : 'Add District';
  const buttonText = district ? 'Update' : 'Add District';

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={onToggle}
        backdrop="static"
        centered
        className="add-district-modal">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            if (!isValid) return;
            if (district && district.active === false && active) {
              return setConfirmModal(true);
            }
            handleSubmit();
          }}>
          <ModalHeader onToggle={onToggle} title={title}>
            <div className="d-flex w-100 justify-content-around">
              {district ? <ClearDistrictInventoryButton districtId={districtId} /> : null}
              <div className="d-flex align-items-center">
                <span>Active</span>
                <SwitchCheckbox
                  name="active"
                  onChange={(evt) => setActive(evt.target.checked)}
                  checked={active}
                />
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={12}>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <RequiredInputLabel>District</RequiredInputLabel>
                      <Input
                        type="text"
                        name="districtName"
                        onChange={(evt) => setDistrictName(evt.target.value)}
                        value={districtName}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <RequiredInputLabel>District ID</RequiredInputLabel>
                      <InputGroup className="input-editable">
                        <Input
                          type="text"
                          name="districtId"
                          value={districtId}
                          onChange={(evt) => setDistrictId(evt.target.value)}
                          onDoubleClick={handleDistrictIdDoubleClick}
                          readOnly={readOnlyDistrictId}
                          required
                        />
                        {readOnlyDistrictId && !district ? (
                          <InputGroupAddon
                            className="disk-icon"
                            addonType="append"
                            onClick={handleDistrictIdDoubleClick}>
                            <InputGroupText>
                              <Icon icon={ic_edit} size={20} />
                            </InputGroupText>
                          </InputGroupAddon>
                        ) : null}
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup>
                      <RequiredInputLabel>Regions</RequiredInputLabel>
                      <InputGroup className="input-editable">
                        <div className="d-flex w-100">
                          <RegionMultiSelect
                            name="regions"
                            value={regions}
                            onChange={(newRegions) => setRegions(newRegions)}
                          />
                          <Button
                            onClick={openRegionModal}
                            className={`ml-3 d-block btn-input-size`}>
                            <Icon icon={ic_plus} />
                          </Button>
                        </div>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <label>Address</label>
                  <InputGroup className="input-editable">
                    <Input
                      type="text"
                      name="address1"
                      value={address1}
                      onChange={(evt) => setAddress1(evt.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <label>Message</label>
                  <Input
                    type="textarea"
                    name="message"
                    placeholder="Optional"
                    onChange={(evt) => setMessage(evt.target.value)}
                    value={message}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <Button
                  block
                  type="submit"
                  color="secondary"
                  className="btn-input-size"
                  disabled={loading || !isValid}>
                  {loading ? <Spinner size="sm" color="primary" /> : buttonText}
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Form>
      </Modal>
      <ConfirmModal
        active={confirmModal}
        toggle={() => setConfirmModal(false)}
        onResponse={(response) => response && handleSubmit()}
        title="Please Confirm"
        body={`Are you sure you want to activate all users of this district?`}
      />
    </>
  );
};

DistrictModal.defaultProps = {
  district: null,
  loading: false,
};

DistrictModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  openReginModal: PropTypes.func,
  district: PropTypes.object,
  loading: PropTypes.bool,
};
