import React from 'react';
import styled from 'styled-components';
import { ListGroup, ListGroupItem, Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useTheme, theme } from '../../../shared/theme';
import {
  ic_arrow_drop_down as arrowDownIcon,
  ic_arrow_drop_up as arrowUpIcon,
} from 'react-icons-kit/md';
import { Icon } from 'react-icons-kit';

export const SubNavigationBar = styled.div`
  width: 340px;
  min-width: 340px;
  background-color: #ffffff;
  border-right: 1px solid #e8e9ed;
  height: 100%;
  overflow-y: auto;
`;

export const SubListItem = styled(ListGroupItem)`
  display: flex;
  align-items: center;
  padding: 0.7rem 2rem;
  background-color: #ffffff;
  border: none;
  transition: 0.2s ease all;
  font-family: 'Zilla Slab';
  color: ${(props) => props.palette.black};
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
  &:hover {
    background-color: ${(props) => props.palette.infoLight};
    border-left: 0;
  }
  &.active {
    color: ${(props) => props.palette.black};
    background-color: ${(props) => props.palette.infoLight};
    border-left: 0;
  }
`;

const currentRoute = (route) => {
  return window.location.pathname === route;
};

export const SubNavigationList = ({ items }) => {
  const theme = useTheme();
  return (
    <ListGroup>
      {items.map(({ href, icon, title, value, ...rest }, i) => (
        <Link key={`sub-nav-item-${i + 1}`} to={href}>
          <SubListItem {...theme} active={currentRoute(href)} {...rest}>
            {icon ? <span className="d-inline-block mr-3">{icon}</span> : null}
            {title}
            <span style={{ fontWeight: 500 }} className="ml-auto text-secondary">
              {value}
            </span>
          </SubListItem>
        </Link>
      ))}
    </ListGroup>
  );
};

const ButtonToggle = styled.button`
  background-color: rgb(232, 233, 237);
  display: block;
  width: 100% !important;
  border: none;
  border-radius: 2px;
  font-size: 22px;
  font-weight: 600;
  color: ${theme.palette.primary};
  margin-left: auto;
  padding: 20px 7px;
  position: relative;
  display: flex;
  padding-left: 33px;
`;

export const CollapsibleSubNavigationList = ({ title, items, defaultOpen = false, ...rest }) => {
  const [isOpen, setIsOpen] = React.useState(defaultOpen);
  const arrowIcon = isOpen ? arrowDownIcon : arrowUpIcon;

  return (
    <div {...rest}>
      <ButtonToggle color onClick={() => setIsOpen(!isOpen)}>
        {title}{' '}
        <Icon
          style={{
            position: 'absolute',
            right: '22px',
          }}
          icon={arrowIcon}
          size="30px"
        />
      </ButtonToggle>
      <Collapse isOpen={isOpen}>
        <SubNavigationList items={items} />
      </Collapse>
    </div>
  );
};

SubNavigationList.propTypes = {
  items: PropTypes.array.isRequired,
};
