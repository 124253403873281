import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import HomeView from '../modules/home/HomeView';
import MyVoiceView from '../modules/my-voice/MyVoiceView';
import MyVoiceLeaderView from '../modules/my-voice/leader/MyVoiceLeaderView';
import InitialAssessmentView from '../modules/my-voice/InitialAssessmentView';
import InitialAssessmentLeaderView from '../modules/my-voice/leader/InitialAssessmentLeaderView';
// import MySchoolView from '../modules/teacher/MySchoolView';
// import MyDashboardView from '../modules/leader/MyDashboardView';
import { ProfileView } from '../modules/profile/ProfileView';
import LeaderProfileEditView from '../modules/profile/LeaderProfileEditView';
import DataImportView from '../modules/data-import/DataImportView';
import ProfileEditView from '../modules/profile/ProfileEditView';
import ProfileResult from '../modules/teacher/profileAlt/ProfileResult';
import { authStore, USER_EVENT } from '../modules/auth/auth-store';
// import ComingSoon from '../modules/landing/ComingSoonView';
import MyDashboardView from '../modules/my-dashboard/MyDashboardView';
import { MyClfDashboardView } from '../modules/my-dashboard/MyClfDashboardView';

// import * as PropTypes from 'prop-types';
import MyDistrict from '../modules/my-district/MyDistrictView';
import { DashboardCoaView } from '../modules/dashboard-coa/DashboardCoaView';

import SuperAdminView from '../modules/super-admin/superAdminView';
import RosterManagerView from '../modules/roster-manager/RosterManagerView';
import MyReportsView from '../modules/my-reports/MyReportsView';
import {
  USER_TYPE_CLF,
  USER_TYPE_COA,
  USER_TYPE_LEADER,
  USER_TYPE_TEACHER,
} from '../shared/userTypes';

// import Covid19DomainsView from '../modules/covid-19-domains/Covid19DomainsView';
// import Covid19DomainsCoaView from '../modules/covid-19-domains/Covid19DomainsCoaView';
import { getTeacherCustomDomains } from '../modules/covid-19-domains/covid-19-domains-actions';

import SolutionsView from '../modules/solutions/SolutionsView';
import { getUserNotificationsSolutionsNew } from '../modules/solutions/solutions-actions';
import { MyDistrictsClfView } from '../modules/my-district/MyDistrictsClfView';
import { InitialAssessmentCoaView } from '../modules/my-voice/InitialAssessmentCoaView';

const TeacherRouter = () => {
  useEffect(() => {
    getTeacherCustomDomains();
  }, []);

  return (
    <Switch>
      {/* sidebar */}
      <ProtectedRoute path="/home" component={HomeView} />
      {/* <ProtectedRoute path="/covid-19-domains" component={Covid19DomainsView} /> */}
      <ProtectedRoute path="/my-voice" component={MyVoiceView} />
      <ProtectedRoute path="/initial-assessment" component={InitialAssessmentView} />
      <ProtectedRoute path="/solutions" component={SolutionsView} />
      {/* <ProtectedRoute path="/my-school" component={MySchoolView} /> */}
      <ProtectedRoute path="/my-school" component={MyVoiceLeaderView} />
      <ProtectedRoute path="/my-profile" component={ProfileView} />
      <ProtectedRoute path="/data-import" component={DataImportView} />
      {/* profile */}
      <ProtectedRoute path="/edit-profile" component={ProfileEditView} />
      <ProtectedRoute path="/profile-result" component={ProfileResult} />
      {/*  Default*/}
      <ProtectedRoute path="/super-admin" component={SuperAdminView} />
      <ProtectedRoute path="/roster-manager" component={RosterManagerView} />
      <Route render={() => <Redirect to={'/'} />} />
    </Switch>
  );
};

const LeaderRouter = () => {
  return (
    <Switch>
      {/* sidebar */}
      <ProtectedRoute path="/home" component={HomeView} />
      {/* <ProtectedRoute path="/my-voice" component={MyVoiceView}/> */}
      {/* <ProtectedRoute path="/covid-19-domains" component={Covid19DomainsView} /> */}
      <ProtectedRoute path="/my-dashboard" component={MyDashboardView} />
      <ProtectedRoute path="/initial-assessment" component={InitialAssessmentLeaderView} />
      <ProtectedRoute path="/solutions" component={SolutionsView} />
      {/* <ProtectedRoute path="/my-school" component={MySchoolView}/> */}
      <ProtectedRoute path="/my-school" component={MyVoiceLeaderView} />
      <ProtectedRoute path="/my-profile" component={ProfileView} />
      <ProtectedRoute path="/data-import" component={DataImportView} />
      {/* profile */}
      <ProtectedRoute path="/edit-profile" component={LeaderProfileEditView} />
      <ProtectedRoute path="/profile-result" component={ProfileResult} />

      <ProtectedRoute path="/super-admin" component={SuperAdminView} />
      <ProtectedRoute path="/roster-manager" component={RosterManagerView} />
      {/*  Default*/}
      <Route render={() => <Redirect to={'/'} />} />
    </Switch>
  );
};

const CoaRouter = () => {
  return (
    <Switch>
      {/* sidebar */}
      <ProtectedRoute path="/my-district" component={MyDistrict} />
      {/* <ProtectedRoute path="/covid-19-domains" component={Covid19DomainsCoaView} /> */}
      <ProtectedRoute path="/initial-assessment" component={InitialAssessmentCoaView} />
      <ProtectedRoute path="/solutions" component={SolutionsView} />
      <ProtectedRoute path="/my-dashboard" component={DashboardCoaView} />
      <ProtectedRoute path="/data-import" component={DataImportView} />
      <ProtectedRoute path="/super-admin" component={SuperAdminView} />
      <ProtectedRoute path="/roster-manager" component={RosterManagerView} />
      <ProtectedRoute path="/my-reports" component={MyReportsView} />
      <ProtectedRoute path="/my-profile" component={ProfileView} />

      <Route render={() => <Redirect to={'/'} />} />
    </Switch>
  );
};

const ClfRouter = () => (
  <Switch>
    <ProtectedRoute path="/my-district" component={MyDistrictsClfView} />
    <ProtectedRoute path="/my-dashboard" component={MyClfDashboardView} />
    <ProtectedRoute path="/my-reports" component={MyReportsView} />
    <ProtectedRoute path="/solutions" component={SolutionsView} />
  </Switch>
);

const Router = () => {
  const user = authStore.getState(USER_EVENT);

  useEffect(() => {
    if (user) getUserNotificationsSolutionsNew();
  }, [user]);

  if (user === null) {
    return (
      <Switch>
        <Route render={() => <Redirect to={'/'} />} />
      </Switch>
    );
  }

  if (user.userType === USER_TYPE_TEACHER) return <TeacherRouter />;
  if (user.userType === USER_TYPE_LEADER) return <LeaderRouter />;
  if (user.userType === USER_TYPE_COA) return <CoaRouter />;
  if (user.userType === USER_TYPE_CLF) return <ClfRouter />;
};

Router.propTypes = {};

export { Router };
