import React from 'react';
import { Button, Popover, PopoverBody } from 'reactstrap';
import Icon from 'react-icons-kit';
import { ic_filter_list } from 'react-icons-kit/md/ic_filter_list';
import styled from 'styled-components';

const ButtonRounded = styled(Button)`
  border-radius: 50%;
`;

export const ButtonFiltersMenu = ({
  open,
  onChangeOpenMenu,
  id,
  children,
  trigger,
  placement,
  popoverProps,
  ...rest
}) => (
  <>
    <ButtonRounded id={id} type="button" color="light" {...rest}>
      <Icon icon={ic_filter_list} size="20px" />
    </ButtonRounded>
    <Popover
      {...popoverProps}
      isOpen={open}
      toggle={() => onChangeOpenMenu(!open)}
      trigger={trigger}
      placement={placement}
      fade
      target={id}>
      <PopoverBody>{children}</PopoverBody>
    </Popover>
  </>
);

ButtonFiltersMenu.defaultProps = {
  trigger: 'click',
  placement: 'bottom',
  popoverProps: {},
};
