import Clear from '../../shared/assets/icons/clear-and-unified-direction.svg';
import Professional from '../../shared/assets/icons/professional-engagement.svg';
import Instructional from '../../shared/assets/icons/instructional-autonomy.svg';
import Collaboration from '../../shared/assets/icons/collaboration.svg';
import Empowerment from '../../shared/assets/icons/empowerment.svg';
import Feedback from '../../shared/assets/icons/feedback-and-reflection.svg';
import Resource from '../../shared/assets/icons/resource-priorities.svg';
import Supporting from '../../shared/assets/icons/support-and-care.svg';
import SenseOfBelonging from '../../shared/assets/icons/sense-of-belonging.svg';
import Advance from '../../shared/assets/icons/teacher-advancement.svg';
//teacher drivers
import DriverClearView from './drivers-views/DriverClearView';
import DriverProfessionalView from './drivers-views/DriverProfessionalView';
import DriverInstructionalView from './drivers-views/DriverInstructionalView';
import DriverCollaborationView from './drivers-views/DriverCollaborationView';
import DriverEmpowermentView from './drivers-views/DriverEmpowermentView';
import DriverFeedbackView from './drivers-views/DriverFeedbackView';
import DriverResourceView from './drivers-views/DriverResourceView';
import DriverSupportView from './drivers-views/DriverSupportView';
import DriverSenseOfBelongingView from './drivers-views/DriverSenseOfBelongingView';
import DriverAdvancementView from './drivers-views/DriverAdvancementView';
//leader drivers
import DriverClearLeaderView from '../my-voice/leader/drivers/DriverClearLeaderView';
import DriverProfessionalLeaderView from '../my-voice/leader/drivers/DriverProfessionalLeaderView';
import DriverInstructionalLeaderView from '../my-voice/leader/drivers/DriverInstructionalLeaderView';
import DriverCollaborationLeaderView from '../my-voice/leader/drivers/DriverCollaborationLeaderView';
import DriverEmpowermentLeaderView from '../my-voice/leader/drivers/DriverEmpowermentLeaderView';
import DriverFeedbackLeaderView from '../my-voice/leader/drivers/DriverFeedbackLeaderView';
import DriverResourceLeaderView from '../my-voice/leader/drivers/DriverResourceLeaderView';
import DriverSupportLeaderView from '../my-voice/leader/drivers/DriverSupportLeaderView';
import DriverSenseOfBelongingLeaderView from '../my-voice/leader/drivers/DriverSenseOfBelongingLeaderView';
import DriverAdvancementLeaderView from '../my-voice/leader/drivers/DriverAdvancementLeaderView';

import { theme } from '../../shared/theme';

export const COLOR_STRONGLY_AGREE = '#043D3C';
export const COLOR_AGREE = '#328285';
export const COLOR_NEUTRAL = '#B9D3DC';
export const COLOR_DISAGREE = '#FCE086';
export const COLOR_STRONGLY_DISAGREE = '#EFB806';
export const COLOR_NOT_FOUND = '#c2c2c2';

export const DRIVERS_LEADER = [
  {
    path: 'clear-and-unified-direction',
    href: '/my-school/clear-and-unified-direction',
    name: 'Clear & Unified Direction',
    shortName: 'Clea',
    id: 'clearUnifiedDirection',
    disabled: false,
    icon: Clear,
    data: 0,
    component: DriverClearLeaderView,
  },
  {
    path: 'professional-engagement',
    href: '/my-school/professional-engagement',
    name: 'Professional Engagement',
    shortName: 'Prof',
    id: 'professionalEngagement',
    disabled: false,
    icon: Professional,
    data: 0,
    component: DriverProfessionalLeaderView,
  },
  {
    path: 'instructional-autonomy',
    href: '/my-school/instructional-autonomy',
    name: 'Instructional Autonomy',
    shortName: 'Inst',
    id: 'instructionalAutonomy',
    disabled: false,
    icon: Instructional,
    data: 0,
    component: DriverInstructionalLeaderView,
  },
  {
    path: 'collaboration',
    href: '/my-school/collaboration',
    name: 'Collaboration',
    shortName: 'Coll',
    id: 'collaboration',
    disabled: false,
    icon: Collaboration,
    data: 0,
    component: DriverCollaborationLeaderView,
  },
  {
    path: 'empowerment',
    href: '/my-school/empowerment',
    name: 'Empowerment',
    shortName: 'Empo',
    id: 'empowerment',
    disabled: false,
    icon: Empowerment,
    data: 0,
    component: DriverEmpowermentLeaderView,
  },
  {
    path: 'feedback-and-reflection',
    href: '/my-school/feedback-and-reflection',
    name: 'Feedback & Reflection',
    shortName: 'Feed',
    id: 'feedbackReflection',
    disabled: false,
    icon: Feedback,
    data: 0,
    component: DriverFeedbackLeaderView,
  },
  {
    path: 'resource-priorities',
    href: '/my-school/resource-priorities',
    name: 'Resource Priorities',
    shortName: 'Reso',
    id: 'resourcePriorities',
    disabled: false,
    icon: Resource,
    data: 0,
    component: DriverResourceLeaderView,
  },
  {
    path: 'support-and-care',
    href: '/my-school/support-and-care',
    name: 'Support & Care',
    shortName: 'Supp',
    id: 'supportCare',
    disabled: false,
    icon: Supporting,
    data: 0,
    component: DriverSupportLeaderView,
  },
  {
    path: 'sense-of-belonging',
    href: '/my-school/sense-of-belonging',
    name: 'Sense of Belonging',
    shortName: 'Sens',
    id: 'senseOfBelonging',
    disabled: false,
    icon: SenseOfBelonging,
    data: 0,
    component: DriverSenseOfBelongingLeaderView,
  },
  {
    path: 'advancement',
    href: '/my-school/advancement',
    name: 'Teacher Advancement',
    shortName: 'Teac',
    id: 'advancement',
    disabled: false,
    icon: Advance,
    data: 0,
    component: DriverAdvancementLeaderView,
  },
];

export const DRIVERS_COA = [
  {
    path: 'clear-and-unified-direction',
    href: '/my-district/clear-and-unified-direction',
    name: 'Clear & Unified Direction',
    shortName: 'Clea',
    id: 'clearUnifiedDirection',
    disabled: false,
    icon: Clear,
    data: 0,
    component: DriverClearLeaderView,
  },
  {
    path: 'professional-engagement',
    href: '/my-district/professional-engagement',
    name: 'Professional Engagement',
    shortName: 'Prof',
    id: 'professionalEngagement',
    disabled: false,
    icon: Professional,
    data: 0,
    component: DriverProfessionalLeaderView,
  },
  {
    path: 'instructional-autonomy',
    href: '/my-district/instructional-autonomy',
    name: 'Instructional Autonomy',
    shortName: 'Inst',
    id: 'instructionalAutonomy',
    disabled: false,
    icon: Instructional,
    data: 0,
    component: DriverInstructionalLeaderView,
  },
  {
    path: 'collaboration',
    href: '/my-district/collaboration',
    name: 'Collaboration',
    shortName: 'Coll',
    id: 'collaboration',
    disabled: false,
    icon: Collaboration,
    data: 0,
    component: DriverCollaborationLeaderView,
  },
  {
    path: 'empowerment',
    href: '/my-district/empowerment',
    name: 'Empowerment',
    shortName: 'Empo',
    id: 'empowerment',
    disabled: false,
    icon: Empowerment,
    data: 0,
    component: DriverEmpowermentLeaderView,
  },
  {
    path: 'feedback-and-reflection',
    href: '/my-district/feedback-and-reflection',
    name: 'Feedback & Reflection',
    shortName: 'Feed',
    id: 'feedbackReflection',
    disabled: false,
    icon: Feedback,
    data: 0,
    component: DriverFeedbackLeaderView,
  },
  {
    path: 'resource-priorities',
    href: '/my-district/resource-priorities',
    name: 'Resource Priorities',
    shortName: 'Reso',
    id: 'resourcePriorities',
    disabled: false,
    icon: Resource,
    data: 0,
    component: DriverResourceLeaderView,
  },
  {
    path: 'support-and-care',
    href: '/my-district/support-and-care',
    name: 'Support & Care',
    shortName: 'Supp',
    id: 'supportCare',
    disabled: false,
    icon: Supporting,
    data: 0,
    component: DriverSupportLeaderView,
  },
  {
    path: 'sense-of-belonging',
    href: '/my-district/sense-of-belonging',
    name: 'Sense of Belonging',
    shortName: 'Sens',
    id: 'senseOfBelonging',
    disabled: false,
    icon: SenseOfBelonging,
    data: 0,
    component: DriverSenseOfBelongingLeaderView,
  },
  {
    path: 'advancement',
    href: '/my-district/advancement',
    name: 'Teacher Advancement',
    shortName: 'Teac',
    id: 'advancement',
    disabled: false,
    icon: Advance,
    data: 0,
    component: DriverAdvancementLeaderView,
  },
];

export const BAR_GRAPH_OPTIONS = {
  chart: {
    type: 'line',
    zoom: {
      enabled: false,
    },
    toolbar: {
      export: {
        csv: {
          filename: undefined,
        },
        svg: {
          filename: undefined,
        },
        png: {
          filename: undefined,
        },
      },
    },
  },
  legend: {
    position: 'top',
    markers: {
      width: 15,
      height: 15,
      radius: 0,
    },
    fontSize: '15px',
    fontFamily: 'Open Sans',
  },
  plotOptions: {
    bar: {
      horizontal: false,
      endingShape: 'rounded',
      columnWidth: '40%',
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
  },
  xaxis: {
    labels: {
      show: true,
      rotate: -55,
      rotateAlways: true,
      minHeight: 100,
      maxHeight: 180,
    },
  },
  yaxis: {
    max: 5,
    min: 0,
    title: {
      text: '',
    },
  },
  colors: [theme.palette.grpahColors.primary, theme.palette.grpahColors.secondary],
  fill: {
    colors: [theme.palette.grpahColors.primary, theme.palette.grpahColors.secondary],
  },
};

export const BAR_GRAPH_OPTIONS_LEADER = {
  chart: {
    type: 'line',
    zoom: {
      enabled: false,
    },
    toolbar: {
      export: {
        csv: {
          filename: undefined,
        },
        svg: {
          filename: undefined,
        },
        png: {
          filename: undefined,
        },
      },
    },
  },
  legend: {
    position: 'top',
  },
  plotOptions: {
    bar: {
      horizontal: false,
      endingShape: 'rounded',
      columnWidth: 70,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 3,
  },
  xaxis: {
    labels: {
      show: true,
      rotate: -55,
      rotateAlways: true,
      minHeight: 100,
      maxHeight: 180,
    },
  },
  yaxis: {
    max: 5,
    min: 0,
    title: {
      text: '',
    },
  },
  colors: [
    theme.palette.grpahColors.tertiary,
    theme.palette.grpahColors.primary,
    theme.palette.secondary,
  ],
  fill: {
    colors: [
      theme.palette.grpahColors.tertiary,
      theme.palette.grpahColors.primary,
      theme.palette.secondary,
    ],
  },
};

export const BAR_GRAPH_OPTIONS_LEADER_SECTIONS = {
  legend: {
    show: false,
  },
  labels: ['Strongly Disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly Agree'],
  dataLabels: {
    enabled: true,
  },
  colors: [
    COLOR_STRONGLY_DISAGREE,
    COLOR_DISAGREE,
    COLOR_NEUTRAL,
    COLOR_AGREE,
    COLOR_STRONGLY_AGREE,
  ],
  fill: {
    colors: [
      COLOR_STRONGLY_DISAGREE,
      COLOR_DISAGREE,
      COLOR_NEUTRAL,
      COLOR_AGREE,
      COLOR_STRONGLY_AGREE,
    ],
  },
  plotOptions: {
    donut: {
      background: 'transparent',
    },
  },
};

export const BAR_GRAPH_OPTIONS_LEADER_SECTIONS_NOT_FOUND = {
  legend: {
    show: false,
  },
  labels: ['0', '1', '2', '3', '4', 'Not Found'],
  dataLabels: {
    enabled: true,
  },
  colors: [COLOR_NOT_FOUND],
  fill: {
    colors: [COLOR_NOT_FOUND],
  },
  plotOptions: {
    donut: {
      background: 'transparent',
    },
  },
};

export const CLEAR_INDICATORS = [
  {
    name:
      '(1.1) I believe the Vision, Mission, and Beliefs (school’s direction) of my school is important.',
    id: 'schoolVision',
    data: 0,
  },
  {
    name: '(1.2) I believe program decisions support the school’s direction.',
    id: 'programDecision',
    data: 0,
  },
  {
    name: '(1.3) I believe policies support the school’s direction.',
    id: 'policiesSupport',
    data: 0,
  },
  {
    name:
      '(1.4) I believe stakeholders share responsibilities for developing the school’s vision, mission, and beliefs.',
    id: 'stakeHoldersResponsibilities',
    data: 0,
  },
  {
    name: '(1.5) I believe the school’s direction is effectively communicated.',
    id: 'schoolDirection',
    data: 0,
  },
];

export const INITIAL_CLEAR_INDICATORS = [
  {
    name:
      '(1.1) The Vision, Mission, and Beliefs (school’s direction) of your school are important.',
    id: 'schoolVision',
    data: 0,
  },
  {
    name: '(1.2) Program decisions support the school’s direction.',
    id: 'programDecision',
    data: 0,
  },
  {
    name: '(1.3) Policies support the school’s direction.',
    id: 'policiesSupport',
    data: 0,
  },
  {
    name:
      '(1.4) Stakeholders share responsibilities for developing the school’s vision, mission, and beliefs.',
    id: 'stakeHoldersResponsibilities',
    data: 0,
  },
  {
    name: '(1.5) The school’s direction is effectively communicated.',
    id: 'schoolDirection',
    data: 0,
  },
];

export const DRIVER_CLEAR_LEADER_MESSAGE = `The school’s vision, mission, and beliefs (school’s direction) are important in developing
    universally understood norms, practices, and policies. Programs are monitored for
    effectiveness in supporting the school’s direction.`;

export const DRIVER_CLEAR_TEACHER_MESSAGE = `The school’s vision, mission, and beliefs (school’s direction) is important in developing
    universally understood norms, practices, and policies. Programs are monitored for
    effectiveness in supporting the school’s direction.`;

export const ADVANCEMENT_MESSAGE = `Opportunities are created for teachers to be leaders and experts in their fields.
Career advancement is fostered through professional learning and innovations that
lead to new professional opportunities.`;

export const COLLABORATION_MESSAGE = `Collaborative planning is required to develop and share instructional resources, and
to embed the professional learning needed for expanding and improving teacher
practices. New instructional designs emanate from shared expertise and support in
using new instructional designs, and then evaluating their effectiveness.`;

export const EMPOWERMENT_MESSAGE = `Teachers have the responsibility to make individual and collective decisions that
impact the school and classroom. Teacher voice and expertise are valued as an
integral part of solving problems, developing school improvement processes, and
planning their own professional growth.`;

export const FEEDBACK_MESSAGE = `Pervasive instructional observations provide immediate feedback and create
conversations about effectiveness. The use of examples from practice and reflection
activities provide clarity for professional growth.`;

export const INSTRUCTIONAL_MESSAGE = `Teachers have the flexibility to make decisions about the success of each student
using multiple metrics. Standards determine the core framework for teachers;
however, flexibility, innovation, and personalization in instructional design is
encouraged, supported, and shared.`;

export const PROFESSIONAL_MESSAGE = `Teachers are lifelong learners necessitating multiple opportunities for them to
engage in professional activities both collectively and individually. Conversations
are created throughout the system to improve practice while modeling the attributes
of a learner.`;

export const RESOURCE_MESSAGE = `Teacher strengths and students needs are aligned in developing schedules that
maximize teachers’ skills. Shared selection of resources are leveraged to activate
innovation and support teachers in meeting the varied needs of students.`;

export const SENSE_MESSAGE = `The feeling of being valued and part of the school brings meaning and importance to
teachers’ work. Teachers avoid working in isolation when they feel a sense of
belonging. Diversity is valued, and programs are in place to make teachers feel like
they belong.`;

export const SUPPORT_MESSAGE = `Experienced teachers are valuable in providing support and mentoring to their
colleagues. Meeting teacher’s personal and professional needs through focused
professional learning with follow-up are essential for professional growth.`;

export const SUPPORT_INDICATORS = [
  {
    name:
      '(8.1) I believe teacher mentoring is embedded through interactions with experienced colleagues.',
    id: 'interactions',
    data: 0,
  },
  {
    name: '(8.2) I believe processes are in place to provide new teachers with timely support.',
    id: 'timelySupport',
    data: 0,
  },
  {
    name:
      '(8.3) I believe teachers observe and give feedback to each other through peer observations.',
    id: 'peerObservations',
    data: 0,
  },
  {
    name:
      '(8.4) I believe teachers have created support structures to seek advice and discuss concerns around classroom practices.',
    id: 'structures',
    data: 0,
  },
  {
    name: '(8.5) I believe teachers care about each other.',
    id: 'care',
    data: 0,
  },
];

export const INITIAL_SUPPORT_INDICATORS = [
  {
    name: '(8.1) Teacher mentoring is embedded through interactions with experienced colleagues.',
    id: 'interactions',
    data: 0,
  },
  {
    name: '(8.2) Processes are in place to provide new teachers with timely support.',
    id: 'timelySupport',
    data: 0,
  },
  {
    name: '(8.3) Teachers observe and give feedback to each other through peer observations.',
    id: 'peerObservations',
    data: 0,
  },
  {
    name:
      '(8.4) Teachers have created support structures to seek advice and discuss concerns around classroom practices.',
    id: 'structures',
    data: 0,
  },
  {
    name: '(8.5) Teachers care about each other.',
    id: 'care',
    data: 0,
  },
];

export const ADVANCEMENT_INDICATORS = [
  {
    name: '(10.1) I believe teacher interests are used to expand opportunities for career growth.',
    id: 'opportunities',
    data: 0,
  },
  {
    name: '(10.2) I believe opportunities are created for teachers to be leaders.',
    id: 'leaderOpportunities',
    data: 0,
  },
  {
    name: '(10.3) I believe professional learning supports career advancement opportunities.',
    id: 'professionalLearning',
    data: 0,
  },
  {
    name: '(10.4) I believe teachers are supported in becoming experts in their field(s).',
    id: 'supportTeachers',
    data: 0,
  },
  {
    name: '(10.5) I believe teachers feel valued for their expertise.',
    id: 'expertise',
    data: 0,
  },
];

export const INITIAL_ADVANCEMENT_INDICATORS = [
  {
    name: '(10.1) Teacher interests are used to expand opportunities for career growth.',
    id: 'opportunities',
    data: 0,
  },
  {
    name: '(10.2) Opportunities are created for teachers to be leaders.',
    id: 'leaderOpportunities',
    data: 0,
  },
  {
    name: '(10.3) Professional learning supports career advancement opportunities.',
    id: 'professionalLearning',
    data: 0,
  },
  {
    name: '(10.4) Teachers are supported in becoming experts in their field(s).',
    id: 'supportTeachers',
    data: 0,
  },
  {
    name: '(10.5) Teachers feel valued because of their expertise.',
    id: 'expertise',
    data: 0,
  },
];

export const COLLABORATION_INDICATORS = [
  {
    name:
      '(4.1) I believe time is regularly allocated for collaborative planning across grades and/or disciplines.',
    id: 'timeAllocation',
    data: 0,
  },
  {
    name:
      '(4.2) I believe teachers collaboratively plan instructional lessons and select resources.',
    id: 'plan',
    data: 0,
  },
  {
    name:
      '(4.3) I believe teachers collectively assess the effectiveness of instructional practices.',
    id: 'assess',
    data: 0,
  },
  {
    name: '(4.4) I believe professional learning is a part of the collaborative planning process.',
    id: 'learning',
    data: 0,
  },
];

export const INITIAL_COLLABORATION_INDICATORS = [
  {
    name:
      '(4.1) Time is regularly allocated for collaborative planning across grades and/or disciplines.',
    id: 'timeAllocation',
    data: 0,
  },
  {
    name: '(4.2) Teachers collaboratively plan instructional lessons and select resources',
    id: 'plan',
    data: 0,
  },
  {
    name: '(4.3) Teachers collectively assess the effectiveness of instructional practices.',
    id: 'assess',
    data: 0,
  },
  {
    name: '(4.4) Professional learning is a part of the collaborative planning process.',
    id: 'learning',
    data: 0,
  },
];

export const EMPOWERMENT_INDICATORS = [
  {
    name:
      '(5.1) I believe teachers have the freedom to make classroom-level decisions to meet the needs of students.',
    id: 'freedom',
    data: 0,
  },
  {
    name: '(5.2) I believe teachers are involved in developing school rules and policies.',
    id: 'rules',
    data: 0,
  },
  {
    name: '(5.3) I believe teachers are involved in school improvement processes.',
    id: 'improvement',
    data: 0,
  },
  {
    name: '(5.4) I believe teacher engagement in school-wide decisions is valued.',
    id: 'engagement',
    data: 0,
  },
  {
    name:
      '(5.5) I believe teacher voice is important in the development of professional learning opportunities.',
    id: 'learning',
    data: 0,
  },
];

export const INITIAL_EMPOWERMENT_INDICATORS = [
  {
    name:
      '(5.1) Teachers have the freedom to make classroom-level decisions to meet the needs of students.',
    id: 'freedom',
    data: 0,
  },
  {
    name: '(5.2) Teachers are involved in developing school rules and policies.',
    id: 'rules',
    data: 0,
  },
  {
    name: '(5.3) Teachers are involved in school improvement processes.',
    id: 'improvement',
    data: 0,
  },
  {
    name: '(5.4) Teacher engagement in school-wide decisions is valued.',
    id: 'engagement',
    data: 0,
  },
  {
    name:
      '(5.5) Teacher voice is important in the development of professional learning opportunities.',
    id: 'learning',
    data: 0,
  },
];

export const FEEDBACK_INDICATORS = [
  {
    name: '(6.1) I believe classroom observations and walkthroughs frame specific feedback.',
    id: 'walkthroughs',
    data: 0,
  },
  {
    name: '(6.2) I believe feedback on instructional practice is immediate.',
    id: 'practice',
    data: 0,
  },
  {
    name:
      '(6.3) I believe artifacts and evidence from practice are used to engage teachers in conversations about their practices.',
    id: 'engage',
    data: 0,
  },
  {
    name:
      '(6.4) I believe reflection is modeled and encouraged to help teachers learn from their own and the practices of others.',
    id: 'encouraged',
    data: 0,
  },
];

export const INITIAL_FEEDBACK_INDICATORS = [
  {
    name: '(6.1) Classroom observations and walkthroughs frame specific feedback.',
    id: 'walkthroughs',
    data: 0,
  },
  {
    name: '(6.2) Feedback on instructional practice is immediate.',
    id: 'practice',
    data: 0,
  },
  {
    name:
      '(6.3) Artifacts and evidence from practice are used to engage teachers in conversations about their practices.',
    id: 'engage',
    data: 0,
  },
  {
    name:
      '(6.4) Reflection is modeled and encouraged to help teachers learn from their own and the practices of others.',
    id: 'encouraged',
    data: 0,
  },
];

export const INSTRUCTIONAL_INDICATORS = [
  {
    name: '(3.1) I believe the curriculum allows for flexibility at the school level.',
    id: 'curriculumFlexibility',
    data: 0,
  },
  {
    name: '(3.2) I believe teachers have the autonomy to make classroom instructional decisions.',
    id: 'classroomDecisions',
    data: 0,
  },
  {
    name:
      '(3.3) I believe teachers are encouraged, supported, and feel safe in trying new instructional designs.',
    id: 'instructionalDesigns',
    data: 0,
  },
  {
    name:
      '(3.4) I believe high-stakes testing is viewed as only one part of a comprehensive assessment system.',
    id: 'comprehensiveAssessment',
    data: 0,
  },
  {
    name: '(3.5) I believe effective instructional models are shared.',
    id: 'instructionalModels',
    data: 0,
  },
];

export const INITIAL_INSTRUCTIONAL_INDICATORS = [
  {
    name: '(3.1) The curriculum allows for flexibility at the school level.',
    id: 'curriculumFlexibility',
    data: 0,
  },
  {
    name: '(3.2) Teachers have the autonomy to make classroom instructional decisions.',
    id: 'classroomDecisions',
    data: 0,
  },
  {
    name:
      '(3.3) Teachers are encouraged, supported, and feel safe in trying new instructional designs.',
    id: 'instructionalDesigns',
    data: 0,
  },
  {
    name:
      '(3.4) High-stakes testing is viewed as only one part of a comprehensive assessment system.',
    id: 'comprehensiveAssessment',
    data: 0,
  },
  {
    name: '(3.5) Effective instructional models are shared.',
    id: 'instructionalModels',
    data: 0,
  },
];

export const PROFESSIONAL_INDICATORS = [
  {
    name:
      '(2.1) I believe professional growth is an expectation to meet student, teacher, and system needs.',
    id: 'professionalGrowth',
    data: 0,
  },
  {
    name:
      '(2.2) I believe professional learning is offered through a variety of modalities (individual, group, online, etc).',
    id: 'learningModalities',
    data: 0,
  },
  {
    name: '(2.3) I believe professional learning initiatives improves instructional practices.',
    id: 'professionalLearning',
    data: 0,
  },
  {
    name: '(2.4) I believe leaders learn with teachers.',
    id: 'learnWithTeachers',
    data: 0,
  },
  {
    name: '(2.5) I believe conversations on instructional practices occur regularly.',
    id: 'instructionalPractices',
    data: 0,
  },
  {
    name: '(2.6) I believe follow-up support is offered after professional learning has occurred.',
    id: 'followUp',
    data: 0,
  },
];

export const INITIAL_PROFESSIONAL_INDICATORS = [
  {
    name: '(2.1) Professional growth is an expectation to meet student, teacher, and system needs.',
    id: 'professionalGrowth',
    data: 0,
  },
  {
    name:
      '(2.2) Professional learning is offered through a variety of modalities (individual, group, online, etc)',
    id: 'learningModalities',
    data: 0,
  },
  {
    name: '(2.3) Professional learning initiatives improves instructional practices.',
    id: 'professionalLearning',
    data: 0,
  },
  {
    name: '(2.4) Leaders learn with teachers.',
    id: 'learnWithTeachers',
    data: 0,
  },
  {
    name: '(2.5) Conversations on instructional practices occur regularly.',
    id: 'instructionalPractices',
    data: 0,
  },
  {
    name: '(2.6) Follow-up support is offered after professional learning has occurred.',
    id: 'followUp',
    data: 0,
  },
];

export const RESOURCE_INDICATORS = [
  {
    name: '(7.1) I believe teacher schedules are based upon their strengths.',
    id: 'schedulesStrengths',
    data: 0,
  },
  {
    name: "(7.2) I believe teacher schedules are based upon students' needs.",
    id: 'schedulesNeeds',
    data: 0,
  },
  {
    name: '(7.3) I believe teachers are involved in the selection of instructional resources.',
    id: 'instructionalResources',
    data: 0,
  },
  {
    name: '(7.4) I believe resources are allocated for teachers to innovate.',
    id: 'teachersInnovate',
    data: 0,
  },
];

export const INITIAL_RESOURCE_INDICATORS = [
  {
    name: '(7.1) Teacher schedules are based upon their strengths.',
    id: 'schedulesStrengths',
    data: 0,
  },
  {
    name: "(7.2) Teacher schedules are based upon students' needs.",
    id: 'schedulesNeeds',
    data: 0,
  },
  {
    name: '(7.3) Teachers are involved in the selection of instructional resources.',
    id: 'instructionalResources',
    data: 0,
  },
  {
    name: '(7.4) Resources are allocated for the teachers to innovate.',
    id: 'teachersInnovate',
    data: 0,
  },
];

export const SENSE_INDICATORS = [
  {
    name: '(9.1) I believe teachers feel their safety and well-being are valued.',
    id: 'teachersValued',
    data: 0,
  },
  {
    name: '(9.2) I believe teachers feel valued for the diversity they bring to the school.',
    id: 'teachersDiversity',
    data: 0,
  },
  {
    name: '(9.3) I believe teachers feel like they are a part of the school.',
    id: 'teachersParts',
    data: 0,
  },
  {
    name: '(9.4) I believe teachers feel like they belong in the school.',
    id: 'teachersBelonging',
    data: 0,
  },
  {
    name: '(9.5) I believe school programs create a sense of belonging.',
    id: 'teacherSenseBelonging',
    data: 0,
  },
];

export const INITIAL_SENSE_INDICATORS = [
  {
    name: '(9.1) Teachers feel their safety and well-being are valued.',
    id: 'teachersValued',
    data: 0,
  },
  {
    name: '(9.2) Teachers feel valued for the diversity they bring to the school.',
    id: 'teachersDiversity',
    data: 0,
  },
  {
    name: '(9.3) Teachers feel like they are a part of the school.',
    id: 'teachersParts',
    data: 0,
  },
  {
    name: '(9.4) Teachers feel like they belong in the school.',
    id: 'teachersBelonging',
    data: 0,
  },
  {
    name: '(9.5) School programs create a sense of belonging.',
    id: 'teacherSenseBelonging',
    data: 0,
  },
];

//LEADER

export const INITIAL_RESOURCE_INDICATORS_LEADER = [
  {
    name: '(7.1) Teacher schedules are based on their strengths.',
    id: 'schedulesStrengths',
    data: 0,
  },
  {
    name: "(7.2) Teacher schedules are based on students' needs. ",
    id: 'schedulesNeeds',
    data: 0,
  },
  {
    name: '(7.3) Teachers are involved in the selection of instructional resources.',
    id: 'instructionalResources',
    data: 0,
  },
  {
    name: '(7.4) Resources are allocated for teachers to innovate.',
    id: 'teachersInnovate',
    data: 0,
  },
];

export const CLEAR_QUESTIONS = [
  'schoolVision',
  'programDecision',
  'policiesSupport',
  'stakeHoldersResponsibilities',
  'schoolDirection',
];
export const PROFESSIONAL_QUESTIONS = [
  'professionalGrowth',
  'learningModalities',
  'professionalLearning',
  'learnWithTeachers',
  'instructionalPractices',
  'followUp',
];
export const INSTRUCTIONAL_QUESTIONS = [
  'curriculumFlexibility',
  'classroomDecisions',
  'instructionalDesigns',
  'comprehensiveAssessment',
  'instructionalModels',
];
export const COLLABORATION_QUESTIONS = ['timeAllocation', 'plan', 'assess', 'learning'];
export const EMPOWERMENT_QUESTIONS = ['freedom', 'rules', 'improvement', 'engagement', 'learning'];
export const FEEDBACK_QUESTIONS = ['walkthroughs', 'practice', 'engage', 'encouraged'];
export const RESOURCE_QUESTIONS = [
  'schedulesStrengths',
  'schedulesNeeds',
  'instructionalResources',
  'teachersInnovate',
];
export const SUPPORT_QUESTIONS = [
  'interactions',
  'timelySupport',
  'peerObservations',
  'structures',
  'care',
];
export const SENSE_QUESTIONS = [
  'teachersValued',
  'teachersDiversity',
  'teachersParts',
  'teachersBelonging',
  'teacherSenseBelonging',
];
export const ADVANCEMENT_QUESTIONS = [
  'opportunities',
  'leaderOpportunities',
  'professionalLearning',
  'supportTeachers',
  'expertise',
];

export const DRIVERS = [
  {
    path: 'clear-and-unified-direction',
    href: '/my-voice/clear-and-unified-direction',
    name: 'Clear & Unified Direction',
    shortName: 'Clea',
    id: 'clearUnifiedDirection',
    disabled: false,
    icon: Clear,
    data: 0,
    component: DriverClearView,
    indicators: CLEAR_INDICATORS,
  },
  {
    path: 'professional-engagement',
    href: '/my-voice/professional-engagement',
    name: 'Professional Engagement',
    shortName: 'Prof',
    id: 'professionalEngagement',
    disabled: false,
    icon: Professional,
    data: 0,
    component: DriverProfessionalView,
    indicators: PROFESSIONAL_INDICATORS,
  },
  {
    path: 'instructional-autonomy',
    href: '/my-voice/instructional-autonomy',
    name: 'Instructional Autonomy',
    shortName: 'Inst',
    id: 'instructionalAutonomy',
    disabled: false,
    icon: Instructional,
    data: 0,
    component: DriverInstructionalView,
    indicators: INSTRUCTIONAL_INDICATORS,
  },
  {
    path: 'collaboration',
    href: '/my-voice/collaboration',
    name: 'Collaboration',
    shortName: 'Coll',
    id: 'collaboration',
    disabled: false,
    icon: Collaboration,
    data: 0,
    component: DriverCollaborationView,
    indicators: COLLABORATION_INDICATORS,
  },
  {
    path: 'empowerment',
    href: '/my-voice/empowerment',
    name: 'Empowerment',
    shortName: 'Empo',
    id: 'empowerment',
    disabled: false,
    icon: Empowerment,
    data: 0,
    component: DriverEmpowermentView,
    indicators: EMPOWERMENT_INDICATORS,
  },
  {
    path: 'feedback-and-reflection',
    href: '/my-voice/feedback-and-reflection',
    name: 'Feedback & Reflection ',
    shortName: 'Feed',
    id: 'feedbackReflection',
    disabled: false,
    icon: Feedback,
    data: 0,
    component: DriverFeedbackView,
    indicators: FEEDBACK_INDICATORS,
  },
  {
    path: 'resource-priorities',
    href: '/my-voice/resource-priorities',
    name: 'Resource Priorities ',
    shortName: 'Reso',
    id: 'resourcePriorities',
    disabled: false,
    icon: Resource,
    data: 0,
    component: DriverResourceView,
    indicators: RESOURCE_INDICATORS,
  },
  {
    path: 'support-and-care',
    href: '/my-voice/support-and-care',
    name: 'Support & Care ',
    shortName: 'Supp',
    id: 'supportCare',
    disabled: false,
    icon: Supporting,
    data: 0,
    component: DriverSupportView,
    indicators: SUPPORT_INDICATORS,
  },
  {
    path: 'sense-of-belonging',
    href: '/my-voice/sense-of-belonging',
    name: 'Sense of Belonging',
    shortName: 'Sens',
    id: 'senseOfBelonging',
    disabled: false,
    icon: SenseOfBelonging,
    data: 0,
    component: DriverSenseOfBelongingView,
    indicators: SENSE_INDICATORS,
  },
  {
    path: 'advancement',
    href: '/my-voice/advancement',
    name: 'Teacher Advancement',
    shortName: 'Teac',
    id: 'advancement',
    disabled: false,
    icon: Advance,
    data: 0,
    component: DriverAdvancementView,
    indicators: ADVANCEMENT_INDICATORS,
  },
];
