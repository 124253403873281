import firebase from 'firebase';
import Flux from '@cobuildlab/flux-state';
import jwtDecode from 'jwt-decode';
import {
  authStore,
  LOGIN_ERROR_EVENT,
  LOGIN_EVENT,
  LOGOUT_EVENT,
  PASSWORD_UPDATE_ERROR,
  PASSWORD_UPDATE_EVENT,
  REQUEST_RECOVER_PASSWORD,
  UPDATE_USER_EVENT,
  USER_CLF_DISTRICTS,
  USER_CLF_DISTRICTS_ERROR,
  USER_ERROR,
  USER_EVENT,
  USER_BY_TOKEN_EVENT,
} from './auth-store';
import { error, log } from 'pure-logger';
import { UserModel } from './auth-models';
import * as R from 'ramda';
import { isValidString } from '../../shared/strings';
import { is } from '../../shared/utils';
import { USER_TYPE_COA, USER_TYPE_TEACHER } from '../../shared/userTypes';
import { saveUserInDB } from '../super-admin/super-admin-actions';

/**
 * Logs the user Log in event.
 *
 * @param firebaseUser
 */
const logUserLogIn = (firebaseUser) => {
  const DB = firebase.firestore();
  const logInLogsCollection = DB.collection('logInLogs');

  const user = authStore.getState(USER_EVENT);
  const logIn = {
    email: firebaseUser.email,
    date: firebase.firestore.FieldValue.serverTimestamp(),
  };

  if (user.userType !== USER_TYPE_COA) {
    logIn.schoolId = user.schoolId;
  }

  logInLogsCollection.add(logIn).then((data) => {
    console.log(`logUserLogIn:`, firebaseUser);
  });
};

/**
 * @param {string} email - User login email.
 * @param rawEmail
 * @param {string} uid - User login uid.
 * @returns {object} - User.
 */
export const loginValidation = async (rawEmail, uid) => {
  const email = rawEmail.toLowerCase();
  let user = await fetchUser(email);

  // If the User does not exist, we need to create him
  if (!user) user = await createUser({ email, uid });

  // data completion
  if (!is(user.needsProfile)) user.needsProfile = true;
  if (!is(user.needsPasswordReset)) user.needsPasswordReset = true;
  if (!isValidString(user.userType)) user.userType = USER_TYPE_TEACHER;

  //Active Validation
  if (!is(user.active) || user.active === false) {
    await logOutAction();
    const e = new Error('Your user is no longer active');
    throw e;
  }

  // Need Change Password
  if (!is(user.needsPasswordReset) || user.needsPasswordReset === true) {
    await logOutAction();
    await requestPasswordReset(user.email);
    const e = new Error(
      'It is required to change the password for your account. An Email has been sent with the instructions to complete the change.',
    );
    throw e;
  }

  return user;
};

/**
 * Login with Firebase.
 *
 * @param email
 * @param password
 * @param rememberMe
 * @returns {Promise<{user: UserModel}|void>}
 */
export const loginAction = async ({ email, password, rememberMe, customToken }) => {
  email = email.trim(); // Delete spaces at the beginning and end
  await firebase
    .auth()
    .setPersistence(
      rememberMe ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION,
    );
  const AUTH = firebase.auth();

  let data;
  try {
    if (customToken) data = await AUTH.signInWithCustomToken(customToken);
    else data = await AUTH.signInWithEmailAndPassword(email, password);
  } catch (e) {
    error('loginAction', e);
    return Flux.dispatchEvent(LOGIN_ERROR_EVENT, new Error(e.message));
  }

  let firebaseUser = {};
  if (customToken) {
    const decode = jwtDecode(customToken);
    firebaseUser.uid = decode.uid;
    firebaseUser.email = decode.claims.email;
  } else {
    firebaseUser = data.user;
  }

  let user = null;
  try {
    user = await loginValidation(firebaseUser.email, firebaseUser.uid);
  } catch (err) {
    error('loginAction', err);
    return Flux.dispatchEvent(LOGIN_ERROR_EVENT, err);
  }

  Flux.dispatchEvent(LOGIN_EVENT, { user, customToken });
  Flux.dispatchEvent(USER_EVENT, user);

  logUserLogIn(firebaseUser);
  return { user };
};

/**
 * Logout with Firebase.
 *
 * @returns {Promise<void>}
 */
export const logOutAction = async () => {
  const AUTH = firebase.auth();
  await AUTH.signOut();
  Flux.dispatchEvent(LOGOUT_EVENT, {});
};

/**
 * Fetches the User of the Platform.
 *
 * @param email - The Email of the User.
 * @returns {Promise<UserModel>} The data of the User or null if does not exist.
 */
export const fetchUser = async (email) => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');
  const schoolsCollection = DB.collection('schools');
  const districtsCollection = DB.collection('districts');
  const lastLoginCollection = DB.collection('logInLogs');

  const userRef = usersCollection.doc(email.toLowerCase());
  let query;
  try {
    query = await userRef.get({ source: 'server' });
  } catch (e) {
    Flux.dispatchEvent(USER_ERROR, new Error(e.message));
    throw e;
  }
  if (!query.exists) return null;
  const user = query.data();

  user.school = {};
  if (isValidString(user.schoolId)) {
    const schoolRef = schoolsCollection.doc(user.schoolId);
    const schoolQuery = await schoolRef.get({ source: 'server' });
    if (schoolQuery.exists) user.school = schoolQuery.data();
  }
  // District Data
  if (isValidString(user.districtId)) {
    const districtRef = districtsCollection.doc(user.districtId);
    const districtQuery = await districtRef.get({ source: 'server' });
    if (districtQuery.exists) user.district = districtQuery.data();
  }

  try {
    const lastLoginRef = await lastLoginCollection
      .where('email', '==', email)
      .limit(1)
      .get();
    if (lastLoginRef.docs.length > 0) {
      const lastLogin = lastLoginRef.docs[0].data();
      user.lastLogin = lastLogin.date.toDate();
    }
  } catch (e) {
    console.log("Can't get last login");
  }

  Flux.dispatchEvent(USER_EVENT, user);

  return user;
};

/**
 * Creates a User of the Platform.
 *
 * @param firebaseUser - The Firebase User.
 * @returns {Promise<UserModel>} The data of the User or null if does not exist.
 */
export const createUser = async (firebaseUser) => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');

  const user = R.clone(UserModel);
  user.email = firebaseUser.email;
  user.id = firebaseUser.uid;

  const userRef = usersCollection.doc(firebaseUser.email);
  try {
    await userRef.set(user, { merge: true });
  } catch (e) {
    Flux.dispatchEvent(USER_EVENT, e);
    throw e;
  }

  Flux.dispatchEvent(USER_EVENT, user);
  return user;
};

/**
 * Updates a User of the Platform.
 *
 * @param UserModel - A User data.
 * @returns {Promise<UserModel>} The data of the User or null if does not exist.
 */
export const updateUser = async ({ firstName, lastName, picture }) => {
  const currentUser = authStore.getState(USER_EVENT) || {};
  const lastUpdatedUser = authStore.getState(UPDATE_USER_EVENT) || {};

  const user = { ...currentUser, ...lastUpdatedUser };

  const update = { firstName, lastName };
  if (picture) update.image = picture;

  const dataUpdated = { ...user, ...update };

  if (picture && picture instanceof File === false) {
    const ERROR = new Error('Picture is not a File');
    Flux.dispatchEvent(USER_ERROR, ERROR);
    throw ERROR;
  }

  try {
    const userUpdated = await saveUserInDB(dataUpdated);
    Flux.dispatchEvent(UPDATE_USER_EVENT, userUpdated);
    return userUpdated;
  } catch (e) {
    Flux.dispatchEvent(USER_ERROR, e.message);
  }
};

export const requestPasswordReset = async (email) => {
  const validateUserByEmail = firebase.functions().httpsCallable('validateUserByEmail');
  let data = {};
  try {
    const response = await validateUserByEmail({ email });
    data = response.data;
  } catch {
    Flux.dispatchEvent(USER_ERROR, new Error('User not exist'));
    return;
  }
  if (data.active === false) {
    Flux.dispatchEvent(
      USER_ERROR,
      new Error("The user is inactive, you can't change the password"),
    );
    return;
  }
  const AUTH = firebase.auth();
  AUTH.sendPasswordResetEmail(email)
    .then((send) => Flux.dispatchEvent(REQUEST_RECOVER_PASSWORD, send))
    .catch((err) => Flux.dispatchEvent(USER_ERROR, err));
};

/**
 * @param {string} code - The confirmation code send via email to the user.
 * @param {string} password - The new password.
 * @param {string} email - The email to search the collection and update the property.
 * @returns {Promise<void>}
 *
 */
export const updatePassword = async (code, password, email) => {
  log(`updatePassword:`, code, password, email);
  const AUTH = await firebase.auth();
  try {
    await AUTH.confirmPasswordReset(code, password);
  } catch (err) {
    console.log('PASSWORD_UPDATE_ERROR confirmPasswordReset');
    return Flux.dispatchEvent(PASSWORD_UPDATE_ERROR, err);
  }

  const updatePassword = firebase.functions().httpsCallable('updatePassword');
  try {
    await updatePassword({ email });
  } catch (err) {
    console.log('PASSWORD_UPDATE_ERROR updatePassword err', err);
  }
  console.log('PASSWORD_UPDATE_EVENT');
  Flux.dispatchEvent(PASSWORD_UPDATE_EVENT);
};

// export const updateEmails = async () => {
//   const DB = firebase.firestore();
//
//   DB.collection('users').get().then(async (querySnapshot) => {
//     querySnapshot.forEach(async (doc) => {
//       const email = doc.id;
//       const data = doc.data();
//       console.log('HAS UPPER CASE', hasUpperCase(email), email);
//       if (hasUpperCase(email)) {
//         console.log(`TOBE UPDATED`, email, ' => ', data);
//         const newEmail = email.toLowerCase();
//         data.email = newEmail;
//         await DB.collection('users').doc(newEmail).set({ ...data }, { merge: true });
//         await DB.collection('users').doc(email).delete();
//       }
//     });
//   });
// };
//
// const hasUpperCase = (text) => {
//   for (let i = 0, j = text.length; i < j; i++)
//     if (text.charAt(i) !== '@' && text.charAt(i) !== '.')
//       if (text.charAt(i) === text.charAt(i).toUpperCase())
//         return true;
//   return false;
// };

export const getUserClfDistrictsData = async () => {
  const user = authStore.getState(USER_EVENT);
  const call = firebase.functions().httpsCallable('getUserClfDistrictsData');
  try {
    const response = await call({ email: user.email });
    Flux.dispatchEvent(USER_CLF_DISTRICTS, response.data);
    console.log('data', response.data);
  } catch (err) {
    console.log(err);
    Flux.dispatchEvent(USER_CLF_DISTRICTS_ERROR, err);
  }
};

export const getUserByCogniaToken = async (token) => {
  const call = firebase.functions().httpsCallable('getUserByCogniaToken');
  try {
    const result = await call(token);
    const { user, token: customToken } = result.data;
    Flux.dispatchEvent(USER_BY_TOKEN_EVENT, { ...user, customToken });
  } catch (err) {
    Flux.dispatchEvent(USER_ERROR, err.message);
  }
};
