import elementary from '../../shared/assets/images/marker-ELEMENTARY.png';
import high from '../../shared/assets/images/marker-HIGH.png';
import middle from '../../shared/assets/images/marker-MIDDLE.png';
import other from '../../shared/assets/images/marker-OTHER.png';

/**
 * Marker icon handler.
 *
 * @param schoolType
 * @param {string} schoolType, - Type of icon to display.
 */
export const getIconMarker = (schoolType) => {
  switch (schoolType) {
  case 'ELEM':
    return elementary;

  case 'HIGH':
    return high;

  case 'MIDDLE':
    return middle;

  case 'OTHER':
    return other;

  default:
    return '';
  }
};

const elementaryCircle = {
  letter: 'E',
  background: '#4EE57C',
  border: '3px solid rgb(67, 181, 92)',
  letterColor: '#276637',
};

const highCircle = {
  letter: 'H',
  background: '#FE293A',
  border: '3px solid rgb(206, 25, 47)',
  letterColor: '#6B101B',
};

const middleCircle = {
  letter: 'M',
  background: '#FCDD2B',
  border: '3px solid rgb(201, 170, 40)',
  letterColor: '#685B19',
};

const otherCircle = {
  letter: 'O',
  background: '#4AD6ED',
  border: '3px solid rgb(60, 176, 188)',
  letterColor: '#194547',
};

/**
 * Marker icon handler.
 *
 * @param schoolType
 * @param {string} schoolType, - Type of icon to display.
 */
export const getStyleCircle = (schoolType) => {
  switch (schoolType) {
  case 'ELEM':
    return elementaryCircle;

  case 'HIGH':
    return highCircle;

  case 'MIDDLE':
    return middleCircle;

  default:
    return otherCircle;
  }
};
