import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

const NoResultsRow = ({ colSpan = 0 }) => (
  <tr>
    <td colSpan={colSpan}>
      <Col md={12} className="content-sub-bar justify-content-center d-flex">
        No results found
      </Col>
    </td>
  </tr>
);

NoResultsRow.defaultProps = {
  colSpan: 0,
};

NoResultsRow.propTypes = {
  colSpan: PropTypes.number,
};

export default NoResultsRow;
