import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Circle = ({ letter, background, border, letterColor }) => {
  const Div = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 10px;
    background: ${background};
    border: ${border};
  `;
  const H2 = styled.h2`
    font-family: sans-serif;
    font-size: 20px;
    font-weight: bold;
    margin: 5px;
    color: ${letterColor};
  `;

  return (
    <Div>
      <H2>{letter}</H2>
    </Div>
  );
};

Circle.propTypes = {
  letter: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  border: PropTypes.string.isRequired,
  letterColor: PropTypes.string.isRequired,
};

export default Circle;
