import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

/**
 * Custom Modal Header.
 *
 * @param {string} title - Modal Title.
 * @param {Function} onToggle - Function to trigger on toggle.
 * @param {*} children - Children.
 * @returns {*} - Modal Header.
 * @class
 */
const ModalHeader = ({ title, onToggle, children = null }) => {
  return (
    <div className="modal-header flex-wrap align-items-center">
      <Col md={!children ? 12 : 4}>
        <h5 className="modal-title my-2">{title}</h5>
      </Col>
      <Col md={8} className="d-flex align-items-center justify-content-end">
        {children}
      </Col>
      <button type="button" className="close" onClick={onToggle}>
        <span>&times;</span>
      </button>
    </div>
  );
};

ModalHeader.defaultProps = {
  children: null,
};

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default ModalHeader;
