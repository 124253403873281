import React from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

const DropZone = ({ onFiles, children, ...rest }) => {
  const { getRootProps, getInputProps } = useDropzone({ noKeyboard: true, onDrop: onFiles });
  return (
    <div {...getRootProps({ className: 'dropzone' })} {...rest}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

DropZone.propTypes = {
  onFiles: PropTypes.func.isRequired,
};

export { DropZone };
