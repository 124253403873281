import React from 'react';
import { Form, Button } from 'reactstrap';
import * as R from 'ramda';
import { UserModel } from './auth-models';
import View from '@cobuildlab/react-flux-state';
import { updatePassword } from './auth-actions';
import { customToast } from '../../shared/toast';
import { getUrlParam } from '../../shared/query-string';
import { authStore, PASSWORD_UPDATE_ERROR, PASSWORD_UPDATE_EVENT } from './auth-store';
import { onErrorMixin } from '../../shared/mixins';
import { AuthLayout, AuthContent } from '../../shared/layouts/AuthLayout';
import { TextField } from '../../shared/components/inputs/TextField';
import { ClipLoader } from '../../shared/components/progress/ClipLoader';

/**
 * Change Password View.
 */

class ChangePasswordView extends View {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userModel: R.clone(UserModel),
      firstInput: '',
      secondInput: '',
      email: '',
      code: getUrlParam(this.props.location.search, 'oobCode'),
      screenWidth: window.innerWidth,
    };
    this.onError = onErrorMixin.bind(this);
  }

  async componentDidMount() {
    this.subscribe(authStore, PASSWORD_UPDATE_EVENT, () => {
      customToast.success('You have set your password successfully');
      this.props.history.push('/login');
    });
    this.subscribe(authStore, PASSWORD_UPDATE_ERROR, this.onError);
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const { firstInput, secondInput, code, email } = this.state;

    if (firstInput !== secondInput) {
      return customToast.error('Passwords do not match');
    } else if (firstInput.length < 6) {
      return customToast.error('the password must contain at least 6 characters');
    } else {
      let newPassword = firstInput;
      this.setState({ loading: true }, () => {
        updatePassword(code, newPassword, email.toLocaleLowerCase()).then(() => {});
      });
    }
  };

  onChange = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { loading, firstInput, secondInput, email } = this.state;
    return (
      <AuthLayout>
        <AuthContent>
          <h4 className="text-center mb-5">Please enter your new password</h4>
          <Form className="mx-auto m-10" onSubmit={this.onSubmit}>
            <TextField
              className="mb-5"
              label="Email"
              onChange={this.onChange}
              name="email"
              value={email}
              type="email"
              required
              disabled={loading}
            />
            <TextField
              className="mb-5"
              label="New Password"
              onChange={this.onChange}
              name="firstInput"
              value={firstInput}
              type="password"
            />
            <TextField
              className="mb-5"
              label="Confirm Password"
              onChange={this.onChange}
              name="secondInput"
              value={secondInput}
              type="password"
              required
            />
            <div className="d-flex pb-4 text-center justify-content-center">
              {loading ? (
                <ClipLoader />
              ) : (
                <Button
                  style={{ width: 200 }}
                  className="mx-auto"
                  type="submit"
                  color="secondary"
                  onClick={this.onSubmit}>
                  Send request
                </Button>
              )}
            </div>
          </Form>
        </AuthContent>
      </AuthLayout>
    );
  }
}

export default ChangePasswordView;
