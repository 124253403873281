import React from 'react';
import {
  BAR_GRAPH_OPTIONS_LEADER_SECTIONS,
  BAR_GRAPH_OPTIONS_LEADER_SECTIONS_NOT_FOUND,
} from '../../../my-voice-models';
import * as R from 'ramda';
import IndicatorDetailCard from './IndicatorDetailCard';
import PropTypes from 'prop-types';

const IndicatorDetailCards = (props) => {
  const { seriesGeneral, questions, type, height } = props;
  const options = R.clone(BAR_GRAPH_OPTIONS_LEADER_SECTIONS);
  const optionsNot = R.clone(BAR_GRAPH_OPTIONS_LEADER_SECTIONS_NOT_FOUND);
  const questionsId = Object.keys(seriesGeneral);

  const content = questionsId.map((element, i) => {
    let finalOptions = optionsNot;
    let series = [0, 0, 0, 0, 0, 1];
    if (seriesGeneral[element].series.length > 0) {
      series = seriesGeneral[element].series;
      finalOptions = options;
    }
    return (
      <div key={`datail-card-${i + 1}`} style={{ padding: 8, marginTop: 10 }}>
        <IndicatorDetailCard
          questions={questions}
          options={finalOptions}
          num={i}
          series={series}
          type={type}
          height={height}
        />
      </div>
    );
  });
  return (
    <div className="d-flex flex-wrap" style={{ marginLeft: -8 }}>
      {content}
    </div>
  );
};

IndicatorDetailCards.propTypes = {
  seriesGeneral: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
};

export default IndicatorDetailCards;
