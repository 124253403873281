import { theme } from '../../../shared/theme';

export const RADIALBAR_GRAPH_BLUE = {
  chart: {
    height: 280,
    type: 'radialBar',
  },
  series: [67],
  colors: [theme.palette.secondary],
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      track: {
        background: theme.palette.infoLight,
        startAngle: -90,
        endAngle: 90,
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          fontSize: '30px',
          show: true,
        },
      },
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      type: 'horizontal',
      gradientToColors: [theme.palette.secondary],
      stops: [0, 100],
    },
  },
  stroke: {
    lineCap: 'butt',
  },
  labels: ['Progress'],
};

export const RADIALBAR_GRAPH_GREEN = {
  chart: {
    height: 280,
    type: 'radialBar',
  },
  series: [67],
  colors: [theme.palette.success],
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      track: {
        background: theme.palette.successLight,
        startAngle: -90,
        endAngle: 90,
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          fontSize: '30px',
          show: true,
        },
      },
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      type: 'horizontal',
      gradientToColors: [theme.palette.success],
      stops: [0, 100],
    },
  },
  stroke: {
    lineCap: 'butt',
  },
  labels: ['Progress'],
};

export const MULTIAXIS_GRAPH_OPTIONS = {
  options: {
    chart: {
      id: 'basic-bar',
    },
  },
  // xaxis: {
  //   categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
  // },
  colors: ['#429EE5', '#FF1654', '#85cbc6'],
  // series: [
  //   {
  //     name: "Series A",
  //     data: [14, 2, 25, 15, 25, 28, 38, 46]
  //   },
  //   {
  //     name: "Series B",
  //     data: [20, 29, 37, 36, 44, 45, 50, 58]
  //   }
  // ],
};

export const MIXED_CHART = {
  options: {
    fill: {
      type: 'solid',
    },
    markers: {
      size: [6, 0],
    },
    tooltip: {
      shared: false,
      intersect: true,
    },
    legend: {
      show: false,
    },
    xaxis: {
      type: 'numeric',
      min: 0,
      max: 12,
      tickAmount: 12,
    },
  },
};
