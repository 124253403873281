import { QueryClient } from 'react-query';
import PropTypes from 'prop-types';
import React from 'react';
/**
 * Helper to check for null or undefined.
 *
 * @param {*} something - An Object.
 * @returns {boolean} - Is Not null or undefined.
 */
export const is = (something) => {
  return !(something === undefined || something === null);
};

/**
 * Get Percentage.
 *
 * @param {number} num - Number from total.
 * @param {number} total - Total.
 * @param {number} fractionDigits - Fraction Digits.
 * @returns {string} - Percentage String.
 */
export function getPercentage(num, total, fractionDigits = 0) {
  let number;
  if (total > 0) {
    number = (num / total) * 100;
  } else {
    number = 0;
  }
  return number.toFixed(fractionDigits);
}

export const truncateString = (str, init = 0, end = 10) =>
  str.length > end ? str.slice(init, end).trim() + '...' : str;

export const wait = (seconds) =>
  new Promise((resolve) => setTimeout(() => resolve(true), seconds * 1000));

export const queryClient = new QueryClient();

export const QtyRow = ({ children }) => (
  <div style={{ maxWidth: 200, textAlign: 'center' }}>{children}</div>
);

QtyRow.propTypes = {
  children: PropTypes.node.isRequired,
};

export const formatStringToId = (v) => {
  return v
    .toLocaleLowerCase()
    .split(' ')
    .join('-');
};

export const validateValue = (value) => {
  return value === 'N/A' || !value ? <span style={{ color: 'lightgrey' }}>N/A</span> : value;
};

export const textUpperCase = (text) => text.replace(/^\w/, (c) => c.toUpperCase());
