import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import { authStore, USER_EVENT } from '../auth/auth-store';

/* COMPONENTS */
import { NavigationBar } from '../../shared/layouts/NavigationBar';
import { NavTabsWithContent } from '../../shared/components/menus/NavTabsWithContent';

import { UsersManagementView } from './UsersManagementView';
import { SchoolsManagementView } from './SchoolsManagementView';
import { ClfManagementView } from './ClfManagementView';
import { DistrictsManagementView } from './DistrictsManagementView';

/**
 * Super Admin View.
 *
 * @param history
 * @returns {*}
 * @class
 */
function SuperAdminView({ history }) {
  const [activeTab, setActiveTab] = useState('users');

  const user = authStore.getState(USER_EVENT);
  if (!user.isAdmin) history.push('/');

  return (
    <NavigationBar currentRoute={'/super-admin'}>
      <Container fluid className="mt-2">
        <NavTabsWithContent
          items={[
            {
              key: 'users',
              label: 'Users',
              content: <UsersManagementView />,
            },
            {
              key: 'schools',
              label: 'Schools',
              content: <SchoolsManagementView />,
            },
            {
              key: 'districts',
              label: 'Districts',
              content: <DistrictsManagementView />,
            },
            {
              key: 'CLF',
              label: 'CLF',
              content: <ClfManagementView />,
            },
          ]}
          onChange={(v) => setActiveTab(v)}
          value={activeTab}
        />
      </Container>
    </NavigationBar>
  );
}

SuperAdminView.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(SuperAdminView);
