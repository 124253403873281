import React from 'react';
import PropTypes from 'prop-types';
import { Badge as ReactBadge } from 'reactstrap';
import styled from 'styled-components';

const Badge = styled(ReactBadge)`
  padding: 0.5rem 1.2rem;
  border-radius: 6px;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07);
`;

const DisabledBadge = styled(Badge)`
  background-color: #597cb1;
`;

export const StatusBadge = ({ active }) =>
  active ? (
    <Badge color="secondary">Active</Badge>
  ) : (
    <DisabledBadge color="secondary">Disabled</DisabledBadge>
  );

StatusBadge.propTypes = {
  active: PropTypes.bool.isRequired,
};
