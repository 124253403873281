import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { customToast } from '../../shared/toast';
import PropTypes from 'prop-types';

const InputImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border: 1px solid #ced4da;
  border-radius: 10px;
  cursor: pointer;
  color: lightgray;
  font-size: 1.2rem;
`;

const DefaultContent = (
  <>
    Profile
    <br />
    Picture
  </>
);

/**
 * Handle a file input.
 *
 * @param {Function} onChange
 * @param {string | null} defaultSrc
 * @param {number} maxSize
 * @param {*} defaultContent
 * @returns {*}
 * @class
 */
const InputImage = ({
  onChange,
  height = 200,
  width,
  maxSize = 500,
  defaultSrc = null,
  defaultContent: propDefaultContent = null,
}) => {
  const pictureRef = useRef();
  const [imageSrc, setImageSrc] = useState(defaultSrc);

  useEffect(() => setImageSrc(defaultSrc), [defaultSrc]);

  const handleClick = () => pictureRef.current.click();
  const handleChangeFile = (evt) => {
    const reader = new FileReader();
    const file = evt.target.files[0];

    if (file) {
      const kiloBytes = file.size / 1024;
      if (kiloBytes > maxSize) {
        // If File is bigger than maxSize
        customToast.error('Image too Large');
      }
      reader.onloadend = () => {
        setImageSrc(reader.result);
        onChange(file);
      };
      reader.readAsDataURL(file);
    } else {
      setImageSrc(defaultSrc);
      onChange(null);
    }
  };

  const imageContent = imageSrc ? (
    <img src={imageSrc} alt="user" width="100%" height="100%" />
  ) : propDefaultContent ? (
    propDefaultContent
  ) : (
    DefaultContent
  );
  return (
    <>
      <InputImageContainer onClick={handleClick} style={{ height, width }}>
        {imageContent}
      </InputImageContainer>
      <input hidden type="file" onChange={handleChangeFile} ref={pictureRef} />
    </>
  );
};

InputImage.defaultValues = {
  maxSize: 500,
  height: 200,
  defaultSrc: null,
  defaultContent: null,
};

InputImage.propTypes = {
  onChange: PropTypes.func.isRequired,
  maxSize: PropTypes.number,
  height: PropTypes.number,
  defaultSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  defaultContent: PropTypes.oneOfType([PropTypes.element, PropTypes.oneOf([null])]),
};

export default InputImage;
