import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSubscription } from '@cobuildlab/react-flux-state';
import { isValidEmail } from '@cobuildlab/validation-utils';
import {
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Spinner,
  FormFeedback,
  FormText,
} from 'reactstrap';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { customToast } from '../../shared/toast';
import MainLayout from '../../shared/layouts/MainLayout';
import { initialUserData } from '../../components/user/ManageUserModal';
import ShowInMap from '../../components/ShowInMap';
import { ClipLoader } from '../../shared/components/progress/ClipLoader';
import myVoiceLogo from '../../shared/assets/images/logo.png';
import cogniaImg from '../../shared/assets/images/Cognia_Trusted.png';
import {
  GET_OPEN_REGISTRATION_ERROR,
  GET_OPEN_REGISTRATION_EVENT,
  ADD_USER_EVENT,
  ADD_USER_ERROR_EVENT,
  superAdminStore,
} from '../super-admin/super-admin-store';
import {
  createUserFromOpenRegistration,
  getOpenRegistrationByToken,
} from '../super-admin/super-admin-actions';
import { authStore, REQUEST_RECOVER_PASSWORD } from './auth-store';
import { requestPasswordReset } from './auth-actions';
import CustomBadge from '../solutions/components/CustomBadge';

const MainContainer = styled(Container)`
  height: 100%;
  padding-top: 1.5rem;
`;

const CardForm = styled.div`
  background-color: #ffff;
  box-shadow: 1px 1px 5px 1px #0000002e;
  border: 1px solid transparent;
  border-radius: 0.5rem;
`;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const ExpiredMessageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ExpiredMessage = styled.p`
  max-width: 350px;
  font-weight: 500;
  color: #002a3f;
`;

const ExpiredMessageLink = styled.a`
  text-decoration: underline;
  color: #3c82ff;
`;

const RegistrationView = ({ history, match }) => {
  const [userData, setUserData] = useState(initialUserData);
  const [school, setSchool] = useState({});
  const [openRegistration, setOpenRegistration] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isExpiredOpReg, setIsExpiredOpReg] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isFirstValue, setIsFirstValue] = useState(true);
  const { allowAllDomains = false, emailDomain: rawEmailDomain } = openRegistration;

  const emailDomain = typeof rawEmailDomain === 'string' ? [rawEmailDomain] : rawEmailDomain || [];

  const isOneEmailDomain = allowAllDomains === false && emailDomain.length === 1;
  const areManyDomains = allowAllDomains === false && emailDomain.length > 1;

  useEffect(() => {
    const opRegToken = match.params.token;
    getOpenRegistrationByToken(opRegToken);
    setIsLoading(true);
  }, [match]);

  useEffect(() => {
    if (school) {
      const { id, districtId } = school;
      onChange({ schoolId: id, districtId });
    }
  }, [school]);

  useSubscription(superAdminStore, GET_OPEN_REGISTRATION_EVENT, (data) => {
    setOpenRegistration(data.openRegistration);
    setSchool(data.school);
    setIsLoading(false);
  });

  useSubscription(superAdminStore, GET_OPEN_REGISTRATION_ERROR, (error) => {
    if (error.details && error.details.expired) {
      setIsExpiredOpReg(true);
      setIsLoading(false);
    } else {
      history.push('/');
      customToast.error(error.message);
    }
  });

  useSubscription(superAdminStore, ADD_USER_EVENT, (newUser) => {
    requestPasswordReset(newUser.email);
  });

  useSubscription(superAdminStore, ADD_USER_ERROR_EVENT, (error) => {
    customToast.error(error);
    setIsSaving(false);
  });

  useSubscription(authStore, REQUEST_RECOVER_PASSWORD, () => {
    history.push('/login');
    customToast.success(
      'Registered user successfully! We have sent you an email to change your password',
    );
    setIsSaving(false);
  });

  const disabledSubmit =
    isFirstValue ||
    emailError ||
    isSaving ||
    isLoading ||
    !userData.email ||
    !userData.firstName ||
    !userData.lastName ||
    school.active === false;

  const validateEmail = (email) => {
    const currentDomain = email.split('@')[1];
    let validEmail = email;

    const emailWithoutCapital = email.toLowerCase();
    const emailHaveCapital = emailWithoutCapital !== email;
    if (emailHaveCapital) {
      setEmailError(true);
      customToast.error('Email must be lowercase');
      return validEmail;
    }

    if (allowAllDomains) {
      setEmailError(!isValidEmail(email));
      return validEmail;
    }

    if (isOneEmailDomain) {
      validEmail = `${email}${emailDomain[0]}`;
      setEmailError(!isValidEmail(validEmail));
      return validEmail;
    }

    if (!areManyDomains) return validEmail;

    if (!emailDomain.includes(`@${currentDomain}`)) {
      setEmailError(true);
      customToast.error('Email domain not allowed.');
      return validEmail;
    }
    setEmailError(!isValidEmail(email));
    return validEmail;
  };

  const onBlur = (e) => {
    e.preventDefault();
    validateEmail(e.target.value);
    setIsFirstValue(false);
  };

  const onChange = (fields) => {
    const auxUserData = { ...userData };
    Object.assign(auxUserData, fields);
    setUserData(auxUserData);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const email = validateEmail(userData.email);
    const data = {
      ...userData,
      email,
    };
    if (!disabledSubmit) {
      setIsSaving(true);
      createUserFromOpenRegistration(data, openRegistration.token);
    }
  };

  if (isLoading) {
    return (
      <Container
        fluid
        className="h-100vh d-flex align-items-center d-flex justify-content-center bg-primary">
        <ClipLoader size={150} />
      </Container>
    );
  }

  const sidebar = (
    <SidebarContainer className="pt-5 px-3">
      <div className="mt-2">
        <img width="100%" src={myVoiceLogo} alt="my voice logo" />
        <p className="mt-4 text-white text-center">
          Improving Schools Through Teacher Voice and Agency
        </p>
      </div>
      <img src={cogniaImg} alt="MyVoice" />
    </SidebarContainer>
  );

  let topbarTitle = '';
  let content = (
    <ExpiredMessageContainer>
      <h4 className="mb-4">Registration period has ended</h4>
      <ExpiredMessage>
        Please contact your school administrator or go to{' '}
        <ExpiredMessageLink href="https://myvoice-cognia.com">
          myvoice-cognia.com
        </ExpiredMessageLink>{' '}
        to log in
      </ExpiredMessage>
    </ExpiredMessageContainer>
  );

  const EmailDescription = () => {
    if (isOneEmailDomain) return null;
    if (areManyDomains && emailDomain) {
      return (
        <FormText>
          Allowed domains:
          {emailDomain.map((domain, key) => (
            <CustomBadge fontSize={'11px'} key={`domain-${key}-${domain}`}>
              {domain}
            </CustomBadge>
          ))}
        </FormText>
      );
    }
    return null;
  };

  if (!isExpiredOpReg) {
    topbarTitle = 'New User Registration';
    content = (
      <Row>
        <Col md={7}>
          <CardForm>
            <CardBody>
              <Form onSubmit={onSubmit}>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          value={userData.email}
                          onChange={({ target: { value } }) => onChange({ email: value })}
                          onBlur={onBlur}
                          placeholder="Email"
                          invalid={emailError}
                          style={{
                            textAlign: isOneEmailDomain ? 'end' : 'none',
                          }}
                        />
                        {isOneEmailDomain && (
                          <InputGroupAddon addonType="append" style={{ width: '50%' }}>
                            <InputGroupText style={{ width: '100%' }}>
                              {emailDomain[0]}
                            </InputGroupText>
                          </InputGroupAddon>
                        )}
                        <FormFeedback>Invalid email address.</FormFeedback>
                      </InputGroup>
                      <EmailDescription />
                    </FormGroup>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Input
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            onChange={({ target: { value } }) => onChange({ firstName: value })}
                            value={userData.firstName}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Input
                            type="text"
                            name="lastName"
                            placeholder="Last name"
                            onChange={({ target: { value } }) => onChange({ lastName: value })}
                            value={userData.lastName}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Input
                        value={school.name || userData.schoolId}
                        disabled
                        placeholder="School"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} className="mt-3">
                    {school.latLon ? (
                      <div style={{ position: 'relative', height: 300 }}>
                        <ShowInMap
                          basic
                          lat={school.latLon._latitude}
                          lng={school.latLon._longitude}
                        />
                      </div>
                    ) : null}
                  </Col>
                </Row>
                <div className="d-flex justify-content-end mt-2">
                  <Button type="submit" disabled={disabledSubmit} className="btn-blue btn-input-h">
                    {isSaving ? (
                      <>
                        <Spinner size="sm" color="primary" /> Registering
                      </>
                    ) : (
                      'Register'
                    )}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </CardForm>
        </Col>
      </Row>
    );
  }

  return (
    <MainLayout
      sidebar={sidebar}
      topBarProps={{
        title: topbarTitle,
      }}>
      <MainContainer fluid>{content}</MainContainer>
    </MainLayout>
  );
};

RegistrationView.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(RegistrationView);
