import React, { useEffect, useRef, useState } from 'react';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import { authStore, UPDATE_USER_EVENT, USER_EVENT } from '../../modules/auth/auth-store';
import defaultPicture from '../assets/images/picture.png';
import { isValidString } from '../strings';
import ToggleMenu from '../components/menus/ToggleMenu';
import { capitalize } from 'lodash';
import { USER_TYPE_LEADER } from '../userTypes';
import InputImage from '../../components/form/InputImage';
import { updateUser } from '../../modules/auth/auth-actions';
import LoadingButton from '../../components/button/LoadingButton';
import { useSubscription } from '@cobuildlab/react-flux-state';

const navbarStyles = { minHeight: 50, borderBottom: '1px solid #E8E9ED' };
const titleStyle = { fontSize: 24 };

// eslint-disable-next-line react/prop-types
const AddUserProfilePicture = ({ user, isOpen, onClose }) => {
  const [profilePIc, setProfilePIc] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSave = () => {
    updateUser({ ...user, picture: profilePIc });
    setLoading(true);
  };

  useSubscription(authStore, UPDATE_USER_EVENT, () => {
    setLoading(false);
    onClose();
  });

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Add Profile Picture
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="form-group">
          <label htmlFor="profilePicture">Profile Picture</label>
          <InputImage
            onChange={(file) => {
              setProfilePIc(file);
            }}
          />
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary btn-input-size"
          data-dismiss="modal"
          onClick={onClose}>
          Close
        </button>
        <LoadingButton
          loading={loading}
          color="secondary"
          className="btn-input-size"
          onClick={handleSave}
          type="button">
          Save changes
        </LoadingButton>
      </div>
    </Modal>
  );
};

const TopBar = ({ title = 'View Name', menuUrls, currentRoute, onLogout }) => {
  const [user, setUser] = React.useState({
    ...authStore.getState(USER_EVENT),
    ...authStore.getState(UPDATE_USER_EVENT),
  });

  const shouldShowAddPicture = useRef(true);
  const [showAddPicture, setShowAddPicture] = useState(false);

  useEffect(() => {
    const subs = authStore.subscribe(UPDATE_USER_EVENT, setUser);
    return () => {
      subs.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user && user.id && !user.lastLogin) {
      if (shouldShowAddPicture.current) {
        setShowAddPicture(true);
        shouldShowAddPicture.current = false;
      }
    }
  }, [user]);

  let titleComponent = (
    <NavbarBrand style={titleStyle} href="#" className="font-weight-bold">
      {title}
    </NavbarBrand>
  );

  if (user.userType === USER_TYPE_LEADER && title === 'MyReports') {
    titleComponent = (
      <>
        <NavbarBrand style={titleStyle} href="#" className="font-weight-bold">
          {title}
        </NavbarBrand>
        <div style={{ borderLeft: '1.5px solid #002a3f' }}>
          <NavbarBrand> </NavbarBrand>
        </div>
        <NavbarBrand href="#">{user.schoolName}</NavbarBrand>
      </>
    );
  }

  let menuComponent = null;

  if (menuUrls && onLogout) {
    const firstName = isValidString(user.firstName) ? user.firstName : '';
    const lastName = isValidString(user.lastName) ? user.lastName : '';
    const name = `${capitalize(firstName)} ${capitalize(lastName)}`;
    const { picture } = user;

    menuComponent = (
      <Nav className="ml-auto" navbar>
        {/*component toggle menu */}
        <ToggleMenu menuUrls={menuUrls} onLogout={onLogout} currentRoute={currentRoute} />
        {user.userType === `COA` ? (
          <></>
        ) : (
          <>
            <NavItem className="divider-nav" />
            <NavItem>
              <NavLink className="mr-2 ml-2 item-name-user" href={'/my-profile'}>
                {name}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="p-0" href={'/my-profile'}>
                {isValidString(picture) ? (
                  <img alt={'User Profile'} src={picture} className="item-profile" />
                ) : (
                  <img alt={'User Profile'} src={defaultPicture} className="item-profile" />
                )}
              </NavLink>
            </NavItem>
          </>
        )}
      </Nav>
    );
  }

  return (
    <Navbar style={navbarStyles} color="white" light expand="md" className="ml-auto">
      <AddUserProfilePicture
        user={user}
        isOpen={showAddPicture}
        onClose={() => setShowAddPicture(false)}
      />
      {titleComponent}
      {menuComponent}
    </Navbar>
  );
};

TopBar.defaultProps = {
  menuUrls: null,
  currentRoute: '',
  onLogout: null,
};

TopBar.propTypes = {
  title: PropTypes.string.isRequired,
  menuUrls: PropTypes.array,
  currentRoute: PropTypes.string,
  onLogout: PropTypes.func,
};

export default TopBar;
