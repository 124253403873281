import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, FormGroup, Input, Modal as ReactModal, ModalBody, Row } from 'reactstrap';
import styled from 'styled-components';
import InputImage from '../form/InputImage';
import SwitchCheckbox from '../../shared/components/inputs/SwitchCheckbox';
import { RequiredInputLabel } from '../../shared/components/typography/RequiredInputLabel';
import { Select } from '../../shared/components/inputs/Select';
import { validateEmail } from '../../modules/super-admin/super-admin-utils';
import {
  USER_TYPE_COA,
  USER_TYPE_LEADER,
  USER_TYPE_TEACHER,
  USER_TYPE_CLF,
} from '../../shared/userTypes';
import LoadingButton from '../button/LoadingButton';
import { customToast } from '../../shared/toast';

const Modal = styled(ReactModal)`
  @media (min-width: 768px) {
    width: 700px !important;
    max-width: 700px !important;
    min-width: 700px !important;
  }
`;

export const userTypes = [
  { id: 0, value: USER_TYPE_TEACHER, name: 'Teacher' },
  { id: 1, value: USER_TYPE_LEADER, name: 'Leader' },
  { id: 2, value: USER_TYPE_COA, name: 'COA', onlyAdmin: true },
  { id: 3, value: USER_TYPE_CLF, name: 'CLF', onlyAdmin: true },
];

export const initialUserData = {
  email: '',
  image: null,
  active: true,
  picture: null,
  userType: USER_TYPE_TEACHER,
  schoolId: '',
  districtId: '',
  lastName: '',
  firstName: '',
  needsProfile: true,
  isRosterManager: false,
  needsPasswordReset: true,
  districts: [],
  schools: [],
};

const ManageUserModal = ({
  isOpen,
  schools: rawSchools,
  districts: rawDistricts,
  onSubmit,
  onToggle,
  user = null,
  admin = false,
  edit = false,
  loading = false,
  CLF = false,
}) => {
  const [email, setEmail] = useState(initialUserData.email);
  const [image, setImage] = useState(initialUserData.image);
  const [active, setActive] = useState(initialUserData.active);
  const [picture, setPicture] = useState(initialUserData.picture);
  const [userType, setUserType] = useState(initialUserData.userType);
  const [schoolId, setSchoolId] = useState(initialUserData.schoolId);
  const [districtId, setDistrictId] = useState(initialUserData.districtId);
  const [lastName, setLastName] = useState(initialUserData.lastName);
  const [firstName, setFirstName] = useState(initialUserData.firstName);
  const [needsProfile, setNeedsProfile] = useState(initialUserData.needsProfile);
  const [isRosterManager, setIsRosterManager] = useState(initialUserData.isRosterManager);
  const [needsPasswordReset, setNeedsPasswordReset] = useState(initialUserData.needsPasswordReset);
  const [originalEmail, setOriginalEmail] = useState(initialUserData.email);
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [selectedDistrics, setSelectedDistrics] = useState([]);

  const schools = useMemo(() => {
    return rawSchools.filter(({ active }) => active !== false);
  }, [rawSchools]);

  const districts = useMemo(() => {
    return rawDistricts.filter(({ active }) => active !== false);
  }, [rawDistricts]);

  const setStateSelectedDistrictsSchools = (stateDistricts) => {
    const auxSelectedschools = [];
    stateDistricts &&
      stateDistricts.forEach((d) => {
        d.schools.forEach((schoolId) => {
          const school = rawSchools.find((school) => school.id === schoolId);
          if (school) auxSelectedschools.push(school);
        });
      });
    setSelectedSchools(auxSelectedschools);
  };

  const setState = (stateData) => {
    setEmail(stateData.email);
    setImage(stateData.image);
    setActive(stateData.active);
    setPicture(stateData.picture);
    setUserType(CLF ? USER_TYPE_CLF : stateData.userType);
    setSchoolId(stateData.schoolId);
    setDistrictId(stateData.districtId);
    setLastName(stateData.lastName);
    setFirstName(stateData.firstName);
    setNeedsProfile(stateData.needsProfile);
    setIsRosterManager(stateData.isRosterManager);
    setNeedsPasswordReset(stateData.needsPasswordReset);
    setOriginalEmail(stateData.email);
    if (stateData.districts) {
      setSelectedDistrics(stateData.districts.map((district) => district.districtId));
      setStateSelectedDistrictsSchools(stateData.districts);
    }
  };

  const isUserTypeCoa = userType === USER_TYPE_COA;
  const isUserTypeClf = userType === USER_TYPE_CLF;

  // Set First School in list by default
  useEffect(() => {
    if (schools.length) {
      if (isUserTypeClf && user) setStateSelectedDistrictsSchools(user.districts);
      else {
        if (schoolId) {
          const school = schools.find((school) => school.id === schoolId);
          const districtId = (school && school.regionDistrictId) || initialUserData.districtId;
          setDistrictId(districtId);
        } else if (schoolId !== initialUserData.schoolId) {
          setSchoolId(initialUserData.schoolId);
        }
      }
    }
  }, [JSON.stringify(schools), user, schoolId, userType]);

  useEffect(() => {
    if (isUserTypeCoa) {
      setDistrictId((user && user.districtId) || initialUserData.districtId);
      setSchoolId(initialUserData.schoolId);
      setIsRosterManager(false);
    } else if (!isUserTypeClf) {
      setDistrictId(initialUserData.districtId);
      setSchoolId((user && user.schoolId) || initialUserData.schoolId);
    }
  }, [userType]);

  // Update States if User Prop change
  useEffect(() => {
    if (isOpen) {
      if (user) {
        setState({
          ...user,
          needsProfile: user.needsProfile || false,
          isRosterManager: user.isRosterManager || false,
          needsPasswordReset: user.needsPasswordReset || false,
        });
      } else {
        setState(initialUserData);
      }
    }
  }, [isOpen, user]);

  // Validation
  const [isValid, setValid] = useState(false);

  useEffect(() => {
    let schoolOrDistrict = schoolId;
    if (userType === USER_TYPE_COA) schoolOrDistrict = districtId;
    else if (userType === USER_TYPE_CLF) {
      const isSelectedDistricts = Boolean(selectedDistrics.length);
      if (isSelectedDistricts) {
        let missingSelectDistictSchool = false;
        for (let i = 0; i < selectedDistrics.length; i++) {
          const district = selectedDistrics[i];
          const districtSchools = selectedSchools.filter(
            (school) => school.regionDistrictId === district,
          );

          missingSelectDistictSchool = !districtSchools.length;
          if (missingSelectDistictSchool) break;
        }
        schoolOrDistrict = !missingSelectDistictSchool;
      } else schoolOrDistrict = false;
    }
    setValid(Boolean(firstName && lastName && schoolOrDistrict && validateEmail(email)));
  }, [
    email,
    firstName,
    lastName,
    districtId,
    userType,
    schoolId,
    JSON.stringify(selectedSchools),
    JSON.stringify(selectedDistrics),
  ]);

  const defaultSrc = user ? picture : null;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.toLocaleLowerCase() !== email) {
      customToast.error('Email must be lowercase');
      return;
    }
    const districsData = selectedDistrics.map((districtId) => ({
      districtId,
      schools: selectedSchools
        .filter((school) => school.regionDistrictId === districtId)
        .map((school) => school.id),
    }));

    const leaderOrTeacher = userType === 'LEADER' || 'TEACHER';

    const school = leaderOrTeacher ? schools.find((school) => school.id === schoolId) : undefined;

    const schoolRegionId = school ? school.regionId : null;

    onSubmit({
      email,
      originalEmail,
      image,
      active,
      picture,
      userType,
      schoolId,
      districtId,
      lastName,
      firstName,
      schoolRegionId,
      needsProfile: isUserTypeClf ? false : needsProfile,
      isRosterManager,
      needsPasswordReset,
      districts: districsData,
      districtIds: selectedDistrics,
      schoolIds: selectedSchools.map((school) => school.id),
    });
  };

  const title = edit ? 'Edit' : CLF ? 'Add CLF' : 'Add User';

  const userTypeOptions = userTypes.map((type) => (
    <option key={type.id} value={type.value} disabled={type.onlyAdmin ? !admin : false}>
      {type.name}
    </option>
  ));

  return (
    <Modal isOpen={isOpen} toggle={onToggle} backdrop="static" centered className="add-user-modal">
      <Form onSubmit={handleSubmit}>
        <div className="modal-header flex-wrap align-items-center">
          <Col md={3}>
            <h5 className="modal-title my-2">{title}</h5>
          </Col>
          {admin && !isUserTypeClf ? (
            <>
              <Col md={4} xs={6} className="d-flex align-items-center">
                <span>Roster Manager</span>
                <SwitchCheckbox
                  name="isRosterManager"
                  onChange={(evt) => setIsRosterManager(evt.target.checked)}
                  checked={isRosterManager}
                  disabled={userType === 'COA'}
                />
              </Col>
            </>
          ) : null}
          <Col md={3} xs={6} className="d-flex align-items-center">
            <span>Active</span>
            <SwitchCheckbox
              name="active"
              onChange={(evt) => setActive(evt.target.checked)}
              checked={active}
            />
          </Col>
          <button type="button" className="close" onClick={onToggle}>
            <span>&times;</span>
          </button>
        </div>
        <ModalBody>
          <Row>
            <Col md={5}>
              <FormGroup>
                <InputImage
                  onChange={(file) => setImage(file)}
                  defaultSrc={defaultSrc}
                  height={CLF ? 200 : 250}
                />
              </FormGroup>
            </Col>
            <Col md={7}>
              <FormGroup>
                <RequiredInputLabel>Email</RequiredInputLabel>
                <Input
                  type="email"
                  name="email"
                  placeholder="@example.com"
                  className="form-control-modal"
                  value={email}
                  onChange={(evt) => setEmail(evt.target.value)}
                  required
                />
              </FormGroup>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <RequiredInputLabel>First Name</RequiredInputLabel>
                    <Input
                      type="text"
                      name="firstName"
                      // placeholder="First Name"
                      onChange={(evt) => setFirstName(evt.target.value)}
                      className="form-control-modal"
                      value={firstName}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <RequiredInputLabel>Last name</RequiredInputLabel>
                    <Input
                      type="text"
                      name="lastName"
                      // placeholder="Last name"
                      onChange={(evt) => setLastName(evt.target.value)}
                      className="form-control-modal"
                      value={lastName}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              {!CLF ? (
                <Row className="align-items-center">
                  <Col md={12}>
                    <FormGroup>
                      <RequiredInputLabel>User type</RequiredInputLabel>
                      <Input
                        type="select"
                        name="userType"
                        onChange={(evt) => setUserType(evt.target.value)}
                        className="form-control-modal"
                        value={userType}
                        required>
                        {userTypeOptions}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <RequiredInputLabel>District</RequiredInputLabel>
                <Select
                  name="districtId"
                  options={districts}
                  getOptionValue={(opt) => opt.id}
                  getOptionLabel={(opt) => opt.name || opt.id}
                  placeholder="select district(s)"
                  isMulti={isUserTypeClf}
                  value={
                    isUserTypeClf
                      ? rawDistricts.filter((d) => selectedDistrics.includes(d.id))
                      : rawDistricts.find((d) => districtId === d.id)
                  }
                  onChange={(data, actionType) => {
                    if (isUserTypeClf) {
                      if (actionType.action === 'remove-value') {
                        setSelectedSchools(
                          selectedSchools.filter(
                            (school) => school.regionDistrictId !== actionType.removedValue.id,
                          ),
                        );
                      } else if (actionType.action === 'clear') setSelectedSchools([]);

                      setSelectedDistrics(data.map((d) => d.id));
                    } else setDistrictId(data.id);
                  }}
                  isDisabled={(!isUserTypeClf && !isUserTypeCoa) || (!admin && !CLF)}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <RequiredInputLabel>School</RequiredInputLabel>
                <Select
                  name="schoolId"
                  options={
                    isUserTypeClf
                      ? schools.filter((school) =>
                        selectedDistrics.includes(school.regionDistrictId),
                      )
                      : schools
                  }
                  getOptionValue={(opt) => opt.id}
                  getOptionLabel={(opt) => opt.name || opt.id}
                  placeholder="select school(s)"
                  value={
                    isUserTypeClf ? selectedSchools : rawSchools.find((s) => s.id === schoolId)
                  }
                  isDisabled={isUserTypeClf ? !selectedDistrics.length : isUserTypeCoa}
                  isMulti={isUserTypeClf}
                  onChange={(data) => {
                    if (isUserTypeClf) setSelectedSchools(data);
                    else setSchoolId(data.id);
                  }}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {admin ? (
              <>
                <Col md={4} xs={6} className="d-flex align-items-center mt-3">
                  <span>Password Reset</span>
                  <SwitchCheckbox
                    disabled={!active}
                    name="needsPasswordReset"
                    onChange={(evt) => setNeedsPasswordReset(evt.target.checked)}
                    checked={needsPasswordReset}
                  />
                </Col>
                <Col md={4} xs={6} className="d-flex align-items-center mt-3">
                  {!isUserTypeClf ? (
                    <>
                      <span>Require Inventory</span>
                      <SwitchCheckbox
                        name="needsProfile"
                        onChange={(evt) => setNeedsProfile(evt.target.checked)}
                        checked={needsProfile}
                      />
                    </>
                  ) : null}
                </Col>
              </>
            ) : (
              <Col md={8} xs={12} />
            )}
            <Col md={4} xs={12} className="d-flex justify-content-end mt-3">
              <LoadingButton
                loading={loading}
                disabled={!isValid || loading}
                type="submit"
                color="secondary"
                className="btn-input-size">
                {edit ? 'Save' : title}
              </LoadingButton>
            </Col>
          </Row>
        </ModalBody>
      </Form>
    </Modal>
  );
};

ManageUserModal.defaultProps = {
  admin: false,
  CLF: false,
  edit: false,
  user: null,
  loading: false,
};

ManageUserModal.propTypes = {
  loading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  schools: PropTypes.array.isRequired,
  districts: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  user: PropTypes.object,
  admin: PropTypes.bool,
  CLF: PropTypes.bool,
  edit: PropTypes.bool,
};

export default ManageUserModal;
