import React, { useState, useEffect } from 'react';
import { Select } from '../../../shared/components/inputs/Select';
import { getAllRegions } from '../super-admin-actions';
import { SUPER_ADMIN_ADD_REGION_EVENT, superAdminStore } from '../super-admin-store';
import { useSubscription } from '@cobuildlab/react-flux-state';

const RegionMultiSelect = ({ placeholder, value, onChange, className, ...props }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchRegions = async () => {
    setLoading(true);
    const regions = await getAllRegions();
    setOptions(regions);
    setLoading(false);
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const handleChange = (data, actionType) => {
    if (actionType.action === 'remove-value') {
      return onChange(value.filter((region) => region !== actionType.removedValue.id));
    }
    if (actionType.action === 'clear') {
      return onChange([]);
    }

    onChange(data.map((d) => d.id));
  };

  useSubscription(superAdminStore, SUPER_ADMIN_ADD_REGION_EVENT, (successMessage) => {
    fetchRegions();
  });

  return (
    <Select
      {...props}
      className={`${className} w-100`}
      options={options}
      getOptionValue={(opt) => opt.id}
      getOptionLabel={(opt) => opt.name || opt.id}
      placeholder={loading ? 'Loading...' : placeholder || 'Select region(s)'}
      isMulti
      value={options.filter((region) => value.includes(region.id))}
      onChange={handleChange}
      isLoading={loading}
    />
  );
};

export default RegionMultiSelect;
