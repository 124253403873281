import React from 'react';
import XLSX from 'xlsx';
import { Badge, Col, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { database } from 'react-icons-kit/fa/database';
import Icon from 'react-icons-kit';
import { isValidRosterManager, setValidUserType } from './data-import-actions';
import { customToast } from '../../shared/toast';

const DataTable = ({ data: workbook = [], onClickSave }) => {
  if (!workbook) return <p>Empty</p>;
  if (workbook.length === 0) return <p className="text-muted ml-1">No data available</p>;
  const sheetNames = workbook.SheetNames;
  const availableSheets = ['users', 'regions', 'schools', 'districts'];
  return (
    <div>
      {sheetNames.map((sheetName, i) => {
        if (!availableSheets.find((field) => field.trim() === sheetName)) return null;
        const workSheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
        let [fields, ...dataElements] = data;

        // Trim all fields
        fields = fields.map((f) => String(f).trim());

        if (sheetName === 'users') {
          if (fields.includes('isRosterManager')) {
            const index = fields.indexOf('isRosterManager');
            dataElements.forEach((element) => {
              if (element.length) {
                element[index] = isValidRosterManager(element[index]) ? 'Yes' : 'No';
              }
            });
          } else {
            // if sheetName is 'users' and don't have field 'isRosterManager'
            // add field 'isRosterManager' and value '0'
            fields.push('isRosterManager');
            dataElements.forEach((ele) => {
              if (ele.length) ele.push('No');
            });
          }

          const userTypeIndex = fields.indexOf('userType');
          if (userTypeIndex >= 0) {
            dataElements.forEach((element) => {
              if (element.length) {
                try {
                  element[userTypeIndex] = setValidUserType(element[userTypeIndex]);
                } catch (error) {
                  console.error(error);
                  setTimeout(() => {
                    customToast.error(`Upload error: ${error.message}`);
                  }, 2000);
                }
              }
            });
          }
        }

        return (
          <Col md={12} key={i}>
            <p className="text-muted ml-1">{sheetName}</p>
            <Table hover size={'md'}>
              <thead className="text-center">
                <tr>
                  {fields.map((fieldName, i) => (
                    <th key={i}>
                      <small>{fieldName}</small>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataElements.map((row, k) => {
                  const rows = [];
                  for (let i = 0, j = row.length; i < j; i++)
                    rows.push(<td key={i}>{row[i] === undefined ? '' : row[i]}</td>);
                  return (
                    <tr key={k} className="text-center">
                      {rows}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        );
      })}
      <Badge color="secondary p-2 mb-4 mt-2" onClick={onClickSave} className={'pointer'}>
        <Icon icon={database} /> Save to the Database
      </Badge>
    </div>
  );
};

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  onClickSave: PropTypes.func.isRequired,
};

export { DataTable };
