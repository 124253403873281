import { toast } from 'react-toastify';

export const customToast = {
  success: (msg, options) =>
    toast.success(msg, {
      ...options,
      className: 'toast-container toast-success',
    }),
  warning: (msg, options) =>
    toast.success(msg, {
      ...options,
      className: 'toast-container toast-warning',
    }),
  error: (msg, options) =>
    toast.error(msg, {
      ...options,
      className: 'toast-container toast-error',
    }),
  info: (msg, options) =>
    toast.info(msg, {
      ...options,
      className: 'toast-container',
    }),
};
