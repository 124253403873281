import React, { useState, useEffect } from 'react';
// import { Media, Button } from 'reactstrap';
// import IndicatorStepper from '../../my-voice/components/IndicatorStepper';
// import DriverIcon from '../../../components/DriverIcon';
// import H2Blue from '../../../components/H2Blue';
// import DriverText from '../../../components/DriverText';
import { DriverDetailsForm } from '../../my-voice/components/DriversDetailsForm';
import PropTypes from 'prop-types';

const CustomDomainSteppers = ({ domain, icon, message, indicators, onSubmit, submitText }) => {
  const [indicatorsData, setIndicatorsData] = useState([]);

  useEffect(() => {
    if (indicators && indicators.length) {
      setIndicatorsData(indicators);
    }
  }, [indicators]);

  const onClickIndicatorStep = (id, stepNumber) => {
    const auxIndicatorData = [...indicatorsData];
    const index = auxIndicatorData.findIndex((item) => item.id === id);
    auxIndicatorData[index].data = stepNumber;
    setIndicatorsData(auxIndicatorData);
  };

  const handdleOnSubmit = () => onSubmit(indicatorsData);

  return (
    <DriverDetailsForm
      icon={icon}
      title={domain}
      indicators={indicators}
      message={message}
      onChangeIndicator={onClickIndicatorStep}
      onSubmit={handdleOnSubmit}
      submitText={submitText}
    />
  );
};

CustomDomainSteppers.defaultProps = {
  submitText: 'Update',
  bodyClass: '',
  icon: null,
};

CustomDomainSteppers.propTypes = {
  domain: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  icon: PropTypes.string,
  indicators: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
};

export default CustomDomainSteppers;
