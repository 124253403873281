import firebase from 'firebase';
import Flux from '@cobuildlab/flux-state';
import {
  MY_CLF_DASHBOARD_TEACHERS_ERROR,
  MY_CLF_DASHBOARD_TEACHERS_EVENT,
  MY_DASHBOARD_ERROR_EVENT,
  MY_DASHBOARD_TEACHERS_EVENT,
  SCHOOL_AVERAGE_UPDATE_EVENT,
  SCHOOL_MONTHLY_AVERAGE_EVENT,
} from './my-dashboard-store';
import { authStore, USER_EVENT } from '../auth/auth-store';
import { USER_TYPE_TEACHER } from '../../shared/userTypes';
import { getDashboardGraphData } from '../dashboard-coa/dashboard-coa-actions';

/**
 * Return the School average from monthlySchoolAverages.
 *
 * @returns {Promise<>}
 */

export const getUpdatedDriversTeachers = async () => {
  const getUpdatedDriversTeachers = firebase
    .functions()
    .httpsCallable('getUpdatedDriversTeachers2');
  const result = await getUpdatedDriversTeachers();
  console.log('getUpdatedDriversTeachers:result:', result);
  Flux.dispatchEvent(SCHOOL_AVERAGE_UPDATE_EVENT, result);
  return result;
};

/**
 * Return the School average from monthlySchoolAverages.
 *
 * @returns {Promise<>}
 */

export const getMySchoolMonthlyAveragesAction = async () => {
  const DB = firebase.firestore();
  const user = authStore.getState(USER_EVENT);
  const monthlyCollection = DB.collection('monthlySchoolAverages');
  const monthlyRef = monthlyCollection.where('schoolId', '==', user.schoolId);
  let monthly;

  try {
    monthly = await monthlyRef.get();
  } catch (err) {
    return Flux.dispatchEvent(MY_DASHBOARD_ERROR_EVENT, err);
  }

  const monthlyList = [];
  monthly.forEach((doc) => {
    monthlyList.push(doc.data());
  });
  Flux.dispatchEvent(SCHOOL_MONTHLY_AVERAGE_EVENT, monthlyList);
  return monthlyList;
};

/**
 * Return the School average.
 *
 * @returns {Promise<>}
 */

export const allUserMyDashboardAction = async () => {
  const DB = firebase.firestore();
  const user = authStore.getState(USER_EVENT);
  const schoolId = user.schoolId;

  const usersCollection = DB.collection('users');

  let teachersQuery;
  try {
    teachersQuery = await usersCollection
      .where('userType', '==', USER_TYPE_TEACHER)
      .where('schoolId', '==', schoolId)
      .where('active', '==', true)
      .get();
  } catch (err) {
    return Flux.dispatchEvent(MY_DASHBOARD_ERROR_EVENT, err);
  }

  const teachers = teachersQuery.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

  try {
    const result = await getDashboardGraphData(teachers);
    Flux.dispatchEvent(MY_DASHBOARD_TEACHERS_EVENT, result);
  } catch (err) {
    return Flux.dispatchEvent(MY_DASHBOARD_ERROR_EVENT, err);
  }
};

const getUserDistrictsAndSchoolsData = async () => {
  const DB = firebase.firestore();
  const districtsCollection = DB.collection('districts');
  const schoolsCollection = DB.collection('schools');
  const user = authStore.getState(USER_EVENT);

  const schoolIds = [];
  const districtIdS = [];

  user.districts.forEach(({ districtId, schools }) => {
    districtIdS.push(districtId);
    schoolIds.push(...schools);
  });

  const districtsPromises = await Promise.all(
    districtIdS.map((id) => districtsCollection.doc(id).get()),
  );
  const schoolsPromises = await Promise.all(schoolIds.map((id) => schoolsCollection.doc(id).get()));

  const districts = districtsPromises.map((doc) => ({ ...doc.data(), id: doc.id }));
  const schools = schoolsPromises.map((doc) => ({ ...doc.data(), id: doc.id }));

  return [districts, schools];
};

export const getUserDistrictsSolutions = async () => {
  const DB = firebase.firestore();
  const { districts } = authStore.getState(USER_EVENT);
  const solutionsCollection = DB.collection('driversDomainsSolutions');

  const promises = [];
  districts.forEach(({ schools }) => {
    promises.push(
      ...schools.map((schoolId) => solutionsCollection.where('schoolId', '==', schoolId).get()),
    );
  });

  const results = await Promise.all(promises);

  const solutions = [];
  results.forEach((result) => {
    const auxSolutions = result.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    solutions.push(...auxSolutions);
  });

  return solutions;
};

export const getMyClfDashboardAction = async () => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');
  const [districts, schools] = await getUserDistrictsAndSchoolsData();
  const solutions = await getUserDistrictsSolutions();

  const promises = schools.map((school) =>
    usersCollection
      .where('userType', '==', USER_TYPE_TEACHER)
      .where('schoolId', '==', school.id)
      .where('active', '==', true)
      .get(),
  );

  let results = [];
  try {
    results = await Promise.all(promises);
  } catch (err) {
    console.log(err);
    Flux.dispatchEvent(MY_CLF_DASHBOARD_TEACHERS_ERROR, err);
    return;
  }

  const allTeachers = [];
  results.forEach((result) => {
    const teachers = result.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    allTeachers.push(...teachers);
  });

  const result = await getDashboardGraphData(allTeachers);
  Flux.dispatchEvent(MY_CLF_DASHBOARD_TEACHERS_EVENT, {
    ...result,
    teachers: allTeachers,
    districts,
    schools,
    solutions,
  });
};
