import React from 'react';
import { Container, Row, Col } from 'reactstrap';

//Logo
import WhiteTitle from '../../components/WhiteTitle';
import WhiteLink from '../../components/WhiteLink';
import H2Blue from '../../components/H2Blue';

const ComponentsView = () => {
  return (
    <Container fluid className="h-100vh d-flex align-items-center d-flex justify-content-center">
      <Row>
        <Col className="bg-primary">
          <WhiteTitle>Coming Soon</WhiteTitle>
        </Col>
        <Col>{`<WhiteTitle>COMING SOON!</WhiteTitle>`}</Col>
      </Row>
      <Row>
        <Col className="bg-primary">
          <WhiteLink href="https://kademeducation.com/" text={'Kadem Education'} />
        </Col>
        <Col>{`<WhiteLink href='https://kademeducation.com/' text={'Kadem Education'} />`}</Col>
      </Row>
      <Row>
        <Col className="bg-primary">
          <H2Blue>Some Title</H2Blue>
        </Col>
        <Col>{`<H2Blue>Some Title</H2Blue>`}</Col>
      </Row>
    </Container>
  );
};

export default ComponentsView;
