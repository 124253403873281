export const getMonth = (month: number) => {
  if (month > 11 || month < 0) throw new Error('Unsupported value, months are between 0 and 11');
  return MONTHS[month];
};

export const MONTHS = Object.freeze([
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]);
