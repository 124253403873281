import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'reactstrap';
import { useSubscription } from '@cobuildlab/react-flux-state';
import { plus as ic_plus } from 'react-icons-kit/fa/plus';
import { ic_search } from 'react-icons-kit/md/ic_search';
import { download2 as ic_download } from 'react-icons-kit/icomoon/download2';
import AddUserModalContainer from './AddUserModalContainer';
import {
  SUPER_ADMIN_USERS_EVENT,
  superAdminStore,
  SUPER_ADMIN_EXCEL_REPORTS_ERROR,
  SUPER_ADMIN_EXCEL_REPORTS_EVENT,
} from '../super-admin-store';
import { InputWithIcon } from '../../../shared/components/inputs/InputWithIcon';
import { ButtonUsersFilter } from './ButtonFilterUsers';
import { createAndDownloadSuperAdminReport } from '../super-admin-actions';
import { customToast } from '../../../shared/toast';
import { ButtomActionsTabs } from './ButtomActionsTabs';

export const initialOtherSearch = {
  school: null,
  district: null,
  role: '',
  onlyActives: 'active',
  search: '',
  generalSearch: '',
};

const UserSearchForm = ({ onSearch, searchFilters, isLoading }) => {
  const [isModalOpen, setModalState] = useState(false);
  const [generalSearch, setGeneralSearch] = useState('');
  const [otherSearch, setOtherSearh] = useState(initialOtherSearch);
  const [loadingReport, setLoadingReport] = useState(false);

  const toggleModal = () => setModalState(!isModalOpen);

  const handleCreateReport = () => {
    setLoadingReport(true);
    createAndDownloadSuperAdminReport(searchFilters, 'users', 'Users Report');
  };

  useEffect(() => {
    setGeneralSearch('');
    setOtherSearh(initialOtherSearch);
  }, []);

  useEffect(() => {
    const search = { generalSearch };
    const { school, district, role, ...restSearch } = otherSearch;
    Object.assign(search, {
      ...restSearch,
      schoolId: school && school.id,
      districtId: district && district.id,
      role: role && role.value,
    });
    onSearch(search);
  }, [generalSearch, otherSearch]);

  const [countResults, setCount] = useState({ count: 0, isMore: false });

  useSubscription(superAdminStore, SUPER_ADMIN_USERS_EVENT, (usersList) => {
    const { users, isMore, clear } = usersList;
    const prevCount = countResults.count;
    const count = clear ? users.length : prevCount + users.length;
    setCount({ count, isMore });
  });

  useSubscription(superAdminStore, SUPER_ADMIN_EXCEL_REPORTS_ERROR, (err) => {
    setLoadingReport(false);
    customToast.error(err);
  });

  useSubscription(superAdminStore, SUPER_ADMIN_EXCEL_REPORTS_EVENT, () => {
    setLoadingReport(false);
  });

  const { count: usersCount, isMore: areMoreUsers } = countResults;
  const countText = (
    <span className="result-count">
      {usersCount}
      {areMoreUsers ? '+' : ''} Results
    </span>
  );

  return (
    <Form onSubmit={(e) => e.preventDefault()} className="form-tabs">
      <AddUserModalContainer toggle={toggleModal} modal={isModalOpen} />

      <h5 className="my-3">Search {countText}</h5>

      <Row className="justify-content-between align-items-center">
        <Col xs={6} md={3}>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <InputWithIcon
                icon={ic_search}
                value={generalSearch}
                onChange={(evt) => setGeneralSearch(evt.target.value)}
                type="text"
                placeholder="Search"
                disabled={isLoading}
              />
            </div>
            <div className="ml-2">
              <ButtonUsersFilter
                data={otherSearch}
                onChange={(values) => setOtherSearh(values)}
                isLoading={isLoading}
              />
            </div>
          </div>
        </Col>
        <Col md={6} xs={6} className="d-flex justify-content-end">
          <ButtomActionsTabs
            actions={[
              {
                onClick: handleCreateReport,
                title: 'Download File',
                icon: ic_download,
                loading: loadingReport,
              },
              {
                onClick: toggleModal,
                title: 'Add User',
                icon: ic_plus,
              },
            ]}
          />
        </Col>
      </Row>
    </Form>
  );
};

UserSearchForm.defaultProps = {
  isLoading: false,
};

UserSearchForm.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchFilters: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

export default UserSearchForm;
