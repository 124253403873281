export const SCHOOL_TYPE_ELEM = 'ELEM';
export const SCHOOL_TYPE_HIGH = 'HIGH';
export const SCHOOL_TYPE_MIDDLE = 'MIDDLE';
export const SCHOOL_TYPE_OTHER = 'OTHER';

export default {
  ELEM: SCHOOL_TYPE_ELEM,
  HIGH: SCHOOL_TYPE_HIGH,
  MIDDLE: SCHOOL_TYPE_MIDDLE,
  OTHER: SCHOOL_TYPE_OTHER,
};
