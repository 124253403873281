import React, { useCallback, useEffect, useState } from 'react';
import { Container, Button } from 'reactstrap';
import Icon from 'react-icons-kit';
import { plus as ic_plus } from 'react-icons-kit/fa/plus';
import { useSubscription } from '@cobuildlab/react-flux-state';
import { MessageCard } from '../home/components/MessageCard';
import { MessageFormModal, initialMeesageData } from './components/MessageFormModal';

import {
  deleteMessage,
  getUserDistrictMessages,
  sendMessageCOA,
  updateMessage,
} from './my-district-actions';
import {
  myDistrictStore,
  GET_MESSAGES_EVENT,
  GET_MESSAGES_ERROR,
  SEND_MESSAGE_EVENT,
  SEND_MESSAGE_ERROR,
  UPDATE_MESSAGE_EVENT,
  UPDATE_MESSAGE_ERROR,
  DELETE_MESSAGE_EVENT,
  DELETE_MESSAGE_ERROR,
} from './my-district-store';
import { customToast } from '../../shared/toast';
import LoadMoreButton from '../super-admin/components/LoadMoreButton';
import { ClipLoader } from '../../shared/components/progress/ClipLoader';
import ConfirmModal from '../../components/modal/ConfirmModal';

export const ComunicationView = () => {
  const [messageData, setMessageData] = useState({ ...initialMeesageData });
  const [openFormModal, setOpenFormModal] = useState(false);
  const [openCofirmModal, setOpenConfirmModal] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [editing, setEditing] = useState(false);

  const [messages, setMessages] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [limit] = useState(10);
  const [isMore, setIsMore] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [isLoadingMoreMessages, setIsLoadingMoreMessages] = useState(false);

  const onSubmit = (messageData) => {
    setSubmiting(true);
    if (editing) updateMessage(messageData);
    else sendMessageCOA(messageData);
  };

  const onConfirmDeletion = () => {
    setSubmiting(true);
    deleteMessage(messageData);
  };

  const getMessages = useCallback(
    (loadMore = false) => {
      if (loadMore) {
        setIsLoadingMoreMessages(true);
        getUserDistrictMessages(limit, lastDoc);
      } else {
        setLoadingMessages(true);
        setMessages([]);
        setIsMore(false);
        setLastDoc(null);
        getUserDistrictMessages(limit);
      }
    },
    [limit, lastDoc],
  );

  useEffect(() => {
    getMessages();
  }, []);

  useSubscription(myDistrictStore, GET_MESSAGES_EVENT, (data) => {
    setLoadingMessages(false);
    setIsLoadingMoreMessages(false);
    setMessages(messages.concat(...data.messages));
    setIsMore(data.isMore);
    setLastDoc(data.lastDoc);
  });

  useSubscription(myDistrictStore, GET_MESSAGES_ERROR, (err) => {
    setLoadingMessages(false);
    setIsLoadingMoreMessages(false);
    customToast.error(err);
  });

  useSubscription(myDistrictStore, SEND_MESSAGE_EVENT, () => {
    setSubmiting(false);
    setOpenFormModal(false);
    setMessageData({ ...initialMeesageData });
    customToast.success('message sent!');
    getMessages();
  });

  useSubscription(myDistrictStore, SEND_MESSAGE_ERROR, (err) => {
    setSubmiting(false);
    customToast.error(err);
  });

  useSubscription(myDistrictStore, UPDATE_MESSAGE_EVENT, () => {
    setSubmiting(false);
    setOpenFormModal(false);
    setTimeout(() => setEditing(false), 500);
    setMessageData({ ...initialMeesageData });
    customToast.success('message updated!');
    getMessages();
  });

  useSubscription(myDistrictStore, UPDATE_MESSAGE_ERROR, (err) => {
    setSubmiting(false);
    customToast.error(err);
  });

  useSubscription(myDistrictStore, DELETE_MESSAGE_EVENT, () => {
    setSubmiting(false);
    setOpenConfirmModal(false);
    setMessageData({ ...initialMeesageData });
    customToast.success('message deleted!');
    getMessages();
  });

  useSubscription(myDistrictStore, DELETE_MESSAGE_ERROR, (err) => {
    setSubmiting(false);
    customToast.error(err);
  });

  let content = messages.map((msg) => (
    <div key={msg.id}>
      <MessageCard
        data={msg}
        editable
        onEdit={() => {
          setMessageData(msg);
          setOpenFormModal(true);
          setEditing(true);
        }}
        onDelete={() => {
          setOpenConfirmModal(true);
          setMessageData(msg);
        }}
      />
      <br />
    </div>
  ));

  if (loadingMessages) {
    content = (
      <div className="d-flex justify-content-center">
        <ClipLoader size={150} />
      </div>
    );
  }

  return (
    <Container className="pt-3">
      <div className="d-flex justify-content-end">
        <Button
          type="button"
          className="btn-input-size"
          onClick={() => {
            setOpenFormModal(true);
            setEditing(false);
          }}>
          <Icon icon={ic_plus} className="mr-2" />
          Add Message
        </Button>
        <MessageFormModal
          isOpen={openFormModal}
          isLoading={submiting}
          editing={editing}
          initialData={messageData}
          onToggle={() => {
            if (openFormModal) setMessageData({ ...initialMeesageData });
            setOpenFormModal(!openFormModal);
          }}
          onSubmit={onSubmit}
        />
      </div>

      <br />

      {content}

      {isMore ? (
        <div className="d-flex justify-content-center mt-3">
          <LoadMoreButton onClick={() => getMessages(true)} loading={isLoadingMoreMessages} />
        </div>
      ) : null}

      <ConfirmModal
        active={openCofirmModal}
        toggle={() => setOpenConfirmModal(false)}
        title="Are you sure ?"
        body="This will permanently delete message"
        onResponse={(val) => {
          if (val) onConfirmDeletion();
          else {
            setMessageData({ ...initialMeesageData });
            setOpenConfirmModal(false);
          }
        }}
      />
    </Container>
  );
};
