import React, { useState } from 'react';
import {
  ic_arrow_drop_down as arrowDownIcon,
  ic_arrow_drop_up as arrowUpIcon,
} from 'react-icons-kit/md';
import { Collapse } from 'reactstrap';
import { Icon } from 'react-icons-kit';
import PropTypes from 'prop-types';

const Accordion = ({ title, initialOpened = true, children }) => {
  const [isOpen, setOpen] = useState(initialOpened);
  const handleToggle = () => setOpen(!isOpen);
  const arrowIcon = isOpen ? arrowDownIcon : arrowUpIcon;

  return (
    <div className="border-bottom">
      <h4
        onClick={handleToggle}
        className="font-weight-bold"
        style={{ marginTop: 10, marginLeft: 16 }}>
        {title}
        <Icon icon={arrowIcon} size="30px" />
      </h4>
      <Collapse className="pl-3" isOpen={isOpen}>
        {children}
      </Collapse>
    </div>
  );
};

Accordion.defaultProps = {
  initialOpened: true,
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  initialOpened: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Accordion;
