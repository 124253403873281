export const calculateDriversAverage = (driversData) => {
  const driversAvg = {};
  const driversIds = Object.keys(driversData);
  // Calculating Averages
  driversIds.forEach((driverId) => {
    const indicators = driversData[driverId];
    const indicatorsIds = Object.keys(indicators);
    const indicatorsValue = [];
    indicatorsIds.forEach((indicatorId) => indicatorsValue.push(indicators[indicatorId]));
    driversAvg[driverId] = (
      indicatorsValue.reduce((acc, curr) => acc + curr, 0) / indicatorsIds.length
    ).toFixed(1);
  });
  return driversAvg;
};
