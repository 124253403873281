import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export const useTheme = () => useContext(ThemeContext);

const grpahColors = {
  primary: '#FFC04E',
  secondary: '#262e67',
  tertiary: '#E6874F',
};

export const palette = {
  primary: '#371d35',
  primaryLight: '#86457D',
  secondary: '#262e67',
  warning: '#efb806',
  warningLight: '#fce086',
  success: '#043D3C',
  successLight: '#328285',
  danger: '#ff9188',
  dangerDark: '#FF554D',
  infoLight: '#b9d3dc',
  black: '#1a1a1a',
  grey: '#495057;',
  disabled: '#e6e6e6',
  grpahColors,
};

export const theme = {
  palette,
};
