import React, { useEffect, useState, useMemo } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal as ReactModal,
  ModalBody,
  Row,
  Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { edit as ic_edit } from 'react-icons-kit/fa/edit';
import { Icon } from 'react-icons-kit';
import _ from 'lodash';
import InputPlaces from '../form/InputPlaces';
import ShowInMap from '../ShowInMap';
import InputImage from '../form/InputImage';
import ModalHeader from '../modal/ModalHeader';
import ClearInventoryButton from './ClearInventoryButton';
import SwitchCheckbox from '../../shared/components/inputs/SwitchCheckbox';
import { RequiredInputLabel } from '../../shared/components/typography/RequiredInputLabel';
import ConfirmModal from '../modal/ConfirmModal';

const Modal = styled(ReactModal)`
  @media (min-width: 768px) {
    width: 700px !important;
    max-width: 700px !important;
    min-width: 700px !important;
  }
`;

const InitialSchool = {
  requireInventory: false,
  active: true,
  id: '',
  name: '',
  message: '',
  schoolType: '',
  regionId: '',
  address1: '',
  latLon: {
    latitude: '',
    longitude: '',
  },
  logoUrl: '',
  schoolLogo: null,
};

const SchoolTypes = [
  { id: 1, type: 'ELEM' },
  { id: 3, type: 'MIDDLE' },
  { id: 4, type: 'HIGH' },
  { id: 5, type: 'OTHER' },
];

const InitialInputsStatus = {
  readOnlySchoolId: true,
  readOnlyCoordinates: true,
};

const ManageSchoolModal = ({ isOpen, onToggle, onSubmit, school, regions, loading }) => {
  const [message, setMessage] = useState(InitialSchool.message);
  const [active, setActive] = useState(InitialSchool.active);
  const [logoUrl, setLogoUrl] = useState(InitialSchool.logoUrl);
  const [address, setAddress] = useState(InitialSchool.address1);
  const [regionId, setRegionId] = useState(InitialSchool.regionId);
  const [schoolId, setSchoolId] = useState(InitialSchool.id);
  const [latitude, setLatitude] = useState(InitialSchool.latLon.latitude);
  const [longitude, setLongitude] = useState(InitialSchool.latLon.longitude);
  const [schoolName, setSchoolName] = useState(InitialSchool.name);
  const [schoolType, setSchoolType] = useState(InitialSchool.schoolType);
  const [requireInventory, setRequireInventory] = useState(InitialSchool.requireInventory);
  const [schoolLogo, setSchoolLogo] = useState(InitialSchool.schoolLogo);

  const [readOnlySchoolId, setReadOnlySchoolId] = useState(InitialInputsStatus.readOnlySchoolId);
  const [readOnlyCoordinates, setReadOnlyCoordinates] = useState(
    InitialInputsStatus.readOnlyCoordinates,
  );

  const [confirmModal, setConfirmModal] = useState(false);

  const [districtId, setDistrictId] = useState(null);
  const [isValid, setValid] = useState(false);

  const setState = (state) => {
    setLogoUrl(state.logoUrl || InitialSchool.logoUrl);
    setActive(() => {
      if (typeof state.active === 'boolean') return state.active;
      return InitialSchool.active;
    });
    setAddress(state.address1 || InitialSchool.address1);
    setMessage(state.message || InitialSchool.message);
    setRegionId(state.regionId || InitialSchool.regionId);
    setSchoolId(state.id);
    setLatitude((state.latLon && state.latLon.latitude) || InitialSchool.latLon.latitude);
    setLongitude((state.latLon && state.latLon.longitude) || InitialSchool.latLon.longitude);
    setSchoolName(state.name);
    setSchoolType(state.schoolType || InitialSchool.schoolType);
    setRequireInventory(state.requireInventory || InitialSchool.requireInventory);
    setSchoolLogo(state.schoolLogo || InitialSchool.schoolLogo);
  };

  useEffect(() => {
    if (school) {
      const schoolData = { ...school };
      if (schoolData.latLon) schoolData.latLon = schoolData.latLon.toJSON();
      setState(schoolData);
      const region = regions.find((region) => school.regionId === region.id);
      setDistrictId(region ? region.districtId : '');
    }
  }, [school]);

  useEffect(() => {
    if (!school) {
      setState(InitialSchool);
      setValid(false);
    }
    setReadOnlySchoolId(InitialInputsStatus.readOnlySchoolId);
    setReadOnlyCoordinates(InitialInputsStatus.readOnlyCoordinates);
  }, [isOpen]);

  useEffect(() => {
    if (!school) {
      const schoolId = schoolName.replace(/\b\w/g, (l) => l.toUpperCase()).replace(/ /g, '');
      setSchoolId(schoolId);
    }
  }, [schoolName]);

  useEffect(() => {
    setValid(
      address &&
        regionId &&
        schoolId &&
        _.isNumber(latitude) &&
        _.isNumber(longitude) &&
        schoolName &&
        schoolType,
    );
  }, [address, regionId, schoolId, latitude, longitude, schoolName, schoolType]);

  useEffect(() => {
    if (schoolType) {
      const schoolTypeObj = SchoolTypes.find(({ type }) => type === schoolType);
      if (!schoolTypeObj) {
        setSchoolType(InitialSchool.schoolType);
      }
    }
  }, [schoolType]);

  const handleSubmit = () => {
    const schoolData = {
      name: schoolName,
      active,
      logoUrl,
      address,
      regionId,
      message,
      schoolId,
      latitude,
      longitude,
      schoolType,
      requireInventory,
      districtId,
    };
    onSubmit(schoolData, schoolLogo);
  };

  const handlePlaceChanged = ({ address, coordinates }) => {
    const { longitude, latitude } = coordinates;
    setLongitude(longitude);
    setLatitude(latitude);
    setAddress(address);
  };
  const handleSchoolIdDoubleClick = () => setReadOnlySchoolId(Boolean(school)); // Can't Edit schoolId if school exists

  const isShowingMap = Boolean(Number(latitude) && Number(longitude));
  const coordinates = { latitude, longitude };

  const title = school ? 'Edit School' : 'Add School';
  const buttonText = school ? 'Update' : 'Add School';

  const schoolTypeOptions = SchoolTypes.map((school) => (
    <option key={school.id} value={school.type}>
      {school.type}
    </option>
  ));

  const handleDistrictChanged = (evt) => {
    setDistrictId(evt.target.value);
    setRegionId('');
  };

  const districtOptions = useMemo(() => {
    return _.uniq(regions.map((region) => region.districtId)).map((districtId, index) => {
      return (
        <option key={index} value={districtId}>
          {districtId}
        </option>
      );
    });
  }, [regions]);

  const regionOptions = useMemo(() => {
    return regions
      .filter((region) => region.districtId === districtId)
      .map((region) => (
        <option key={region.id} value={region.id}>
          {region.name || region.id}
        </option>
      ));
  }, [regions, districtId]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={onToggle}
        backdrop="static"
        centered
        className="add-school-modal">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            if (!isValid) return;
            if (school && school.active === false && active) {
              return setConfirmModal(true);
            }
            handleSubmit();
          }}>
          <ModalHeader onToggle={onToggle} title={title}>
            <div className="d-flex w-100 justify-content-around">
              {school ? <ClearInventoryButton school={school} /> : null}
              <div className="d-flex align-items-center">
                <span>Active</span>
                <SwitchCheckbox
                  name="active"
                  onChange={(evt) => setActive(evt.target.checked)}
                  checked={active}
                />
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={5}>
                <FormGroup>
                  <InputImage
                    defaultSrc={logoUrl}
                    onChange={(image) => setSchoolLogo(image)}
                    height={245}
                    width={245}
                    className="w-100"
                    defaultContent={
                      <>
                        School <br /> Logo
                      </>
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={7}>
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <RequiredInputLabel>School</RequiredInputLabel>
                      <Input
                        type="text"
                        name="schoolName"
                        onChange={(evt) => setSchoolName(evt.target.value)}
                        value={schoolName}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup>
                      <RequiredInputLabel>School ID</RequiredInputLabel>
                      <InputGroup className="input-editable">
                        <Input
                          type="text"
                          name="schoolId"
                          value={schoolId}
                          onChange={(evt) => setSchoolId(evt.target.value)}
                          onDoubleClick={handleSchoolIdDoubleClick}
                          readOnly={readOnlySchoolId}
                          required
                        />
                        {readOnlySchoolId && !school ? (
                          <InputGroupAddon
                            className="disk-icon"
                            addonType="append"
                            onClick={handleSchoolIdDoubleClick}>
                            <InputGroupText>
                              <Icon icon={ic_edit} size={20} />
                            </InputGroupText>
                          </InputGroupAddon>
                        ) : null}
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <RequiredInputLabel>School Type</RequiredInputLabel>
                      <Input
                        type="select"
                        name="schoolType"
                        value={schoolType}
                        onChange={(evt) => setSchoolType(evt.target.value)}
                        required>
                        <option value="" disabled>
                          {' '}
                          - School Type -
                        </option>
                        {schoolTypeOptions}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <RequiredInputLabel>District ID</RequiredInputLabel>
                  <Input
                    type="select"
                    name="districtId"
                    value={districtId}
                    onChange={handleDistrictChanged}>
                    <option value="">- School District -</option>
                    {districtOptions}
                  </Input>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <RequiredInputLabel>Region ID</RequiredInputLabel>
                  <Input
                    type="select"
                    name="regionId"
                    readOnly={!regionOptions.length}
                    value={regionId}
                    onChange={(evt) => setRegionId(evt.target.value)}
                    required>
                    <option value="" disabled>
                      - Region -
                    </option>
                    {regionOptions}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Input
                type="textarea"
                name="message"
                placeholder="Optional - message from leadership"
                onChange={(evt) => setMessage(evt.target.value)}
                value={message}
              />
            </FormGroup>
            <FormGroup>
              <RequiredInputLabel>Address</RequiredInputLabel>
              <InputPlaces
                address={address}
                coordinates={coordinates}
                onChange={handlePlaceChanged}
              />
            </FormGroup>
            <Row className="align-items-end">
              <Col md={4}>
                <RequiredInputLabel>Latitude</RequiredInputLabel>
                <InputGroup className="input-editable">
                  <Input
                    type="number"
                    name="latitude"
                    step={0.000001}
                    placeholder="Latitude"
                    onChange={(evt) => setLatitude(parseFloat(evt.target.value))}
                    value={latitude}
                    onDoubleClick={() => setReadOnlyCoordinates(false)}
                    readOnly={readOnlyCoordinates}
                    required
                  />
                  {readOnlyCoordinates ? (
                    <InputGroupAddon
                      onClick={() => setReadOnlyCoordinates(false)}
                      className="disk-icon"
                      addonType="append">
                      <InputGroupText>
                        <Icon icon={ic_edit} size={20} />
                      </InputGroupText>
                    </InputGroupAddon>
                  ) : null}
                </InputGroup>
              </Col>
              <Col md={4}>
                <RequiredInputLabel>Longitude</RequiredInputLabel>
                <InputGroup className="input-editable">
                  <Input
                    type="number"
                    name="longitude"
                    step={0.000001}
                    onChange={(evt) => setLongitude(parseFloat(evt.target.value))}
                    value={longitude}
                    onDoubleClick={() => setReadOnlyCoordinates(false)}
                    readOnly={readOnlyCoordinates}
                    required
                  />
                  {readOnlyCoordinates ? (
                    <InputGroupAddon
                      onClick={() => setReadOnlyCoordinates(false)}
                      className="disk-icon"
                      addonType="append">
                      <InputGroupText>
                        <Icon icon={ic_edit} size={20} />
                      </InputGroupText>
                    </InputGroupAddon>
                  ) : null}
                </InputGroup>
              </Col>

              <Col md={4}>
                <Button
                  block
                  type="submit"
                  color="secondary"
                  className="btn-input-size"
                  disabled={loading || !isValid}>
                  {loading ? <Spinner size="sm" color="primary" /> : buttonText}
                </Button>
              </Col>
            </Row>
            {isShowingMap ? (
              <div className="mt-4" style={{ position: 'relative', height: 300 }}>
                <ShowInMap basic lat={latitude} lng={longitude} />
              </div>
            ) : null}
          </ModalBody>
        </Form>
      </Modal>
      <ConfirmModal
        active={confirmModal}
        toggle={() => setConfirmModal(false)}
        onResponse={(response) => response && handleSubmit()}
        title="Please Confirm"
        body={`Are you sure you want to activate all users for this school?`}
      />
    </>
  );
};

ManageSchoolModal.defaultProps = {
  school: null,
  loading: false,
};

ManageSchoolModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  regions: PropTypes.array.isRequired,
  school: PropTypes.object,
  loading: PropTypes.bool,
};

export default ManageSchoolModal;
