import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Button, Input } from 'reactstrap';
import { Icon } from 'react-icons-kit';
import { plus as plusIcon } from 'react-icons-kit/fa/plus';
import ModalIndicators from './ModalIndicators';
import { IndicatorBadge } from '../CustomSolutionCard';
import { DRIVERS_AND_DOMAINS } from './../../solutions-models';
import { Card, BoxInfo } from './StepCard';
import { RequiredInputLabel } from '../../../../shared/components/typography/RequiredInputLabel';
import { ErrorInputMsg } from '../../../../shared/components/typography/ErrorInputMsg';
import { theme } from '../../../../shared/theme';

const Step1 = ({ active, editable, onChange, solution, loading, onError }) => {
  const [selectDriverModal, setSelectDriverModal] = useState(false);

  const driverModaltoggle = () => setSelectDriverModal(!selectDriverModal);

  const handleOnSelectDomainIndicators = (driverDomain, indicators) => {
    onChange(['driverDomain', 'relatedIndicators'], [driverDomain, indicators]);
    driverModaltoggle();
  };

  const indicatorsBagdes = solution.relatedIndicators.map((indicator, index) => {
    const driverDomainItem =
      DRIVERS_AND_DOMAINS.find((item) => item.id === solution.driverDomain) || {};
    if (!driverDomainItem) return null;
    if (!driverDomainItem.indicators || !driverDomainItem.indicators.length) return null;
    const indicatorItem = driverDomainItem.indicators.find((item) => item.id === indicator);
    if (!indicatorItem) return null;
    return (
      <div className="pl-2 mb-2" key={indicatorItem.id}>
        <IndicatorBadge>{indicatorItem.name}</IndicatorBadge>
      </div>
    );
  });

  return (
    <>
      <ModalIndicators
        isOpen={selectDriverModal}
        selectedDriverDomain={solution.driverDomain}
        selectedIndicators={solution.relatedIndicators}
        onSubmit={handleOnSelectDomainIndicators}
        toggle={driverModaltoggle}
      />
      <Card active={active}>
        <Row className="d-flex flex-column">
          <h4>Step 1</h4>
          <div>{"Let's first identify the issues"}</div>
        </Row>

        <Row className="mt-4 d-flex flex-column">
          <RequiredInputLabel>Solution Name</RequiredInputLabel>
          {editable && active ? (
            <Input
              type="text"
              value={solution.name}
              onChange={(e) => onChange('name', e.target.value)}
              disabled={loading}
              maxLength="70"
            />
          ) : (
            <BoxInfo>{solution.name}</BoxInfo>
          )}
          <ErrorInputMsg>{onError('name')}</ErrorInputMsg>
        </Row>

        <Row className="mt-4 d-flex flex-column">
          <RequiredInputLabel>Related Indicators</RequiredInputLabel>
          {editable && active && (
            <Button
              color="link"
              className="d-flex justify-content-start"
              style={{ textDecoration: 'none', color: theme.palette.secondary }}
              onClick={() => driverModaltoggle()}
              disabled={loading}>
              <Icon className="mr-2" icon={plusIcon} style={{ cursor: 'pointer' }} /> Select
              Indicators
            </Button>
          )}
          {indicatorsBagdes}
          <ErrorInputMsg>{onError('relatedIndicators')}</ErrorInputMsg>
        </Row>

        <Row className="mt-2 d-flex flex-column">
          <RequiredInputLabel>Existing Needs Identified</RequiredInputLabel>
          {editable && active ? (
            <textarea
              onChange={(e) => onChange('needsIdentified', e.target.value)}
              rows="6"
              className="form-control"
              value={solution.needsIdentified}
              disabled={loading}
            />
          ) : (
            <BoxInfo>{solution.needsIdentified}</BoxInfo>
          )}
          <ErrorInputMsg>{onError('needsIdentified')}</ErrorInputMsg>
        </Row>
        <Row className="mt-4 d-flex flex-column">
          <RequiredInputLabel>Desired Outcomes</RequiredInputLabel>
          {editable && active ? (
            <textarea
              onChange={(e) => onChange('desiredOutcomes', e.target.value)}
              rows="6"
              className="form-control"
              value={solution.desiredOutcomes}
              disabled={loading}
            />
          ) : (
            <BoxInfo>{solution.desiredOutcomes}</BoxInfo>
          )}
          <ErrorInputMsg>{onError('desiredOutcomes')}</ErrorInputMsg>
        </Row>
      </Card>
    </>
  );
};

Step1.propTypes = {
  active: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  solution: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onError: PropTypes.func.isRequired,
};

export default Step1;
