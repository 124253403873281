import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import Checkbox from '../../../components/form/Checkbox';
import { theme } from '../../theme';

const StyledButton = styled(Button)`
  display: inline-flex !important;
  align-items: center;
  color: ${theme.palette.primary} !important;
  background-color: #fff !important;
  border: 1px solid ${({ active }) => (active ? theme.palette.primary : '#ced4da')} !important;
`;

export const CheckButton = ({ active, onChange, children, ...rest }) => (
  <StyledButton
    active={active}
    className="btn-input-size"
    onClick={() => onChange(!active)}
    {...rest}>
    <Checkbox checked={active} className="mr-2" />
    {children}
  </StyledButton>
);

CheckButton.propTypes = {
  active: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
