import React from 'react';
import ManageSchoolModal from './ManageSchoolModal';
import PropTypes from 'prop-types';

/**
 * Edit School Modal Component.
 *
 * @param {object} school - School object to edit.
 * @param {boolean} isOpen - Define if modal is open or close.
 * @param {Function} onToggle - Function to trigger on toggle.
 * @param {Function} onSubmit - Function to trigger on submit.
 * @param {Array} regions - Array of available regions to select.
 * @param {boolean} loading - Is component loading.
 * @returns {*} - ManageSchoolModal Component.
 * @class
 */
const EditSchoolModal = ({ isOpen, onToggle, onSubmit, regions, school, loading = false }) => {
  return (
    <ManageSchoolModal
      school={school}
      regions={regions}
      onSubmit={onSubmit}
      onToggle={onToggle}
      isOpen={isOpen}
      loading={loading}
    />
  );
};

EditSchoolModal.defaultProps = {
  loading: false,
};

EditSchoolModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  regions: PropTypes.array.isRequired,
  school: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

export default EditSchoolModal;
