import { DRIVERS } from '../my-voice/my-voice-models';
import { DOMAINS } from '../covid-19-domains/covid-19-domains-models';

export const SOLUTIONS_STAGES = [
  {
    number: 1,
    name: 'Issue Identified',
    id: 'issueIdentified',
  },
  {
    number: 2,
    name: 'Solution Created',
    id: 'solutionCreated',
  },
  {
    number: 3,
    name: 'Implementation',
    id: 'implementation',
  },
];

export const DRIVERS_AND_DOMAINS = [...DRIVERS, ...DOMAINS];
