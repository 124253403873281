import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { ic_save } from 'react-icons-kit/md/ic_save';
import LoadingButton from '../../../components/button/LoadingButton';

const iconStyle = {
  transform: 'translate(0px, -2px)',
  marginRight: 5,
};

/**
 * Save Report Button.
 *
 * @param {Function} onClick - Function triggered on click.
 * @returns {*} - Export Report Button Component.
 * @class
 */
const SaveReportButton = ({ onClick, loading }) => {
  return (
    <LoadingButton loading={loading} size="sm" color="primary" onClick={onClick}>
      <Icon style={iconStyle} size={18} icon={ic_save} />
      Save
    </LoadingButton>
  );
};

SaveReportButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SaveReportButton;
