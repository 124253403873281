import React from 'react';
import { NavigationBar } from '../../../shared/layouts/NavigationBar';
import { DriversLeaderNavigationBar } from '../components/DriversLeaderNavigationBar';
import {
  fetchMySchoolAverage,
  fetchMyVoiceAction,
  fetchMyVoiceActionLeader,
  getSchoolAverageQuestionsTeacher,
  updateMyVoiceActionLeader,
} from '../my-voice-actions';
import View from '@cobuildlab/react-flux-state';
import {
  AVG_QUESTIONS_TEACHER_EVENT,
  MY_VOICE_ERROR_EVENT,
  MY_VOICE_EVENT,
  MY_VOICE_UPDATE_EVENT,
  myVoiceStore,
  SCHOOL_AVERAGE_EVENT,
} from '../my-voice-store';
import { onErrorMixin } from '../../../shared/mixins';
import ProtectedRoute from '../../../routes/ProtectedRoute';
import { Switch, Redirect } from 'react-router-dom';
import { MyVoiceLeaderGraph } from './MyVoiceLeaderGraph';
import { DRIVERS_LEADER } from '../my-voice-models';
import { customToast } from '../../../shared/toast';
import { Col } from 'reactstrap';
import { ClipLoader } from '../../../shared/components/progress/ClipLoader';
import { authStore, USER_EVENT } from '../../auth/auth-store';
import { USER_TYPE_TEACHER } from '../../../shared/userTypes';
import Exclamation from '../../../shared/assets/images/exclamation-circle-solid.svg';
import { SubNavigationLayout } from '../../../shared/layouts/SubNavigationLayout';
import { DOMAINS } from '../../covid-19-domains/covid-19-domains-models';
import DomainDetails from '../../covid-19-domains/components/DomainDetails';
import {
  calculateSchoolDomainAverages,
  getSchoolTeacherDomains,
} from '../../covid-19-domains/covid-19-domains-actions';
import covid19DomainsStore, {
  COVID_19_SCHOOLS_DOMAINS_EVENT,
  COVID_19_SCHOOLS_DOMAINS_ERROR,
} from '../../covid-19-domains/covid-19-domains-store';
import {
  buildPathBaseCulture,
  buildPathBaseCovid19,
} from '../../../shared/components/menus/CultureAndCovidCollapsibleLists';
import { PageInfo } from '../../../shared/components/PageInfo';
import DomainsGraph from '../../covid-19-domains/components/DomainsGraph';

const pathBase = '/my-school';
const pathBaseCulture = buildPathBaseCulture(pathBase);
const pathBaseCovid19 = buildPathBaseCovid19(pathBase);

class MyVoiceLeaderView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      schoolAvg: {},
      schoolInitialAvg: null,
      schoolInitialDriversAverage: null,
      driversIndicatorsCount: null,
      numberTeachers: 0,
      loading: true,
      loadingDrivers: true,
      loadingDomains: true,
      respondedDomains: [],
      domainsAverage: {},
    };
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    const user = authStore.getState(USER_EVENT);
    this.subscribe(myVoiceStore, MY_VOICE_ERROR_EVENT, this.onError);
    this.subscribe(myVoiceStore, AVG_QUESTIONS_TEACHER_EVENT, (state) => {
      if (state.driversAverage && Object.values(state.driversAverage).length) {
        this.setState({
          loadingDrivers: false,
          schoolInitialDriversAverage: state.initialDriversAverage,
          schoolDriversAverage: state.driversAverage,
          driversIndicatorsCount: state.driversIndicatorsCount,
          totalTeachers: state.totalTeachers,
          numberTeachersResponded: state.numberTeachersResponded,
        });
      } else {
        customToast.info('No Teacher Answered');
        this.setState({
          loading: false,
          schoolDriversAverage: null,
          driversIndicatorsCount: null,
          totalTeachers: state.totalTeachers,
          numberTeachersResponded: state.numberTeachersResponded,
        });
      }
    });
    this.subscribe(covid19DomainsStore, COVID_19_SCHOOLS_DOMAINS_EVENT, (data) => {
      const respondedDomains = data.filter((td) => td !== null && Object.keys(td).length);
      this.setState({
        teacherDomains: data,
        respondedDomains,
        domainsAverage: calculateSchoolDomainAverages(respondedDomains),
        loadingDomains: false,
      });
    });
    this.subscribe(covid19DomainsStore, COVID_19_SCHOOLS_DOMAINS_ERROR, () => {
      customToast.error('get school domains error');
    });

    this.subscribe(myVoiceStore, MY_VOICE_EVENT, (state) => {
      const { driversData } = state;
      this.setState({ data: driversData });
    });
    this.subscribe(myVoiceStore, SCHOOL_AVERAGE_EVENT, (state) => {
      const { data } = state;
      if (data && data.results) {
        this.setState({ schoolAvg: data.results });
      }
    });

    this.subscribe(myVoiceStore, MY_VOICE_UPDATE_EVENT, (state) => {
      const { data } = this.state;
      const driverId = Object.keys(state)[0];
      let driverData = data[driverId];
      // When a User comes here for the First Time, this.state.data
      // does not contains all the Drivers Key, so this my be undefined
      if (driverData === undefined) {
        data[driverId] = {};
        driverData = data[driverId];
      }
      const indicatorsId = Object.keys(state[driverId]);
      indicatorsId.forEach((indicatorId) => {
        driverData[indicatorId] = state[driverId][indicatorId];
      });
      this.setState({ data, loading: false });
      customToast.success('Driver successfully Updated!');

      getSchoolAverageQuestionsTeacher();
      getSchoolTeacherDomains(user.schoolId);
    });

    if (user.userType === USER_TYPE_TEACHER) {
      fetchMyVoiceAction();
    } else {
      fetchMyVoiceActionLeader();
    }
    getSchoolAverageQuestionsTeacher();
    getSchoolTeacherDomains(user.schoolId);
    fetchMySchoolAverage();
  }

  onSubmit = (driverId, indicatorsList) => {
    this.setState({ loading: true }, () => {
      const indicators = indicatorsList.filter((indicator) => indicator.data !== 0);
      const indicatorsObj = {};
      indicators.forEach(({ id, data }) => {
        indicatorsObj[id] = data;
      });
      updateMyVoiceActionLeader(driverId, indicatorsObj);
    });
  };

  render() {
    const {
      data,
      schoolAvg,
      schoolInitialDriversAverage,
      schoolDriversAverage,
      driversIndicatorsCount,
      totalTeachers,
      numberTeachersResponded,
      respondedDomains,
      domainsAverage,
      loadingDomains,
      loadingDrivers,
    } = this.state;
    const loading = loadingDrivers || loadingDomains;
    const driversAvg = {};

    let content = (
      <div className="align-items-center justify-content-center h-100 d-flex">
        <ClipLoader />
      </div>
    );

    if (!loading) {
      content = (
        <Col md={6} lg={8} className="align-items-center justify-content-center d-flex p-4">
          <div className="d-flex justify-content-center align-items-center text-center">
            <img src={Exclamation} alt="icon" height={32} width={35} className="mr-3" />
            <span className="h6">No teachers have answered</span>
          </div>
        </Col>
      );
    }

    let avgs = true;
    if (
      driversIndicatorsCount === null ||
      schoolDriversAverage === null ||
      totalTeachers === null ||
      numberTeachersResponded === null
    ) {
      avgs = false;
    }

    if (!loading && avgs) {
      const user = authStore.getState(USER_EVENT);
      const driversIds = Object.keys(data);

      // Calculating Averages
      driversIds.forEach((driverId) => {
        const indicators = data[driverId];
        const indicatorsIds = Object.keys(indicators);
        const indicatorsValue = [];
        indicatorsIds.forEach((indicatorId) => indicatorsValue.push(indicators[indicatorId]));
        driversAvg[driverId] = (
          indicatorsValue.reduce((acc, curr) => acc + curr, 0) / indicatorsIds.length
        ).toFixed(2);
      });

      content = (
        <Switch>
          {DRIVERS_LEADER.map((driver) => {
            const { component: Component, name } = driver;
            const path = `${pathBaseCulture}/${driver.path}`;
            const component = (
              <Component
                numberTeachers={totalTeachers}
                numberTeachersResponded={numberTeachersResponded}
                questionsTeacher={driversIndicatorsCount}
                name={name}
                type={'pie'}
                height={300}
              />
            );
            return <ProtectedRoute key={driver.id} path={path} component={() => component} />;
          })}

          {DOMAINS.map((domain) => {
            const filteredTeacherDomains = respondedDomains.filter((td) =>
              Object.keys(td).find((d) => d === domain.id),
            );
            const indicatorsData = filteredTeacherDomains.map((td) => td[domain.id]);
            const respondedTeacherCount = filteredTeacherDomains.length;
            const path = `${pathBaseCovid19}/${domain.path}`;

            const component = (
              <DomainDetails
                key={domain.id}
                domain={domain.name}
                message={domain.message}
                indicators={domain.indicators}
                indicatorsData={indicatorsData}
                numberTeachersResponded={respondedTeacherCount}
                numberTeachers={totalTeachers}
              />
            );
            return (
              <ProtectedRoute key={domain.id} path={path} component={() => <>{component}</>} />
            );
          })}

          <ProtectedRoute
            path={pathBaseCulture}
            render={() => (
              <MyVoiceLeaderGraph
                avg={driversAvg}
                schoolAvg={schoolDriversAverage}
                schoolInitialAvg={schoolInitialDriversAverage}
                user={user}
              />
            )}
          />

          <ProtectedRoute
            path={pathBaseCovid19}
            component={() =>
              !domainsAverage || !Object.keys(domainsAverage).length ? (
                <PageInfo text="No Teachers Have Answered" />
              ) : (
                <DomainsGraph schoolName="" domainAverages={domainsAverage} />
              )
            }
          />

          <ProtectedRoute to={pathBase} component={() => <Redirect to={pathBaseCulture} />} />
        </Switch>
      );
    }

    return (
      <NavigationBar currentRoute={pathBase}>
        <SubNavigationLayout
          sidebar={
            <DriversLeaderNavigationBar
              driversAverage={schoolAvg}
              domainsAverage={domainsAverage}
            />
          }>
          {content}
        </SubNavigationLayout>
      </NavigationBar>
    );
  }
}

export default MyVoiceLeaderView;
