import Flux from '@cobuildlab/flux-state';
import firebase from 'firebase';
import {
  COVID_19_TEACHER_DOMAINS_EVENT,
  COVID_19_TEACHER_DOMAINS_ERROR,
  COVID_19_TEACHER_CUSTOM_DOMAINS_EVENT,
  COVID_19_TEACHER_CUSTOM_DOMAINS_ERROR,
  COVID_19_SCHOOLS_DOMAINS_EVENT,
  COVID_19_SCHOOLS_DOMAINS_ERROR,
  COVID_19_SCHOOL_CUSTOM_DOMAINS_AVERAGE_EVENT,
  COVID_19_SCHOOL_CUSTOM_DOMAINS_AVERAGE_ERROR,
  UPDATE_COVID_19_TEACHER_CUSTOM_DOMAINS_EVENT,
  UPDATE_COVID_19_TEACHER_CUSTOM_DOMAINS_ERROR,
} from './covid-19-domains-store';
import { authStore, USER_EVENT } from '../auth/auth-store';

export const getTeacherDomains = async () => {
  const fetchTeacherDomains = firebase.functions().httpsCallable('fetchTeacherDomains');

  try {
    const response = await fetchTeacherDomains();
    Flux.dispatchEvent(COVID_19_TEACHER_DOMAINS_EVENT, response.data);
  } catch (err) {
    return Flux.dispatchEvent(COVID_19_TEACHER_DOMAINS_ERROR);
  }
};

export const getTeacherCustomDomains = async () => {
  const DB = firebase.firestore();
  const { email } = authStore.getState(USER_EVENT);

  try {
    const customDomainsQuery = await DB.collection('customDomainsTeacher')
      .where('email', '==', email)
      .get();
    const customDomainsDocs = customDomainsQuery.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    const teacherCustomDomains = customDomainsDocs[0];
    Flux.dispatchEvent(COVID_19_TEACHER_CUSTOM_DOMAINS_EVENT, teacherCustomDomains);
  } catch (err) {
    return Flux.dispatchEvent(COVID_19_TEACHER_CUSTOM_DOMAINS_ERROR);
  }
};

export const getSchoolTeacherDomains = async (schoolId) => {
  const fetchSchoolTeacherDomains = firebase.functions().httpsCallable('fetchSchoolTeacherDomains');
  const { districtId } = authStore.getState(USER_EVENT);

  try {
    const response = await fetchSchoolTeacherDomains({ schoolId, districtId });
    Flux.dispatchEvent(COVID_19_SCHOOLS_DOMAINS_EVENT, response.data);
  } catch (err) {
    return Flux.dispatchEvent(COVID_19_SCHOOLS_DOMAINS_ERROR);
  }
};

export const getSchoolCustomDomainAverages = async () => {
  const fetchSchoolCustomDomainAverage = firebase
    .functions()
    .httpsCallable('fetchSchoolCustomDomainAverage');

  try {
    const response = await fetchSchoolCustomDomainAverage();
    Flux.dispatchEvent(COVID_19_SCHOOL_CUSTOM_DOMAINS_AVERAGE_EVENT, response.data);
  } catch (err) {
    Flux.dispatchEvent(COVID_19_SCHOOL_CUSTOM_DOMAINS_AVERAGE_ERROR, err);
  }
};

export const updateTeacherCustomDomains = async (domainsId, domains) => {
  const DB = firebase.firestore();
  const { email } = authStore.getState(USER_EVENT);
  const domainsCollection = DB.collection('customDomainsTeacher');
  let promise;
  if (!domainsId) promise = domainsCollection.add({ email, domains });
  else promise = domainsCollection.doc(domainsId).set({ domains }, { merge: true });

  try {
    await promise;
    Flux.dispatchEvent(UPDATE_COVID_19_TEACHER_CUSTOM_DOMAINS_EVENT, domains);
  } catch (err) {
    return Flux.dispatchEvent(UPDATE_COVID_19_TEACHER_CUSTOM_DOMAINS_ERROR, err);
  }
};

export const calculateDomainAverages = (domainsData) => {
  const domainAverages = {};
  for (const domain of Object.keys(domainsData)) {
    const domainIndicators = domainsData[domain];
    const indicatorsCount = Object.keys(domainIndicators).length;
    let domainAverage = 0;
    for (const indicator of Object.keys(domainIndicators)) {
      const indicatorValue = domainIndicators[indicator] / indicatorsCount;
      domainAverage += indicatorValue;
    }
    domainAverages[domain] = domainAverage.toFixed(2);
  }
  return domainAverages;
};

/**
 * Calculate domain averages.
 *
 * @param {object} schoolTeacherDomains - School teacher domains.
 * @returns {object} - School domains average.
 */
export const calculateSchoolDomainAverages = (schoolTeacherDomains) => {
  const domainIndicatorAverages = {};
  for (const teacherDomains of schoolTeacherDomains) {
    for (const domain of Object.keys(teacherDomains)) {
      const domainIndicators = teacherDomains[domain];
      if (!domainIndicatorAverages[domain]) domainIndicatorAverages[domain] = {};
      for (const indicator of Object.keys(domainIndicators)) {
        if (!domainIndicatorAverages[domain][indicator])
          domainIndicatorAverages[domain][indicator] = 0;
        const respondedIndicatorCount = schoolTeacherDomains.filter(
          (td) => td[domain] && td[domain][indicator],
        ).length;
        const indicatorValue = parseFloat(
          (domainIndicators[indicator] / respondedIndicatorCount).toFixed(2),
        );
        domainIndicatorAverages[domain][indicator] += indicatorValue;
      }
    }
  }

  const domainAverges = calculateDomainAverages(domainIndicatorAverages);

  return domainAverges;
};

/**
 *
 * @param {Array<string>} schoolIds
 * @returns
 */
export const getManySchoolsTeachersDomains = async (schoolIds) => {
  const fetchSchoolTeacherDomains = firebase.functions().httpsCallable('fetchSchoolTeacherDomains');

  const allTeachers = [];
  const getTeachers = async (schoolId) => {
    const response = await fetchSchoolTeacherDomains({ schoolId });
    allTeachers.push(...response.data);
  };

  try {
    await Promise.all(schoolIds.map(getTeachers));
    Flux.dispatchEvent(COVID_19_SCHOOLS_DOMAINS_EVENT, allTeachers);
  } catch (err) {
    return Flux.dispatchEvent(COVID_19_SCHOOLS_DOMAINS_ERROR);
  }
};
