import { Col, Container, Row } from 'reactstrap';
import React, { useState } from 'react';
import { DropZone } from './DropZone';
import PropTypes from 'prop-types';
import { Badge, Button } from 'reactstrap';
import { previewFile } from '../../../shared/files';
import defaultPicture from '../../../shared/assets/images/picture.png';
import { isValidString } from '../../../shared/strings';

/**
 * Edit User component.
 *
 * @param onSave
 * @param onCancel
 * @param picture
 * @param firstName
 * @param lastName
 * @returns {*}
 * @class
 */
const EditUser = ({ onSave, onCancel, user: { picture, firstName, lastName, anonymityId } }) => {
  const [newPicture, setPicture] = useState(null);
  const [firstNameValue, setFirstName] = useState(firstName);
  const [lastNameValue, setLastName] = useState(lastName);
  const imgIdSelector = 'edit-user-Profile';

  const onFiles = (files) => {
    previewFile(imgIdSelector, files[0]);
    setPicture(files[0]);
  };

  const onSubmit = () => {
    const values = {};
    if (newPicture !== null) values.picture = newPicture;
    if (isValidString(firstNameValue)) values.firstName = firstNameValue;
    if (isValidString(lastNameValue)) values.lastName = lastNameValue;
    onSave({ ...values });
  };
  return (
    <>
      <Container>
        <Row>
          <Col sm={3} lg={2} className="text-center justify-content-center ">
            <Row>
              <Col className="E-margin IE-margin">
                {isValidString(picture) ? (
                  <img
                    id={imgIdSelector}
                    alt={'User Profile'}
                    src={picture}
                    className="item-my-profile mr-2 IE-image"
                  />
                ) : (
                  <img
                    id={imgIdSelector}
                    alt={'User Profile'}
                    src={defaultPicture}
                    className="item-my-profile mr-2 IE-image"
                  />
                )}
              </Col>
              <Col>
                <DropZone className="IE-dropzone dropzone" onFiles={onFiles}>
                  <Badge className="IE-badge" color="secondary">
                    Change
                  </Badge>
                </DropZone>
              </Col>
            </Row>
          </Col>
          <Col sm={4} md={3}>
            <div className="pt-2 pr-3">
              <div className="mb-1">
                <h5 className="item-textgray">First Name</h5>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstNameValue}
                  name="firstName"
                />
              </div>
            </div>
          </Col>
          <Col sm={4} md={3}>
            <div className="pt-2 pr-3">
              <div className="mb-1">
                <h5 className="item-textgray">Last Name</h5>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastNameValue}
                  name="lastName"
                />
              </div>
            </div>
          </Col>
          <Col className="d-flex align-items-center justify-content-center flex-md-wrap">
            <div className="ml-2">
              <Button
                onClick={onSubmit}
                type="button"
                className="mx-sm-auto m-lg-0 d-block btn-input-size">
                Save
              </Button>
            </div>
            <div className="ml-2">
              <Button
                onClick={onCancel}
                type="button"
                className="mx-sm-auto m-lg-0 d-block btn-input-size">
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

EditUser.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export { EditUser };
