import React from 'react';
import { Button, Form } from 'reactstrap';
import { Link } from 'react-router-dom';
import { requestPasswordReset } from './auth-actions';
import View from '@cobuildlab/react-flux-state';
import { authStore, REQUEST_RECOVER_PASSWORD, USER_ERROR } from './auth-store';
import { customToast } from '../../shared/toast';
import { onErrorMixin } from '../../shared/mixins';
import { TextField } from '../../shared/components/inputs/TextField';
import { ClipLoader } from '../../shared/components/progress/ClipLoader';
import { AuthLayout, AuthContent } from '../../shared/layouts/AuthLayout';

/**
 * Recover Password View.
 *
 * @param e
 */
class ForgotPasswordView extends View {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: '',
    };
    this.onError = onErrorMixin.bind(this);
  }

  async componentDidMount() {
    this.subscribe(authStore, USER_ERROR, this.onError);
    this.subscribe(authStore, REQUEST_RECOVER_PASSWORD, () => {
      this.setState({ loading: false }, () => {
        customToast.success('Request for Recover Password processed');
        this.props.history.goBack();
      });
    });
  }

  onChange = async (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { email } = this.state;
    this.setState({ loading: true }, () => {
      requestPasswordReset(email.toLocaleLowerCase());
    });
  };

  render() {
    const { loading } = this.state;

    return (
      <AuthLayout>
        <AuthContent>
          <h4 className="text-center mb-5">
            Enter your email address and {`we'll`} send <br />
            you a Password reset link.
          </h4>
          <Form onSubmit={this.onSubmit} className="vertical-align m-10">
            <TextField
              className="mb-5"
              label="Email"
              name="email"
              type="Email"
              required
              onChange={this.onChange}
            />

            <div className="text-center pb-4">
              {loading ? (
                <ClipLoader />
              ) : (
                <Button style={{ width: 200 }} color="secondary">
                  Send request
                </Button>
              )}
            </div>
          </Form>
          <div className="text-center pb-4">
            <Link to="/login">Already have an account? Login.</Link>
          </div>
        </AuthContent>
      </AuthLayout>
    );
  }
}

export default ForgotPasswordView;
