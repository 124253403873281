import React from 'react';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { BAR_GRAPH_OPTIONS_LEADER, DRIVERS_COA } from '../my-voice-models';

const MyVoiceCoaGraph = ({ myDriversAvg, schoolName, schoolAvg = {}, schoolInitialAvg = {} }) => {
  const driversOrder = [
    'clearUnifiedDirection',
    'professionalEngagement',
    'instructionalAutonomy',
    'collaboration',
    'empowerment',
    'feedbackReflection',
    'resourcePriorities',
    'supportCare',
    'senseOfBelonging',
    'advancement',
  ];

  const labels = [];

  const exportFilename = `${schoolName}-drivers-average`;

  let schoolInitialAvgData = [];
  if (schoolInitialAvg) {
    schoolInitialAvgData = driversOrder.map((driverKey) => {
      return schoolInitialAvg[driverKey] || 0;
    });
  }

  let schoolAvgData = [];
  if (schoolAvg) {
    schoolAvgData = driversOrder.map((driverKey) => {
      const driver = DRIVERS_COA.find((driver) => driver.id === driverKey);
      if (driver) {
        labels.push(driver.name);
        return schoolAvg[driver.id] || 0;
      } else {
        console.error(`Not Driver with ID '${driverKey}' was found`);
      }
      return 0;
    });
  }

  let myDriversData = [];

  const options = { ...BAR_GRAPH_OPTIONS_LEADER };
  options.chart.toolbar.export.svg.filename = exportFilename;
  options.chart.toolbar.export.png.filename = exportFilename;
  options.chart.toolbar.export.csv.filename = exportFilename;
  options.xaxis.categories = labels;

  const series = [
    {
      name: 'Baseline',
      data: schoolInitialAvgData,
      type: 'bar',
      color: '#E6874F',
    },
    {
      name: 'Realtime',
      data: schoolAvgData,
      type: 'bar',
      color: '#FFC04E',
    },
  ];

  if (myDriversAvg) {
    let isEmptyValues = true;
    Object.values(myDriversAvg).forEach((v) => (isEmptyValues = Number(v) <= 0));
    if (!isEmptyValues) {
      DRIVERS_COA.forEach((driver) => {
        myDriversData.push(myDriversAvg[driver.id] || 0);
      });

      series.push({
        name: 'My Culture Score',
        data: myDriversData,
        type: 'line',
      });
    }
  }

  return (
    <div>
      <ReactApexChart
        className="voiceGraph"
        options={options}
        series={series}
        type="line"
        height="500"
        width="80%"
      />
    </div>
  );
};

MyVoiceCoaGraph.defaultProps = {
  schoolName: '',
  myDriversAvg: null,
};

MyVoiceCoaGraph.propTypes = {
  schoolName: PropTypes.string,
  schoolAvg: PropTypes.object.isRequired,
  schoolInitialAvg: PropTypes.object.isRequired,
  myDriversAvg: PropTypes.object,
};

export { MyVoiceCoaGraph };
