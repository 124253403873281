import React from 'react';
import { NavigationBar } from '../../../shared/layouts/NavigationBar';
import {
  bulkUpdateMyVoiceActionLeader,
  bulkUpdateMyVoiceInitialActionLeader,
} from '../my-voice-actions';
import View from '@cobuildlab/react-flux-state';
import { MY_VOICE_ERROR_EVENT, MY_VOICE_UPDATE_EVENT, myVoiceStore } from '../my-voice-store';
import { onErrorMixin } from '../../../shared/mixins';
import { DRIVERS } from '../my-voice-models';
import { customToast } from '../../../shared/toast';
import { Col, Alert, Progress } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import { authStore, USER_EVENT } from '../../auth/auth-store';

class InitialAssessmentLeaderView extends View {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      driverIndex: 0,
    };
    this.onError = onErrorMixin.bind(this);
    this.data = {};
  }

  componentDidMount() {
    this.subscribe(myVoiceStore, MY_VOICE_ERROR_EVENT, this.onError);
    this.subscribe(myVoiceStore, MY_VOICE_UPDATE_EVENT, () => {
      customToast.success('Driver successfully Updated!');
      this.props.history.push('/my-school');
    });
  }

  onSubmit = (driverId, indicatorsList) => {
    const indicators0 = indicatorsList.filter((indicator) => indicator.data === 0);
    if (indicators0.length > 0) return customToast.error('You Must fill all the indicators');
    const indicatorsObj = {};
    indicatorsList.forEach(({ id, data }) => {
      indicatorsObj[id] = data;
    });
    this.data[driverId] = indicatorsObj;
    if (this.state.driverIndex === DRIVERS.length - 1) {
      bulkUpdateMyVoiceActionLeader(this.data);
      bulkUpdateMyVoiceInitialActionLeader(this.data);
    } else {
      this.setState({ driverIndex: this.state.driverIndex + 1 });
    }
  };

  render() {
    const { loading, driverIndex } = this.state;
    let content = (
      <Col
        md={12}
        className="content-sub-bar align-items-center justify-content-center h-100vh d-flex">
        <ClipLoader sizeUnit={'px'} size={150} color={'#123abc'} loading={true} />
      </Col>
    );

    if (!loading) {
      const { component: Component, id } = DRIVERS[driverIndex];
      const text = driverIndex === DRIVERS.length - 1 ? 'Submit' : 'Next';
      const user = authStore.getState(USER_EVENT);
      content = (
        <Component
          user={user}
          isInitial={true}
          submitText={text}
          bodyClass={'driver-sub-bar-full'}
          data={undefined}
          onSubmit={(indicators) => this.onSubmit(id, indicators)}
        />
      );
    }

    const progress = Math.ceil((driverIndex * 100) / DRIVERS.length);
    return (
      <NavigationBar currentRoute={'/initial-assessment'}>
        <Alert color="primary">
          Please fill all the indicators for each Driver.
          <div className="text-center">{`${progress} %`}</div>
          <Progress value={progress} />
          <br />
        </Alert>
        {content}
      </NavigationBar>
    );
  }
}

export default InitialAssessmentLeaderView;
