import React from 'react';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import PropTypes from 'prop-types';
import { default as firebaseConfig } from '../config/firebase.json';

// eslint-disable-next-line
/**
 * Returns a Map showing the coordinate in it.
 *
 * @param {{
 *  lat: number,
 *  lng: number,
 *  height: number,
 *  zoom: number,
 *  google: object,
 * }} point - The point to show.
 * @class
 */
const ShowInMap = ({ lat, lng, height = 300, zoom = 18, google }) => {
  const position = { lat, lng };
  return (
    <Map
      streetViewControl={false}
      scaleControl={false}
      zoomControl={false}
      mapTypeControl={false}
      fullscreenControl={false}
      google={google}
      initialCenter={position}
      center={position}
      zoom={zoom}
      style={{ height }}>
      <Marker position={position} />
    </Map>
  );
};

ShowInMap.defaultProps = {
  height: 300,
  zoom: 18,
};

ShowInMap.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  height: PropTypes.number,
  zoom: PropTypes.number,
  google: PropTypes.any.isRequired,
};

export default GoogleApiWrapper({
  apiKey: firebaseConfig.apiKey,
})(ShowInMap);
