import React from 'react';
import { Button, Spinner } from 'reactstrap';
import Icon from 'react-icons-kit';
import { buttomPropsType } from '../super-admin-types';

export const ButtomActionsTabs = ({ actions = [] }) =>
  actions.map(({ title, onClick, icon, loading = false }, index) => (
    <div className="ml-2" key={title + index}>
      <Button
        onClick={onClick}
        disabled={loading}
        type="button"
        className="mx-sm-auto m-lg-0 d-block btn-input-size">
        {loading ? <Spinner size="sm" className="mr-2" /> : <Icon icon={icon} className="mr-2" />}
        {title}
      </Button>
    </div>
  ));

ButtomActionsTabs.propTypes = buttomPropsType;
