import React from 'react';
import View from '@cobuildlab/react-flux-state';
import { customToast } from '../../../shared/toast';
import { createUserSuperAdmin, getAllDistricts, fetchAllSchools } from '../super-admin-actions';
import {
  ADD_USER_ERROR_EVENT,
  ADD_USER_EVENT,
  SUPER_ADMIN_ALL_DISTRICTS_EVENT,
  SUPER_ADMIN_ALL_SCHOOLS_EVENT,
  superAdminStore,
} from '../super-admin-store';
import AddUserModal from '../../../components/user/AddUserModal';

class AddUserModalContainer extends View {
  state = {
    schools: [],
    districts: [],
    loading: false,
  };

  handleSubmit = (user) => {
    this.setState({ loading: true });
    createUserSuperAdmin(user);
  };

  componentDidMount() {
    this.subscribe(superAdminStore, ADD_USER_EVENT, (user) => {
      customToast.success('User created successfully');
      this.props.toggle();
      this.setState({ loading: false });
    });
    this.subscribe(superAdminStore, ADD_USER_ERROR_EVENT, (err) => {
      customToast.error(err);
      this.setState({ loading: false });
    });
    this.subscribe(superAdminStore, SUPER_ADMIN_ALL_SCHOOLS_EVENT, (schools) => {
      this.setState({ schools });
    });
    this.subscribe(superAdminStore, SUPER_ADMIN_ALL_DISTRICTS_EVENT, (districts) => {
      this.setState({ districts });
    });
    fetchAllSchools();
    getAllDistricts();
  }

  render() {
    const { schools, districts, loading } = this.state;
    const { modal, toggle } = this.props;

    return (
      <AddUserModal
        admin
        loading={loading}
        onSubmit={this.handleSubmit}
        schools={schools}
        districts={districts}
        onToggle={toggle}
        isOpen={modal}
      />
    );
  }
}

export default AddUserModalContainer;
