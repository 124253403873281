import React, { Component } from 'react';
import ClearAndUnifiedDirectionIcon from '../../../shared/assets/icons/clear-and-unified-direction.svg';
import {
  CLEAR_INDICATORS,
  INITIAL_CLEAR_INDICATORS,
  DRIVER_CLEAR_LEADER_MESSAGE,
  DRIVER_CLEAR_TEACHER_MESSAGE,
} from '../my-voice-models';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { updateIndicator, updateIndicators } from '../my-voice-actions';
import { authStore, USER_EVENT } from '../../auth/auth-store';
import { DriverDetailsForm } from '../components/DriversDetailsForm';

/**
 * Driver Clear View.
 *
 * @param indicatorId
 * @param indicatorValue
 */
class DriverClearView extends Component {
  constructor(props) {
    super(props);
    const data = this.props.data;
    const user = authStore.getState(USER_EVENT);
    const indicators = updateIndicators(
      R.clone(
        // this.props.isInitial
        //   ? this.props.user.userType === 'leader'
        //     ? INITIAL_CLEAR_INDICATORS_LEADER
        //     : INITIAL_CLEAR_INDICATORS
        //   : this.props.userType === 'leader'
        //   ? CLEAR_INDICATORS_LEADER
        //   : CLEAR_INDICATORS,
        this.props.isInitial ? INITIAL_CLEAR_INDICATORS : CLEAR_INDICATORS,
      ),
      data,
    );
    this.state = {
      indicators,
      messages:
        user.userType === `LEADER`
          ? R.clone(DRIVER_CLEAR_LEADER_MESSAGE)
          : R.clone(DRIVER_CLEAR_TEACHER_MESSAGE),
    };
  }

  onClickStep = (indicatorId, indicatorValue) => {
    const indicators = updateIndicator(this.state.indicators, indicatorId, indicatorValue);
    this.setState({ indicators });
  };

  onSubmit = () => {
    this.props.onSubmit(this.state.indicators);
  };

  render() {
    const { indicators } = this.state;
    const { submitText } = this.props;
    // const user = authStore.getState(USER_EVENT);
    const { messages } = this.state;
    return (
      <DriverDetailsForm
        icon={ClearAndUnifiedDirectionIcon}
        title="Clear & Unified Direction"
        message={messages}
        indicators={indicators}
        onChangeIndicator={(id, stepNumber) => this.onClickStep(id, stepNumber)}
        submitText={submitText}
        onSubmit={this.onSubmit}
      />
    );
  }
}

DriverClearView.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  isInitial: PropTypes.bool,
};

DriverClearView.defaultProps = {
  submitText: 'Update',
  isInitial: false,
};

export default DriverClearView;
