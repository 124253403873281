import Flux from '@cobuildlab/flux-state';
import { REVIEW_UPLOAD_DATA, UPLOAD_DATA } from './data-import-store';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import firebase from 'firebase';
import XLSX from 'xlsx';
import { USER_TYPE_COA, USER_TYPE_LEADER, USER_TYPE_TEACHER } from '../../shared/userTypes';
import { createUserWithEmailAdmin, saveUserInDB } from '../super-admin/super-admin-actions';
import { findIndex, uniq } from 'lodash';
import { validateEmail } from '../super-admin/super-admin-utils';
import schoolTypes from '../../shared/schoolTypes';
import { wait } from '../../shared/utils';

export const availableUserFields = [
  'email',
  'firstName',
  'lastName',
  'schoolId',
  'isRosterManager',
  'userType',
];
export const availableSchoolFields = [
  'id',
  'name',
  'address1',
  'regionId',
  'lat',
  'lon',
  'schoolType',
];
export const availableRegionFields = ['id', 'name', 'districtId'];
export const availableDistrictFields = ['id', 'name', 'message'];

/**
 * Upload Data.
 *
 * @param workbook
 * @returns {Promise<void>}
 */
export const uploadImportedDataAction = async (workbook) => {
  const availableCollections = ['districts', 'regions', 'schools', 'users'];
  const sheetNames = workbook.SheetNames;

  const results = [];

  for (const collectionName of availableCollections) {
    const sheetName = sheetNames.find(
      (sheetName) => collectionName.toLowerCase() === sheetName.toLowerCase(),
    );

    if (!sheetName) continue;

    const workSheet = workbook.Sheets[sheetName];
    const data = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
    const [fields, ...dataElements] = data;

    const haveData = dataElements.filter((item) => item.length).length;
    if (!haveData) continue;

    switch (sheetName.toLowerCase()) {
    case 'users':
      try {
        const result = await saveUsers(fields, dataElements);
        results.push([true, result]);
      } catch (e) {
        results.push([false, e.message]);
      }
      break;
    case 'regions':
      if (dataElements.length) {
        try {
          const result = await saveRegions(fields, dataElements);
          results.push([true, result]);
        } catch (e) {
          results.push([false, e.message]);
        }
      }
      break;
    case 'schools':
      try {
        const result = await saveSchools(fields, dataElements);
        results.push([true, result]);
      } catch (e) {
        results.push([false, e.message]);
      }
      break;
    case 'districts':
      try {
        const result = await saveDistricts(fields, dataElements);
        results.push([true, result]);
      } catch (e) {
        results.push([false, e.message]);
      }
      break;
    default:
      console.error('Collection not specified.');
      results.push([false, 'Collection not specified.']);
    }
  }

  Flux.dispatchEvent(UPLOAD_DATA, { results });
};

/**
 *
 * @param {string} collectionName - Collection name.
 * @param {string} value - Field value.
 */
export const reviewAlreadyExistDoc = async (collectionName, value) => {
  const DB = firebase.firestore();
  const collection = DB.collection(collectionName);
  const doc = await collection.doc(String(value)).get();
  return doc.exists;
};

/**
 *
 * @param {string} field - Field To validate.
 * @param {Array<string>} fields - Data Fields.
 * @param {Array<Array>} data - Data.
 * @param {string} collectionName - Collection Name.
 * @param {string | null} collectionField - Collection field.
 */
export const reviewAlreadyDataElements = async (field, fields, data, collectionName) => {
  const fieldIndex = fields.findIndex((v) => v === field);

  if (fieldIndex < 0) return [];

  const filteredData = data.filter((row) => row.length && row[fieldIndex]);

  const promises = filteredData.map(async (row) => {
    const value = row[fieldIndex];
    const exist = await reviewAlreadyExistDoc(collectionName, value);
    return { [field]: value, exist };
  });

  return await Promise.all(promises);
};

/**
 * Upload Data.
 *
 * @param {object} workbook - Workbook excel.
 */
export const reviewAlreadyExistData = async (workbook) => {
  const availableCollections = ['districts', 'regions', 'schools', 'users'];
  const sheetNames = workbook.SheetNames;

  const reviews = {
    someOneExist: false,
    users: [],
    schools: [],
    regions: [],
    districts: [],
  };

  for (const collectionName of availableCollections) {
    const sheetName = sheetNames.find(
      (sheetName) => collectionName.toLowerCase() === sheetName.toLowerCase(),
    );

    if (!sheetName) continue;

    const workSheet = workbook.Sheets[sheetName];
    const data = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
    const [fields, ...dataElements] = data;

    const haveData = dataElements.filter((item) => item.length).length;
    if (!haveData) continue;

    switch (sheetName.toLowerCase()) {
    case 'users':
      try {
        const results = await reviewAlreadyDataElements('email', fields, dataElements, 'users');
        reviews.users.push(...results);
        if (results.filter((values) => values.exist).length) {
          reviews.someOneExist = true;
        }
      } catch (err) {
        console.log('error reviewing users', err);
      }
      break;
    case 'regions':
      try {
        const results = await reviewAlreadyDataElements('id', fields, dataElements, 'regions');
        reviews.regions.push(...results);
        if (results.filter((values) => values.exist).length) {
          reviews.someOneExist = true;
        }
      } catch (err) {
        console.log('error reviewing regions', err);
      }
      break;
    case 'schools':
      try {
        const results = await reviewAlreadyDataElements('id', fields, dataElements, 'schools');
        reviews.schools.push(...results);
        if (results.filter((values) => values.exist).length) {
          reviews.someOneExist = true;
        }
      } catch (err) {
        console.log('error reviewing schools', err);
      }
      break;
    case 'districts':
      try {
        const results = await reviewAlreadyDataElements('id', fields, dataElements, 'districts');
        reviews.districts.push(...results);
        if (results.filter((values) => values.exist).length) {
          reviews.someOneExist = true;
        }
      } catch (err) {
        console.log('error reviewing districts', err);
      }
      break;
    default:
      continue;
    }
  }

  Flux.dispatchEvent(REVIEW_UPLOAD_DATA, reviews);
};

/**
 * Check the User Type and set a default type if needed.
 *
 * @param {string} userType - User Type.
 * @returns {string} - User Type Validated.
 */
export function setValidUserType(userType) {
  userType = String(userType).toUpperCase();
  const customUserTypes = {
    leader: USER_TYPE_LEADER.toUpperCase(),
    coa: USER_TYPE_COA.toUpperCase(),
    teacher: USER_TYPE_TEACHER.toUpperCase(),
  };

  const userTypesValues = Object.values(customUserTypes);

  if (!userTypesValues.includes(userType)) throw new Error(`Invalid user Type: ${userType}`);
  if (userType === customUserTypes.leader) return USER_TYPE_LEADER;
  if (userType === customUserTypes.coa) return USER_TYPE_COA;

  return USER_TYPE_TEACHER;
}

/**
 * Check the School Type and set a default type if needed.
 *
 * @param {string} schoolType - School Type.
 * @returns {string} - School Type Validated.
 */
export function setValidSchoolType(schoolType) {
  const customSchoolType = String(schoolType).toUpperCase();
  if (!schoolTypes[customSchoolType]) {
    const msg = `Invalid school type: ${schoolType}`;
    console.error(msg);
    throw new Error(msg);
  }
  return schoolTypes[customSchoolType];
}

/**
 * Fetch district data from school id.
 *
 * @param {string} schoolId - School ID.
 */
export const fetchDistrictFromSchool = async (schoolId) => {
  const db = firebase.firestore();

  let school = null;
  try {
    const schoolRef = db.collection('schools').doc(schoolId);
    school = await schoolRef.get();
    if (!school.exists) throw new Error('School not found');
  } catch (e) {
    throw new Error(e.message);
  }

  try {
    const districtRef = db.collection('districts').doc(school.data().regionDistrictId);
    const district = await districtRef.get();
    if (!district.exists) throw new Error('District not found');
  } catch (e) {
    throw new Error(e.message);
  }

  return school.data().regionDistrictId;
};

const saveUsers = async (fields, data) => {
  fields = fields.map((columnName) => columnName.trim().toLowerCase());

  const fieldColumnIndexes = availableUserFields.map((field) => {
    field = field.trim().toLowerCase();
    return findIndex(fields, (columnName) => columnName === field);
  });

  const users = [];
  const userIdsMap = {};
  for (const row of data) {
    if (row.length) {
      const user = {};
      availableUserFields.forEach((field, index) => {
        const fieldColumnIndex = fieldColumnIndexes[index];
        if (fieldColumnIndex >= 0) {
          const fieldValue = row[fieldColumnIndex];
          if (fieldValue !== undefined) {
            user[field] = String(fieldValue).trim();
          }
        }
      });

      if (!user.lastName || user.lastName === '') {
        throw new Error('All users need a lastName, 0 users uploaded');
      }
      if (!user.firstName || user.firstName === '') {
        throw new Error('All users need a firstName, 0 users uploaded');
      }
      if (user.userType !== USER_TYPE_COA) {
        if (!user.schoolId || user.schoolId === '') {
          throw new Error('All users need a schoolId, 0 users uploaded');
        }
        if (user.schoolId.split(' ').length > 1) {
          throw new Error(
            `user school id "${user.id}" must be not contain spaces , 0 users uploaded`,
          );
        }
      }
      if (!user.email || user.email === '') {
        throw new Error('All users need a email, 0 users uploaded');
      }
      if (userIdsMap[user.email.trim()]) {
        throw new Error(`user id ${user.id} duplicated, 0 users uploaded`);
      }

      user.email = String(user.email)
        .toLowerCase()
        .trim();
      user.isRosterManager = isValidRosterManager(user.isRosterManager);
      user.userType = setValidUserType(user.userType);

      if (user.userType === USER_TYPE_COA && USER_TYPE_COA.isRosterManager) {
        throw new Error(`user type COA cannot be roster manager`);
      }

      if (!validateEmail(user.email)) {
        throw new Error(`Email '${user.email}' is not valid, 0 users uploaded`);
      }

      users.push(user);
      userIdsMap[user.email.trim()] = user;
    }
  }

  console.log(`saveUser:users to be saved:`, { users });

  const schoolIDs = uniq(users.map((user) => user.schoolId).filter((id) => id));

  const invalidSchoolIDs = await checkSchoolIds(schoolIDs);
  if (invalidSchoolIDs.length) {
    const message =
      invalidSchoolIDs.length > 1 ? 'These schools ids are invalid' : 'This school id is invalid';
    throw new Error(`Users - ${message}: ${invalidSchoolIDs.join(', ')}`);
  }

  const results = [];
  const errors = [];

  const dbRequests = users.map(async (user) => {
    let customUser = user;
    const userType = user.userType;
    const userIsTeacherOrLeader = userType === USER_TYPE_TEACHER || userType === USER_TYPE_LEADER;

    if (userIsTeacherOrLeader) {
      const db = firebase.firestore();
      const school = await db
        .collection('schools')
        .doc(user.schoolId)
        .get();
      const schoolRegionId = school.data().regionId;
      if (schoolRegionId) customUser.schoolRegionId = schoolRegionId;
    }

    try {
      const createUserResult = await createUserWithEmailAdmin(customUser.email);
      if (!createUserResult) {
        console.log(`${customUser.email} user already exists.`);
      }
      if (customUser.userType.toLowerCase() === USER_TYPE_COA.toLowerCase()) {
        if (!customUser.schoolId || customUser.schoolId === '') {
          throw new Error(`${customUser.email} user type is COA but schoolId is missing`);
        }
        let userDistrictCoa = await fetchDistrictFromSchool(customUser.schoolId);
        customUser = {
          schoolId: '',
          districtId: userDistrictCoa,
          ...customUser,
        };
      }

      if (!customUser.districtId && customUser.schoolId) {
        let userDistrict = await fetchDistrictFromSchool(customUser.schoolId);
        customUser = {
          ...customUser,
          districtId: userDistrict,
        };
      }
      await saveUserInDB(customUser);

      results.push(customUser);
    } catch (e) {
      errors.push({ error: e, customUser });
    }
  });

  await Promise.all(dbRequests);

  const response =
    results.length === 1 ? `1 user was uploaded` : `${results.length} users were uploaded`;

  if (errors.length) {
    console.error(errors);
    const errorMessage =
      errors.length === 1 ? `couldn't upload 1 user` : `couldn't upload ${errors.length} users`;
    const message = `${errorMessage}. ${response}`;
    throw new Error(message);
  }

  return response;
};

const saveRegions = async (fields, data) => {
  fields = fields.map((columnName) => columnName.trim().toLowerCase());

  const fieldColumnIndexes = availableRegionFields.map((field) => {
    field = field.trim().toLowerCase();
    return findIndex(fields, (columnName) => columnName === field);
  });

  const regions = [];
  const regionsIdsMap = {};
  for (const row of data) {
    if (row.length) {
      const region = {};
      availableRegionFields.forEach((field, index) => {
        const fieldColumnIndex = fieldColumnIndexes[index];
        if (fieldColumnIndex >= 0) {
          const fieldValue = row[fieldColumnIndex];
          if (fieldValue !== undefined) {
            region[field] = String(fieldValue).trim();
          }
        }
      });

      if (!region.id || region.id === '') {
        throw new Error('All regions need a id, 0 regions uploaded');
      }
      if (region.id.trim().split(' ').length > 1) {
        throw new Error(`region id "${region.id}" must be not contain spaces , 0 regions uploaded`);
      }
      if (regionsIdsMap[region.id.trim()]) {
        throw new Error(`region id ${region.id} duplicated, 0 regions uploaded`);
      }
      if (!region.name || region.name === '') {
        throw new Error('All regions need a name, 0 regions uploaded');
      }
      if (!region.districtId || region.districtId === '') {
        throw new Error('All regions need a districtId, 0 regions uploaded');
      }
      if (region.districtId.trim().split(' ').length > 1) {
        throw new Error(
          `region district id "${region.districtId}" must be not contain spaces , 0 regions uploaded`,
        );
      }

      regions.push(region);
      regionsIdsMap[region.id.trim()] = region;
    }
  }

  const districtIDs = uniq(regions.map((region) => region.districtId).filter((id) => id));

  const invalidDistrictIDs = await checkDistrictIds(districtIDs);
  if (invalidDistrictIDs.length) {
    const message =
      invalidDistrictIDs.length > 1
        ? 'These district ids are invalid'
        : 'This district id is invalid';
    throw new Error(`Regions - ${message}: ${invalidDistrictIDs.join(', ')}`);
  }

  console.log({ regions });

  const DB = firebase.firestore();
  const regionsCollection = DB.collection('regions');

  const dbRequests = regions.map((region) => {
    const regionId = region.id;
    delete region.id;
    return regionsCollection.doc(regionId).set(region, { merge: true });
  });

  await Promise.all(dbRequests);

  return regions.length === 1 ? `1 region was uploaded` : `${regions.length} regions were uploaded`;
};

/**
 *
 * @param {string[]} fields
 * @param {string[][]} data
 * @returns {Promise<string>}
 */
const saveSchools = async (fields, data) => {
  const DB = firebase.firestore();
  const schoolsCollection = DB.collection('schools');

  fields = fields.map((columnName) => columnName.trim().toLowerCase());

  const fieldColumnIndexes = availableSchoolFields.map((field) => {
    field = field.trim().toLowerCase();
    return findIndex(fields, (columnName) => columnName === field);
  });

  const schools = [];
  const schoolsIdsMap = {};
  for (const row of data) {
    if (row.length) {
      const school = {};
      availableSchoolFields.forEach((field, index) => {
        const fieldColumnIndex = fieldColumnIndexes[index];
        if (fieldColumnIndex >= 0) {
          const fieldValue = row[fieldColumnIndex];
          if (fieldValue !== undefined) {
            school[field] = String(fieldValue).trim();
          }
        }
      });
      if (!school.id || school.id === '') {
        throw new Error('All schools need a ID, 0 schools uploaded');
      }
      if (school.id.trim().split(' ').length > 1) {
        throw new Error(`school id "${school.id}" must be not contain spaces , 0 schools uploaded`);
      }
      if (schoolsIdsMap[school.id.trim()]) {
        throw new Error(`school id ${school.id} duplicated, 0 schools uploaded`);
      }
      if (!school.address1) {
        throw new Error('All schools need a valid Address1, 0 schools uploaded');
      }
      if (!school.regionId) {
        throw new Error('All schools need a valid regionId, 0 schools uploaded');
      }
      if (school.regionId.trim().split(' ').length > 1) {
        throw new Error(
          `school region id "${school.regionId}" must be not contain spaces , 0 schools uploaded`,
        );
      }
      if (!school.name || school.name === '') {
        throw new Error('All schools need a name, 0 schools uploaded');
      }
      if (!school.schoolType || school.schoolType === '') {
        throw new Error('All schools need a type, 0 schools uploaded');
      }
      school.schoolType = setValidSchoolType(school.schoolType);

      schools.push(school);
      schoolsIdsMap[school.id.trim()] = school;
    }
  }
  const regionIDs = uniq(schools.map((school) => school.regionId).filter((id) => id));

  const invalidRegionIDs = await checkRegionIDs(regionIDs);
  if (invalidRegionIDs.length) {
    const message =
      invalidRegionIDs.length > 1 ? 'These region ids are invalid' : 'This region id is invalid';
    throw new Error(`Schools - ${message}: ${invalidRegionIDs.join(', ')}`);
  }

  const latitudesRequests = async (school) => {
    try {
      const { lat, lng } = await getLatitudes(school.address1);
      school.latLon = new firebase.firestore.GeoPoint(lat, lng);
    } catch (e) {
      console.log(`Invalid address for school '${school.name}', error:${e}`);
      throw new Error(`Invalid address for school '${school.name}', 0 schools uploaded`);
    }
  };

  let promises = [];
  for (const school of schools) {
    const promise = latitudesRequests(school);
    promises.push(promise);

    if (promises.length >= 5) {
      try {
        await Promise.allSettled(promises);
      } catch (e) {
        console.log(e.message);
        throw new Error(e.message);
      }
      promises = [];
      await wait(1);
    }
  }
  if (promises.length >= 0) {
    try {
      await Promise.allSettled(promises);
    } catch (e) {
      console.log(e.message);
      throw new Error(e.message);
    }
    promises = [];
    await wait(1);
  }

  console.log({ schools });

  const dbRequests = schools.map((school) => {
    const schoolId = school.id;
    delete school.id;
    return schoolsCollection.doc(schoolId).set(school, { merge: true });
  });

  await Promise.all(dbRequests);

  const message =
    schools.length === 1 ? '1 school was uploaded' : `${schools.length} schools were uploaded`;
  return message;
};

const saveDistricts = async (fields, data) => {
  fields = fields.map((columnName) => columnName.trim().toLowerCase());

  const fieldColumnIndexes = availableDistrictFields.map((field) => {
    field = field.trim().toLowerCase();
    return findIndex(fields, (columnName) => columnName === field);
  });

  const districts = [];
  const districtsIdsMap = {};
  for (const row of data) {
    if (row.length) {
      const district = {};
      availableDistrictFields.forEach((field, index) => {
        const fieldColumnIndex = fieldColumnIndexes[index];
        if (fieldColumnIndex >= 0) {
          const fieldValue = row[fieldColumnIndex];
          if (fieldValue !== undefined) {
            district[field] = String(fieldValue).trim();
          }
        }
      });

      if (!district.id || district.id === '') {
        throw new Error('All districts need a id, 0 districts uploaded');
      }
      if (districtsIdsMap[district.id.trim()]) {
        throw new Error(`district id ${district.id} duplicated, 0 districts uploaded`);
      }
      if (!district.name || district.name === '') {
        throw new Error('All districts need a name, 0 districts uploaded');
      }

      districts.push(district);
      districtsIdsMap[district.id.trim()] = district;
    }
  }

  console.log({ districts });

  const DB = firebase.firestore();
  const districtsCollection = DB.collection('districts');

  const dbRequests = districts.map((district) => {
    const districtId = district.id;
    delete district.id;
    return districtsCollection.doc(districtId).set(district, { merge: true });
  });

  await Promise.all(dbRequests);

  return districts.length === 1
    ? `1 district was uploaded`
    : `${districts.length} districts were uploaded`;
};

/**
 * If isRosterManager is undefined or null, nor equals 1, isRosterManager equals false.
 *
 * @param {*} value - Roster Manager Value.
 * @returns {boolean} - Is a valid roster manager.
 */
export function isValidRosterManager(value) {
  if (Number(value) === 1) return true;
  if (typeof value === 'string' && value.trim().toLowerCase() === 'yes') return true;
  return false;
}

/**
 * Check if all district ids exists.
 *
 * @param {string[]} districtIDs - District IDs to check.
 * @returns {Promise<string[]>} - Array of invalid districts.
 */
function checkDistrictIds(districtIDs) {
  return checkValidIDsInCollection(districtIDs, 'districts');
}

/**
 * Check if all schools ids exists.
 *
 * @param {string[]} schoolIDs - School IDs to check.
 * @returns {Promise<string[]>} - Array of invalid schools.
 */
function checkSchoolIds(schoolIDs) {
  return checkValidIDsInCollection(schoolIDs, 'schools');
}

/**
 * Check if all region ids exists.
 *
 * @param regionIDs
 * @param {string[]} schoolIDs - School IDs to check.
 * @returns {Promise<string[]>} - Array of invalid schools.
 */
function checkRegionIDs(regionIDs) {
  return checkValidIDsInCollection(regionIDs, 'regions');
}

/**
 * @param ids
 * @param collectionName
 */
async function checkValidIDsInCollection(ids, collectionName) {
  const db = firebase.firestore();
  const collection = db.collection(collectionName);
  const invalidIDs = [];

  const requests = ids.map(async (id) => {
    const ref = await collection.doc(id).get();
    if (!ref.exists) {
      invalidIDs.push(id);
    }
  });

  await Promise.all(requests);

  return invalidIDs;
}

/**
 * @param address
 */
async function getLatitudes(address) {
  const geocode = await geocodeByAddress(address);
  return await getLatLng(geocode[0]);
}
