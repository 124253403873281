import React from 'react';
import { CarouselItem, Col, Row } from 'reactstrap';
import Report from './Report';
import Carousel from './Carousel';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import { document } from 'react-icons-kit/iconic/document';

const colorStyle = {
  color: 'rgba(0, 42, 63, .7)',
};

/**
 * Return List of reports.
 *
 * @returns {*} - Array of reports components.
 * @class
 *
 */
const ReportList = ({ reports }) => {
  const reportsPerSlide = 6;

  if (!reports.length) {
    return (
      <div align="center" style={{ padding: 15 }}>
        <Icon style={colorStyle} size={48} icon={document} />
        <br />
        <h4 style={colorStyle}> No saved reports. </h4>
      </div>
    );
  }

  const reportItems = reports.map((report) => {
    return (
      <Col
        key={report.id}
        sm={12}
        md={6}
        lg={4}
        style={{ padding: 10 }}
        className="d-flex justify-content-center align-items-center mw-30">
        <Report report={report} />
      </Col>
    );
  });

  const slides = getSlides(reportItems, reportsPerSlide);

  return (
    <div className="mw-30 h-50">
      <Carousel>{slides}</Carousel>
    </div>
  );
};

/**
 * Return Slides For Items.
 *
 * @param {Array} items - Items to distribute.
 * @param {number} itemPerSlide - Number of items per slide.
 * @returns {CarouselItem[]} - Array of slides.
 */
function getSlides(items, itemPerSlide) {
  const slides = [];
  const numPag = Math.ceil(items.length / itemPerSlide);
  for (let index = 0; index < numPag; index++) {
    const startItemIndex = index * itemPerSlide;
    const endItemIndex = startItemIndex + itemPerSlide;
    slides.push(
      <CarouselItem className="custom-tag" tag="div" key={index}>
        <Row className="justify-content-center align-items-center">
          {items.slice(startItemIndex, endItemIndex)}
        </Row>
      </CarouselItem>,
    );
  }
  return slides;
}

ReportList.propTypes = {
  reports: PropTypes.array.isRequired,
};

export default ReportList;
