import React, { useState } from 'react';
import { Carousel as ReactStrapCarousel, CarouselControl } from 'reactstrap';
import PropTypes from 'prop-types';

/**
 * Carousel.
 *
 * @param {object} props - Carousel Props.
 * @returns {*} - Carousel.
 */
const Carousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const slideProps = {
    onExiting: () => setAnimating(true),
    onExited: () => setAnimating(false),
  };

  const slides = React.Children.map(props.children, (child) => {
    return React.cloneElement(child, slideProps);
  });

  const numSlides = slides.length;
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === numSlides - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex ? activeIndex - 1 : numSlides - 1;
    setActiveIndex(nextIndex);
  };

  return (
    <div className="mw-30 h-50">
      <ReactStrapCarousel
        outerClasses="carousel-padding"
        style={{ paddingLeft: 50, paddingRight: 50 }}
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        interval={false}>
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </ReactStrapCarousel>
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Carousel;
