import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Icon from 'react-icons-kit';
import { ic_more_horiz } from 'react-icons-kit/md/ic_more_horiz';
import styled from 'styled-components';

const DropdownToggleStyled = styled(DropdownToggle)`
  padding: 0.25rem 0.35rem;
  border-radius: 50%;
`;

export const DropdownActions = ({ onEdit, onDelete }) => (
  <UncontrolledDropdown>
    <DropdownToggleStyled color="light">
      <Icon icon={ic_more_horiz} size="25px" style={{ transform: 'rotate(90deg)' }} />
    </DropdownToggleStyled>
    <DropdownMenu>
      <DropdownItem onClick={() => onEdit()}>Edit</DropdownItem>
      <DropdownItem onClick={() => onDelete()}>Delete</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

DropdownActions.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
