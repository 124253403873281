import React, { useState } from 'react';
import useFetchPage from '../../shared/useFetchPage';
import { ManagementView, TABLE_LIMIT } from '../../components/ManagementView';
import { useSubscription } from '@cobuildlab/react-flux-state';
import { TableDistricts } from './components/TableDistricts';
import { ButtonFilterDistrict } from './components/ButtonFilterDistricts';
import {
  fetchDistricts,
  mutateDistrictAction,
  createRegionAction,
  createAndDownloadSuperAdminReport,
} from './super-admin-actions';
import { DistrictModal } from './components/DistrictModal';
import { RegionModal } from './components/RegionModal';
import { plus as ic_plus } from 'react-icons-kit/fa/plus';
import { download2 as ic_download } from 'react-icons-kit/icomoon/download2';

import {
  superAdminStore,
  SUPER_ADMIN_ADD_DISTRICT_ERROR,
  SUPER_ADMIN_ADD_DISTRICT_EVENT,
  SUPER_ADMIN_ADD_REGION_ERROR,
  SUPER_ADMIN_ADD_REGION_EVENT,
  SUPER_ADMIN_EXCEL_REPORTS_ERROR,
  SUPER_ADMIN_EXCEL_REPORTS_EVENT,
} from './super-admin-store';
import { customToast } from '../../shared/toast';
import { useDebounce } from '../../shared/hooks';
import { queryClient } from '../../shared/utils';

export const INITIAL_FILTERS = {
  search: '',
  onlyActives: 'active',
  teachersMaximumQty: 0,
  teachersMinimumQty: 0,
  leadersQtyMaximumQty: 0,
  leadersQtyMinimumQty: 0,
  schoolsQtyMaximum: 0,
  schoolsQtyMinimum: 0,
};

const fetchKey = 'fetchAllDistricts';

export const DistrictsManagementView = () => {
  const [districtsFilter, setDistrictsFilter] = useState(INITIAL_FILTERS);
  const [search, setSearch] = useState('');

  const [regionModal, setRegionModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [districtModal, setDistrictModal] = useState(false);

  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [loadingReport, setLoadingReport] = useState(false);

  const {
    isLoading,
    data,
    fetchNextPage,
    isFetchingNextPage: isLoadingMore,
    status,
  } = useFetchPage(
    [fetchKey, districtsFilter],
    ({ queryKey, pageParam = null }) => {
      const [, filters] = queryKey;
      return fetchDistricts(filters, TABLE_LIMIT, pageParam);
    },
    {
      onError: (err) => {
        customToast.error(err);
      },
    },
  );
  const handleCreateReport = () => {
    setLoadingReport(true);
    createAndDownloadSuperAdminReport(districtsFilter, 'districts', 'Districts Report');
  };

  useDebounce(() => {
    setDistrictsFilter({ ...districtsFilter, search });
  }, search);

  const handleSubmitDistrictModal = async (district) => {
    const isEdit = Boolean(selectedDistrict);
    setModalLoading(true);
    await mutateDistrictAction(district, isEdit);
  };

  const handleSubmitRegionModal = async (region) => {
    setModalLoading(true);
    await createRegionAction(region);
  };

  useSubscription(superAdminStore, SUPER_ADMIN_ADD_DISTRICT_ERROR, (err) => {
    customToast.error(err);
    setModalLoading(false);
  });

  useSubscription(superAdminStore, SUPER_ADMIN_ADD_DISTRICT_EVENT, (successMessage) => {
    customToast.success(successMessage);
    queryClient.invalidateQueries([fetchKey]);
    setModalLoading(false);
    setDistrictModal(false);
  });

  useSubscription(superAdminStore, SUPER_ADMIN_ADD_REGION_ERROR, (err) => {
    customToast.error(err);
    setModalLoading(false);
  });

  useSubscription(superAdminStore, SUPER_ADMIN_ADD_REGION_EVENT, (successMessage) => {
    customToast.success(successMessage);
    setModalLoading(false);
    setRegionModal(false);
  });

  useSubscription(superAdminStore, SUPER_ADMIN_EXCEL_REPORTS_ERROR, (err) => {
    setLoadingReport(false);
    customToast.error(err);
  });

  useSubscription(superAdminStore, SUPER_ADMIN_EXCEL_REPORTS_EVENT, () => {
    setLoadingReport(false);
  });

  const loading = isLoading || isLoadingMore;

  const loadingRows = status === 'loading';
  return (
    <>
      <ManagementView
        count={data.count}
        haveMoreToLoad={data.haveMoreToLoad}
        isLoading={loading}
        searchInput={{
          value: search,
          onChange: (value) => {
            setSearch(value);
          },
        }}
        openFilterButton={
          <ButtonFilterDistrict
            data={districtsFilter}
            onChange={(newFilters) => {
              setDistrictsFilter({
                ...districtsFilter,
                ...newFilters,
                search: districtsFilter.search,
              });
            }}
          />
        }
        buttomActions={[
          {
            onClick: handleCreateReport,
            title: 'Download File',
            icon: ic_download,
            loading: loadingReport,
          },
          {
            onClick: () => setRegionModal(true),
            title: 'Add Region',
            icon: ic_plus,
          },
          {
            onClick: () => setDistrictModal(true),
            title: 'Add District',
            icon: ic_plus,
          },
        ]}>
        <TableDistricts
          isLoading={loading || loadingRows}
          isMoreToLoad={data.haveMoreToLoad}
          onLoadMore={() => {
            fetchNextPage({ pageParam: data.startAfter });
          }}
          rows={loadingRows ? [] : (data.districts || [])}
          onEdit={(district) => {
            setSelectedDistrict(district);
            setDistrictModal(true);
          }}
        />
      </ManagementView>
      <DistrictModal
        isOpen={districtModal}
        district={selectedDistrict}
        loading={modalLoading}
        onToggle={() => {
          setDistrictModal(!districtModal);
          setSelectedDistrict(null);
        }}
        onSubmit={handleSubmitDistrictModal}
        openRegionModal={() => setRegionModal(true)}
      />
      <RegionModal
        isOpen={regionModal}
        loading={modalLoading}
        onToggle={() => {
          setRegionModal(!regionModal);
        }}
        onSubmit={handleSubmitRegionModal}
      />
    </>
  );
};
