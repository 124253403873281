import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FeedbackIcon from '../../../shared/assets/icons/feedback-and-reflection.svg';
import { updateIndicator, updateIndicators } from '../my-voice-actions';
import * as R from 'ramda';
import { FEEDBACK_INDICATORS, INITIAL_FEEDBACK_INDICATORS } from '../my-voice-models';
import { DriverDetailsForm } from '../components/DriversDetailsForm';

// INITIAL_PROFESSIONAL_INDICATORS,
// PROFESSIONAL_INDICATORS,

class DriverFeedbackView extends Component {
  constructor(props) {
    super(props);
    const data = this.props.data;
    const indicators = updateIndicators(
      R.clone(this.props.isInitial ? INITIAL_FEEDBACK_INDICATORS : FEEDBACK_INDICATORS),
      data,
    );
    this.state = {
      indicators,
    };
  }

  onClickStep = (indicatorId, indicatorValue) => {
    const indicators = updateIndicator(this.state.indicators, indicatorId, indicatorValue);
    this.setState({ indicators });
  };

  onSubmit = () => {
    this.props.onSubmit(this.state.indicators);
  };

  render() {
    const { indicators } = this.state;
    const { submitText } = this.props;
    return (
      <DriverDetailsForm
        icon={FeedbackIcon}
        title="Feedback & Reflection"
        message="Pervasive instructional observations provide immediate feedback and create
        conversations about effectiveness. The use of examples from practice and reflection
        activities provide clarity for professional growth."
        indicators={indicators}
        onChangeIndicator={(id, stepNumber) => this.onClickStep(id, stepNumber)}
        submitText={submitText}
        onSubmit={this.onSubmit}
      />
    );
  }
}

DriverFeedbackView.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  isInitial: PropTypes.bool,
};

DriverFeedbackView.defaultProps = {
  submitText: 'Update',
  isInitial: false,
};

export default DriverFeedbackView;
