import React, { useState } from 'react';
import { useSubscription } from '@cobuildlab/react-flux-state';
import { Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { customToast } from '../../shared/toast';

// FLUX STATE
import { DRIVERS_COA } from '../my-voice/my-voice-models';
import {
  AVG_QUESTIONS_TEACHER_EVENT_CLF,
  MY_VOICE_ERROR_EVENT,
  myVoiceStore,
} from '../my-voice/my-voice-store';
import { getSchoolsAverageQuestionsTeacherClf } from '../my-voice/my-voice-actions';
import covid19DomainsStore, {
  COVID_19_SCHOOLS_DOMAINS_EVENT,
  COVID_19_SCHOOLS_DOMAINS_ERROR,
} from '../covid-19-domains/covid-19-domains-store';
import {
  getManySchoolsTeachersDomains,
  calculateSchoolDomainAverages,
} from '../covid-19-domains/covid-19-domains-actions';

// COMPONENTS
import ProtectedRoute from '../../routes/ProtectedRoute';
import { NavigationBar } from '../../shared/layouts/NavigationBar';
import { SubNavigationLayout } from '../../shared/layouts/SubNavigationLayout';
import { ClipLoader } from '../../shared/components/progress/ClipLoader';
import { DriversClfNavigationBar } from '../my-voice/components/DriversClfNavigationBar';
import { MyVoiceCoaGraph } from '../my-voice/leader/MyVoiceCoaGraph';
import DomainDetails from '../covid-19-domains/components/DomainDetails';
import { DOMAINS } from '../covid-19-domains/covid-19-domains-models';
import {
  buildPathBaseCulture,
  buildPathBaseCovid19,
} from '../../shared/components/menus/CultureAndCovidCollapsibleLists';
import { PageInfo } from '../../shared/components/PageInfo';
import DomainsGraph from '../covid-19-domains/components/DomainsGraph';

const CenterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const pathBase = '/my-district';
const pathBaseCulture = buildPathBaseCulture(pathBase);
const pathBaseCovid19 = buildPathBaseCovid19(pathBase);

export const MyDistrictsClfView = () => {
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [averagesData, setAveragesData] = useState({});
  const [domainsData, setDomainsData] = useState({});
  const [selectFlag, setSelectFlag] = useState(true);
  const [isLoadingDrivers, setIsLoadingDrivers] = useState(false);
  const [isLoadingDomains, setIsLoadingDomains] = useState(false);

  const {
    driversAverage,
    initialDriversAverage,
    driversIndicatorsCount,
    totalTeachers,
    numberTeachersResponded,
  } = averagesData;

  const { domainsAverage, respondedDomains } = domainsData;

  const isLoading = isLoadingDrivers || isLoadingDomains;

  const handleOnSelectDistricts = () => {
    setSelectFlag(true);
  };

  const handleOnSelectSchools = (schools) => {
    const schoolIds = schools.map((s) => s.id);
    setSelectedSchools(schools);
    getSchoolsAverageQuestionsTeacherClf(schoolIds);
    getManySchoolsTeachersDomains(schoolIds);
    setSelectFlag(false);
    setIsLoadingDrivers(true);
    setIsLoadingDomains(true);
  };

  useSubscription(myVoiceStore, MY_VOICE_ERROR_EVENT, (err) => {
    customToast.error(err.message);
    setIsLoadingDrivers(false);
  });

  useSubscription(myVoiceStore, AVG_QUESTIONS_TEACHER_EVENT_CLF, (data) => {
    if (data) {
      if (Object.keys(data.driversAverage).length !== 0)
        setAveragesData({
          initialDriversAverage: data.initialDriversAverage,
          driversAverage: data.driversAverage,
          driversIndicatorsCount: data.driversIndicatorsCount,
          totalTeachers: data.totalTeachers,
          numberTeachersResponded: data.numberTeachersResponded,
        });
      else
        setAveragesData({
          totalTeachers: data.totalTeachers,
          numberTeachersResponded: data.numberTeachersResponded,
        });
    }

    setIsLoadingDrivers(false);
  });

  useSubscription(covid19DomainsStore, COVID_19_SCHOOLS_DOMAINS_EVENT, (data) => {
    const respondedDomains = data.filter((td) => td !== null && Object.keys(td).length);
    setDomainsData({
      teacherDomains: data,
      respondedDomains,
      domainsAverage: calculateSchoolDomainAverages(respondedDomains),
    });
    setIsLoadingDomains(false);
  });

  useSubscription(covid19DomainsStore, COVID_19_SCHOOLS_DOMAINS_ERROR, () => {
    customToast.error('get school(s) domains error');
    setIsLoadingDomains(false);
  });

  let content = null;
  let schoolName = 'schools';
  if (selectedSchools.length === 1) schoolName = selectedSchools[0].name;

  if (isLoading) {
    content = (
      <CenterContent>
        <ClipLoader />
      </CenterContent>
    );
  } else if (selectFlag) content = <PageInfo text="Please select a school" />;
  else {
    content = (
      <Switch>
        {DRIVERS_COA.map((driver) => {
          const { component: Component, name } = driver;
          const path = `${pathBaseCulture}/${driver.path}`;
          let component = (
            <Component
              numberTeachers={totalTeachers}
              numberTeachersResponded={numberTeachersResponded}
              questionsTeacher={driversIndicatorsCount}
              name={name}
              type={'pie'}
              height={300}
            />
          );

          if (!driversAverage || !driversAverage[driver.id])
            component = <PageInfo text="No Teachers Have Answered" />;

          return <ProtectedRoute key={driver.id} path={path} component={() => <>{component}</>} />;
        })}

        {DOMAINS.map((domain) => {
          const filteredTeacherDomains = respondedDomains.filter((td) =>
            Object.keys(td).find((d) => d === domain.id),
          );
          const indicatorsData = filteredTeacherDomains.map((td) => td[domain.id]);
          const respondedTeacherCount = filteredTeacherDomains.length;
          const path = `${pathBaseCovid19}/${domain.path}`;

          let component = (
            <DomainDetails
              key={domain.id}
              domain={domain.name}
              message={domain.message}
              indicators={domain.indicators}
              indicatorsData={indicatorsData}
              numberTeachersResponded={respondedTeacherCount}
              numberTeachers={totalTeachers}
            />
          );

          if (!respondedTeacherCount) component = <PageInfo text="No Teachers Have Answered" />;
          return <ProtectedRoute key={domain.id} path={path} component={() => <>{component}</>} />;
        })}

        <ProtectedRoute
          path={pathBaseCulture}
          render={() =>
            !driversAverage ? (
              <PageInfo text="No Teachers Have Answered" />
            ) : (
              <MyVoiceCoaGraph
                schoolName={schoolName}
                schoolAvg={driversAverage}
                schoolInitialAvg={initialDriversAverage}
              />
            )
          }
        />

        <ProtectedRoute
          path={pathBaseCovid19}
          component={() =>
            !domainsAverage || !Object.keys(domainsAverage).length ? (
              <PageInfo text="No Teachers Have Answered" />
            ) : (
              <DomainsGraph schoolName={schoolName} domainAverages={domainsAverage} />
            )
          }
        />
        <ProtectedRoute to={pathBase} component={() => <Redirect to={pathBaseCulture} />} />
      </Switch>
    );
  }

  return (
    <NavigationBar currentRoute={pathBase}>
      <SubNavigationLayout
        sidebar={
          <DriversClfNavigationBar
            avg={driversAverage || {}}
            domainsAvg={domainsAverage || {}}
            onSelectDistricts={handleOnSelectDistricts}
            onSelectSchools={handleOnSelectSchools}
          />
        }>
        {content}
      </SubNavigationLayout>
    </NavigationBar>
  );
};
