import React from 'react';
import View from '@cobuildlab/react-flux-state';
import { Switch, Redirect } from 'react-router-dom';
import { customToast } from '../../shared/toast';
import { Row, Container } from 'reactstrap';
import styled from 'styled-components';
import { onErrorMixin } from '../../shared/mixins';
import { NavigationBar } from '../../shared/layouts/NavigationBar';
import Markers from './components/Markers';
import MapComponent from './components/MapComponent';
import { DriversCoaNavigationBar } from '../my-voice/components/DriversCoaNavigationBar';
import { DRIVERS_COA } from '../my-voice/my-voice-models';
import ProtectedRoute from '../../routes/ProtectedRoute';
import { MyVoiceCoaGraph } from '../my-voice/leader/MyVoiceCoaGraph';
import {
  fetchMyVoiceActionCoa,
  fetchMyVoiceActionLeader,
  getSchoolAverageQuestionsTeacherCoa,
} from '../my-voice/my-voice-actions';
import {
  getSchoolTeacherInitialDriversAvg,
  getSchoolTeachersDriversAvg,
} from './my-district-actions';
import {
  MY_DISTRICT_ERROR_EVENT,
  myDistrictStore,
  SCHOOLS_BY_DISTRCIT_EVENT,
} from './my-district-store';
import {
  AVG_QUESTIONS_TEACHER_EVENT_COA,
  MY_VOICE_ERROR_EVENT,
  MY_VOICE_EVENT,
  MY_VOICE_COA_EVENT,
  myVoiceStore,
} from '../my-voice/my-voice-store';
import covid19DomainsStore, {
  COVID_19_SCHOOLS_DOMAINS_EVENT,
  COVID_19_SCHOOLS_DOMAINS_ERROR,
} from '../covid-19-domains/covid-19-domains-store';
import {
  calculateSchoolDomainAverages,
  getSchoolTeacherDomains,
} from '../covid-19-domains/covid-19-domains-actions';
import { calculateDriversAverage } from './my-district-utils';
import Exclamation from '../../shared/assets/images/exclamation-circle-solid.svg';
import { NavTabsWithContent } from '../../shared/components/menus/NavTabsWithContent';
import { SubNavigationLayout } from '../../shared/layouts/SubNavigationLayout';
import DomainDetails from '../covid-19-domains/components/DomainDetails';
import { DOMAINS } from '../covid-19-domains/covid-19-domains-models';
import { ClipLoader } from '../../shared/components/progress/ClipLoader';
import {
  buildPathBaseCulture,
  buildPathBaseCovid19,
} from '../../shared/components/menus/CultureAndCovidCollapsibleLists';
import { PageInfo } from '../../shared/components/PageInfo';
import DomainsGraph from '../covid-19-domains/components/DomainsGraph';
import { authStore, USER_EVENT } from '../auth/auth-store';
import { USER_TYPE_COA } from '../../shared/userTypes';
import { ComunicationView } from './ComunicationView';

const CenterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const pathBase = '/my-district';
const pathBaseCulture = buildPathBaseCulture(pathBase);
const pathBaseCovid19 = buildPathBaseCovid19(pathBase);

const initialDataState = {
  data: {},
  schoolInitialDriversAverage: null,
  schoolDriversAverage: null,
  driversIndicatorsCount: null,
  numberTeachers: 0,
  respondedDomains: [],
  domainsAverage: {},
};

class MyDistrictView extends View {
  constructor(props) {
    super(props);
    this.state = {
      ...initialDataState,
      schools: [],
      school: {},
      loadingSchools: false,
      loadingDrivers: false,
      loadingDomains: false,
      myDriversAverage: {},
      selectFlag: true,
      activeTab: 'schools',
    };
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    const user = authStore.getState(USER_EVENT);
    this.subscribe(myVoiceStore, MY_VOICE_ERROR_EVENT, this.onError);
    this.subscribe(myDistrictStore, MY_DISTRICT_ERROR_EVENT, () => {
      customToast.error('Error From Data Base');
    });
    this.subscribe(myVoiceStore, AVG_QUESTIONS_TEACHER_EVENT_COA, (state) => {
      if (Object.keys(state.driversAverage).length !== 0) {
        this.setState({
          selectFlag: false,
          loadingDrivers: false,

          schoolInitialDriversAverage: state.initialDriversAverage,
          schoolDriversAverage: state.driversAverage,

          driversIndicatorsCount: state.driversIndicatorsCount,
          totalTeachers: state.totalTeachers,
          numberTeachersResponded: state.numberTeachersResponded,
        });
      } else {
        this.setState({
          loadingDrivers: false,
          schoolDriversAverage: null,
          driversIndicatorsCount: null,
          totalTeachers: state.totalTeachers,
          numberTeachersResponded: state.numberTeachersResponded,
          selectFlag: false,
        });
      }
    });
    this.subscribe(covid19DomainsStore, COVID_19_SCHOOLS_DOMAINS_EVENT, (data) => {
      const respondedDomains = data.filter((td) => td !== null && Object.keys(td).length);
      this.setState({
        teacherDomains: data,
        respondedDomains,
        domainsAverage: calculateSchoolDomainAverages(respondedDomains),
        loadingDomains: false,
      });
    });
    this.subscribe(covid19DomainsStore, COVID_19_SCHOOLS_DOMAINS_ERROR, () => {
      customToast.error('get school domains error');
    });
    this.subscribe(myVoiceStore, MY_VOICE_EVENT, (state) => {
      const { driversData, driversAverage } = state;
      this.setState({ data: driversData, driversAverage });
    });
    this.subscribe(myVoiceStore, MY_VOICE_COA_EVENT, (state) => {
      const { driversAverages } = state;
      this.setState({ myDriversAverage: driversAverages });
    });
    this.subscribe(myDistrictStore, SCHOOLS_BY_DISTRCIT_EVENT, (resp) => {
      const schools = resp.data;
      this.setState({ schools, loadingSchools: false });
    });

    if (user.userType === USER_TYPE_COA) fetchMyVoiceActionCoa();
    fetchMyVoiceActionLeader();
  }

  tabActive = (activeTab) => {
    if (this.state.activeTab !== activeTab) this.setState({ activeTab });
  };

  loadSchoolData = (school = {}) => {
    this.setState({
      school,
      loadingDrivers: true,
      loadingDomains: true,
      ...initialDataState,
    });
    const schoolId = school.id || null;
    setTimeout(() => {
      getSchoolTeachersDriversAvg(schoolId);
      getSchoolTeacherInitialDriversAvg(schoolId);
      getSchoolAverageQuestionsTeacherCoa(schoolId);
      getSchoolTeacherDomains(schoolId);
    }, 500);
  };

  dropSelect = (school) => {
    this.loadSchoolData(school);
  };

  handleAllSchoolAvg = () => {
    this.loadSchoolData();
  };

  selectTarget = (school) => {
    this.setState({ selectFlag: false });
    this.loadSchoolData(school);
    this.tabActive('summary');
  };

  render() {
    const { activeTab } = this.state;
    const {
      data,
      loadingSchools,
      loadingDrivers,
      loadingDomains,
      schoolDriversAverage,
      schoolInitialDriversAverage,
      driversIndicatorsCount,
      totalTeachers,
      numberTeachersResponded,
      school,
      schools,
      selectFlag,
      domainsAverage,
      respondedDomains,
      myDriversAverage,
    } = this.state;

    const loading = loadingSchools || loadingDrivers || loadingDomains;
    let driversAvg = {};
    let content = null;

    if (loading) {
      content = (
        <CenterContent>
          <ClipLoader />
        </CenterContent>
      );
    } else if (selectFlag || !schoolDriversAverage) {
      const text = selectFlag ? 'Please select a school' : 'No Teachers Have Answered';
      content = <PageInfo text={text} />;
    }

    let avgs = true;
    if (
      driversIndicatorsCount === null ||
      schoolInitialDriversAverage === null ||
      totalTeachers === null ||
      numberTeachersResponded === null
    ) {
      avgs = false;
    }

    if (avgs) {
      driversAvg = calculateDriversAverage(data);

      content = (
        <Switch>
          {DRIVERS_COA.map((driver) => {
            const { component: Component, name } = driver;
            const path = `${pathBaseCulture}/${driver.path}`;
            let component = (
              <Component
                numberTeachers={totalTeachers}
                numberTeachersResponded={numberTeachersResponded}
                questionsTeacher={driversIndicatorsCount}
                name={name}
                type={'pie'}
                height={300}
              />
            );

            if (!driversIndicatorsCount[driver.id])
              component = (
                <CenterContent>
                  <div className="text-center">
                    <img src={Exclamation} alt="icon" height={32} width={35} className="mr-3" />
                    <span className="h6">No Teachers Have Answered</span>
                  </div>
                </CenterContent>
              );

            return (
              <ProtectedRoute key={driver.id} path={path} component={() => <>{component}</>} />
            );
          })}

          {DOMAINS.map((domain) => {
            const filteredTeacherDomains = respondedDomains.filter((td) =>
              Object.keys(td).find((d) => d === domain.id),
            );
            const indicatorsData = filteredTeacherDomains.map((td) => td[domain.id]);
            const respondedTeacherCount = filteredTeacherDomains.length;
            const path = `${pathBaseCovid19}/${domain.path}`;

            const component = (
              <DomainDetails
                key={domain.id}
                domain={domain.name}
                message={domain.message}
                indicators={domain.indicators}
                indicatorsData={indicatorsData}
                numberTeachersResponded={respondedTeacherCount}
                numberTeachers={totalTeachers}
              />
            );
            return (
              <ProtectedRoute key={domain.id} path={path} component={() => <>{component}</>} />
            );
          })}

          <ProtectedRoute
            path={pathBaseCulture}
            render={() => (
              <MyVoiceCoaGraph
                myDriversAvg={myDriversAverage}
                schoolName={school.name || 'schools'}
                schoolAvg={schoolDriversAverage}
                schoolInitialAvg={schoolInitialDriversAverage}
              />
            )}
          />

          <ProtectedRoute
            path={pathBaseCovid19}
            component={() =>
              !domainsAverage || !Object.keys(domainsAverage).length ? (
                <PageInfo text="No Teachers Have Answered" />
              ) : (
                <DomainsGraph
                  schoolName={school.name || 'schools'}
                  domainAverages={domainsAverage}
                />
              )
            }
          />

          <ProtectedRoute to={pathBase} component={() => <Redirect to={pathBaseCulture} />} />
        </Switch>
      );
    }

    const navItems = [
      {
        key: 'schools',
        label: 'Schools',
        content: (
          <Container fluid>
            <Markers />
            <Row className="content-tab-pane" id="contenTabPane">
              <MapComponent selectTarget={this.selectTarget} />
            </Row>
          </Container>
        ),
      },
      {
        key: 'summary',
        label: 'Summary',
        content: (
          <SubNavigationLayout
            sidebar={
              <DriversCoaNavigationBar
                avg={driversAvg}
                school={school}
                schools={schools}
                schoolDriversAverage={schoolDriversAverage}
                domainsAverage={domainsAverage}
                dropSelect={this.dropSelect}
                onAllSchool={this.handleAllSchoolAvg}
              />
            }>
            {content}
          </SubNavigationLayout>
        ),
      },
      {
        key: 'communication',
        label: 'Communication',
        content: <ComunicationView />,
      },
    ];

    return (
      <NavigationBar currentRoute={pathBase}>
        <div className="pt-2 overflow-hidden">
          <NavTabsWithContent
            style={{ marginLeft: 15 }}
            items={navItems}
            onChange={this.tabActive}
            value={activeTab}
          />
        </div>
      </NavigationBar>
    );
  }
}

export default MyDistrictView;
