import Flux from '@cobuildlab/flux-state';

export const COVID_19_TEACHER_DOMAINS_EVENT = "COVID_19_TEACHER_DOMAINS_EVENT";
export const COVID_19_TEACHER_DOMAINS_ERROR = "COVID_19_TEACHER_DOMAINS_ERROR";
export const COVID_19_TEACHER_CUSTOM_DOMAINS_EVENT = "COVID_19_TEACHER_CUSTOM_DOMAINS_EVENT";
export const COVID_19_TEACHER_CUSTOM_DOMAINS_ERROR = "COVID_19_TEACHER_CUSTOM_DOMAINS_ERROR";
export const UPDATE_COVID_19_TEACHER_CUSTOM_DOMAINS_ERROR = "UPDATE_COVID_19_TEACHER_CUSTOM_DOMAINS_ERROR";
export const UPDATE_COVID_19_TEACHER_CUSTOM_DOMAINS_EVENT = "UPDATE_COVID_19_TEACHER_CUSTOM_DOMAINS_EVENT";
export const COVID_19_SCHOOLS_DOMAINS_EVENT = "COVID_19_SCHOOLS_DOMAINS_EVENT";
export const COVID_19_SCHOOLS_DOMAINS_ERROR = "COVID_19_SCHOOLS_DOMAINS_ERROR";
export const COVID_19_SCHOOL_CUSTOM_DOMAINS_AVERAGE_EVENT = "COVID_19_SCHOOL_CUSTOM_DOMAINS_AVERAGE_EVENT";
export const COVID_19_SCHOOL_CUSTOM_DOMAINS_AVERAGE_ERROR = "COVID_19_SCHOOL_CUSTOM_DOMAINS_AVERAGE_ERROR";

class Covid19DomainsStore extends Flux.Store {
  constructor() {
    super();
    this.addEvent(COVID_19_TEACHER_DOMAINS_EVENT);
    this.addEvent(COVID_19_TEACHER_DOMAINS_ERROR);
    this.addEvent(COVID_19_TEACHER_CUSTOM_DOMAINS_EVENT);
    this.addEvent(COVID_19_TEACHER_CUSTOM_DOMAINS_ERROR);
    this.addEvent(COVID_19_SCHOOLS_DOMAINS_EVENT);
    this.addEvent(COVID_19_SCHOOLS_DOMAINS_ERROR);
    this.addEvent(UPDATE_COVID_19_TEACHER_CUSTOM_DOMAINS_EVENT);
    this.addEvent(UPDATE_COVID_19_TEACHER_CUSTOM_DOMAINS_ERROR);
    this.addEvent(COVID_19_SCHOOL_CUSTOM_DOMAINS_AVERAGE_EVENT);
    this.addEvent(COVID_19_SCHOOL_CUSTOM_DOMAINS_AVERAGE_ERROR);
  }
}

export default new Covid19DomainsStore();
