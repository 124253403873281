import { useMemo, useRef } from 'react';
import { useInfiniteQuery } from 'react-query';

const useFetchPage = (queryKey, queryFn, options) => {
  const { data, ...result } = useInfiniteQuery(queryKey, queryFn, options);
  const count = useRef(0);
  const newData = useMemo(() => {
    if (data) {
      const lastData = data.pages[data.pages.length - 1];
      const haveMoreToLoad = lastData.isMore;
      const startAfter = lastData.startAfter;

      const keyWithData = Object.entries(lastData).find(([key, value]) => Array.isArray(value))[0];

      const newCount = lastData[keyWithData].length;
      count.current = lastData.clear ? newCount : count.current + newCount;

      const mappedData = lastData.clear
        ? lastData[keyWithData]
        : data.pages.flatMap((page) => page[keyWithData]);
      return {
        ...data,
        [keyWithData]: mappedData,
        haveMoreToLoad,
        startAfter,
        count: count.current,
      };
    }
    return {
      count: 0,
      startAfter: null,
      haveMoreToLoad: false,
    };
  }, [data]);
  return { data: newData, ...result };
};
export default useFetchPage;

/**
 * @typedef {import("react-query").QueryFunctionContext} QueryFunctionContext
 * @typedef {import("react-query").UseInfiniteQueryOptions} UseInfiniteQueryOptions
 * @typedef {import("react-query").UseInfiniteQueryResult} UseInfiniteQueryResult
 * @typedef {(
 *     queryKey: [string, any],
 *     queryFn: (context: QueryFunctionContext) => Promise<any>,
 *     options: UseInfiniteQueryOptions<any, any, any, any, [string, any]>
 * ) => UseInfiniteQueryResult} UseFetchPage
 */

/** @typedef {object} UtilityData
 * @property {boolean} haveMoreToLoad
 * @property {string|null} startAfter
 * @property {number} count
 */
