import React from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';
import CustomSolutionCard, { Card, CardBody, CardSubtitle } from './CustomSolutionCard';
import { Icon } from 'react-icons-kit';
import { plus as plusIcon } from 'react-icons-kit/fa/plus';
import styled from 'styled-components';

const StyledCarBody = styled(CardBody)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SolutionsCardGroup = ({
  oneColumn,
  solutions,
  selected,
  onSelect,
  creatingSolution,
  onNewSolution,
  notificationsSolutionsNew,
  showSchool,
}) => {
  let selectedSolutionCard = null;
  let newSolutionCard = null;

  if (selected > -1) {
    const notificationSolutionNew = notificationsSolutionsNew.find(
      (n) => n.solutionId === solutions[selected].id,
    );
    const isSolutionNew = notificationSolutionNew ? !notificationSolutionNew.viewed : false;

    selectedSolutionCard = (
      <CustomSolutionCard
        showSchool={showSchool}
        isNew={isSolutionNew}
        solution={solutions[selected]}
        selected
        onClick={() => onSelect(selected)}
      />
    );
  }

  if (onNewSolution) {
    newSolutionCard = (
      <Card selected={creatingSolution} small={oneColumn} onClick={onNewSolution}>
        <StyledCarBody>
          <CardSubtitle>
            <Icon className="mr-2" icon={plusIcon} /> New Solution
          </CardSubtitle>
        </StyledCarBody>
      </Card>
    );
  }

  return (
    <Row>
      {newSolutionCard}
      {selectedSolutionCard}
      {solutions.map((solution, i) => {
        if (selected === i) return null;
        const notificationSolutionNew = notificationsSolutionsNew.find(
          (n) => n.solutionId === solution.id,
        );
        const isSolutionNew = notificationSolutionNew ? !notificationSolutionNew.viewed : false;
        return (
          <CustomSolutionCard
            key={`solution-${i + 1}`}
            showSchool={showSchool}
            isNew={isSolutionNew}
            solution={solution}
            onClick={() => onSelect(i)}
          />
        );
      })}
    </Row>
  );
};

SolutionsCardGroup.defaultProps = {
  oneColumn: false,
  creatingSolution: false,
  showSchool: false,
  onNewSolution: undefined,
};

SolutionsCardGroup.propTypes = {
  oneColumn: PropTypes.bool,
  solutions: PropTypes.array.isRequired,
  selected: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  creatingSolution: PropTypes.bool,
  onNewSolution: PropTypes.func,
  notificationsSolutionsNew: PropTypes.array.isRequired,
  showSchool: PropTypes.bool,
};

export default SolutionsCardGroup;
