import { Col } from 'reactstrap';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { RADIALBAR_GRAPH_BLUE, RADIALBAR_GRAPH_GREEN } from './radialbarGraph';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { getPercentage } from '../../../shared/utils';
import { useTheme } from '../../../shared/theme';

const GraphTeacherActivity = ({
  haveLoggedIn,
  haveLogged30,
  numTeachersInitial,
  numTeachers,
  schoolUpdateData,
}) => {
  const theme = useTheme();

  const blue = R.clone(RADIALBAR_GRAPH_BLUE);
  const green = R.clone(RADIALBAR_GRAPH_GREEN);

  const porHaveLoggedIn = getPercentage(haveLoggedIn, numTeachers);
  const porHaveLogged30 = getPercentage(haveLogged30, numTeachers);
  const porHaveCompleted = getPercentage(numTeachersInitial, numTeachers);
  const porUpdateVoice = getPercentage(schoolUpdateData, numTeachers);

  const oneLoggedIn = (
    <>
      <strong>{haveLoggedIn} Teacher</strong>
      <h5 style={{ color: 'black', fontSize: '0.83em', fontWeight: '400' }}>{'Has Logged In'}</h5>
    </>
  );
  const someLoggedIn = (
    <>
      <strong>{haveLoggedIn} Teachers</strong>
      <h5 style={{ color: 'black', fontSize: '0.83em', fontWeight: '400' }}>{'Have Logged In'}</h5>
    </>
  );
  const oneLogged30 = (
    <>
      <strong>{haveLogged30} Teacher</strong>
      <h5 style={{ color: 'black', fontSize: '0.83em', fontWeight: '400' }}>
        {'Has Logged In the Past 30 Days'}
      </h5>
    </>
  );
  const someLogged30 = (
    <>
      <strong>{haveLogged30} Teachers</strong>
      <h5 style={{ color: 'black', fontSize: '0.83em', fontWeight: '400' }}>
        {'Have Logged In the Past 30 Days'}
      </h5>
    </>
  );
  const oneTeachersInitial = (
    <>
      <strong>{numTeachersInitial} Teacher</strong>
      <h5 style={{ color: 'black', fontSize: '0.83em', fontWeight: '400' }}>
        {'Has Completed the Initial Inventory'}
      </h5>
    </>
  );
  const someTeachersInitial = (
    <>
      <strong>{numTeachersInitial} Teachers</strong>
      <h5 style={{ color: 'black', fontSize: '0.83em', fontWeight: '400' }}>
        {'Have Completed the Initial Inventory'}
      </h5>
    </>
  );
  const oneInitialDrivers = (
    <>
      <strong>{schoolUpdateData} Teacher</strong>
      <h5 style={{ color: 'black', fontSize: '0.83em', fontWeight: '400' }}>
        {'Has Updated MyVoice In the Past 30 Days'}
      </h5>
    </>
  );
  const someInitialDrivers = (
    <>
      <strong>{schoolUpdateData} Teachers</strong>
      <h5 style={{ color: 'black', fontSize: '0.83em', fontWeight: '400' }}>
        {'Have Updated MyVoice In the Past 30 Days'}
      </h5>
    </>
  );
  return (
    <>
      <Col lg={3} md={6}>
        <div id="chart_1" style={{ textAlign: 'center' }}>
          <ReactApexChart
            className="voiceGraph"
            options={blue}
            series={[porHaveLoggedIn]}
            type="radialBar"
            height={240}
          />
          <div
            style={{
              marginTop: '10px',
              color: theme.palette.secondary,
              display: 'flex',
              flexDirection: 'column',
            }}>
            <> {haveLoggedIn === 1 ? oneLoggedIn : someLoggedIn} </>
          </div>
        </div>
      </Col>
      <Col lg={3} md={6}>
        <div id="chart_2" style={{ textAlign: 'center' }}>
          <ReactApexChart
            className="voiceGraph"
            options={blue}
            series={[porHaveLogged30]}
            type="radialBar"
            height={240}
          />
          <div
            style={{
              marginTop: '10px',
              color: theme.palette.secondary,
              display: 'flex',
              flexDirection: 'column',
            }}>
            <>{haveLogged30 === 1 ? oneLogged30 : someLogged30}</>
          </div>
        </div>
      </Col>
      <Col lg={3} md={6}>
        <div id="chart_3" style={{ textAlign: 'center' }}>
          <ReactApexChart
            className="voiceGraph"
            options={green}
            series={[porHaveCompleted]}
            type="radialBar"
            height={240}
          />
          <div
            style={{
              marginTop: '10px',
              color: theme.palette.success,
              display: 'flex',
              flexDirection: 'column',
            }}>
            <>{numTeachersInitial === 1 ? oneTeachersInitial : someTeachersInitial}</>
          </div>
        </div>
      </Col>
      <Col lg={3} md={6}>
        <div id="chart_4" style={{ textAlign: 'center' }}>
          <ReactApexChart
            className="voiceGraph"
            options={green}
            series={[porUpdateVoice]}
            type="radialBar"
            height={240}
          />
          <div
            style={{
              marginTop: '10px',
              color: theme.palette.success,
              display: 'flex',
              flexDirection: 'column',
            }}>
            <>{schoolUpdateData === 1 ? oneInitialDrivers : someInitialDrivers}</>
          </div>
        </div>
      </Col>
    </>
  );
};

GraphTeacherActivity.propTypes = {
  haveLoggedIn: PropTypes.number.isRequired,
  haveLogged30: PropTypes.number.isRequired,
  numTeachersInitial: PropTypes.number.isRequired,
  numTeachers: PropTypes.number.isRequired,
  schoolUpdateData: PropTypes.number.isRequired,
};

export { GraphTeacherActivity };
