import React, { useState } from 'react';
import { initialOtherSearch } from './components/SchoolSearchForm';
import { ManagementView, TABLE_LIMIT } from '../../components/ManagementView';
import {
  createAndDownloadSuperAdminReport,
  createOpenRegistration,
  editSchoolAction,
  extendOpenRegistration,
  getOpenRegistrationBySchoolId,
  getRegions,
  getSchoolsAction,
  stopOpenRegistration,
} from './super-admin-actions';
import { download2 as ic_download } from 'react-icons-kit/icomoon/download2';
import { plus as ic_plus } from 'react-icons-kit/fa/plus';
import { useSubscription } from '@cobuildlab/react-flux-state';
import {
  ADD_OPEN_REGISTRATION_ERROR,
  ADD_OPEN_REGISTRATION_EVENT,
  EXTEND_OPEN_REGISTRATION_ERROR,
  EXTEND_OPEN_REGISTRATION_EVENT,
  GET_OPEN_REGISTRATION_ERROR,
  GET_OPEN_REGISTRATION_EVENT,
  STOP_OPEN_REGISTRATION_ERROR,
  STOP_OPEN_REGISTRATION_EVENT,
  superAdminStore,
  SUPER_ADMIN_ADD_SCHOOL_EVENT,
  SUPER_ADMIN_EDIT_SCHOOL_ERROR,
  SUPER_ADMIN_EDIT_SCHOOL_EVENT,
  SUPER_ADMIN_EXCEL_REPORTS_ERROR,
  SUPER_ADMIN_EXCEL_REPORTS_EVENT,
} from './super-admin-store';
import { customToast } from '../../shared/toast';
import { useDebounce } from '../../shared/hooks';
import { TableSchools } from './components/TableSchools';
import AddSchoolModalContainer from './components/AddSchoolModalContainer';
import { ButtonSchoolFilter } from './components/ButtonFilterSchool';
import moment from 'moment';
import { queryClient } from '../../shared/utils';
import EditSchoolModal from '../../components/school/EditSchoolModal';
import ActiveRegistrationModal from './components/ActiveRegistrationModal';
import { useQuery } from 'react-query';
import useFetchPage from '../../shared/useFetchPage';

const initialOpenRegistration = {
  token: '',
  emailDomain: [],
  schoolId: '',
  allowAllDomains: false,
  expireDate: moment()
    .add(30, 'days')
    .toDate(),
};

const fetchKey = 'fetchSchools';
const fetchKeyRegions = 'fetchKeyRegions';

export const SchoolsManagementView = () => {
  const [schoolFilters, setSchoolFilters] = useState(initialOtherSearch);
  const [search, setSearch] = useState('');
  const [modal, setModal] = useState(false);
  const [schoolSelected, setSchoolSelected] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [isActiveRegistrationOpen, setIsActiveRegistrationOpen] = useState(false);
  const [openRegistration, setOpenRegistration] = useState(initialOpenRegistration);
  const [isLoadingOpenRegistration, setIsLoadingOpenRegistration] = useState(false);
  const [isSavingOpenRegistration, setIsSavingOpenRegistration] = useState(false);
  const [isExtendingOpenRegistration, setIsExtendingOpenRegistration] = useState(false);

  useDebounce(() => {
    setSchoolFilters({ ...schoolFilters, school: search });
  }, search);

  const toggle = () => setModal(!modal);

  const handleCreateReport = () => {
    setLoadingReport(true);
    createAndDownloadSuperAdminReport(schoolFilters, 'schools', 'Schools Report');
  };

  const { data: regions } = useQuery(fetchKeyRegions, () => {
    return getRegions();
  });

  const {
    isLoading,
    data,
    fetchNextPage,
    isFetchingNextPage: isLoadingMore,
    refetch,
  } = useFetchPage(
    [fetchKey, schoolFilters],
    ({ queryKey, pageParam = null }) => {
      const [, rawFilters] = queryKey;
      const filters = { ...rawFilters };
      return getSchoolsAction(filters, TABLE_LIMIT, pageParam);
    },
    {
      onError: (err) => {
        customToast.error(err);
      },
    },
  );

  useSubscription(superAdminStore, SUPER_ADMIN_EXCEL_REPORTS_ERROR, (err) => {
    setLoadingReport(false);
    customToast.error(err);
  });

  useSubscription(superAdminStore, SUPER_ADMIN_EXCEL_REPORTS_EVENT, () => {
    setLoadingReport(false);
  });

  const loading = isLoading || isLoadingMore;

  useSubscription(superAdminStore, SUPER_ADMIN_ADD_SCHOOL_EVENT, () => {
    customToast.success('School has been added successfully');
    queryClient.invalidateQueries([fetchKey]);
  });

  useSubscription(superAdminStore, SUPER_ADMIN_EDIT_SCHOOL_EVENT, () => {
    customToast.success('School has been edited successfully');
    setIsEditModalOpen(false);
    queryClient.invalidateQueries([fetchKey]);
  });

  useSubscription(superAdminStore, SUPER_ADMIN_EDIT_SCHOOL_ERROR, (error) => {
    customToast.error(error);
  });

  useSubscription(superAdminStore, GET_OPEN_REGISTRATION_EVENT, (data) => {
    setOpenRegistration(data.openRegistration);
    setIsLoadingOpenRegistration(false);
  });

  useSubscription(superAdminStore, GET_OPEN_REGISTRATION_ERROR, () => {
    customToast.error('error getting school open registration');
    setIsLoadingOpenRegistration(false);
  });

  useSubscription(superAdminStore, ADD_OPEN_REGISTRATION_EVENT, ({ openRegistration, school }) => {
    setIsSavingOpenRegistration(false);
    setOpenRegistration(openRegistration);
    setSchoolSelected(school);
    customToast.success('open registration created successfully!');
    // Update school list
    queryClient.setQueryData([fetchKey, schoolFilters], (oldData) => {
      const newSchools = oldData.pages.map((page) => {
        return {
          ...page,
          schools: page.schools.map((newSchool) => {
            if (newSchool.id === openRegistration.schoolId) {
              return {
                ...newSchool,
                openRegistrationExpireDate: moment(openRegistration.expireDate),
              };
            }
            return newSchool;
          }),
        };
      });
      return { ...oldData, pages: newSchools };
    });
  });

  useSubscription(superAdminStore, ADD_OPEN_REGISTRATION_ERROR, () => {
    setIsSavingOpenRegistration(false);
    customToast.error('Error editing school');
  });

  useSubscription(superAdminStore, EXTEND_OPEN_REGISTRATION_EVENT, (data) => {
    const auxSchool = {
      ...schoolSelected,
      expireOpenRegistration: data.expireDate,
    };

    setIsExtendingOpenRegistration(false);
    setSchoolSelected(auxSchool);
    setOpenRegistration(data);

    customToast.success('open registration extended successfully!');
    refetch();
  });

  useSubscription(superAdminStore, EXTEND_OPEN_REGISTRATION_ERROR, (error) => {
    setIsExtendingOpenRegistration(false);
    customToast.error(error);
  });

  useSubscription(superAdminStore, STOP_OPEN_REGISTRATION_EVENT, () => {
    const auxSchool = { ...schoolSelected };
    delete auxSchool.openRegistrationExpireDate;

    setSchoolSelected(auxSchool);
    setIsSavingOpenRegistration(false);
    setOpenRegistration(initialOpenRegistration);
    customToast.success('Open registration stopped successfully!');
    queryClient.setQueryData([fetchKey, schoolFilters], (oldData) => {
      const newSchools = oldData.pages.map((page) => {
        return {
          ...page,
          schools: page.schools.map((newSchool) => {
            if (newSchool.id === schoolSelected.id) {
              return {
                ...newSchool,
                openRegistrationExpireDate: null,
              };
            }
            return newSchool;
          }),
        };
      });
      return { ...oldData, pages: newSchools };
    });
  });

  useSubscription(superAdminStore, STOP_OPEN_REGISTRATION_ERROR, () => {
    setIsSavingOpenRegistration(false);
    customToast.error('error stopping open registration');
  });

  const handleEditSchool = (school) => {
    setSchoolSelected(school);
    setIsEditModalOpen(true);
  };

  const toggleActiveRegistrations = () => {
    if (isActiveRegistrationOpen) setOpenRegistration(initialOpenRegistration);
    setIsActiveRegistrationOpen(!isActiveRegistrationOpen);
  };

  const handleOnSchoolActiveRegistration = (school) => {
    if (school.active !== false) {
      if (school.openRegistrationExpireDate) {
        setIsLoadingOpenRegistration(true);
        getOpenRegistrationBySchoolId(school.id);
      }

      setSchoolSelected(school);
      setIsActiveRegistrationOpen(true);
    }
  };

  const handleOnChangeOpenRegistration = (valuesOpenRegistration) => {
    setOpenRegistration((currentOpenRegistration) => ({
      ...currentOpenRegistration,
      ...valuesOpenRegistration,
    }));
  };

  const handleOnSubmitOpenRegistration = () => {
    setIsSavingOpenRegistration(true);

    if (openRegistration.id) stopOpenRegistration(openRegistration);
    else createOpenRegistration(schoolSelected, openRegistration);
  };

  const handleOnExtendOpenRegistration = () => {
    setIsExtendingOpenRegistration(true);
    extendOpenRegistration(openRegistration);
  };

  return (
    <>
      <ManagementView
        openFilterButton={
          <ButtonSchoolFilter
            data={schoolFilters}
            onChange={(val) => setSchoolFilters(val)}
            isLoading={loading}
          />
        }
        count={data.count}
        haveMoreToLoad={data.haveMoreToLoad}
        searchInput={{
          value: search,
          onChange: (value) => setSearch(value),
        }}
        isLoading={loading}
        buttomActions={[
          {
            onClick: handleCreateReport,
            title: 'Download File',
            icon: ic_download,
            loading: loadingReport,
          },
          {
            onClick: toggle,
            title: 'Add School',
            icon: ic_plus,
          },
        ]}>
        <TableSchools
          isLoading={loading}
          isMoreToLoad={data.haveMoreToLoad}
          onLoadMore={() => fetchNextPage({ pageParam: data.startAfter })}
          rows={data.schools || []}
          onEdit={handleEditSchool}
          handleOnSchoolActiveRegistration={handleOnSchoolActiveRegistration}
        />
      </ManagementView>
      <AddSchoolModalContainer onToggle={toggle} isOpen={modal} />
      {schoolSelected && (
        <>
          <EditSchoolModal
            school={schoolSelected}
            regions={regions}
            onSubmit={editSchoolAction}
            onToggle={() => setIsEditModalOpen(!isEditModalOpen)}
            isOpen={isEditModalOpen}
          />
          <ActiveRegistrationModal
            isOpen={isActiveRegistrationOpen}
            onToggle={toggleActiveRegistrations}
            school={schoolSelected}
            openRegistration={openRegistration}
            onChange={handleOnChangeOpenRegistration}
            onSubmit={handleOnSubmitOpenRegistration}
            onExtend={handleOnExtendOpenRegistration}
            isSaving={isSavingOpenRegistration}
            isLoading={isLoadingOpenRegistration}
            isExtending={isExtendingOpenRegistration}
          />
        </>
      )}
    </>
  );
};
