import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
import { GoogleApiWrapper } from 'google-maps-react';
import { default as firebaseConfig } from '../../config/firebase.json';
import styled from 'styled-components';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

const SuggestionContainer = styled.div`
    position: absolute;
    border: 1px solid #ced4da;
    border-top: none;
    border-bottom: none
    border-radius: 0 0 .25rem .25rem;
    top: 100%;
    z-index: 3;
    left: 0;
    right: 0;
`;
const SuggestionItem = styled.div`
    padding: 0.375rem 0.75rem;
    border-bottom: 1px solid #ced4da
    cursor: pointer;
    &:hover {
      background-color: #3C82FF;
      color: white;
    }
`;
const Suggestion = (props) => {
  const {
    suggestion: { active, description },
  } = props;
  const style = {
    backgroundColor: active ? '#3C82FF' : 'white',
    color: active ? 'white' : 'black',
  };
  return (
    <SuggestionItem style={style} {...props}>
      {description}
    </SuggestionItem>
  );
};

const InitialState = {
  address: '',
  coordinates: {
    latitude: '',
    longitude: '',
  },
};

/**
 * Input with autocomplete to pick places.
 *
 * @param {Function} onChange - Function to be trigger on change.
 * @param {string} initialAddress - Address.
 * @param {object} initialCoordinates - Object with initialCoordinates.
 * @returns {*} - Return and Input.
 * @class
 */
const InputPlaces = ({ onChange, address: initialAddress, coordinates: initialCoordinates }) => {
  // Using state to batch state updates
  const [place, setPlace] = useState(() => ({
    address: initialAddress || InitialState.address,
    coordinates: initialCoordinates || initialCoordinates,
  }));

  const handleChange = (address) => {
    setPlace({ ...place, address });
  };

  const handleSelect = async (address, placeId) => {
    let place;
    if (placeId) {
      [place] = await geocodeByPlaceId(placeId);
    } else {
      [place] = await geocodeByAddress(address);
    }
    if (place) {
      const { lat, lng } = await getLatLng(place);
      const coordinates = {
        latitude: parseFloat(lat),
        longitude: parseFloat(lng),
      };
      setPlace({ address, coordinates });
    }
  };

  useEffect(() => onChange(place), [place]);

  useEffect(() => {
    if (initialAddress !== place.address) {
      setPlace({ address: initialAddress, coordinates: initialCoordinates });
    }
  }, [initialAddress]);

  return (
    <PlacesAutocomplete value={place.address} onChange={handleChange} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const suggestionList = (
          <SuggestionContainer>
            {suggestions.map((suggestion) => (
              <Suggestion
                key={suggestion.id}
                suggestion={suggestion}
                {...getSuggestionItemProps(suggestion)}
              />
            ))}
          </SuggestionContainer>
        );

        return (
          <div style={{ position: 'relative' }}>
            <input
              {...getInputProps({
                placeholder: 'Address',
                className: 'form-control',
              })}
            />
            {loading && (
              <div
                className="d-flex justify-content-center"
                style={{ marginTop: 15, marginBottom: 15 }}>
                <Spinner color="primary" />
              </div>
            )}
            {suggestions.length ? suggestionList : null}
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
};

InputPlaces.defaultProps = {
  address: InitialState.address,
  coordinates: InitialState.coordinates,
};

InputPlaces.propTypes = {
  address: PropTypes.string,
  coordinates: PropTypes.shape({
    latitude: PropTypes.string.isRequired,
    longitude: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
};

export default GoogleApiWrapper({
  apiKey: firebaseConfig.apiKey,
})(InputPlaces);
