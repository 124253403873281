import React from 'react';
import PropTypes from 'prop-types';
import TableSortable from '../../../components/table/TableSortable';
import { StatusBadge } from '../../../shared/components/badge/StatusBadge';
import Icon from 'react-icons-kit';
import { edit as EditIcon } from 'react-icons-kit/fa/edit';

const QtyRow = ({ children }) => (
  <div style={{ maxWidth: 200, textAlign: 'center' }}>{children}</div>
);

QtyRow.propTypes = {
  children: PropTypes.node.isRequired,
};

export const TableDistricts = ({ onEdit, ...props }) => {
  const statusColumn = (val = true) => <StatusBadge active={val} />;

  const qtyRow = (qty = 0) => <QtyRow>{qty}</QtyRow>;

  const regionColumn = (val, district) => {
    return (
      <div className="d-flex justify-content-center">
        {qtyRow(val.length)}
        <span
          onClick={() => onEdit(district)}
          style={{ width: '10px' }}
          className="table-button-edit ml-3">
          <Icon icon={EditIcon} size="25px" />
        </span>
      </div>
    );
  };

  return (
    <TableSortable
      columns={[
        {
          title: 'Name',
          field: 'name',
          render: (val) => val,
        },
        {
          title: 'ID',
          field: 'id',
        },
        {
          title: 'Status',
          field: 'active',
          render: statusColumn,
        },
        {
          title: 'Schools',
          field: 'schools',
          render: qtyRow,
        },
        {
          title: 'Leaders',
          field: 'leaders',
          render: qtyRow,
        },
        {
          title: 'Teachers',
          field: 'teachers',
          render: qtyRow,
        },
        {
          title: 'Regions',
          field: 'regions',
          render: regionColumn,
        },
      ]}
      {...props}
    />
  );
};

TableDistricts.propTypes = {
  onEdit: PropTypes.func.isRequired,
};
