import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import Circle from '../../../components/Circle';

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
};

const Markers = () => (
  <Container className="container-marker-image" style={{ margin: '5px' }}>
    <Row>
      <Col xs="auto" style={flexCenter}>
        <Circle
          letter={'E'}
          background={'#4EE57C'}
          border={'3px solid rgb(67, 181, 92)'}
          letterColor={'#276637'}
        />
        Elementary
      </Col>
      <Col xs="auto" style={flexCenter}>
        <Circle
          letter={'M'}
          background={'#FCDD2B'}
          border={'3px solid rgb(201, 170, 40)'}
          letterColor={'#685B19'}
        />
        Middle
      </Col>
      <Col xs="auto" style={flexCenter}>
        <Circle
          letter={'H'}
          background={'#FE293A'}
          border={'3px solid rgb(206, 25, 47)'}
          letterColor={'#6B101B'}
        />
        High
      </Col>
      <Col xs="auto" style={flexCenter}>
        <Circle
          letter={'O'}
          background={'#4AD6ED'}
          border={'3px solid rgb(60, 176, 188)'}
          letterColor={'#194547'}
        />
        Other
      </Col>
    </Row>
  </Container>
);

export default Markers;
