import { Col } from 'reactstrap';
import LoadMoreButton from '../../modules/super-admin/components/LoadMoreButton';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Loading Row Component.
 *
 * @param {boolean} isLoading - Is content loading.
 * @param {Function} onClick - Handler function when click event is triggered.
 * @param {number} colSpan - Number of column to span.
 * @returns {*} - Loading Row Component.
 * @class
 */
const LoadMoreRow = ({ isLoading, onClick, colSpan = 0 }) => (
  <tr>
    <td colSpan={colSpan}>
      <Col md={12} className="content-sub-bar justify-content-center d-flex">
        <LoadMoreButton loading={isLoading} onClick={onClick} />
      </Col>
    </td>
  </tr>
);

LoadMoreRow.defaultProps = {
  colSpan: 0,
};

LoadMoreRow.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  colSpan: PropTypes.number,
};

export default LoadMoreRow;
