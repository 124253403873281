import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import { Card, BoxInfo } from './StepCard';
import { RequiredInputLabel } from '../../../../shared/components/typography/RequiredInputLabel';
import { ErrorInputMsg } from '../../../../shared/components/typography/ErrorInputMsg';

const Step2 = ({ active, editable, onChange, solution, loading, onError }) => {
  return (
    <Card active={active}>
      <Row className="d-flex flex-column">
        <h4>Step 2</h4>
        <div> Solution Creation</div>
      </Row>
      <Row className="mt-4 d-flex flex-column">
        <RequiredInputLabel>Solution Summary</RequiredInputLabel>
        {editable && active ? (
          <textarea
            rows="6"
            className="form-control"
            onChange={(e) => onChange('summary', e.target.value)}
            value={solution.summary}
          />
        ) : (
          <BoxInfo>{solution.summary}</BoxInfo>
        )}
        <ErrorInputMsg>{onError('summary')}</ErrorInputMsg>
      </Row>
      <Row className="mt-4 d-flex flex-column">
        <RequiredInputLabel>Goal Desired Outcome</RequiredInputLabel>
        {editable && active ? (
          <textarea
            rows="6"
            className="form-control"
            onChange={(e) => onChange('goalDesired', e.target.value)}
            value={solution.goalDesired}
          />
        ) : (
          <BoxInfo>{solution.goalDesired}</BoxInfo>
        )}
        <ErrorInputMsg>{onError('goalDesired')}</ErrorInputMsg>
      </Row>
    </Card>
  );
};

Step2.propTypes = {
  active: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  solution: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onError: PropTypes.func.isRequired,
};

export default Step2;
