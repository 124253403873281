import React from 'react';
import {
  EMPOWERMENT_INDICATORS,
  EMPOWERMENT_MESSAGE,
  EMPOWERMENT_QUESTIONS,
} from '../../my-voice-models';
import TitleQuestions from './TitleQuestions';
import PropTypes from 'prop-types';
import IndicatorDetailCards from './indicatorsCards/IndicatorDetailCards';
import Icon from '../../../../shared/assets/icons/empowerment.svg';
import { getSeries } from '../../../../shared/driversGraphs';

/**
 * My Voice Leader Questions empowerm, ent View.
 *
 * @param questionsTeacher
 * @param numberTeachers
 * @returns {*}
 */
const DriverEmpowermentLeaderView = ({
  questionsTeacher,
  numberTeachers,
  numberTeachersResponded,
  name,
  type,
  height,
}) => {
  const seriesGeneral = getSeries(EMPOWERMENT_QUESTIONS, questionsTeacher['empowerment']);

  const content = (
    <IndicatorDetailCards
      seriesGeneral={seriesGeneral}
      questions={EMPOWERMENT_INDICATORS}
      type={type}
      height={height}
    />
  );
  return (
    <div>
      <TitleQuestions
        numberTeachers={numberTeachers}
        numberTeachersResponded={numberTeachersResponded}
        message={EMPOWERMENT_MESSAGE}
        icon={Icon}
        name={name}
      />
      {content}
    </div>
  );
};

DriverEmpowermentLeaderView.propTypes = {
  questionsTeacher: PropTypes.object.isRequired,
  numberTeachers: PropTypes.number.isRequired,
  numberTeachersResponded: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
};

export default DriverEmpowermentLeaderView;
