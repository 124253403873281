import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a LOGIN error.
 *
 * @type {string}
 */
export const LOGIN_ERROR_EVENT = 'onLoginErrorEvent';

/**
 * Event that triggers a User fetch Event.
 *
 * @type {string}
 */
export const USER_EVENT = 'onUserEvent';
export const USER_BY_TOKEN_EVENT = 'onUserByTokenEvent';
export const USER_ERROR = 'onUserError';
export const REQUEST_RECOVER_PASSWORD = 'onUserRequestRecoverPassword';
export const USER_CLF_DISTRICTS = 'onUserClfDistricts';
export const USER_CLF_DISTRICTS_ERROR = 'onUserClfDistrictsError';

/**
 * Event that triggers a User update Event.
 *
 * @type {string}
 */
export const UPDATE_USER_EVENT = 'onUpdateUserEvent';

/**
 * Event that triggers when a user Logs in.
 *
 * @type {string}
 */
export const LOGIN_EVENT = 'onLoginEvent';

/**
 * Event that triggers when a user Logs out.
 *
 * @type {string}
 */
export const LOGOUT_EVENT = 'onLogoutEvent';
export const PASSWORD_UPDATE_EVENT = 'onPasswordUpdateEvent';
export const PASSWORD_UPDATE_ERROR = 'onPasswordUpdateError';

class AuthStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(LOGOUT_EVENT);
    this.addEvent(LOGIN_EVENT);
    this.addEvent(LOGIN_ERROR_EVENT);
    this.addEvent(USER_EVENT);
    this.addEvent(USER_ERROR);
    this.addEvent(UPDATE_USER_EVENT);
    this.addEvent(REQUEST_RECOVER_PASSWORD);
    this.addEvent(PASSWORD_UPDATE_EVENT);
    this.addEvent(PASSWORD_UPDATE_ERROR);
    this.addEvent(USER_CLF_DISTRICTS);
    this.addEvent(USER_CLF_DISTRICTS_ERROR);
    this.addEvent(USER_BY_TOKEN_EVENT);
  }
}

const authStore = new AuthStore();

export { authStore };
