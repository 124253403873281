import React from 'react';
import {
  ADVANCEMENT_INDICATORS,
  ADVANCEMENT_MESSAGE,
  ADVANCEMENT_QUESTIONS,
} from '../../my-voice-models';
import Advancement from '../../../../shared/assets/icons/teacher-advancement.svg';
import TitleQuestions from './TitleQuestions';
import PropTypes from 'prop-types';
import IndicatorDetailCards from './indicatorsCards/IndicatorDetailCards';
import { getSeries } from '../../../../shared/driversGraphs';

/**
 * My Voice Leader Questions advancement View.
 *
 * @param questionsTeacher
 * @param numberTeachers
 * @returns {*}
 */
const DriverAdvancementLeaderView = ({
  questionsTeacher,
  numberTeachers,
  numberTeachersResponded,
  name,
  type,
  height,
}) => {
  const seriesGeneral = getSeries(ADVANCEMENT_QUESTIONS, questionsTeacher['advancement']);

  const content = (
    <IndicatorDetailCards
      seriesGeneral={seriesGeneral}
      questions={ADVANCEMENT_INDICATORS}
      type={type}
      height={height}
    />
  );

  return (
    <div>
      <TitleQuestions
        numberTeachers={numberTeachers}
        numberTeachersResponded={numberTeachersResponded}
        message={ADVANCEMENT_MESSAGE}
        icon={Advancement}
        name={name}
      />
      {content}
    </div>
  );
};

DriverAdvancementLeaderView.propTypes = {
  questionsTeacher: PropTypes.object.isRequired,
  numberTeachers: PropTypes.number.isRequired,
  numberTeachersResponded: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
};

export default DriverAdvancementLeaderView;
