import * as myVoiceModels from '../my-voice/my-voice-models';
// import resourceIcon from '../../shared/assets/icons/resource-priorities.svg';
import { theme } from '../../shared/theme';

// ===================== DOMAINS MESSAGE / DESCRIPTION =====================
export const SOCIAL_EMOTIONAL_MESSAGE =
  'Teachers benefit from social-emotional support as they navigate the challenges created from professional isolation, the unknown risks with COVID-19, and meeting the diverse needs of their students.';

export const INSTRUCTIONAL_DESIGN_MESSAGE =
  'In a constantly shifting educational environment, teachers need to be empowered in making decisions through a flexible curriculum, instructional autonomy, and involvement in program decisions.';

export const INSTRUCTIONAL_COLLABORATION_MESSAGE =
  'Teachers need time to collaborate in developing and evaluating the effectiveness of instructional practices and materials used in multiple instructional models.';

export const RESOURCES_MESSAGE =
  'Teachers need to be engaged in decisions on how to leverage limited resources for school materials and use of personnel.';

export const PROFESSIONAL_LEARNING_MESSAGE =
  'With changes in instructional designs, teachers benefit from focused and personalized professional learning activities that can be accessed using a variety of delivery options.';

export const TEACHER_HEALTH_MESSAGE =
  'Teachers are on the frontline of schools and need to be involved in decisions and processes to support their physical and social emotional safety.';

export const STUDENT_SUPPORT_MESSAGE =
  'Teachers care deeply about the physical, social-emotional, and intellectual health of their students and the systems in place to meet their needs.';

// ===================== DOMAINS INDICATORS =====================
export const SOCIAL_EMOTIONAL_INDICATORS = [
  {
    name: myVoiceModels.SUPPORT_INDICATORS[4].name,
    id: myVoiceModels.SUPPORT_INDICATORS[4].id,
    data: 0,
  },
  {
    name: myVoiceModels.SENSE_INDICATORS[0].name,
    id: myVoiceModels.SENSE_INDICATORS[0].id,
    data: 0,
  },
  {
    name: myVoiceModels.SENSE_INDICATORS[1].name,
    id: myVoiceModels.SENSE_INDICATORS[1].id,
    data: 0,
  },
  {
    name: myVoiceModels.SENSE_INDICATORS[2].name,
    id: myVoiceModels.SENSE_INDICATORS[2].id,
    data: 0,
  },
  {
    name: myVoiceModels.SENSE_INDICATORS[3].name,
    id: myVoiceModels.SENSE_INDICATORS[3].id,
    data: 0,
  },
];

export const INSTRUCTIONAL_DESIGN_INDICATORS = [
  {
    name: myVoiceModels.CLEAR_INDICATORS[1].name,
    id: myVoiceModels.CLEAR_INDICATORS[1].id,
    data: 0,
  },
  {
    name: myVoiceModels.INSTRUCTIONAL_INDICATORS[0].name,
    id: myVoiceModels.INSTRUCTIONAL_INDICATORS[0].id,
    data: 0,
  },
  {
    name: myVoiceModels.INSTRUCTIONAL_INDICATORS[1].name,
    id: myVoiceModels.INSTRUCTIONAL_INDICATORS[1].id,
    data: 0,
  },
  {
    name: myVoiceModels.INSTRUCTIONAL_INDICATORS[2].name,
    id: myVoiceModels.INSTRUCTIONAL_INDICATORS[2].id,
    data: 0,
  },
  {
    name: myVoiceModels.EMPOWERMENT_INDICATORS[0].name,
    id: myVoiceModels.EMPOWERMENT_INDICATORS[0].id,
    data: 0,
  },
  {
    name: myVoiceModels.FEEDBACK_INDICATORS[3].name,
    id: myVoiceModels.FEEDBACK_INDICATORS[3].id,
    data: 0,
  },
  {
    name: myVoiceModels.RESOURCE_INDICATORS[2].name,
    id: myVoiceModels.RESOURCE_INDICATORS[2].id,
    data: 0,
  },
];

export const INSTRUCTIONAL_COLLABORATION_INDICATORS = [
  {
    name: myVoiceModels.INSTRUCTIONAL_INDICATORS[4].name,
    id: myVoiceModels.INSTRUCTIONAL_INDICATORS[4].id,
    data: 0,
  },
  {
    name: myVoiceModels.COLLABORATION_INDICATORS[0].name,
    id: myVoiceModels.COLLABORATION_INDICATORS[0].id,
    data: 0,
  },
  {
    name: myVoiceModels.COLLABORATION_INDICATORS[1].name,
    id: myVoiceModels.COLLABORATION_INDICATORS[1].id,
    data: 0,
  },
  {
    name: myVoiceModels.COLLABORATION_INDICATORS[2].name,
    id: myVoiceModels.COLLABORATION_INDICATORS[2].id,
    data: 0,
  },
  {
    name: myVoiceModels.COLLABORATION_INDICATORS[3].name,
    id: myVoiceModels.COLLABORATION_INDICATORS[3].id,
    data: 0,
  },
  {
    name: myVoiceModels.SUPPORT_INDICATORS[3].name,
    id: myVoiceModels.SUPPORT_INDICATORS[3].id,
    data: 0,
  },
];

export const RESOURCES_INDICATORS = [
  {
    name: myVoiceModels.RESOURCE_INDICATORS[0].name,
    id: myVoiceModels.RESOURCE_INDICATORS[0].id,
    data: 0,
  },
  {
    name: myVoiceModels.RESOURCE_INDICATORS[1].name,
    id: myVoiceModels.RESOURCE_INDICATORS[1].id,
    data: 0,
  },
  {
    name: myVoiceModels.RESOURCE_INDICATORS[2].name,
    id: myVoiceModels.RESOURCE_INDICATORS[2].id,
    data: 0,
  },
  {
    name: myVoiceModels.RESOURCE_INDICATORS[3].name,
    id: myVoiceModels.RESOURCE_INDICATORS[3].id,
    data: 0,
  },
];

export const PROFESSIONAL_LEARNING_INDICATORS = [
  {
    name: myVoiceModels.PROFESSIONAL_INDICATORS[0].name,
    id: myVoiceModels.PROFESSIONAL_INDICATORS[0].id,
    data: 0,
  },
  {
    name: myVoiceModels.PROFESSIONAL_INDICATORS[1].name,
    id: myVoiceModels.PROFESSIONAL_INDICATORS[1].id,
    data: 0,
  },
  {
    name: myVoiceModels.PROFESSIONAL_INDICATORS[2].name,
    id: myVoiceModels.PROFESSIONAL_INDICATORS[2].id,
    data: 0,
  },
  {
    name: myVoiceModels.PROFESSIONAL_INDICATORS[3].name,
    id: myVoiceModels.PROFESSIONAL_INDICATORS[3].id,
    data: 0,
  },
  {
    name: myVoiceModels.PROFESSIONAL_INDICATORS[4].name,
    id: myVoiceModels.PROFESSIONAL_INDICATORS[4].id,
    data: 0,
  },
  {
    name: myVoiceModels.PROFESSIONAL_INDICATORS[5].name,
    id: myVoiceModels.PROFESSIONAL_INDICATORS[5].id,
    data: 0,
  },
  {
    name: myVoiceModels.EMPOWERMENT_INDICATORS[4].name,
    id: myVoiceModels.EMPOWERMENT_INDICATORS[4].id,
    data: 0,
  },
  {
    name: myVoiceModels.FEEDBACK_INDICATORS[3].name,
    id: myVoiceModels.FEEDBACK_INDICATORS[3].id,
    data: 0,
  },
];

export const TEACHER_HEALTH_INDICATORS = [
  {
    name: '(T.1) I believe teacher health and safety is important in school decisions.',
    id: 'teacherHealthSafety',
    data: 0,
  },
  {
    name: '(T.2) I believe procedures are in place to support safe work environments.',
    id: 'supportSafeWork',
    data: 0,
  },
  {
    name: '(T.3) I believe COVID - 19 policies and procedures support teachers and students.',
    id: 'policiesProceduresSupport',
    data: 0,
  },
  {
    name:
      '(T.4) I believe processes are in place for teachers to report unsafe working conditions.',
    id: 'reportUnsafeWorking',
    data: 0,
  },
  {
    name: '(T.5) I believe teachers are involved in decisions about their work environment.',
    id: 'involvedDecisionsWork',
    data: 0,
  },
  {
    name:
      '(T.6) I believe teachers are involved in decisions on teaching assignments (in-school, virtual, mixed, etc.).',
    id: 'involvedDesisionsAssignments',
    data: 0,
  },
  {
    name: '(T.7) I believe safe working conditions are a priority.',
    id: 'safeWorkingConditions',
    data: 0,
  },
  {
    name: '(T.8) I believe instructional models (ex. virtual/hybrid) are reviewed and evaluated.',
    id: 'reviewedEvaluatedModels',
    data: 0,
  },
  {
    name: '(T.9) I believe teacher social emotional needs (in-school) are supported.',
    id: 'teacherSocialSupported',
    data: 0,
  },
  {
    name: '(T.10) I believe teacher social-emotional needs (virtual school) are supported.',
    id: 'schoolSocialSupported',
    data: 0,
  },
];

export const STUDENT_SUPPORT_INDICATORS = [
  {
    name: '(S.1) I believe student health and safety are important in school decisions.',
    id: 'studentHealthSafety',
    data: 0,
  },
  {
    name: '(S.2) I believe decisions are made to safeguard students.',
    id: 'decisionsSafeguard',
    data: 0,
  },
  {
    name: '(S.3) I believe students with identified learning needs are supported.',
    id: 'supportedLearning',
    data: 0,
  },
  {
    name:
      '(S.4) I believe students have appropriate learning options (In-school, virtual, mixed, etc.).',
    id: 'appropriateLearning',
    data: 0,
  },
  {
    name: '(S.5) I believe student COVID - 19 screening procedures are adequate.',
    id: 'proceduresAdequate',
    data: 0,
  },
  {
    name: '(S.6) I believe student social-emotional needs (in-school) are supported.',
    id: 'supportedSchoolSocial',
    data: 0,
  },
  {
    name: '(S.7) I believe student social-emotional needs (virtual school) are supported.',
    id: 'supportedVirtualSocial',
    data: 0,
  },
  {
    name:
      '(S.8) I believe students have adequate learning materials using the current delivery model(s).',
    id: 'adequateMaterials',
    data: 0,
  },
  {
    name: '(S.9) I believe students have technology tools to effectively access their education.',
    id: 'technologyToolsEffectively',
    data: 0,
  },
];

export const DOMAINS = [
  // {
  //   path: 'social-emotional',
  //   href: '/covid-19-domains/social-emotional',
  //   name: 'Social-Emotional',
  //   id: 'socialEmotional',
  //   message: SOCIAL_EMOTIONAL_MESSAGE,
  //   indicators: SOCIAL_EMOTIONAL_INDICATORS,
  //   isCustom: false,
  // },
  // {
  //   path: 'instructional-design',
  //   href: '/covid-19-domains/instructional-design',
  //   name: 'Instructional Design',
  //   id: 'instructionalDesign',
  //   message: INSTRUCTIONAL_DESIGN_MESSAGE,
  //   indicators: INSTRUCTIONAL_DESIGN_INDICATORS,
  //   isCustom: false,
  // },
  // {
  //   path: 'instructional-collaboration',
  //   href: '/covid-19-domains/instructional-collaboration',
  //   name: 'Instructional Collaboration',
  //   id: 'instructionalCollaboration',
  //   message: INSTRUCTIONAL_COLLABORATION_MESSAGE,
  //   indicators: INSTRUCTIONAL_COLLABORATION_INDICATORS,
  //   isCustom: false,
  // },
  // {
  //   path: 'resources',
  //   href: '/covid-19-domains/resources',
  //   name: 'Resources',
  //   id: 'resources',
  //   // icon: resourceIcon,
  //   message: RESOURCES_MESSAGE,
  //   indicators: RESOURCES_INDICATORS,
  //   isCustom: false,
  // },
  // {
  //   path: 'professional-learning',
  //   href: '/covid-19-domains/professional-learning',
  //   name: 'Professional Learning',
  //   id: 'professionalLearning',
  //   message: PROFESSIONAL_LEARNING_MESSAGE,
  //   indicators: PROFESSIONAL_LEARNING_INDICATORS,
  //   isCustom: false,
  // },
  // {
  //   path: 'teacher-health',
  //   href: '/covid-19-domains/teacher-health',
  //   name: 'Teacher Health',
  //   icon: TeacherHealthIcon,
  //   id: 'teacherHealth',
  //   message: TEACHER_HEALTH_MESSAGE,
  //   indicators: TEACHER_HEALTH_INDICATORS,
  //   isCustom: true,
  // },
  // {
  //   path: 'student-support',
  //   href: '/covid-19-domains/student-support',
  //   name: 'Student Support',
  //   icon: StudentSupportIcon,
  //   id: 'studentSupport',
  //   message: STUDENT_SUPPORT_MESSAGE,
  //   indicators: STUDENT_SUPPORT_INDICATORS,
  //   isCustom: true,
  // },
];

export const CUSTOM_DOMAINS = [
  // {
  //   path: 'teacher-health',
  //   href: '/my-voice/teacher-health',
  //   name: 'Teacher Health',
  //   icon: TeacherHealthIcon,
  //   id: 'teacherHealth',
  //   message: TEACHER_HEALTH_MESSAGE,
  //   indicators: TEACHER_HEALTH_INDICATORS,
  // },
  // {
  //   path: 'student-support',
  //   href: '/my-voice/student-support',
  //   name: 'Student Support',
  //   icon: StudentSupportIcon,
  //   id: 'studentSupport',
  //   message: STUDENT_SUPPORT_MESSAGE,
  //   indicators: STUDENT_SUPPORT_INDICATORS,
  // },
];

export const ALL_DOMAIN_GRAPH_OPTIONS = {
  chart: {
    type: 'line',
    zoom: {
      enabled: false,
    },
    toolbar: {
      export: {
        csv: {
          filename: undefined,
        },
        svg: {
          filename: undefined,
        },
        png: {
          filename: undefined,
        },
      },
    },
  },
  legend: {
    position: 'top',
    showForSingleSeries: true,
    markers: {
      width: 15,
      height: 15,
      radius: 0,
    },
    fontSize: '15px',
    fontFamily: 'Open Sans',
  },
  plotOptions: {
    bar: {
      horizontal: false,
      endingShape: 'rounded',
      columnWidth: '40%',
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
  },
  xaxis: {
    labels: {
      show: true,
      rotate: -55,
      rotateAlways: true,
      minHeight: 100,
      maxHeight: 180,
    },
    categories: DOMAINS.map((domain) => domain.name),
  },
  yaxis: {
    max: 5,
    min: 0,
  },
  colors: [theme.palette.grpahColors.primary, theme.palette.grpahColors.secondary],
  fill: {
    colors: [theme.palette.grpahColors.primary, theme.palette.grpahColors.secondary],
  },
};
