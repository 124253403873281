import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import AdvanceIcon from '../../../shared/assets/icons/teacher-advancement.svg';
import { updateIndicator, updateIndicators } from '../my-voice-actions';
import { ADVANCEMENT_INDICATORS, INITIAL_ADVANCEMENT_INDICATORS } from '../my-voice-models';
import { DriverDetailsForm } from '../components/DriversDetailsForm';

class DriverAdvancementView extends Component {
  constructor(props) {
    super(props);
    const data = this.props.data;
    const indicators = updateIndicators(
      R.clone(this.props.isInitial ? INITIAL_ADVANCEMENT_INDICATORS : ADVANCEMENT_INDICATORS),
      data,
    );
    this.state = {
      indicators,
    };
  }

  onClickStep = (indicatorId, indicatorValue) => {
    const indicators = updateIndicator(this.state.indicators, indicatorId, indicatorValue);
    this.setState({ indicators });
  };

  onSubmit = () => {
    this.props.onSubmit(this.state.indicators);
  };

  render() {
    const { indicators } = this.state;
    const { submitText } = this.props;
    return (
      <DriverDetailsForm
        icon={AdvanceIcon}
        title="Teacher Advancement"
        message="Opportunities are created for teachers to be leaders and experts in their fields.
        Career advancement is fostered through professional learning and innovations that
        lead to new professional opportunities"
        indicators={indicators}
        onChangeIndicator={(id, stepNumber) => this.onClickStep(id, stepNumber)}
        submitText={submitText}
        onSubmit={this.onSubmit}
      />
    );
  }
}

DriverAdvancementView.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  // bodyClass: PropTypes.string,
  submitText: PropTypes.string,
  isInitial: PropTypes.bool,
};

DriverAdvancementView.defaultProps = {
  submitText: 'Update',
  isInitial: false,
};

export default DriverAdvancementView;
