import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a My VOICE error.
 *
 * @type {string}
 */
export const MY_DASHBOARD_ERROR_EVENT = 'MY_DASHBOARD_ERROR_EVENT';
export const MY_DASHBOARD_TEACHERS_EVENT = 'MY_DASHBOARD_TEACHERS_EVENT';
export const SCHOOL_MONTHLY_AVERAGE_EVENT = 'SCHOOL_MONTHLY_AVERAGE_EVENT';
export const SCHOOL_AVERAGE_UPDATE_EVENT = 'SCHOOL_AVERAGE_UPDATE_EVENT';
export const MY_CLF_DASHBOARD_TEACHERS_EVENT = 'MY_CLF_DASHBOARD_TEACHERS_EVENT';
export const MY_CLF_DASHBOARD_TEACHERS_ERROR = 'MY_CLF_DASHBOARD_TEACHERS_ERROR';

class MyDashboardStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(MY_DASHBOARD_ERROR_EVENT);
    this.addEvent(MY_DASHBOARD_TEACHERS_EVENT);
    this.addEvent(SCHOOL_MONTHLY_AVERAGE_EVENT);
    this.addEvent(SCHOOL_AVERAGE_UPDATE_EVENT);
    this.addEvent(MY_CLF_DASHBOARD_TEACHERS_EVENT);
    this.addEvent(MY_CLF_DASHBOARD_TEACHERS_ERROR);
  }
}

const myDashboardStore = new MyDashboardStore();

export { myDashboardStore };
