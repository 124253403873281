import React, { Component } from 'react';
import * as R from 'ramda';
// import { Row, Col, Media, Button } from 'reactstrap';
import PropTypes from 'prop-types';
// import IndicatorStepper from '../components/IndicatorStepper';
// import H2Blue from '../../../components/H2Blue';
// //icon
import EmpowermentIcon from '../../../shared/assets/icons/empowerment.svg';
// import DriverIcon from '../../../components/DriverIcon';
// import DriverText from '../../../components/DriverText';
import { updateIndicator, updateIndicators } from '../my-voice-actions';
import { EMPOWERMENT_INDICATORS, INITIAL_EMPOWERMENT_INDICATORS } from '../my-voice-models';
import { DriverDetailsForm } from '../components/DriversDetailsForm';

class DriverEmpowermentView extends Component {
  constructor(props) {
    super(props);
    const data = this.props.data;
    const indicators = updateIndicators(
      R.clone(this.props.isInitial ? INITIAL_EMPOWERMENT_INDICATORS : EMPOWERMENT_INDICATORS),
      data,
    );
    this.state = {
      indicators,
    };
  }

  onClickStep = (indicatorId, indicatorValue) => {
    const indicators = updateIndicator(this.state.indicators, indicatorId, indicatorValue);
    this.setState({ indicators });
  };

  onSubmit = () => {
    this.props.onSubmit(this.state.indicators);
  };

  render() {
    const { indicators } = this.state;
    const { submitText } = this.props;
    return (
      <DriverDetailsForm
        icon={EmpowermentIcon}
        title="Empowerment"
        message="Teachers have the responsibility to make individual and collective decisions that
        impact the school and classroom. Teacher voice and expertise are valued as an
        integral part of solving problems, developing school improvement processes, and
        planning their own professional growth."
        indicators={indicators}
        onChangeIndicator={(id, stepNumber) => this.onClickStep(id, stepNumber)}
        submitText={submitText}
        onSubmit={this.onSubmit}
      />
    );
  }
}

DriverEmpowermentView.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  isInitial: PropTypes.bool,
};

DriverEmpowermentView.defaultProps = {
  submitText: 'Update',
  isInitial: false,
};

export default DriverEmpowermentView;
