import React, { Component } from 'react';
import ResourceIcon from '../../../shared/assets/icons/resource-priorities.svg';
import { updateIndicator, updateIndicators } from '../my-voice-actions';
import * as R from 'ramda';
import {
  INITIAL_RESOURCE_INDICATORS,
  RESOURCE_INDICATORS,
  INITIAL_RESOURCE_INDICATORS_LEADER,
} from '../my-voice-models';
import PropTypes from 'prop-types';
import { USER_TYPE_LEADER } from '../../../shared/userTypes';
import { DriverDetailsForm } from '../components/DriversDetailsForm';

class DriverResourceView extends Component {
  constructor(props) {
    super(props);
    const data = this.props.data;
    const indicators = updateIndicators(
      R.clone(
        this.props.isInitial
          ? this.props.user.userType === USER_TYPE_LEADER
            ? INITIAL_RESOURCE_INDICATORS_LEADER
            : INITIAL_RESOURCE_INDICATORS
          : RESOURCE_INDICATORS,
      ),
      data,
    );
    this.state = {
      indicators,
    };
  }

  onClickStep = (indicatorId, indicatorValue) => {
    const indicators = updateIndicator(this.state.indicators, indicatorId, indicatorValue);
    this.setState({ indicators });
  };

  onSubmit = () => {
    this.props.onSubmit(this.state.indicators);
  };

  render() {
    const { indicators } = this.state;
    const { submitText } = this.props;
    return (
      <DriverDetailsForm
        icon={ResourceIcon}
        title="Resource Priorities"
        message="Teacher strengths and students needs are aligned in developing schedules that
        maximize teachers’ skills. Shared selection of resources are leveraged to activate
        innovation and support teachers in meeting the varied needs of students."
        indicators={indicators}
        onChangeIndicator={(id, stepNumber) => this.onClickStep(id, stepNumber)}
        submitText={submitText}
        onSubmit={this.onSubmit}
      />
    );
  }
}

DriverResourceView.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  isInitial: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  user: PropTypes.object,
};

DriverResourceView.defaultProps = {
  submitText: 'Update',
  isInitial: false,
};

export default DriverResourceView;
