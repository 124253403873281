import React from 'react';
import PropTypes from 'prop-types';

/**
 * Return List of options.
 *
 * @param list - Array of options.
 * @returns {*} - Array of options components.
 * @class
 */
const OptionList = ({ list }) => {
  return list.map((item, index) => (
    <option key={index} value={item.hasOwnProperty('value') ? item.value : item.name}>
      {item.name}
    </option>
  ));
};

OptionList.propTypes = {
  list: PropTypes.array.isRequired,
};

export default OptionList;
