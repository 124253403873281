import Flux from '@cobuildlab/flux-state';
import firebase from 'firebase';
import crypto from 'crypto';
import moment from 'moment';
import {
  ADD_USER_ERROR_EVENT,
  ADD_USER_EVENT,
  CLEAR_INVENTORY_ERROR_EVENT,
  CLEAR_INVENTORY_EVENT,
  SUPER_ADMIN_ADD_SCHOOL_ERROR,
  SUPER_ADMIN_ADD_SCHOOL_EVENT,
  SUPER_ADMIN_ALL_DISTRICTS_EVENT,
  SUPER_ADMIN_ALL_SCHOOLS_EVENT,
  SUPER_ADMIN_EDIT_SCHOOL_ERROR,
  SUPER_ADMIN_EDIT_SCHOOL_EVENT,
  SUPER_ADMIN_REGIONS,
  SUPER_ADMIN_SCHOOLS_EVENT,
  SUPER_ADMIN_USERS_EVENT,
  UPDATE_USER_ERROR_EVENT,
  UPDATE_USER_EVENT,
  ADD_OPEN_REGISTRATION_EVENT,
  ADD_OPEN_REGISTRATION_ERROR,
  EXTEND_OPEN_REGISTRATION_EVENT,
  EXTEND_OPEN_REGISTRATION_ERROR,
  STOP_OPEN_REGISTRATION_EVENT,
  STOP_OPEN_REGISTRATION_ERROR,
  GET_OPEN_REGISTRATION_EVENT,
  GET_OPEN_REGISTRATION_ERROR,
  SUPER_ADMIN_USERS_CLF_EVENT,
  SUPER_ADMIN_USERS_CLF_ERROR,
  SUPER_ADMIN_DELETE_USER_EVENT,
  SUPER_ADMIN_DELETE_USER_ERROR,
  SUPER_ADMIN_DISTRICTS_EVENT,
  SUPER_ADMIN_DISTRICTS_VIEW_EVENT,
  SUPER_ADMIN_DISTRICTS_VIEW_ERROR,
  SUPER_ADMIN_ADD_DISTRICT_ERROR,
  SUPER_ADMIN_ADD_DISTRICT_EVENT,
  SUPER_ADMIN_ADD_REGION_ERROR,
  SUPER_ADMIN_ADD_REGION_EVENT,
  SUPER_ADMIN_EXCEL_REPORTS_ERROR,
  SUPER_ADMIN_EXCEL_REPORTS_EVENT,
  CLEAR_DISTRICT_INVENTORY_EVENT,
  CLEAR_DISTRICT_INVENTORY_ERROR_EVENT,
} from './super-admin-store';
import { filterQuery, getFilterUsers } from '../roster-manager/roster-manager-actions';
import { USER_TYPE_LEADER, USER_TYPE_TEACHER } from '../../shared/userTypes';
import { MAX_EXCEL_PARAMETERS } from '../../shared/constanst';
import {
  columnsCLFReport,
  columnsDistrictReport,
  columnsSchoolReport,
  columnsUserReport,
  concatFilters,
  splitArray,
  transformDataReport,
} from './super-admin-utils';
import { createXlsxFile } from '../../shared/fileExport';

/**
 * @param {object} filters - Filters.
 * @param {number} limit - Limit number of results.
 * @param {object} startAfterUser - Start After object.
 * @param {Array<any>} lastDocs - Last docs.
 * @returns {Promise<*>} - User List.
 */
export async function searchUsersSuperAdmin(
  filters = {},
  limit = 50,
  startAfterUser,
  lastDocs = [],
) {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');
  const schoolsCollection = DB.collection('schools');

  const schoolIDs = [];
  const schoolsByIDs = {};

  let usersQuery = usersCollection;

  const { docs: userDocs, isMore } = await getFilterUsers(
    usersQuery,
    filters,
    limit,
    startAfterUser,
    lastDocs,
    true,
  );

  const users = userDocs.map((ref) => ({ ...ref.data(), id: ref.id }));

  const schoolRefs = await Promise.all(
    schoolIDs.map((schoolID) => schoolsCollection.doc(schoolID).get()),
  );

  schoolRefs.forEach((schoolRef) => {
    if (schoolRef.exists) {
      const school = schoolRef.data();
      const schoolName = school.name.toLowerCase();
      if (filters.school && !schoolName.startsWith(filters.school.toLowerCase())) {
        return;
      }
      schoolsByIDs[schoolRef.id] = school;
    }
  });

  await setLastLoginToUsers(users);

  //ADD THE ID OF REGION AND ID DISTRICT TO EACH USER
  await searchUserRegionDistric(users);

  const lastUser = users.lastItem;

  Flux.dispatchEvent(SUPER_ADMIN_USERS_EVENT, {
    users,
    startAfter: lastUser,
    isMore,
    clear: !startAfterUser,
  });

  return {
    users,
    startAfter: lastUser,
    isMore,
    clear: !startAfterUser,
  };
}

/**
 * Returns the array users with the id of region and id of district of each school.
 *
 * @param {Array} users
 * @returns {Array}
 *
 */

export const searchUserRegionDistric = async (users = []) => {
  const DB = firebase.firestore();
  const schoolCollection = await DB.collection('schools').get();
  const regionsCollection = await DB.collection('regions').get();

  users.forEach((user) => {
    schoolCollection.docs.forEach((element) => {
      if (user.schoolId === element.id) {
        user.regionId = element.data().regionId;
      }
    });
  });

  users.forEach((user) => {
    regionsCollection.docs.forEach((element) => {
      if (user.regionId === element.id) {
        user.districtId = element.data().districtId;
      }
    });
  });

  return users;
};

/**
 * Set lastLoggedIn parameters to objects in array.
 *
 * @param {Array} users - Array of users objects.
 * @returns {Promise<undefined[]>} - Resolves to logInLogs references.
 *
 */
export function setLastLoginToUsers(users) {
  // PROMISE LOGS ARRAY
  const DB = firebase.firestore();
  const logInLogsCollection = DB.collection('logInLogs');
  const responses = users.map(async (user) => {
    const { docs: logDoc } = await logInLogsCollection
      .where('email', '==', user.email || '')
      .orderBy('date', 'desc')
      .limit(1)
      .get();

    if (logDoc.length) {
      const log = logDoc[0].data();
      const lastLoggedInMoment = moment(log.date.toDate());
      user.lastLoggedIn = lastLoggedInMoment.format('MM/DD/YYYY');
      return log;
    }
    return null;
  });
  return Promise.all(responses);
}

export const fetchAllSchools = async () => {
  const DB = firebase.firestore();
  const schoolsCollection = await DB.collection('schools').get();

  const schools = schoolsCollection.docs.map((doc) => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
    };
  });

  Flux.dispatchEvent(SUPER_ADMIN_ALL_SCHOOLS_EVENT, schools);
  return schools;
};

/**
 * @param filters
 * @param limit
 * @param startAfter
 * @returns {Promise<{startAfter: *, isMore: boolean, users: firebase.firestore.DocumentData[]}>}
 */

export const getSchoolsAction = async (
  filters,
  limit = 50,
  startAfterSchoolName = null,
  lastDocs = [],
  dispatchEvent = true,
) => {
  const DB = firebase.firestore();
  const schoolsCollection = DB.collection('schools');

  let allSchools = [];
  let allSchoolsMap = {};
  let lastSchoolName = startAfterSchoolName;
  let isMore = false;
  let alreadyExistBySchoolId = false;

  const lastDocsMap = {};
  for (const doc of lastDocs) {
    if (!alreadyExistBySchoolId && filters.school && doc.id === filters.school) {
      alreadyExistBySchoolId = true;
    }

    if (lastDocsMap[doc.id]) continue;
    lastDocsMap[doc.id] = doc;
  }

  const defaultQuery = schoolsCollection
    .orderBy('name')
    .startAfter(lastSchoolName || 0)
    .limit(limit);

  const filterSchoolStartWith = (schools = []) =>
    schools.filter(
      (school) =>
        compareStartWith(school.name, filters.school) ||
        compareStartWith(school.id, filters.school),
    );

  const filterSchoolWithOnlyActive = (schools = []) => {
    return schools.filter((school) => {
      if (filters.onlyActive === 'active') {
        return school.active !== false;
      }
      if (filters.onlyActive === 'inactive') {
        return school.active === false;
      }
      return true;
    });
  };

  const filterSchoolByIsActiveOpReg = (schools = []) =>
    schools.filter((school) => {
      if (school.openRegistrationExpireDate)
        return isActiveOpenRegistration(school.openRegistrationExpireDate.toDate());
      return false;
    });

  const filterByDistrictId = (schools = []) =>
    schools.filter((school) => {
      if (filters.containsDistrictIds) {
        return filters.containsDistrictIds.includes(school.regionDistrictId);
      } else {
        return filters.districtId === school.regionDistrictId;
      }
    });

  const filterSchoolBySchoolType = (schools = []) =>
    schools.filter((school) => school.schoolType === filters.schoolType);

  const filterSchoolByTeachersCount = (schools) =>
    schools.filter((school) => {
      const max = Number(filters.maxTeachersNumbers);
      const min = Number(filters.minTeachersNumbers);
      let filter = school.teachersNumbers >= min;
      if (filter && max >= min) {
        filter = school.teachersNumbers <= max;
      }
      return filter;
    });

  const validNotExistDoc = (doc) => !lastDocsMap[doc.id] && !allSchoolsMap[doc.id];

  const pushDoc = (doc) => {
    const school = { ...doc.data(), id: doc.id };
    allSchoolsMap[school.id] = doc.id;
    allSchools.push(school);
  };

  const defaultFilterQuery = async (func, fields, value, reverChart, operator) =>
    func(schoolsCollection, fields, value, limit, lastSchoolName, reverChart, operator);

  const pushFilterQuery = async (func, fields, value, reverChart, operator) => {
    const data = await defaultFilterQuery(func, fields, value, reverChart, operator);
    data.result.docs.filter(validNotExistDoc).forEach(pushDoc);
    isMore = isMore || data.result.docs.length >= limit;
  };

  const pushByShoolId = async () => {
    const doc = await schoolsCollection.doc(filters.school).get();

    if (doc.exists && validNotExistDoc(doc)) {
      pushDoc(doc);
      alreadyExistBySchoolId = true;
    }
  };

  if (
    filters.school ||
    filters.districtId ||
    filters.schoolType ||
    filters.containsDistrictIds ||
    filters.onlyRegistrations ||
    filters.minTeachersNumbers ||
    filters.maxTeachersNumbers ||
    filters.onlyActive
  ) {
    const maxIterationCount = 5;
    let iterationCount = 0;
    do {
      const iterationPromises = [];
      isMore = false;

      if (filters.school) {
        if (!alreadyExistBySchoolId) {
          iterationPromises.push(pushByShoolId());
        }
        iterationPromises.push(pushFilterQuery(filterQueryLike, 'name', filters.school));
      }

      if (filters.districtId) {
        iterationPromises.push(
          pushFilterQuery(filterQuery, 'regionDistrictId', filters.districtId),
        );
      } else if (filters.containsDistrictIds && filters.containsDistrictIds.length) {
        filters.containsDistrictIds.forEach((districtId) => {
          iterationPromises.push(pushFilterQuery(filterQuery, 'regionDistrictId', districtId));
        });
      }

      if (filters.schoolType) {
        iterationPromises.push(pushFilterQuery(filterQuery, 'schoolType', filters.schoolType));
      }

      if (filters.onlyActive === 'inactive') {
        iterationPromises.push(pushFilterQuery(filterQuery, 'active', false));
      }

      if (iterationPromises.length) await Promise.all(iterationPromises);
      else {
        const result = await defaultQuery.get();
        allSchools = result.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        isMore = result.size === limit;
      }

      if (filters.school) allSchools = filterSchoolStartWith(allSchools);

      if (filters.districtId || filters.containsDistrictIds)
        allSchools = filterByDistrictId(allSchools);

      if (filters.schoolType) allSchools = filterSchoolBySchoolType(allSchools);

      if (filters.onlyRegistrations) allSchools = filterSchoolByIsActiveOpReg(allSchools);

      if (filters.onlyActive) allSchools = filterSchoolWithOnlyActive(allSchools);

      //ADD THE NUMBER OF TEACHERS FROM EACH SCHOOL
      allSchools = await searchSchoolsTeachersNumbers(allSchools);

      if (filters.minTeachersNumbers || filters.maxTeachersNumbers)
        allSchools = filterSchoolByTeachersCount(allSchools);

      lastSchoolName = allSchools.lastItem ? allSchools.lastItem.name : null;

      iterationCount += 1;
      if (iterationCount === maxIterationCount && !allSchools.length) isMore = false;
    } while (allSchools.length < limit && isMore && iterationCount < maxIterationCount);

    if (allSchools.length > limit) {
      allSchools = allSchools.slice(0, limit);
      ({ name: lastSchoolName } = allSchools.lastItem);
    }
  } else {
    const result = await defaultQuery.get();
    allSchools = result.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    allSchools = await searchSchoolsTeachersNumbers(allSchools);
    isMore = result.size === limit;
    lastSchoolName = allSchools.lastItem ? allSchools.lastItem.name : null;
  }
  if (dispatchEvent) {
    Flux.dispatchEvent(SUPER_ADMIN_SCHOOLS_EVENT, {
      schools: allSchools,
      startAfterSchoolName: lastSchoolName,
      isMore,
      clear: !startAfterSchoolName,
    });
  }
  return {
    schools: allSchools,
    startAfter: lastSchoolName,
    isMore,
    clear: !startAfterSchoolName,
  };
};

/**
 * Returns the array schools with the sum of the teachers of each school.
 *
 * @param {Array} schools
 * @returns {Array}
 *
 */

export const searchSchoolsTeachersNumbers = async (schools = []) => {
  const getSchoolsTeacherNumber = firebase.functions().httpsCallable('getSchoolsTeacherNumber');
  const result = await getSchoolsTeacherNumber({ schools });
  result.data.schools.forEach((school, i) => {
    Object.assign(schools[i], {
      teachersNumbers: school.teachersNumbers || 0,
    });
  });
  return schools;
};

/**
 *
 * @param user - The date from user to create.
 * @returns {Promise<*>}
 *
 */

export const createUserSuperAdmin = async (user) => {
  try {
    const createdUser = await createUser(user);
    Flux.dispatchEvent(ADD_USER_EVENT, createdUser);
    return user;
  } catch (e) {
    Flux.dispatchEvent(ADD_USER_ERROR_EVENT, e.message);
    console.error(e);
  }
};

/**
 * Function to create a user for login.
 *
 * @param {string} email - User Email.
 * @returns {Promise<boolean|firebase.functions.HttpsCallableResult>} - CreateUser function.
 */
export async function createUserWithEmailAdmin(email) {
  const createUserFunc = firebase.functions().httpsCallable('createUserWithEmail');
  try {
    return await createUserFunc({ email, password: makePassword(10) });
  } catch (e) {
    return false;
  }
}

/**
 * Function to create a key receiving the size.
 *
 * @param {number} length - Length.
 * @returns {string} - Result.
 */
const makePassword = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/**
 * Updates a User of the Platform.
 *
 * @param {object} user - A User data.
 * @returns {Promise<object|void>} The data of the User.
 */
export const updateUser = async (user) => {
  const DB = firebase.firestore();
  const schoolsCollection = DB.collection('schools');
  const districtsCollection = DB.collection('districts');

  let newUserData;
  try {
    const { schoolId, schoolIds, districtId, districtIds } = user;
    if (user.active) {
      if (schoolId) {
        const schoolRef = await schoolsCollection.doc(schoolId).get();
        const selectedSchool = schoolRef.data();

        if (selectedSchool.active === false) {
          throw new Error('You cannot activate a user with a inactive school');
        }
      } else if (schoolIds.length) {
        const splittedSchoolsRef = await Promise.all(
          splitArray(schoolIds).map(
            async (schoolIdsChunk) =>
              await (
                await schoolsCollection
                  .where(firebase.firestore.FieldPath.documentId(), 'in', schoolIdsChunk)
                  .get()
              ).docs,
          ),
        );
        const inactiveSchool = splittedSchoolsRef.flat().find((ref) => {
          const school = ref.data();
          return school.active === false;
        });
        if (inactiveSchool) throw new Error('You cannot activate a user with a inactive school');
      } else if (districtId) {
        const districtDoc = await districtsCollection.doc(districtId).get();
        const selectedDistrict = districtDoc.data();
        if (selectedDistrict.active === false) {
          throw new Error('You cannot activate a user with a inactive district');
        }
      } else if (districtIds.length) {
        const districts = await Promise.all(
          districtIds.map(async (districtId) => {
            const districtDoc = await districtsCollection.doc(districtId).get();
            const selectedDistrict = districtDoc.data();
            return selectedDistrict;
          }),
        );
        let isAllDistrictInactive = true;
        for (let i = 0; i < districts.length; i++) {
          if (!isAllDistrictInactive) break;
          const district = districts[i];
          isAllDistrictInactive = !district.active;
        }
        if (isAllDistrictInactive) {
          throw new Error('You cannot activate a user with inactive all districts');
        }
      }
    }
    newUserData = await editUserFirebase(user);
  } catch (e) {
    return Flux.dispatchEvent(UPDATE_USER_ERROR_EVENT, e.message);
  }

  Flux.dispatchEvent(UPDATE_USER_EVENT, newUserData);
  return newUserData;
};

/**
 * Updates a User on firebase.
 *
 * @param {object} user - User data.
 * @returns {Promise<object>} Updated User Object.
 */
export async function editUserFirebase(user) {
  return await saveUserInDB(user);
}

/**
 * @param {object} school - School Object data.
 * @param {File} logoImage - Logo Image.
 */
export const createSchoolAction = async (school, logoImage) => {
  const DB = firebase.firestore();
  const doc = await DB.collection('schools')
    .doc(school.schoolId)
    .get();

  if (doc.exists) {
    Flux.dispatchEvent(SUPER_ADMIN_ADD_SCHOOL_ERROR, 'school id already exist');
    return;
  }
  const latLon = new firebase.firestore.GeoPoint(school.latitude, school.longitude);

  if (logoImage) {
    try {
      school.logoUrl = await uploadSchoolLogo(logoImage, school.schoolId);
    } catch (e) {
      Flux.dispatchEvent(
        SUPER_ADMIN_ADD_SCHOOL_ERROR,
        "Can't create new school: Error uploading logo",
      );
      return;
    }
  }

  const newSchool = {
    name: school.name,
    active: school.active,
    message: school.message,
    logoUrl: school.logoUrl,
    address1: school.address,
    regionId: school.regionId,
    schoolType: school.schoolType,
    requireInventory: school.requireInventory,
    latLon,
  };

  const schoolsCollection = DB.collection('schools');
  const schoolRef = schoolsCollection.doc(school.schoolId);
  try {
    await schoolRef.set(newSchool, { merge: true });
    Flux.dispatchEvent(SUPER_ADMIN_ADD_SCHOOL_EVENT);
  } catch (error) {
    Flux.dispatchEvent(SUPER_ADMIN_ADD_SCHOOL_ERROR, 'Error creating school');
  }
};

/**
 * @param {object} school - School Object data.
 * @param {File} logoImage - URL of the logo.
 */
export const editSchoolAction = async (school, logoImage) => {
  const DB = firebase.firestore();
  const schoolsCollection = DB.collection('schools');
  const districtCollection = DB.collection('districts');

  const latLon = new firebase.firestore.GeoPoint(school.latitude, school.longitude);
  if (logoImage) {
    try {
      school.logoUrl = await uploadSchoolLogo(logoImage, school.schoolId);
    } catch (e) {
      console.log(e);
      Flux.dispatchEvent(
        SUPER_ADMIN_EDIT_SCHOOL_ERROR,
        "Can't create new school: Error uploading logo",
      );
      return;
    }
  }

  const selectedDistrictRef = await districtCollection.doc(school.districtId).get();
  const selectedDistrict = selectedDistrictRef.data();

  if (selectedDistrict.active === false && school.active) {
    return Flux.dispatchEvent(
      SUPER_ADMIN_EDIT_SCHOOL_ERROR,
      'You cannot activate a school with a inactive district',
    );
  }

  const editSchool = {
    name: school.name,
    active: school.active,
    message: school.message,
    logoUrl: school.logoUrl,
    address1: school.address,
    regionId: school.regionId,
    schoolType: school.schoolType,
    requireInventory: school.requireInventory,
    latLon,
  };

  const schoolRef = schoolsCollection.doc(school.schoolId);
  try {
    await schoolRef.set(editSchool, { merge: true });
    Flux.dispatchEvent(SUPER_ADMIN_EDIT_SCHOOL_EVENT);
  } catch (error) {
    console.log(error);
    Flux.dispatchEvent(SUPER_ADMIN_EDIT_SCHOOL_ERROR, 'Error editing school');
  }
};

export const getRegions = async () => {
  const DB = firebase.firestore();
  const query = await DB.collection('regions').get();
  const regions = query.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  Flux.dispatchEvent(SUPER_ADMIN_REGIONS, regions);
  return regions;
};

/**
 *
 * @param {File} image - Image file to be uploaded.
 * @param {string} fileName - File name of the image.
 * @returns {Promise<string|any>} - Returns the Download URL from firebase.
 */
export const uploadUserProfileImage = async (image, fileName) => {
  return await uploadFileToFireStorage(image, `/usersImages/${fileName}`);
};

/**
 * Upload School logo to fireStorage.
 *
 * @param {File} image - Image file to be uploaded.
 * @param {string} schoolId - School Id to reference the image with.
 * @returns {Promise<string|any>} - Returns the Download URL from firebase.
 */
export const uploadSchoolLogo = async (image, schoolId) => {
  return await uploadFileToFireStorage(image, `/schoolLogoImages/${schoolId}`);
};

/**
 * Upload file to fireStorage.
 *
 * @param {File | Blob} file - File to be uploaded.
 * @param {string} filePath - Path of the image.
 * @returns {Promise<string>} - Returns the Download URL from firebase.
 */
export const uploadFileToFireStorage = async (file, filePath) => {
  const storage = firebase.storage();
  const storageRef = storage.ref(filePath);
  const task = await storageRef.put(file);
  return await task.ref.getDownloadURL();
};

export const createUser = async (user) => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');

  const userRef = usersCollection.doc(user.email.toLowerCase());
  const userByEmailId = await userRef.get();

  const userByEmail = await usersCollection
    .where('email', '==', user.email.toLowerCase())
    .limit(1)
    .get();

  if (userByEmailId.exists || userByEmail.size) {
    throw new Error('Email already exists');
  }

  const flagTry = await createUserWithEmailAdmin(user.email.toLowerCase());
  if (!flagTry) {
    throw new Error('Unexpected Error');
  }

  const newUserData = await saveUserInDB(user);

  return newUserData;
};

/**
 * @param {object} user - User.
 */
export async function saveUserInDB(user) {
  const db = firebase.firestore();
  const usersCollection = db.collection('users');

  const {
    active = true,
    firstName,
    lastName,
    needsPasswordReset = true,
    needsProfile = true,
    schoolId,
    districtId = null,
    districts = null,
    districtIds = null,
    schoolIds = null,
    userType = USER_TYPE_TEACHER,
    isRosterManager = false,
    image,
    originalEmail = null,
    picture = null,
    schoolRegionId,
  } = user;

  const email = String(user.email)
    .trim()
    .toLowerCase();

  let imageUrl = picture;
  if (image) {
    imageUrl = await uploadUserProfileImage(image, email);
  }

  const regionId = schoolRegionId ? schoolRegionId : null;

  const newUserData = {
    districtId,
    picture: imageUrl,
    active,
    email,
    firstName: String(firstName).trim(),
    lastName: String(lastName).trim(),
    needsPasswordReset,
    needsProfile,
    schoolId,
    userType,
    isRosterManager,
    districts,
    districtIds,
    schoolIds,
    schoolRegionId: regionId,
  };

  if (originalEmail && originalEmail !== email) {
    const updateUserEmail = firebase.functions().httpsCallable('updateUserEmail');
    try {
      await updateUserEmail({ oldEmail: originalEmail, user: newUserData });
    } catch (e) {
      throw new Error(e.message);
    }
  } else {
    let userRef = usersCollection.doc(email);

    // Validate if it exists
    const userDoc = await userRef.get();
    if (!userDoc.exists) {
      // If a user already exists with this email, use its document.
      const userByEmail = await usersCollection
        .where('email', '==', email)
        .limit(1)
        .get();
      if (userByEmail.size) {
        userRef = userByEmail.docs[0].ref;
      }
    }

    try {
      await userRef.set(newUserData, { merge: true });
    } catch (e) {
      console.error(e);
      throw new Error('Error saving user.');
    }
  }

  return newUserData;
}

/**
 * Add Query ability to filter docs by text and wildcards.
 *
 * @param {firebase.firestore.Query} query - Firebase Query.
 * @param {object|string} fields - Object containing specific fields.
 * @param {*} value - Value of the field to query by.
 * @param {number} limit - Limit Result.
 * @param {*} startAfterValue - Start value.
 * @param {string} revertChar - Character used to determine if the value should be inverted.
 * @returns {Promise<{result: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>, isMore: boolean}>} - Firebase Query.
 */
export async function filterQueryLike(
  query,
  fields,
  value,
  limit,
  startAfterValue = null,
  revertChar = '*',
) {
  let field;
  let isReversed = false;
  if (isValidString(fields)) {
    field = fields;
  } else {
    const { normal: normalField, reversed: reversedField } = fields;
    isReversed = value.startsWith(revertChar); // revert value if it starts with revertChar.
    field = isReversed ? reversedField || normalField : normalField;
  }

  if (isReversed) {
    value = reverseString(value.substr(1));
  }

  let result;
  let isMore;
  if (startAfterValue) {
    if (field === 'email') {
      result = await query
        .orderBy('email')
        .limit(limit)
        .startAt(startAfterValue + '\uf8ff')
        .endAt(value + '\uf8ff')
        .get();
      isMore = result.size === limit;
    } else {
      result = await query
        .orderBy(field)
        .startAt(value)
        .endAt(value + '\uf8ff')
        .get();
      isMore = false;
    }
  } else {
    result = await query
      .orderBy(field)
      .limit(limit)
      .startAt(value)
      .endAt(value + '\uf8ff')
      .get();
    isMore = result.size === limit;
  }
  return { result, isMore };
}

/**
 * Reverse Strings.
 *
 * @param {string} str - String to reverse.
 * @returns {string} - Reversed string.
 */
function reverseString(str) {
  return str
    .split('')
    .reverse()
    .join('');
}

/**
 * @param {string} str - String value.
 * @returns {string} - Value type.
 */
function isValidString(str) {
  return typeof str === 'string';
}

/**
 * Returns the array of all the districts.
 *
 * @returns {Array}
 *
 */

export const getAllDistricts = async () => {
  const DB = firebase.firestore();
  const districtsCollection = await DB.collection('districts').get();

  const districts = [];

  districtsCollection.docs.forEach((doc) => {
    const data = doc.data();
    districts.push({
      id: doc.id,
      ...data,
    });
  });

  Flux.dispatchEvent(SUPER_ADMIN_ALL_DISTRICTS_EVENT, districts);
  return districts;
};

/**
 * Clear School's Inventory.
 *
 * @param {string} schoolId - Id of the school to clear its inventory.
 * @returns {Promise<void>} - Process Finished.
 */
export async function clearSchoolInventory(schoolId) {
  const db = firebase.firestore();
  const schoolsCollection = db.collection('schools');
  const usersCollection = db.collection('users');

  const schoolRef = await schoolsCollection.doc(schoolId).get();
  if (schoolRef.exists) {
    const usersInSchool = await usersCollection
      .where('userType', 'in', [USER_TYPE_TEACHER, USER_TYPE_LEADER])
      .where('schoolId', '==', schoolId)
      .get();
    const requests = usersInSchool.docs.map((doc) => {
      return doc.ref.update({ needsProfile: true });
    });

    try {
      await Promise.all(requests);
    } catch (e) {
      Flux.dispatchEvent(CLEAR_INVENTORY_ERROR_EVENT, 'Error updating users.');
      return;
    }

    Flux.dispatchEvent(CLEAR_INVENTORY_EVENT, 'School Inventory Cleared');
  } else {
    Flux.dispatchEvent(CLEAR_INVENTORY_ERROR_EVENT, "Couldn't found school");
    return;
  }
}

/**
 * Clear District School's Inventory.
 *
 * @param {string} districtId - Id of the district to clear its inventory.
 * @returns {Promise<void>} - Process Finished.
 */
export async function clearInventorySchoolsOfDistrict(districtId) {
  const ClearInventorySchoolsOfDistrictFunction = firebase
    .functions()
    .httpsCallable('clearInventorySchoolsOfDistrict');

  try {
    const { data } = await ClearInventorySchoolsOfDistrictFunction(districtId);
    Flux.dispatchEvent(CLEAR_DISTRICT_INVENTORY_EVENT, data.message);
  } catch (error) {
    Flux.dispatchEvent(CLEAR_DISTRICT_INVENTORY_ERROR_EVENT, error.message);
  }
}

/**
 * Get open registration by schoolId.
 *
 * @param {string} schoolId - SchoolId.
 * @returns {object} - Open registration.
 */
export const getOpenRegistrationBySchoolId = async (schoolId) => {
  const db = firebase.firestore();
  const schoolOpenRegistrationsCollect = db.collection('schoolOpenRegistrations');

  try {
    const response = await schoolOpenRegistrationsCollect
      .where('schoolId', '==', schoolId)
      .orderBy('expireDate', 'desc')
      .limit(1)
      .get();
    const docs = response.docs.map((doc) => {
      const docData = doc.data();
      if (typeof docData.emailDomain === 'string') {
        docData.emailDomain = [docData.emailDomain];
      }
      if (!docData.allowAllDomains) {
        docData.allowAllDomains = false;
      }
      if (docData.expireDate) {
        docData.expireDate = docData.expireDate.toDate();
      }
      return { id: doc.id, ...docData };
    });

    if (!docs.length) {
      Flux.dispatchEvent(GET_OPEN_REGISTRATION_EVENT, null);
      return;
    }

    const openRegistration = { ...docs[0] };
    Flux.dispatchEvent(GET_OPEN_REGISTRATION_EVENT, { openRegistration });
  } catch (err) {
    console.log('err :>> ', err);
    Flux.dispatchEvent(GET_OPEN_REGISTRATION_ERROR, err);
    return;
  }
};

/**
 * Get open registration by unique token.
 *
 * @param {string} token - Unique open registration Token.
 * @returns {object} - Open registration and school.
 */
export const getOpenRegistrationByToken = async (token) => {
  const getOpenRegistration = firebase.functions().httpsCallable('getOpenRegistration');

  try {
    const response = await getOpenRegistration({ token });
    Flux.dispatchEvent(GET_OPEN_REGISTRATION_EVENT, response.data);
  } catch (err) {
    console.log('err :>> ', err.message);
    Flux.dispatchEvent(GET_OPEN_REGISTRATION_ERROR, err);
    return;
  }
};

/**
 * Create Open Registration for school.
 *
 * @param {object} school - School for open registration.
 * @param {string} newOpenRegistration - Open registration data to set up.
 */
export const createOpenRegistration = async (school, newOpenRegistration) => {
  const { emailDomain, allowAllDomains } = newOpenRegistration;
  const db = firebase.firestore();
  const schoolCollection = db.collection('schools');
  const schoolOpenRegistrationsCollect = db.collection('schoolOpenRegistrations');

  let openRegistrationActive = null;
  try {
    const response = await schoolOpenRegistrationsCollect
      .where('schoolId', '==', school.id)
      .orderBy('expireDate', 'desc')
      .limit(1)
      .get();
    const docs = response.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
    openRegistrationActive = !docs.length ? null : docs[0];
  } catch (error) {
    console.log('Error on fetch open registration: ', error);
  }

  const expireDate = moment()
    .add(30, 'days')
    .toDate();
  const token = crypto.randomBytes(5).toString('hex');
  const openRegistration = {
    token,
    emailDomain,
    allowAllDomains,
    schoolId: school.id,
    isCanceled: false,
    expireDate,
  };
  const updatedSchool = {
    ...school,
    openRegistrationExpireDate: firebase.firestore.Timestamp.fromDate(expireDate),
  };
  const openRegistrationPromise =
    openRegistrationActive === null
      ? schoolOpenRegistrationsCollect.add(openRegistration)
      : schoolOpenRegistrationsCollect.doc(openRegistrationActive.id).update(openRegistration);

  const promises = [
    openRegistrationPromise,
    schoolCollection
      .doc(school.id)
      .set({ openRegistrationExpireDate: expireDate }, { merge: true }),
  ];

  try {
    const [opRegData] = await Promise.all(promises);
    openRegistration.id = opRegData ? opRegData.id : openRegistrationActive.id;
    const data = { openRegistration, school: updatedSchool };
    Flux.dispatchEvent(ADD_OPEN_REGISTRATION_EVENT, data);
  } catch (err) {
    Flux.dispatchEvent(ADD_OPEN_REGISTRATION_ERROR, err);
    return;
  }
};

export const extendOpenRegistration = async (openRegistration) => {
  const db = firebase.firestore();
  const schoolCollection = db.collection('schools');
  const schoolOpenRegistrationsCollect = db.collection('schoolOpenRegistrations');

  const expireDate = moment(openRegistration.expireDate)
    .add(30, 'days')
    .toDate();

  const updateOpenRegistration = schoolOpenRegistrationsCollect
    .doc(openRegistration.id)
    .set({ isCanceled: false, expireDate }, { merge: true });
  const updateSchool = schoolCollection
    .doc(openRegistration.schoolId)
    .set({ openRegistrationExpireDate: expireDate }, { merge: true });
  const promises = [updateOpenRegistration, updateSchool];

  try {
    await promises;
    Flux.dispatchEvent(EXTEND_OPEN_REGISTRATION_EVENT, { ...openRegistration, expireDate });
  } catch (err) {
    console.log(err);
    Flux.dispatchEvent(EXTEND_OPEN_REGISTRATION_ERROR, err.message);
  }
};

export const stopOpenRegistration = async (openRegistration) => {
  const db = firebase.firestore();
  const schoolCollection = db.collection('schools');
  const schoolOpenRegistrationsCollect = db.collection('schoolOpenRegistrations');

  const updateschoolData = { openRegistrationExpireDate: firebase.firestore.FieldValue.delete() };

  try {
    await schoolOpenRegistrationsCollect
      .doc(openRegistration.id)
      .set({ isCanceled: true }, { merge: true });
    await schoolCollection.doc(openRegistration.schoolId).set(updateschoolData, { merge: true });
    Flux.dispatchEvent(STOP_OPEN_REGISTRATION_EVENT, { ...openRegistration, isCanceled: true });
  } catch (err) {
    console.log('err :>> ', err);
    Flux.dispatchEvent(STOP_OPEN_REGISTRATION_ERROR);
  }
};

/**
 * Create user from open registration page.
 *
 * @param {object} user - User data to registration.
 * @param {string} opRegToken - Open Registration token.
 */
export const createUserFromOpenRegistration = async (user, opRegToken) => {
  const createUserByOpenRegistration = firebase
    .functions()
    .httpsCallable('createUserByOpenRegistration');
  try {
    const auxUser = { ...user, email: user.email.toLowerCase() };
    if (auxUser.image) {
      const pictureUrl = await uploadUserProfileImage(auxUser.image, auxUser.email);
      auxUser.picture = pictureUrl;
    }
    const response = await createUserByOpenRegistration({ user: auxUser, opRegToken });
    const createdUser = response.data.user;
    Flux.dispatchEvent(ADD_USER_EVENT, createdUser);
  } catch (e) {
    console.log(e);
    Flux.dispatchEvent(ADD_USER_ERROR_EVENT, e.message);
  }
};

/**
 * Validate Open registration is expired.
 *
 * @param {Date} expireDate - Open registration date.
 * @returns {boolean} - Is expired ?.
 */
export const isActiveOpenRegistration = (expireDate) => {
  const expireDateDiff = moment(expireDate).diff(moment(), 'days');
  return expireDateDiff > 0;
};

/**
 * Set Districts and Shcools parameters to objects in array.
 *
 * @param {Array} users - Array of users objects.
 * @returns {Promise<Array>} - Users with Data.
 *
 */
export const setDistrictsAndSchoolsToUsersCLF = async (users) => {
  const DB = firebase.firestore();
  const schoolsCollection = DB.collection('schools');
  const districtsCollection = DB.collection('districts');

  const districtsIdsMap = {};
  const schoolIdsMap = {};

  // MAP ALL SCHOOLS AND DISTRICTS
  users.forEach((user) => {
    user.districts.forEach(({ districtId, schools }) => {
      if (!districtsIdsMap[districtId]) districtsIdsMap[districtId] = {};
      if (schools && schools.length) {
        schools.forEach((schoolId) => {
          if (!schoolIdsMap[schoolId]) schoolIdsMap[schoolId] = {};
        });
      }
    });
  });

  const districtsIds = Object.keys(districtsIdsMap);
  const schoolsIds = Object.keys(schoolIdsMap);

  // GET DISTRICTS AND SCHOOLS DATA
  const districtDocs = await Promise.all(
    districtsIds.map((districtId) => districtsCollection.doc(districtId).get()),
  );
  const schoolDocs = await Promise.all(
    schoolsIds.map((schoolId) => schoolsCollection.doc(schoolId).get()),
  );

  // PUSH DISTRICTS AND SCHOOLS DATA TO MAPS
  districtDocs.forEach((doc) => {
    if (doc.exists) districtsIdsMap[doc.id] = { ...doc.data(), id: doc.id };
  });
  schoolDocs.forEach((doc) => {
    if (doc.exists) schoolIdsMap[doc.id] = { ...doc.data(), id: doc.id };
  });

  // PUSH DISTRICTS AND SCHOOLS DATA TO MAPS
  const fullUsers = users.map((user) => {
    const allSchools = [];
    const districts = user.districts
      .map(({ districtId, schools }, j) => {
        const district = districtsIdsMap[districtId];
        const newsSchools = schools
          .map((schoolId) => schoolIdsMap[schoolId])
          .filter((school) => Boolean(school));
        allSchools.push(...newsSchools);
        return (
          district && {
            ...district,
            schools: newsSchools,
          }
        );
      })
      .filter((district) => Boolean(district));

    return {
      ...user,
      districts: districts,
      schools: allSchools,
    };
  });

  return fullUsers;
};

/**
 * @param {object} filters - Filters.
 * @param {number} limit - Limit number of results.
 * @param {object} startAfterUser - Start After object.
 * @param {boolean} dispatchEvent - Dispatch event.
 * @returns {Promise<*>} - User List.
 */
export const searchUsersCLF = async (
  filters = {},
  limit = 50,
  startAfterUser,
  dispatchEvent = true,
) => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');
  const usersQuery = usersCollection.where('userType', '==', 'CLF');
  const { district = [], school = [], ...restFilter } = filters;
  const customFilters = {
    ...restFilter,
    containsDistrictIds: district.map((d) => d.id),
    containsSchoolIds: school.map((s) => s.id),
  };

  delete customFilters.role;

  let result;
  try {
    result = await getFilterUsers(usersQuery, customFilters, limit, startAfterUser);
  } catch (err) {
    console.log(err);
    Flux.dispatchEvent(SUPER_ADMIN_USERS_CLF_ERROR, err.message);
    return;
  }

  const users = result.docs.map((ref) => ({ ...ref.data(), id: ref.id }));
  await setLastLoginToUsers(users);
  const fullUsers = await setDistrictsAndSchoolsToUsersCLF(users);

  const data = {
    users: fullUsers,
    startAfter: users.lastItem,
    isMore: result.isMore,
    clear: !startAfterUser,
  };

  if (dispatchEvent) {
    Flux.dispatchEvent(SUPER_ADMIN_USERS_CLF_EVENT, data);
  }
  return data;
};

export const formatUserWithoutDistrictsData = (user) => ({
  ...user,
  districts: user.districts.map((d) => ({
    districtId: d.id,
    schools: d.schools.map((s) => s.id),
  })),
});

export const deleteUser = async (email) => {
  const call = firebase.functions().httpsCallable('deleteUser');

  try {
    const response = await call({ email });
    Flux.dispatchEvent(SUPER_ADMIN_DELETE_USER_EVENT, response.data);
  } catch (err) {
    Flux.dispatchEvent(SUPER_ADMIN_DELETE_USER_ERROR, err);
  }
};

/**
 *
 * @param {string} a - A Element.
 * @param {string} b - B Element.
 * @returns {boolean} -
 */
export const compareStartWith = (a, b) => a.toLowerCase().startsWith(b.toLowerCase());

export const searchDistrictsAction = async (filters, limit, startAfterDistrictName) => {
  const DB = firebase.firestore();
  const districtsCollection = DB.collection('districts');

  let allDistricts = [];
  let lastDistrictName = startAfterDistrictName;
  let isMore;

  do {
    let districts = [];
    isMore = false;

    let districtsQuery = districtsCollection.orderBy('name').limit(limit);

    if (lastDistrictName) {
      districtsQuery = districtsQuery.startAfter(lastDistrictName);
    }

    const result = await districtsQuery.get();
    districts = result.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    if (districts.length) {
      lastDistrictName = districts.lastItem.name;
      isMore = districts.length === limit;
      if (filters.district) {
        districts = districts.filter((district) =>
          compareStartWith(district.name, filters.district),
        );
      }
    }

    allDistricts = [...allDistricts, ...districts];
  } while (allDistricts.length < limit && isMore);

  if (allDistricts.length > limit) {
    allDistricts = allDistricts.slice(0, limit);
    ({ name: lastDistrictName } = allDistricts.lastItem);
  }

  Flux.dispatchEvent(SUPER_ADMIN_DISTRICTS_EVENT, {
    districts: allDistricts,
    startAfterDistrictName: lastDistrictName,
    isMore,
    clear: !startAfterDistrictName,
  });
  return allDistricts;
};

const filterDistricts = async (filters, limit, startAfterDistrict = null) => {
  const getDistricts = firebase.functions().httpsCallable('fetchAllDistricts');
  const {
    data: { districts, isMore },
  } = await getDistricts({ ...filters, limit, startAfterDistrict });
  const lastItem = districts[districts.length - 1];

  return { docs: districts, isMore, lastItem: lastItem ? lastItem.name : '' };
};

/**
 * @param {object} filters - District Filters.
 * @param {number} limit - Limit number of results.
 * @param {object} startAfterUser - Start After object.
 * @param {boolean} dispatchEvent - Dispatch Event.
 * @returns {Promise<*>} - District List.
 */
export const fetchDistricts = async (filters = {}, limit, startAfterUser, dispatchEvent = true) => {
  let result;
  try {
    result = await filterDistricts(filters, limit, startAfterUser);
  } catch (err) {
    console.log(err);
    Flux.dispatchEvent(SUPER_ADMIN_DISTRICTS_VIEW_ERROR, err.message);
    throw new Error(err.message);
  }
  const data = {
    districts: result.docs,
    startAfter: result.lastItem,
    isMore: result.isMore,
    clear: !startAfterUser,
  };

  if (dispatchEvent) {
    Flux.dispatchEvent(SUPER_ADMIN_DISTRICTS_VIEW_EVENT, data);
  }
  return data;
};

export const mutateDistrictAction = async (district, isEdit = false) => {
  const DB = firebase.firestore();
  const doc = await DB.collection('districts')
    .doc(district.districtId)
    .get();
  const regionCollection = await DB.collection('regions');

  if (!isEdit && doc.exists) {
    Flux.dispatchEvent(SUPER_ADMIN_ADD_DISTRICT_ERROR, 'District id already exist');
    return;
  }

  const newDistrict = {
    name: district.name || '',
    active: district.active,
    id: district.districtId || '',
    message: district.message || '',
    logoUrl: district.logoUrl || '',
    address1: district.address1 || '',
  };

  const districtsCollection = DB.collection('districts');
  const districtRef = districtsCollection.doc(district.districtId);
  try {
    // Update / Create district
    await districtRef.set(newDistrict, { merge: true });
    // Update regions
    const regionsToUpdate = await regionCollection
      .where(firebase.firestore.FieldPath.documentId(), 'in', district.regions)
      .get();

    const updatedRegions = regionsToUpdate.docs.map((regionToUpdate) => {
      return regionToUpdate.ref.set({ districtId: districtRef.id }, { merge: true });
    });

    await Promise.all(updatedRegions);

    Flux.dispatchEvent(
      SUPER_ADMIN_ADD_DISTRICT_EVENT,
      `District has been ${isEdit ? 'edited' : 'added'} successfully`,
    );
  } catch (error) {
    console.log('>>> error', error);
    Flux.dispatchEvent(
      SUPER_ADMIN_ADD_DISTRICT_ERROR,
      `Error ${isEdit ? 'editing' : 'creating'} the district`,
    );
  }
};

export const getAllRegions = async () => {
  const DB = firebase.firestore();
  const regionsCollection = await DB.collection('regions')
    .orderBy('name')
    .get();

  const regions = regionsCollection.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return regions;
};

export const createRegionAction = async (region) => {
  const DB = firebase.firestore();
  const doc = await DB.collection('regions').doc(region.id);

  if ((await doc.get()).exists) {
    Flux.dispatchEvent(SUPER_ADMIN_ADD_REGION_ERROR, 'Region id already exist');
    return;
  }

  const newRegion = {
    name: region.name || '',
    id: region.id || '',
  };

  try {
    // Create region
    await doc.set(newRegion, { merge: true });

    Flux.dispatchEvent(SUPER_ADMIN_ADD_REGION_EVENT, `Region has been added successfully`);
  } catch (error) {
    console.log('>>> error', error);
    Flux.dispatchEvent(SUPER_ADMIN_ADD_REGION_ERROR, `Error creating the region`);
  }
};

/**
 * @param {object} filters - Filters.
 * @param {number} limit - Limit number of results.
 */
export async function searchAllUsersSuperAdmin(filters = {}, limit = 50) {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');

  let usersQuery = usersCollection;

  const { docs: userDocs } = await getFilterUsers(usersQuery, filters, limit, null, [], true);

  const users = userDocs.map((ref) => ({ ...ref.data(), id: ref.id }));

  await setLastLoginToUsers(users);

  await searchUserRegionDistric(users);

  return users;
}

/**
 * @param {object} filters - Filters.
 * @param {string} whatAction - Report type.
 * @param {string} reportName - Report name.
 */
export async function createAndDownloadSuperAdminReport(
  filters,
  whatAction = 'users',
  reportName = 'Report',
) {
  const whatActionLower = whatAction.toLocaleLowerCase();
  const action = {
    users: {
      func: () => searchAllUsersSuperAdmin(filters, MAX_EXCEL_PARAMETERS.rows),
      columnsReport: columnsUserReport,
    },
    schools: {
      func: async () => {
        const data = await getSchoolsAction(filters, MAX_EXCEL_PARAMETERS.rows, null, [], false);
        return data.schools;
      },
      columnsReport: columnsSchoolReport,
    },
    districts: {
      func: async () => {
        const data = await fetchDistricts(filters, MAX_EXCEL_PARAMETERS.rows, null, false);
        return data.districts;
      },
      columnsReport: columnsDistrictReport,
    },
    clf: {
      func: async () => {
        const data = await searchUsersCLF(filters, MAX_EXCEL_PARAMETERS.rows, null, false);
        return data.users;
      },
      columnsReport: columnsCLFReport,
    },
  };
  const data = [];
  try {
    const response = await action[whatActionLower].func();
    data.push(...response);
  } catch (error) {
    const msg = 'Error fetching user report data, Please try again. Error: ' + error.message;
    console.log(msg);
    return Flux.dispatchEvent(SUPER_ADMIN_EXCEL_REPORTS_ERROR, msg);
  }

  if (data.length === 0) {
    return Flux.dispatchEvent(
      SUPER_ADMIN_EXCEL_REPORTS_ERROR,
      'No data to export, Please try again.',
    );
  }

  try {
    const customData = transformDataReport(data, action[whatActionLower].columnsReport);
    const filterRow = ['Filters:', concatFilters(filters)];
    await createXlsxFile(customData, reportName, [filterRow]);
    return Flux.dispatchEvent(SUPER_ADMIN_EXCEL_REPORTS_EVENT, customData);
  } catch (error) {
    const msg = 'Error creating user report, Please try again. Error: ' + error.message;
    console.log(error);
    return Flux.dispatchEvent(SUPER_ADMIN_EXCEL_REPORTS_ERROR, msg);
  }
}
