import React from 'react';
import { TabContent, TabPane, Nav as ReactNav, NavItem, NavLink as ReactNavLink } from 'reactstrap';
import classnames from 'classnames';
import styled from 'styled-components';
import { useTheme } from '../../theme';

const Nav = styled(ReactNav)`
  width: 100%
  border-bottom: 1px solid #edebeb;
`;

const NavLink = styled(ReactNavLink)`
  cursor: pointer;
  color: ${(props) => props.palette.primary};
  border: 1px solid #edebeb !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  &.active {
    color: #fff !important;
    background-color: ${(props) => props.palette.primary} !important;
  }
`;

export const NavTabsWithContent = ({ items, onChange, value, tabContentProps, ...rest }) => {
  const theme = useTheme();

  const navProps = { ...rest, ...theme };

  return (
    <>
      <Nav {...navProps} tabs>
        {items.map((item, i) => (
          <NavItem {...theme} key={`nav-item-${i + 1}`}>
            <NavLink
              {...theme}
              className={classnames({ active: item.key === value })}
              onClick={() => onChange(item.key)}>
              {item.label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={value}>
        {items.map(({ key, content }, i) => (
          <TabPane key={`tab-panel-${i + 1}`} tabId={key}>
            {value === key ? content : null}
          </TabPane>
        ))}
      </TabContent>
    </>
  );
};
