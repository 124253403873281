import { Button, Table } from 'reactstrap';
import LoadingRow from './LoadingRow';
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Icon from 'react-icons-kit';
import { ic_keyboard_arrow_down as arrowDown } from 'react-icons-kit/md/ic_keyboard_arrow_down';
import { ic_keyboard_arrow_up as arrowUp } from 'react-icons-kit/md/ic_keyboard_arrow_up';
import LoadMoreRow from './LoadMoreRow';
import NoResultsRow from './NoResultsRow';

const Tr = ({ children, ...props }) => <tr {...props}>{children}</tr>;
const Td = ({ children, ...props }) => <td {...props}>{children}</td>;

/**
 * Table Component to sort and load more content.
 *
 * @param {Array} columns - Table columns.
 * @param {Array} rows - Table Rows.
 * @param {boolean} isLoading - Is data loading.
 * @param {Function} onLoadMore - Function trigger on loading more.
 * @param {boolean} isMoreToLoad - Is more content to load.
 * @param {*} customRow - Custom Row Component.
 * @param {object} tableProps - Props.
 * @returns {*} - Table component.
 * @class
 */
const TableSortable = ({
  columns,
  rows,
  isLoading,
  onLoadMore,
  isMoreToLoad,
  customRow = null,
  filters,
  ...tableProps
}) => {
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('desc');
  const directionIcon = sortOrder === 'desc' ? arrowDown : arrowUp;

  const handleSortColumn = (columnField) => {
    if (columnField !== sortBy) {
      setSortBy(columnField);
      setSortOrder('desc');
    } else {
      if (sortOrder === 'desc') {
        setSortOrder('asc');
      } else {
        setSortOrder('desc');
        setSortBy(null);
      }
    }
  };

  let rowsSorted = [...rows];

  if (sortBy) {
    rowsSorted.sort((a, b) => {
      const aValue = a[sortBy];
      const bValue = b[sortBy];
      const order = sortOrder === 'desc' ? 1 : -1;

      if (!aValue && !bValue) {
        return -1 * order;
      }
      if (aValue === bValue) {
        return 0;
      }
      if (Array.isArray(aValue) && Array.isArray(bValue)) {
        return (bValue.length - aValue.length) * order;
      }
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const aValueAsDate = moment(aValue);
        const bValueAsDate = moment(bValue);
        if (aValueAsDate.isValid() && bValueAsDate.isValid()) {
          return aValueAsDate.diff(bValueAsDate) * order;
        }
        return aValue.localeCompare(bValue) * order;
      }
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return (aValue > bValue ? -1 : 1) * order;
      }
      if (!aValue && Boolean(bValue)) {
        return -1 * order;
      }
      if (Boolean(aValue) && !bValue) {
        return 1 * order;
      }
      return (aValue > bValue ? -1 : 1) * order;
    });
  }

  const loadingComponent = rows.length ? (
    isMoreToLoad ? (
      <LoadMoreRow isLoading={isLoading} onClick={onLoadMore} colSpan={columns.length} />
    ) : null
  ) : isLoading ? (
    <LoadingRow colSpan={columns.length} />
  ) : null;

  const rowsComponents = useMemo(() => {
    return rowsSorted.map((row, rowIndex) => {
      if (filters && filters.onlyActives === 'inactive' && row.active) {
        return null;
      }
      const Row = customRow ? customRow : Tr;
      const columnFields = columns.map((col, colIndex) => {
        const { field, customColumn = null, onClick = null, render = null, className = '' } = col;
        const value = row[field];
        const Col = customColumn ? customColumn : Td;

        return (
          <Col
            key={`${colIndex} ${row.anonymityId}`}
            onClick={() => onClick && onClick(row)}
            className={`pt-2 pb-2 ${className}`}>
            {render ? render(value, row) : value}
          </Col>
        );
      });
      return (
        <Row key={rowIndex} row={row}>
          {columnFields}
        </Row>
      );
    });
  }, [rowsSorted]);

  if (!isLoading && !rowsComponents.length)
    rowsComponents[0] = <NoResultsRow colSpan={columns.length} />;

  const columnsHeaders = columns.map((col, colIndex) => {
    const { title = '', field, sort = true } = col;
    const isSorting = sortBy === field;

    return (
      <th key={colIndex}>
        <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
          {title}
          {sort && (
            <Button
              onClick={() => handleSortColumn(field)}
              color="link"
              size={'sm'}
              style={{ padding: 0, paddingBottom: 5, paddingLeft: 5 }}>
              <Icon
                size={18}
                icon={isSorting ? directionIcon : arrowDown}
                style={{ color: isSorting ? null : 'grey' }}
              />
            </Button>
          )}
        </div>
      </th>
    );
  });

  return (
    <div style={{ maxHeight: 'calc(100vh - 260px)', overflowY: 'auto', overflowX: 'hidden' }}>
      <Table hover {...tableProps}>
        <thead>
          <tr>{columnsHeaders}</tr>
        </thead>
        <tbody>
          {rowsComponents}
          {loadingComponent}
        </tbody>
      </Table>
    </div>
  );
};

TableSortable.defaultProps = {
  customRow: null,
  isLoading: false,
  isMoreToLoad: false,
};

TableSortable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  onLoadMore: PropTypes.func.isRequired,
  isMoreToLoad: PropTypes.bool,
  customRow: PropTypes.any,
};

export default TableSortable;
