import React from 'react';
import { FirstStepContent, SecondStepContent, ThirdStepContent } from './DataImportContent';
import { NavTabsWithContent } from '../../../shared/components/menus/NavTabsWithContent';

const DataInstructions = () => {
  const [activeTab, setActiveTab] = React.useState(1);

  return (
    <NavTabsWithContent
      value={activeTab}
      onChange={(v) => setActiveTab(v)}
      items={[
        {
          key: 1,
          label: '1st Step',
          content: <FirstStepContent />,
        },
        {
          key: 2,
          label: '2st Step',
          content: <ThirdStepContent />,
        },
        {
          key: 3,
          label: '3st Step',
          content: <SecondStepContent />,
        },
      ]}
    />
  );
};

export default DataInstructions;
