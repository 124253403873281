import React from 'react';
import * as R from 'ramda';
import { BAR_GRAPH_OPTIONS_LEADER_SECTIONS, COLOR_NOT_FOUND } from '../../my-voice/my-voice-models';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';

const IndicatorGraphCard = ({ indicator, responses }) => {
  const CHART_OPTIONS = R.clone(BAR_GRAPH_OPTIONS_LEADER_SECTIONS);
  const CHART_SERIES = CHART_OPTIONS.labels.map(() => 0);

  if (!responses || !responses.length) {
    CHART_OPTIONS.labels.push('Not Found');
    CHART_OPTIONS.colors.push(COLOR_NOT_FOUND);
    CHART_OPTIONS.fill.colors.push(COLOR_NOT_FOUND);
    CHART_SERIES.push(1);
  } else {
    for (const response of responses) {
      let indexToIncrement = response - 1;
      if (response > CHART_SERIES.length) {
        indexToIncrement = CHART_SERIES.length;
      }
      CHART_SERIES[indexToIncrement]++;
    }
  }

  return (
    <div className="card" style={{ width: 360, height: 400 }}>
      <div className="card-header" style={{ height: 100 }}>
        {indicator}
      </div>
      <div className="pt-2">
        <Chart options={CHART_OPTIONS} series={CHART_SERIES} type="pie" />
      </div>
    </div>
  );
};

IndicatorGraphCard.propTypes = {
  indicator: PropTypes.number.isRequired,
  responses: PropTypes.array.isRequired,
};

export default IndicatorGraphCard;
