import React from 'react';
import Stepper from 'react-js-stepper';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '../../../shared/theme';

const StepTitle = styled.h5`
  color: ${theme.palette.black};
  font-weight: bold;
  font-size: 16px;
`;

const calculateStepsFrom = () => [
  { title: 'Strongly Disagree' },
  { title: 'Disagree' },
  { title: 'Neutral' },
  { title: 'Agree' },
  { title: 'Strongly Agree' },
];

const IndicatorStepper = ({ title, active, onClick }) => (
  <div style={{ marginBottom: '10px' }}>
    <StepTitle>{title}</StepTitle>
    <Stepper
      onSelect={onClick}
      showNumber={true}
      steps={calculateStepsFrom()}
      activeStep={active}
    />
  </div>
);

IndicatorStepper.propTypes = {
  title: PropTypes.string.isRequired,
  active: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

// TODO: Refactor to non default exports
export default IndicatorStepper;
