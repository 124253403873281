import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { truncateString } from '../../utils';

export const DropdownPicker = ({
  options,
  value,
  onChange,
  onSelectAll,
  defaultSelectLabel,
  allOptionsLabel,
  isAllOptions,
}) => {
  const [isOpen, setOpen] = useState(false);

  const toggleDropDown = () => setOpen(!isOpen);

  const allSchoolOption = onSelectAll ? (
    <DropdownItem onClick={onSelectAll}>{allOptionsLabel}</DropdownItem>
  ) : null;

  const displayValue = value ? truncateString(value.name, 0, 15) : defaultSelectLabel;

  return (
    <Dropdown isOpen={isOpen} toggle={toggleDropDown} size="sm">
      <DropdownToggle caret color="light">
        {isAllOptions ? allOptionsLabel : displayValue}
      </DropdownToggle>
      <DropdownMenu>
        {allSchoolOption}
        {options.map((item) => (
          <DropdownItem id={item.id} key={item.id} onClick={() => onChange(item)}>
            {item.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

DropdownPicker.defaultProps = {
  value: null,
  onSelectAll: null,
  allOptionsLabel: 'All Options',
  defaultSelectLabel: '- Select a School -',
  isAllOptions: false,
};

DropdownPicker.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func,
  allOptionsLabel: PropTypes.string,
  defaultSelectLabel: PropTypes.string,
  isAllOptions: PropTypes.bool,
};
