import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { BAR_GRAPH_OPTIONS, DRIVERS } from './my-voice-models';
// import { CUSTOM_DOMAINS } from '../covid-19-domains/covid-19-domains-models';
import * as R from 'ramda';
import PropTypes from 'prop-types';

/**
 * My Voice View.
 *
 * @param avg
 * @param schoolAvg
 * @returns {*}
 * @class
 */
const MyVoiceGraph = ({ avg, domainsAvg, schoolAvg = {}, schoolDomainsAvg, user }) => {
  const labels = [];
  const data = [];
  const schoolAvgData = [];

  DRIVERS.forEach((driver) => {
    labels.push(driver.name);
    data.push(avg[driver.id] || 0);
    schoolAvgData.push(schoolAvg[driver.id] || 0);
  });

  // CUSTOM_DOMAINS.forEach(domain => {
  //   labels.push(domain.name);
  //   data.push(domainsAvg[domain.id] || 0);
  //   schoolAvgData.push(schoolDomainsAvg[domain.id] || 0);
  // });

  const schoolName = user.school ? user.school.name : '';
  const exportFilename = `${schoolName}-drivers-average`;
  const options = R.clone(BAR_GRAPH_OPTIONS);

  options.xaxis.categories = labels;
  options.chart.toolbar.export.svg.filename = exportFilename;
  options.chart.toolbar.export.png.filename = exportFilename;
  options.chart.toolbar.export.csv.filename = exportFilename;

  const series = [
    {
      name: 'School Culture Score',
      data: schoolAvgData,
      type: 'bar',
    },
    {
      name: 'My Culture Score',
      data: data,
      type: 'line',
    },
  ];

  return (
    <div>
      <h2 className="my-5" style={{ fontFamily: 'Zilla Slab' }}>
        {schoolName}
      </h2>
      <ReactApexChart
        className=""
        options={options}
        series={series}
        type="line"
        height="500"
        width="80%"
      />
    </div>
  );
};

MyVoiceGraph.propTypes = {
  avg: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  domainsAvg: PropTypes.object.isRequired,
  schoolAvg: PropTypes.object.isRequired,
  schoolDomainsAvg: PropTypes.object.isRequired,
};

export { MyVoiceGraph };
