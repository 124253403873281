import { Button, Col } from 'reactstrap';
import React from 'react';
import PropTypes from 'prop-types';
import defaultPicture from '../../../shared/assets/images/picture.png';
import { isValidString } from '../../../shared/strings';
import { capitalize } from 'lodash';

const User = ({
  onEditUser,
  onEditProfile,
  user: { picture, firstName, lastName, anonymityId },
}) => {
  return (
    <>
      <Col sm={3} lg={2} className="d-flex text-center justify-content-center">
        {isValidString(picture) ? (
          <img alt={'User Profile'} src={picture} className="item-my-profile" />
        ) : (
          <img alt={'User Profile'} src={defaultPicture} className="item-my-profile" />
        )}
      </Col>
      <Col sm={4} md={3}>
        <div className="pt-2 pr-3">
          <div className="mb-3">
            <h5 className="item-textgray">First Name</h5>
            <h6 className="item-textgray">{capitalize(firstName)}</h6>
          </div>
        </div>
      </Col>
      <Col sm={4} md={3}>
        <div className="pt-2 pr-3">
          <div className="mb-3">
            <h5 className="item-textgray">Last Name</h5>
            <h6 className="item-textgray">{capitalize(lastName)}</h6>
          </div>
        </div>
      </Col>
      <Col className="d-flex align-items-center justify-content-center flex-md-wrap">
        <div className="ml-2">
          <Button
            onClick={onEditUser}
            type="button"
            className="mx-sm-auto m-lg-0 d-block btn-input-size">
            Edit Name
          </Button>
        </div>
        <div className="ml-2">
          <Button
            onClick={onEditProfile}
            type="button"
            className="mx-sm-auto m-lg-0 d-block btn-input-size">
            Edit Profile
          </Button>
        </div>
      </Col>
    </>
  );
};

User.propTypes = {
  onEditUser: PropTypes.func.isRequired,
  onEditProfile: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export { User };
