import React from 'react';
// import { Col } from 'reactstrap';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';

const IndicatorDetailCard = (props) => {
  const { questions, options, series, num, type, height } = props;
  return (
    <div className="card" style={{ width: 360, height: 450 }}>
      <div className="card-header" style={{ height: 115 }}>
        {questions[num].name}
      </div>
      <div className="pt-2">
        <Chart options={options} series={series} type={type} height={height} />
      </div>
    </div>
  );
};

IndicatorDetailCard.propTypes = {
  questions: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
  series: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  num: PropTypes.number.isRequired,
};

export default IndicatorDetailCard;
