import React, { useState } from 'react';
import { Col, Form, FormGroup, Input, Row } from 'reactstrap';
import styled from 'styled-components';
import SwitchCheckbox from '../../../shared/components/inputs/SwitchCheckbox';
import { QUESTIONS } from '../../profile/profile-models';
import OptionList from './OptionList';
import ExportReportButton from './ExportReportButton';
import { exportReport, getUserReports, saveReport } from '../my-reports-actions';
import SaveReportButton from './SaveReportButton';
import { authStore, USER_EVENT } from '../../auth/auth-store';
import { USER_TYPE_CLF } from '../../../shared/userTypes';

const DefaultLabel = styled.label`
  position: unset !important;
  color: #002a3f;
`;

const questions = QUESTIONS.flatMap((item) => item.options).reduce((object, question) => {
  const { id, values } = question;
  object[id] = values;
  return object;
}, {});

const reportTypes = [
  { name: 'District Driver Report', value: 0 },
  { name: 'District Indicator Report', value: 1 },
];

/**
 * Returns string value for checks.
 *
 * @param {boolean | null} checked - Check value.
 * @returns {string | null} - Returns Yes or No.
 */
function convertCheckedValue(checked) {
  if (typeof checked === 'boolean') {
    return checked ? 'Yes' : 'No';
  }
  return checked;
}

const isInvalidValidOption = (option) => {
  return option.startsWith('-') && option.endsWith('-');
};

/**
 * Build My Reports.
 *
 * @returns {*}
 * @function
 */
export default function BuildMyReports() {
  const user = authStore.getState(USER_EVENT);
  const [reportType, setReportType] = useState(reportTypes[0]);

  const [selectedDistrict, setSelectedDistrict] = useState('');

  const [age, setAge] = useState(null);
  const [race, setRace] = useState(null);
  const [gender, setGender] = useState(null);
  const [schoolLevel, setSchoolLevel] = useState(null);
  const [highestDegree, setHighestDegree] = useState(null);
  const [howManySchools, setHowManySchools] = useState(null);
  const [yearsInEducation, setYearsInEducation] = useState(null);
  const [howManyPrincipals, setHowManyPrincipals] = useState(null);
  const [certificationType, setCertificationType] = useState(null);
  const [yearsInCurrentSchool, setYearsInCurrentSchool] = useState(null);

  const [leavingSchool, setLeavingSchool] = useState(null);
  const [leavingTeaching, setLeavingTeaching] = useState(null);
  const [interestLeadership, setInterestLeadership] = useState(null);

  const [saving, setSaving] = useState(false);
  const [exporting, setExporting] = useState(false);

  const isUserClf = user.userType === USER_TYPE_CLF;

  const reportConfig = {
    age,
    race,
    gender,
    schoolLevel,
    highestDegree,
    howManySchools,
    yearsInEducation,
    howManyPrincipals,
    certificationType,
    yearsInCurrentSchool,
    consideringLivingSchool: convertCheckedValue(leavingSchool),
    consideringLivingProfession: convertCheckedValue(leavingTeaching),
    interestedOnLeadership: convertCheckedValue(interestLeadership),
  };

  const handleExportReport = async () => {
    setExporting(true);
    await exportReport(reportType, reportConfig, selectedDistrict);
    setExporting(false);
  };
  const handleSaveReport = async () => {
    setSaving(true);
    await saveReport(reportType, reportConfig, selectedDistrict);
    await getUserReports();
    setSaving(false);
  };

  const handleReportTypeChanged = (evt) => {
    const reportType = reportTypes.find((report) => report.value === parseInt(evt.target.value));
    setReportType(reportType);
  };

  const inputSelectDistrict = isUserClf ? (
    <Col md={4} lg={4}>
      <DefaultLabel className="font-weight-bold">District</DefaultLabel>
      <Input
        type="select"
        value={selectedDistrict}
        onChange={(e) => setSelectedDistrict(e.target.value)}>
        <option disabled value="">
          -- Select district --
        </option>
        {user.districts.map(({ districtId }) => (
          <option key={districtId} value={districtId}>
            {districtId}
          </option>
        ))}
      </Input>
    </Col>
  ) : null;

  return (
    <Form>
      <FormGroup>
        <Row>
          {inputSelectDistrict}
          <Col md={4}>
            <DefaultLabel className="font-weight-bold">Report Type</DefaultLabel>
            <Input type="select" value={reportType.value} onChange={handleReportTypeChanged}>
              <OptionList list={reportTypes} />
            </Input>
          </Col>
          <Col md={isUserClf ? 4 : 8}>
            <div className="d-flex justify-content-end" style={{ height: '100%' }}>
              <div className="d-flex align-items-end">
                <div style={{ marginRight: 8 }}>
                  <SaveReportButton loading={saving} onClick={handleSaveReport} />
                </div>
                <ExportReportButton loading={exporting} onClick={handleExportReport} />
              </div>
            </div>
          </Col>
        </Row>
      </FormGroup>
      <hr />

      <Row>
        <Col lg={2} md={4} className="d-flex flex-wrap align-items-end">
          <FormGroup className="w-100">
            <DefaultLabel className="font-weight-bold">Gender</DefaultLabel>
            <Input
              type="select"
              value={gender}
              onChange={(evt) => {
                if (isInvalidValidOption(evt.target.value)) return setGender(null);
                setGender(evt.target.value);
              }}>
              <option value={null}>- Select Gender -</option>
              <OptionList list={questions.gender} />
            </Input>
          </FormGroup>
        </Col>
        <Col lg={2} md={4} className="d-flex flex-wrap align-items-end">
          <FormGroup className="w-100">
            <DefaultLabel className="font-weight-bold">Race</DefaultLabel>
            <Input
              type="select"
              value={race}
              onChange={(evt) => {
                if (isInvalidValidOption(evt.target.value)) return setRace(null);
                setRace(evt.target.value);
              }}>
              <option value={null}>- Select Race -</option>
              <OptionList list={questions.race} />
            </Input>
          </FormGroup>
        </Col>
        <Col lg={2} md={4} className="d-flex flex-wrap align-items-end">
          <FormGroup className="w-100">
            <DefaultLabel className="font-weight-bold">Age</DefaultLabel>
            <Input
              type="select"
              value={age}
              onChange={(evt) => {
                if (isInvalidValidOption(evt.target.value)) return setAge(null);
                setAge(evt.target.value);
              }}>
              <option value={null}>- Select Age -</option>
              <OptionList list={questions.age} />
            </Input>
          </FormGroup>
        </Col>
        <Col lg={2} md={4} className="d-flex flex-wrap align-items-end">
          <FormGroup className="w-100">
            <DefaultLabel className="font-weight-bold">Years in education</DefaultLabel>
            <Input
              type="select"
              value={yearsInEducation}
              onChange={(evt) => {
                if (isInvalidValidOption(evt.target.value)) return setYearsInEducation(null);
                setYearsInEducation(evt.target.value);
              }}>
              <option value={null}>- Select Years -</option>
              <OptionList list={questions.yearsInEducation} />
            </Input>
          </FormGroup>
        </Col>
        <Col lg={2} md={4} className="d-flex flex-wrap align-items-end">
          <FormGroup className="w-100">
            <DefaultLabel className="font-weight-bold">Years in current school</DefaultLabel>
            <Input
              type="select"
              value={yearsInCurrentSchool}
              onChange={(evt) => {
                if (isInvalidValidOption(evt.target.value)) return setYearsInCurrentSchool(null);
                setYearsInCurrentSchool(evt.target.value);
              }}>
              <option value={null}>- Select Years -</option>
              <OptionList list={questions.yearsInCurrentSchool} />
            </Input>
          </FormGroup>
        </Col>
        <Col lg={2} md={4} className="d-flex flex-wrap align-items-end">
          <FormGroup className="w-100">
            <DefaultLabel className="font-weight-bold">School Level</DefaultLabel>
            <Input
              type="select"
              value={schoolLevel}
              onChange={(evt) => {
                if (isInvalidValidOption(evt.target.value)) return setSchoolLevel(null);
                setSchoolLevel(evt.target.value);
              }}>
              <option value={null}>- Select School Level -</option>
              <OptionList list={questions.schoolLevel} />
            </Input>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={6} className="d-flex flex-wrap align-items-end">
          <FormGroup className="w-100">
            <DefaultLabel className="font-weight-bold">
              How many principals have you worked with during your tenure in this building ?
            </DefaultLabel>
            <Input
              type="select"
              value={howManyPrincipals}
              onChange={(evt) => {
                if (isInvalidValidOption(evt.target.value)) return setHowManyPrincipals(null);
                setHowManyPrincipals(evt.target.value);
              }}>
              <option value={null}>- Select Number of Principals -</option>
              <OptionList list={questions.howManyPrincipals} />
            </Input>
          </FormGroup>
        </Col>
        <Col lg={4} md={6} className="d-flex flex-wrap align-items-end">
          <FormGroup className="w-100">
            <DefaultLabel className="font-weight-bold">
              How many schools have you worked in during your career ?
            </DefaultLabel>
            <Input
              type="select"
              value={howManySchools}
              onChange={(evt) => {
                if (isInvalidValidOption(evt.target.value)) return setHowManySchools(null);
                setHowManySchools(evt.target.value);
              }}>
              <option value={null}>- Select Number of Schools -</option>
              <OptionList list={questions.howManySchools} />
            </Input>
          </FormGroup>
        </Col>
        <Col lg={2} md={4} className="d-flex flex-wrap align-items-end">
          <FormGroup className="w-100">
            <DefaultLabel className="font-weight-bold">Highest degree attained</DefaultLabel>
            <Input
              type="select"
              value={highestDegree}
              onChange={(evt) => {
                if (isInvalidValidOption(evt.target.value)) return setHighestDegree(null);
                setHighestDegree(evt.target.value);
              }}>
              <option value={null}>- Select Degree -</option>
              <OptionList list={questions.highestDegree} />
            </Input>
          </FormGroup>
        </Col>
        <Col lg={2} md={4} className="d-flex flex-wrap align-items-end">
          <FormGroup className="w-100">
            <DefaultLabel className="font-weight-bold">Certification</DefaultLabel>
            <Input
              type="select"
              value={certificationType}
              onChange={(evt) => {
                if (isInvalidValidOption(evt.target.value)) return setCertificationType(null);
                setCertificationType(evt.target.value);
              }}>
              <option value={null}>- Select Certification -</option>
              <OptionList list={questions.certificationType} />
            </Input>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6} lg={4}>
          <FormGroup className="row">
            <DefaultLabel className="col-6">Considering leaving current school</DefaultLabel>
            <Col xs={6} className="d-flex align-items-center">
              <span>No</span>
              <SwitchCheckbox
                onChange={(evt) => setLeavingSchool(evt.target.checked)}
                checked={leavingSchool}
                name="leavingSchool"
              />
              <span className="ml-2">Yes</span>
            </Col>
          </FormGroup>
        </Col>
        <Col md={6} lg={4}>
          <FormGroup className="row">
            <DefaultLabel className="col-6">
              Considering leaving the teaching profession
            </DefaultLabel>
            <Col xs={6} className="d-flex align-items-center">
              <span>No</span>
              <SwitchCheckbox
                onChange={(evt) => setLeavingTeaching(evt.target.checked)}
                checked={leavingTeaching}
                name="leavingTeaching"
              />
              <span className="ml-2">Yes</span>
            </Col>
          </FormGroup>
        </Col>
        <Col md={6} lg={4}>
          <FormGroup className="row">
            <DefaultLabel className="col-6">
              Have an interest in a leadership-type position
            </DefaultLabel>
            <Col xs={6} className="d-flex align-items-center">
              <span>No</span>
              <SwitchCheckbox
                onChange={(evt) => setInterestLeadership(evt.target.checked)}
                checked={interestLeadership}
                name="interestLeadership"
              />
              <span className="ml-2">Yes</span>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
}
