import React from 'react';
import PropTypes from 'prop-types';

const WhiteTitle = ({ children }) => {
  return <h1 className="text-center title-cooming-soon text-white">{children}</h1>;
};

WhiteTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default WhiteTitle;
