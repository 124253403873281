import * as firebase from 'firebase';
import moment from 'moment';
import { createExcelFile, downloadBuffer } from '../../shared/fileExport';
import { customToast } from '../../shared/toast';
import _, { isNull, pickBy } from 'lodash';
import { authStore, USER_EVENT } from '../auth/auth-store';
import { uploadFileToFireStorage } from '../super-admin/super-admin-actions';
import Flux from '@cobuildlab/flux-state';
import { MY_REPORTS_GET_REPORTS } from './my-reports-store';
import { USER_TYPE_TEACHER, USER_TYPE_CLF } from '../../shared/userTypes';

const reportConfigDescription = {
  age: 'Age',
  race: 'Race',
  gender: 'Gender',
  schoolLevel: 'School Level',
  highestDegree: 'Highest degree attained',
  howManySchools: 'Number of schools worked during career',
  yearsInEducation: 'Years in education',
  howManyPrincipals: 'Number of principals worked with',
  certificationType: 'Certification',
  yearsInCurrentSchool: 'Years in current school',
  consideringLivingSchool: 'Considering leaving current school',
  consideringLivingProfession: 'Considering leaving profession',
  interestedOnLeadership: 'Interest in leadership',
};

/**
 * Get user's save reports.
 *
 * @returns {Promise<object[]>} - Array of reports.
 */
export async function getUserReports() {
  const db = firebase.firestore();
  const reportsCollection = db.collection('reports');

  const user = authStore.getState(USER_EVENT);

  const reportsRef = await reportsCollection
    .orderBy('date', 'desc')
    .where('userId', '==', user.email)
    .get();

  const reports = reportsRef.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

  Flux.dispatchEvent(MY_REPORTS_GET_REPORTS, reports);
  return reports;
}

/**
 * Export Report to Excel File.
 *
 * @param {{filters: {user: {districtId: string, profile: object, userType: string}}, type: object}} reportConfig - Report Config.
 * @returns {Promise<Buffer>} - Download Excel File.
 */
export async function createReport(reportConfig) {
  const { type, filters } = reportConfig;
  const { user: userFilter, districtId, schools } = filters;

  let report;
  switch (type.value) {
  case 0:
    report = await getDistrictDriverDetail(districtId, userFilter, schools);
    break;
  case 1:
    report = await getDistrictIndicatorsDetail(districtId, userFilter, schools);
    break;
  default:
    throw new Error('Invalid Report type');
  }
  return await createExcelFile(report.columns, report.rows);
}

/**
 * Create and Download Report.
 *
 * @param {object} reportType - Report Type.
 * @param {object} reportConfig - Report Configuration.
 * @param {string} districtId - District ID.
 * @returns {Promise<void>} - Void.
 */
export async function exportReport(reportType, reportConfig, districtId = null) {
  const user = authStore.getState(USER_EVENT);
  const isUserClf = user.userType === USER_TYPE_CLF;

  try {
    if (isUserClf && !districtId) throw new Error('district is required');
    const config = filterReportConfig(reportType, reportConfig, districtId);
    const fileBuffer = await createReport(config);
    const fileName = getReportFileName(reportType);
    downloadBuffer(fileBuffer, fileName);
  } catch (e) {
    customToast.error(e.message);
  }
}

/**
 * Create and Download Report.
 *
 * @param {object} reportType - Report Type.
 * @param {object} reportConfig - Report Configuration.
 * @param {string} districtId - District ID.
 * @returns {Promise<void>} - Void.
 */
export async function saveReport(reportType, reportConfig, districtId = null) {
  const user = authStore.getState(USER_EVENT);
  const isUserClf = user.userType === USER_TYPE_CLF;

  try {
    if (isUserClf && !districtId) throw new Error('district is required');
    const config = filterReportConfig(reportType, reportConfig, districtId);
    const fileBuffer = await createReport(config);

    const fileName = getReportFileName(reportType);
    const reportUrl = await uploadReport(fileBuffer, fileName);

    const db = firebase.firestore();
    const reportsCollection = db.collection('reports');

    const user = authStore.getState(USER_EVENT);

    const description = Object.keys(config.filters.user.profile)
      .map((key) => {
        return `${reportConfigDescription[key]}: ${config.filters.user.profile[key]}`;
      })
      .join('\n');

    const reportRef = reportsCollection.doc();
    await reportRef.set({
      type: reportType.name,
      userId: user.email,
      url: reportUrl,
      date: new Date(),
      description,
    });

    customToast.success('Report Saved!');
  } catch (e) {
    console.error(e);
    customToast.error(e.message);
  }
}

/**
 * Get File name for Report.
 *
 * @param {object} reportType - Report Type.
 * @returns {string} - File Name.
 */
function getReportFileName(reportType) {
  const date = moment().format('MM-DD-YYYY');

  switch (reportType.value) {
  case 0:
    return `district-drivers-${date}.xlsx`;
  case 1:
    return `district-indicators-${date}.xlsx`;
  default:
    return `my-report-${date}.xlsx`;
  }
}

/**
 * Upload report to fireStorage.
 *
 * @param {Buffer} fileBuffer - File to Upload.
 * @param {string} fileName - FileName - File name.
 * @returns {Promise<string>} - Download URL of the resource.
 */
async function uploadReport(fileBuffer, fileName) {
  const fileBlob = new Blob([fileBuffer]);
  const unixTime = new Date().getTime();
  const path = `reports/${unixTime}/${fileName}`;
  return await uploadFileToFireStorage(fileBlob, path);
}

/**
 * @param {object} reportType - Report Type.
 * @param {object} reportConfig - Report configuration.
 * @param {string} districtId - District ID.
 * @returns {{filters: {user: {districtId: string, profile: object, userType: string}}, type: object}} - Report Full Configuration.
 */
function filterReportConfig(reportType, reportConfig, districtId = null) {
  const profileFiltersCleaned = pickBy(reportConfig, (value) => !isNull(value));
  const user = authStore.getState(USER_EVENT);
  const filters = {
    type: reportType,
    user: {
      userType: USER_TYPE_TEACHER,
      profile: profileFiltersCleaned,
    },
  };

  let defintiveDistrict = user.districtId;

  if (user.userType === USER_TYPE_CLF) {
    defintiveDistrict = districtId;
    const district = user.districts.find((d) => d.districtId === districtId);
    Object.assign(filters, {
      schools: district ? district.schools : [],
    });
  }

  filters.districtId = defintiveDistrict;
  filters.user.districtId = defintiveDistrict;

  return {
    type: reportType,
    filters,
  };
}

/**
 * Get Rows for 'District Driver Summary' Report.
 *
 * @param {string} districtId - District ID.
 * @param {object} userFilter - User Profile Filters.
 * @param {string[]} schools - Selected District school IDs.
 * @returns {Promise<{columns: [], rows: []}>} - Reports Rows.
 */
async function getDistrictDriverDetail(districtId, userFilter, schools = null) {
  const { userType, profile: profileFilter } = userFilter;

  const usersInDistrict = await getUsersByTypeAndDistrict(userType, districtId, schools);

  const users = await getUsersByProfiles(profileFilter, usersInDistrict);

  const requests = users.map(async (user) => {
    try {
      const drivers = await getTeacherDrivers(user);
      const driversAvg = getDriversAvg(drivers);
      return { user, driversAvg };
    } catch (e) {
      // Enters if the user doesn't have a driver.
      return;
    }
  });

  const rowsData = (await Promise.all(requests)).filter((row) => row);

  if (!rowsData.length) {
    throw new Error('Report without records.');
  }

  let drivers = [];
  rowsData.forEach((data) => {
    const { driversAvg } = data;
    drivers = _.union(drivers, Object.keys(driversAvg));
  });

  const columns = [
    { name: 'Anonymity Id', width: 32, filterButton: true },
    { name: 'School Id', width: 20, filterButton: true },
    ...drivers.map((driver) => ({ name: driver })),
  ];

  const rows = rowsData.map((rowData) => {
    const { user, driversAvg } = rowData;
    const { anonymityId, schoolId } = user;

    const driverValues = drivers.map((driver) => {
      const value = driversAvg[driver];
      return value ? value : '-';
    });

    return [anonymityId, schoolId, ...driverValues];
  });

  return { columns, rows };
}

/**
 * Get Rows for 'District Driver Summary' Report.
 *
 * @param {string} districtId - District ID.
 * @param {object} userFilter - User Profile Filters.
 * @param {string[]} schoolIds - Selected District school IDs.
 * @returns {Promise<{columns: [], rows: []}>} - Reports Rows.
 */
async function getDistrictIndicatorsDetail(districtId, userFilter, schoolIds) {
  const { userType, profile: profileFilter } = userFilter;

  const usersInDistrict = await getUsersByTypeAndDistrict(userType, districtId, schoolIds);

  const users = await getUsersByProfiles(profileFilter, usersInDistrict);

  const requests = users.map(async (user) => {
    try {
      const drivers = await getTeacherDrivers(user);
      const indicators = getDriversIndicators(drivers);
      return { user, indicators };
    } catch (e) {
      // Enters if the user doesn't have a driver.
      return;
    }
  });

  const rowsData = (await Promise.all(requests)).filter((row) => row);

  if (!rowsData.length) {
    throw new Error('Report without records.');
  }

  let indicatorsObjs = [];
  rowsData.forEach((data) => {
    const { indicators } = data;
    const objs = Object.keys(indicators).map((key) => {
      const { driver, indicator } = indicators[key];
      return { key, driver, indicator };
    });
    indicatorsObjs = _.unionWith(indicatorsObjs, objs, _.isEqual);
  });
  indicatorsObjs = _.sortBy(indicatorsObjs, ['driver', 'indicator']);

  const indicatorsColumns = indicatorsObjs.map((obj) => {
    return { name: `${obj.driver}-${obj.indicator}`, width: 13 };
  });

  const columns = [
    { name: 'Anonymity Id', width: 32, filterButton: true },
    { name: 'School Id', width: 20, filterButton: true },
    ...indicatorsColumns,
  ];

  const rows = rowsData.map((rowData) => {
    const { user, indicators } = rowData;
    const { anonymityId, schoolId } = user;

    const driverValues = indicatorsObjs.map((indicatorObj) => {
      const indicator = indicators[indicatorObj.key];
      if (indicator) {
        return indicator.value;
      } else {
        return '-';
      }
    });

    return [anonymityId, schoolId, ...driverValues];
  });

  return { columns, rows };
}

/**
 * @param drivers
 * @param {Array} usersDrivers - Users Drivers.
 * @returns {{}}
 */
function getDriversAvg(drivers) {
  const driversAvg = {};

  Object.keys(drivers).forEach((driverKey) => {
    const driver = drivers[driverKey];
    const values = Object.values(driver);
    const sum = values.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    const average = sum / values.length;
    driversAvg[driverKey] = average.toFixed(2);
  });

  return driversAvg;
}

/**
 * Get Driver for User.
 *
 * @param user
 * @param {object} users - User List.
 * @returns {Promise<object>}
 */
async function getTeacherDrivers(user) {
  const db = firebase.firestore();
  const driversTeacherCollection = db.collection('driversTeacher');

  const driverDoc = await driversTeacherCollection.doc(user.email).get();

  if (driverDoc.exists) {
    return driverDoc.data();
  } else {
    throw new Error('Teacher does not have drivers.');
  }
}

/**
 * Get All indicators in Drivers.
 *
 * @param {object} drivers - Drivers.
 * @returns {object} - Array of indicators.
 */
function getDriversIndicators(drivers) {
  const indicators = {};
  Object.keys(drivers).forEach((driver) => {
    Object.keys(drivers[driver]).forEach((indicator) => {
      const key = `${driver}-${indicator}`;
      indicators[key] = {
        driver,
        indicator,
        value: drivers[driver][indicator],
      };
    });
  });
  return indicators;
}

/**
 * Get Users By UserType and School.
 *
 * @param {string} userType - User Type.
 * @param {string} districtId - District Id.
 * @param {string[]} schoolIds - Selected District school IDs.
 * @returns {Promise<*[]>} - Array of users.
 */
async function getUsersByTypeAndDistrict(userType, districtId, schoolIds = null) {
  const db = firebase.firestore();
  const usersCollection = db.collection('users');

  let queryBase = usersCollection.where('active', '==', true).where('userType', '==', userType);

  const users = [];

  const addUserDocs = (docs) => {
    users.push(...docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  if (schoolIds) {
    const results = await Promise.all(
      schoolIds.map((schoolId) => queryBase.where('schoolId', '==', schoolId).get()),
    );
    results.forEach((result) => addUserDocs(result.docs));
  } else {
    // Get All Teacher Users in School
    const result = await queryBase.where('districtId', '==', districtId).get();

    addUserDocs(result.docs);
  }

  return users;
}

/**
 * Filter Users by profile.
 *
 * @param {*} filterProfile - Profile to filter by.
 * @param {Array} users - User List.
 * @returns {Promise<*[]>} - Filtered users.
 */
async function getUsersByProfiles(filterProfile, users) {
  const db = firebase.firestore();
  const profilesTeacherCollection = db.collection('profilesTeacher');

  const unfilteredProfilesDocs = await Promise.all(
    users.map((user) => profilesTeacherCollection.doc(user.email).get()),
  );

  const filterProfileFields = Object.keys(filterProfile);

  const profilesDocs = unfilteredProfilesDocs.filter((doc) => {
    if (doc.exists) {
      const profile = doc.data();
      for (const field of filterProfileFields) {
        if (filterProfile[field] !== profile[field]) {
          return false;
        }
      }
      return true;
    }
    return false;
  });

  const profiles = profilesDocs.map((doc) => ({ id: doc.id, ...doc.data() }));

  const usersEmailInProfiles = profiles.map((profile) => profile.id);

  console.log('USERS', users);
  console.log('PROFILES', profiles);

  return users.filter((user) => usersEmailInProfiles.includes(user.email));
}
