import React from 'react';
import View from '@cobuildlab/react-flux-state';
import { PropTypes } from 'prop-types';
import { SubNavigationBar } from '../../../shared/components/menus/SubNavigationBar';
import { CultureAndCovidCollapsibleLists } from '../../../shared/components/menus/CultureAndCovidCollapsibleLists';

class DriversLeaderNavigationBar extends View {
  render() {
    const { driversAverage, domainsAverage } = this.props;

    return (
      <SubNavigationBar>
        <CultureAndCovidCollapsibleLists
          pathBase="/my-school"
          driversAvg={driversAverage}
          domainsAvg={domainsAverage}
        />
      </SubNavigationBar>
    );
  }
}

DriversLeaderNavigationBar.propTypes = {
  driversAverage: PropTypes.object.isRequired,
  domainsAvg: PropTypes.object.isRequired,
};

export { DriversLeaderNavigationBar };
