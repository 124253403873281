import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { User } from './User';
import { EditUser } from './EditUser';
import { ClipLoader } from 'react-spinners';

export const UserProfileHeader = ({
  loading = false,
  user,
  onUpdateUser,
  editUser,
  onEditUser,
  onEditProfile,
  onCancel,
}) => {
  if (loading) {
    return (
      <Col sm={'12'} className={'text-center'}>
        <ClipLoader sizeUnit={'px'} size={120} color={'#123abc'} loading={true} />
      </Col>
    );
  }
  if (editUser) return <EditUser onSave={onUpdateUser} onCancel={onCancel} user={user} />;

  return <User onEditUser={onEditUser} onEditProfile={onEditProfile} user={user} />;
};

UserProfileHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  editUser: PropTypes.bool.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
  onEditUser: PropTypes.func.isRequired,
  onEditProfile: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
