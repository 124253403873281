import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
// ICONS
import ExclamationIcon from '../assets/images/exclamation-circle-solid.svg';

const CenterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const PageInfo = ({ text }) => (
  <CenterContent>
    <div className="text-center">
      <img src={ExclamationIcon} alt="icon" height={32} width={35} className="mr-3" />
      <span className="h6">{text}</span>
    </div>
  </CenterContent>
);

PageInfo.propTypes = {
  text: PropTypes.string.isRequired,
};
