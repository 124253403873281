import React from 'react';
import View from '@cobuildlab/react-flux-state';
import Icon from 'react-icons-kit';
import XLSX from 'xlsx';
import { Container, Row, Badge, Col } from 'reactstrap';
import { customToast } from '../../shared/toast';
import { DataTable } from './DataTable';
import dataImportStore, {
  REVIEW_UPLOAD_DATA,
  UPLOAD_DATA,
  UPLOAD_DATA_ERROR,
} from './data-import-store';
import DataInstructions from './components/DataInstructions';
import { getExtension } from '../../shared/files';
import { upload } from 'react-icons-kit/fa/upload';
import { onErrorMixin } from '../../shared/mixins';
import { uploadImportedDataAction, reviewAlreadyExistData } from './data-import-actions';
import { ClipLoader } from '../../shared/components/progress/ClipLoader';
import { authStore, USER_EVENT } from '../auth/auth-store';
import { NavigationBar } from '../../shared/layouts/NavigationBar';
import { DropZone } from '../profile/components/DropZone';
import ConfirmModal from '../../components/modal/ConfirmModal';

const ListExistData = ({ title, items, field }) => (
  <>
    {title}:
    <ul>
      {items.map((item, key) => (
        <li key={`item-${key + 1}`}>
          {field}: <strong>{item[field]}</strong>
        </li>
      ))}
    </ul>
  </>
);

const initialReviewsData = {
  someOneExist: false,
  users: [],
  schools: [],
  regions: [],
  districts: [],
};

class DataImportView extends View {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      reviewed: false,
      canceled: false,
      reviews: { ...initialReviewsData },
      openConfirmUploadDialog: false,
      data: [],
      file: null,
      fileError: null,
    };
    this.onError = onErrorMixin.bind(this);
    const user = authStore.getState(USER_EVENT);
    if (user.isAdmin !== true) throw new Error('This View is Just for Admin');
  }

  componentDidMount() {
    this.subscribe(dataImportStore, REVIEW_UPLOAD_DATA, (reviews) => {
      this.setState({
        loading: !reviews.someOneExist,
        reviewed: true,
        reviews,
        openConfirmUploadDialog: reviews.someOneExist,
      });
      if (!reviews.someOneExist) this.onSave();
    });

    this.subscribe(dataImportStore, UPLOAD_DATA, (state) => {
      this.setState({ loading: false, data: [], reviewed: false }, () => {
        state.results.forEach((result) => {
          const [success, message] = result;
          if (success) {
            customToast.info(message);
          } else {
            customToast.error(message);
          }
        });
      });
    });
    this.subscribe(dataImportStore, UPLOAD_DATA_ERROR, this.onError);
  }

  /**
   * @function onFiles
   *  Handles the DropZone event listener, and attach all properties of the current file uploaded.
   * @param {[]} files
   * @returns {void}
   */
  onFiles = (files) => {
    this.setState({ file: files[0] });
  };

  /**
   * @function onSubmit
   * Upload the current workbook file.
   * @returns {void}
   */
  onSubmit = () => {
    const file = this.state.file;
    if (file === null) return customToast.error('Select a File');
    if (getExtension(file.name) !== 'xlsx')
      return customToast.error('Upload error: Wrong File Extension');

    const reader = new FileReader();
    const that = this;
    reader.onload = function(e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      that.setState({
        data: workbook,
        file: null,
        reviews: { ...initialReviewsData },
        reviewed: false,
      });
    };
    reader.readAsArrayBuffer(file);
    customToast.success('File uploaded successfully');
  };

  onSave = () => {
    if (this.state.fileError !== null)
      return customToast.error('Upload error: Something went Wrong processing the file');

    if (this.state.canceled && this.state.reviewed) {
      this.setState({ openConfirmUploadDialog: true });
    } else {
      this.setState(
        {
          loading: true,
          openConfirmUploadDialog: false,
          canceled: false,
        },
        () => {
          if (this.state.reviewed) uploadImportedDataAction(this.state.data);
          else reviewAlreadyExistData(this.state.data);
        },
      );
    }
  };

  render() {
    const { file, data, fileError, loading, reviews, reviewed } = this.state;

    let content =
      fileError === null ? (
        <Col md={12}>
          <DataTable data={data} onClickSave={this.onSave} />
        </Col>
      ) : (
        fileError
      );

    if (loading)
      content = (
        <Col md={12} className="align-items-center justify-content-center h-100vh d-flex">
          <ClipLoader sizeUnit={'px'} size={150} style={{ position: 'absolute' }} />
        </Col>
      );

    let confirmModalBody = null;
    if (reviewed) {
      const alreadyExistUsers = reviews.users.filter((item) => item.exist);
      const alreadyExistSchools = reviews.schools.filter((item) => item.exist);
      const alreadyExistRegions = reviews.regions.filter((item) => item.exist);
      const alreadyExistDistricts = reviews.districts.filter((item) => item.exist);

      confirmModalBody = (
        <>
          <p>
            some records are already in the database, when saving the existing data will be replaced
          </p>
          {alreadyExistUsers.length ? (
            <ListExistData title="Users" items={alreadyExistUsers} field="email" />
          ) : null}
          {alreadyExistSchools.length > 0 ? (
            <>
              <br />
              <ListExistData title="Schools" items={alreadyExistSchools} field="id" />
            </>
          ) : null}
          {alreadyExistRegions.length > 0 ? (
            <>
              <br />
              <ListExistData title="Regions" items={alreadyExistRegions} field="id" />
            </>
          ) : null}
          {alreadyExistDistricts.length ? (
            <>
              <br />
              <ListExistData title="Districts" items={alreadyExistDistricts} field="id" />
            </>
          ) : null}
        </>
      );
    }

    return (
      <NavigationBar currentRoute={'/data-import'}>
        <Container fluid className="mt-3 E-margin">
          <Row className="m-1">
            <Col className="mt-1">
              <h4 className="mt-1 mb-4">Instructions</h4>
              <DataInstructions />
            </Col>
          </Row>
          <Row className="m-1">
            <Col md={12}>
              <h4 className="d-inline-block">Select File:</h4>
              <DropZone onFiles={this.onFiles} className={'m-2 d-inline-block'}>
                <Badge style={{ position: 'relative', bottom: 2.5 }} className="p-2 pointer">
                  Browse
                </Badge>
              </DropZone>
              <br />
              <div className="mt-4">
                {file && (
                  <>
                    <span className="mr-3 mt-1">
                      <span className="text-muted">Filename:</span> {file.name}
                    </span>
                    <button
                      style={{ position: 'relative', top: -2 }}
                      className="btn btn-link p-0"
                      size={'sm'}
                      onClick={this.onSubmit}>
                      <Icon style={{ position: 'relative', top: -2 }} icon={upload} /> Upload File
                    </button>
                  </>
                )}
              </div>
            </Col>
          </Row>
          {content}
        </Container>
        <ConfirmModal
          active={this.state.openConfirmUploadDialog}
          toggle={() => this.setState({ openConfirmUploadDialog: false })}
          title="Data already exist"
          body={confirmModalBody}
          onResponse={(response) => {
            if (response) this.onSave();
            else this.setState({ canceled: true });
          }}
        />
      </NavigationBar>
    );
  }
}

export default DataImportView;
