import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col, Row, Input } from 'reactstrap';
import { Card, BoxInfo } from './StepCard';
import { RequiredInputLabel } from '../../../../shared/components/typography/RequiredInputLabel';
import { ErrorInputMsg } from '../../../../shared/components/typography/ErrorInputMsg';

const InputDrivers = styled(Input)`
  width: 100%;
  display: inline-block !important;
`;

const Step3 = ({ active, editable, onChange, solution, loading, onError }) => {
  return (
    <Card active={active}>
      <Row className="d-flex flex-column">
        <h4>Step 3</h4>
        <div>Implementing Solutions</div>
      </Row>
      <Row className="mt-4 d-flex flex-column">
        <RequiredInputLabel>Implementation Plan Summary</RequiredInputLabel>
        {editable && active ? (
          <textarea
            rows="6"
            className="form-control"
            onChange={(e) => onChange('implementationPlan', e.target.value)}
            value={solution.implementationPlan}
            disabled={loading}
          />
        ) : (
          <BoxInfo>{solution.implementationPlan}</BoxInfo>
        )}
        <ErrorInputMsg>{onError('implementationPlan')}</ErrorInputMsg>
      </Row>
      <Row className="mt-4 d-flex flex-column">
        <RequiredInputLabel>Implementation Results</RequiredInputLabel>
        {editable && active ? (
          <textarea
            rows="6"
            className="form-control"
            onChange={(e) => onChange('implementationCompletion', e.target.value)}
            value={solution.implementationCompletion}
            disabled={loading}
          />
        ) : (
          <BoxInfo>{solution.implementationCompletion}</BoxInfo>
        )}
        <ErrorInputMsg>{onError('implementationCompletion')}</ErrorInputMsg>
      </Row>
      <Row className="mt-4 d-flex flex-row justify-content-between">
        <Col>
          <RequiredInputLabel>Status</RequiredInputLabel>
          {editable && active ? (
            <InputDrivers
              type="select"
              value={solution.status}
              onChange={(e) => onChange('status', e.target.value)}
              disabled={loading}>
              <option value="inProgress">In progress</option>
              <option value="completed">Completed</option>
            </InputDrivers>
          ) : (
            <BoxInfo>{solution.status}</BoxInfo>
          )}
        </Col>
        <Col>
          <>
            {solution.status === 'completed' && editable && active ? (
              <>
                <RequiredInputLabel>Completion Date</RequiredInputLabel>
                <InputDrivers
                  type="date"
                  value={solution.completionDate}
                  onChange={(e) => onChange('completionDate', e.target.value)}
                  disabled={loading}
                />
              </>
            ) : (
              <>
                <label>Completion Date</label>
                <BoxInfo>{solution.completionDate}</BoxInfo>
              </>
            )}
          </>
        </Col>
      </Row>
    </Card>
  );
};

Step3.propTypes = {
  active: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  solution: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onError: PropTypes.func.isRequired,
};

export default Step3;
