import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a My VOICE error.
 *
 * @type {string}
 */
export const MY_VOICE_ERROR_EVENT = 'onMyVoiceErrorEvent';
export const MY_VOICE_INITIAL_ERROR_EVENT = 'onMyVoiceInitialErrorEvent';

/**
 * Event that triggers a My Voice fetch Event.
 *
 * @type {string}
 */
export const MY_VOICE_EVENT = 'onMyVoiceEvent';
export const MY_VOICE_INITIAL_EVENT = 'onMyVoiceInitialEvent';
export const MY_VOICE_COA_EVENT = 'onMyVoiceCoaEvent';
export const SCHOOL_AVERAGE_EVENT = 'onSchoolAverageEvent';
export const SCHOOL_INITIAL_AVERAGE_EVENT = 'onSchoolInitialAverageEvent';
export const AVG_QUESTIONS_TEACHER_EVENT = 'avgQuestionsTeacherEvent';
export const AVG_QUESTIONS_TEACHER_EVENT_COA = 'avgQuestionsTeacherEventCoa';
export const AVG_QUESTIONS_TEACHER_EVENT_CLF = 'avgQuestionsTeacherEventClf';

/**
 * Event that triggers a My Voice update Event.
 *
 * @type {string}
 */
export const MY_VOICE_UPDATE_EVENT = 'onMyVoiceUpdateEvent';

class MyVoiceStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(MY_VOICE_ERROR_EVENT);
    this.addEvent(MY_VOICE_EVENT);
    this.addEvent(MY_VOICE_INITIAL_ERROR_EVENT);
    this.addEvent(MY_VOICE_INITIAL_EVENT);
    this.addEvent(MY_VOICE_COA_EVENT);
    this.addEvent(MY_VOICE_UPDATE_EVENT);
    this.addEvent(SCHOOL_AVERAGE_EVENT);
    this.addEvent(SCHOOL_INITIAL_AVERAGE_EVENT);
    this.addEvent(AVG_QUESTIONS_TEACHER_EVENT);
    this.addEvent(AVG_QUESTIONS_TEACHER_EVENT_COA);
    this.addEvent(AVG_QUESTIONS_TEACHER_EVENT_CLF);
  }
}

const myVoiceStore = new MyVoiceStore();

export { myVoiceStore };
