import React, { useEffect, useState } from 'react';
import { NavigationBar } from '../../shared/layouts/NavigationBar';
import { TableUsers } from './components/TableUsers';
import { plus as ic_plus } from 'react-icons-kit/fa/plus';
import { withRouter } from 'react-router-dom';
import { authStore, USER_EVENT } from '../auth/auth-store';
import PropTypes from 'prop-types';
import { ManagementView, TABLE_LIMIT } from '../../components/ManagementView';
import {
  getAllSchoolsAction,
  getUsersAction,
  updateUserRosterManager,
} from './roster-manager-actions';
import useFetchPage from '../../shared/useFetchPage';
import { ButtonUsersFilter } from '../super-admin/components/ButtonFilterUsers';
import { USER_TYPE_COA } from '../../shared/userTypes';
import { useDebounce } from '../../shared/hooks';
import { customToast } from '../../shared/toast';
import { Container } from 'reactstrap';
import {
  rosterManagerStore,
  ROSTER_MANAGER_ADD_USER_ERROR_EVENT,
  ROSTER_MANAGER_ADD_USER_EVENT,
  ROSTER_MANAGER_ALL_SCHOOLS_EVENT,
  ROSTER_MANAGER_UPDATE_USER_ERROR_EVENT,
  ROSTER_MANAGER_UPDATE_USER_EVENT,
} from './RosterManagerStore';
import { useSubscription } from '@cobuildlab/react-flux-state';
import { queryClient } from '../../shared/utils';
import { superAdminStore, SUPER_ADMIN_ALL_DISTRICTS_EVENT } from '../super-admin/super-admin-store';
import { getAllDistricts } from '../super-admin/super-admin-actions';
import EditUserModal from '../../components/user/EditUserModal';
import AddUserModalContainer from './components/AddUserModalContainer';

const fetchKey = 'rosterManagerView';

export const initialOtherSearch = {
  school: null,
  role: '',
  onlyActives: 'active',
  generalSearch: '',
};

const RosterManagerView = ({ history }) => {
  const user = authStore.getState(USER_EVENT);

  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [schools, setSchools] = useState([]);
  const [districts, setDistricts] = useState([]);

  const [loadingEdit, setLoadingEdit] = useState(false);
  const [filters, setFilters] = useState(initialOtherSearch);

  const [search, setSearch] = useState('');
  const [userData, setUserData] = useState(null);

  if (!user.isRosterManager) history.push('/');

  const { isLoading, data, fetchNextPage, isFetchingNextPage: isLoadingMore } = useFetchPage(
    [fetchKey, filters],
    ({ queryKey, pageParam = null }) => {
      const [, rawFilters] = queryKey;
      const filters = { ...rawFilters };
      return getUsersAction(filters, TABLE_LIMIT, pageParam);
    },
    {
      onError: (err) => {
        customToast.error(err);
      },
    },
  );

  useDebounce(() => {
    setFilters({ ...filters, generalSearch: search });
  }, search);

  const toggleCreate = () => setCreateModal(!createModal);
  const toggleEdit = () => setEditModal(!editModal);

  const handleEditModal = (user) => {
    setLoadingEdit(true);
    updateUserRosterManager(user);
  };

  const handleEdit = (userDetail) => {
    setUserData(userDetail);
    toggleEdit();
  };

  useSubscription(rosterManagerStore, ROSTER_MANAGER_ALL_SCHOOLS_EVENT, (schools) => {
    setSchools(schools);
  });

  // Add user events
  useSubscription(rosterManagerStore, ROSTER_MANAGER_ADD_USER_EVENT, () => {
    queryClient.invalidateQueries([fetchKey]);
    customToast.success('User created successfully');
  });
  useSubscription(rosterManagerStore, ROSTER_MANAGER_ADD_USER_ERROR_EVENT, (err) => {
    customToast.error(err);
  });
  useSubscription(superAdminStore, SUPER_ADMIN_ALL_DISTRICTS_EVENT, (districts) => {
    setDistricts(districts);
  });

  // Update Events
  useSubscription(rosterManagerStore, ROSTER_MANAGER_UPDATE_USER_EVENT, () => {
    customToast.success('User updated successfully');
    toggleEdit();
    setLoadingEdit(false);
    queryClient.invalidateQueries([fetchKey]);
  });
  useSubscription(rosterManagerStore, ROSTER_MANAGER_UPDATE_USER_ERROR_EVENT, (error) => {
    console.log(error);
    customToast.error('Error updating user');
    setLoadingEdit(false);
  });

  useEffect(() => {
    getAllSchoolsAction();
    getAllDistricts();
  }, []);

  const loading = isLoading || isLoadingMore;

  const isCoa = user.userType === USER_TYPE_COA;

  return (
    <NavigationBar currentRoute={'/roster-manager'}>
      <Container fluid className="mt-2">
        <ManagementView
          openFilterButton={
            <ButtonUsersFilter
              data={filters}
              onChange={(values) => {
                setFilters({ ...values });
              }}
              fixedValues={{
                schoolId: !isCoa ? user.schoolId : null,
                districtId: user.districtId,
              }}
              isLoading={loading}
            />
          }
          count={data.count}
          haveMoreToLoad={data.haveMoreToLoad}
          searchInput={{
            value: search,
            onChange: (value) => setSearch(value),
          }}
          isLoading={loading}
          buttomActions={[
            {
              onClick: toggleCreate,
              title: 'Add User',
              icon: ic_plus,
            },
          ]}>
          <TableUsers
            isLoading={loading}
            isMoreToLoad={data.haveMoreToLoad}
            onLoadMore={() => fetchNextPage({ pageParam: data.startAfter })}
            rows={data.users || []}
            onEdit={handleEdit}
          />
          {userData && (
            <EditUserModal
              loading={loadingEdit}
              user={userData}
              schools={schools}
              districts={districts}
              onSubmit={handleEditModal}
              onToggle={toggleEdit}
              isOpen={editModal}
            />
          )}
          <AddUserModalContainer toggle={toggleCreate} modal={createModal} />
        </ManagementView>
      </Container>
    </NavigationBar>
  );
};

RosterManagerView.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(RosterManagerView);
