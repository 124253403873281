import React from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { ClipLoader } from '../../shared/components/progress/ClipLoader';

/**
 * Loading Row Component.
 *
 * @returns {*} - Loading Row Component.
 * @class
 */
const LoadingRow = ({ colSpan = 0 }) => (
  <tr>
    <td colSpan={colSpan}>
      <Col md={12} className="content-sub-bar justify-content-center d-flex">
        <ClipLoader size={150} />
      </Col>
    </td>
  </tr>
);

LoadingRow.defaultProps = {
  colSpan: 0,
};

LoadingRow.propTypes = {
  colSpan: PropTypes.number,
};

export default LoadingRow;
