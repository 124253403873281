import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSubscription } from '@cobuildlab/react-flux-state';
import { Button } from 'reactstrap';
import { ButtonFiltersMenu } from '../../../shared/components/menus/ButtonFiltersMenu';
import { SchoolsFilter } from '../../../shared/components/inputs/SchoolsFilter';
import { superAdminStore, SUPER_ADMIN_ALL_DISTRICTS_EVENT } from '../super-admin-store';
import { getAllDistricts } from '../super-admin-actions';
import { initialOtherSearch as initialSearchData } from './SchoolSearchForm';

export const ButtonSchoolFilter = ({ data, onChange, isMulti, isLoading }) => {
  const [localData, setLocalData] = useState(initialSearchData);
  const [districts, setDistricts] = useState([]);
  const [isLoadingDistricts, setIsLoadingDistricts] = useState(false);

  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    getAllDistricts();
    setIsLoadingDistricts(true);
  }, []);

  useSubscription(superAdminStore, SUPER_ADMIN_ALL_DISTRICTS_EVENT, (allDistricts) => {
    setIsLoadingDistricts(false);
    setDistricts(allDistricts);
  });

  const handleOnLocalDataChange = (newValues) => {
    const auxLocalData = { ...localData };
    Object.assign(auxLocalData, newValues);
    setLocalData({ ...auxLocalData });
  };

  const changedFilters = JSON.stringify(localData) !== JSON.stringify(data);
  const isEmptyFilter = JSON.stringify(localData) === JSON.stringify(initialSearchData);

  const handleApply = () => {
    const newFilters = { ...localData };
    if (newFilters.district) {
      newFilters.districtId = newFilters.district.id;
    }
    if (newFilters.schoolType) {
      newFilters.schoolType = newFilters.schoolType.value;
    }
    if (newFilters.search) {
      newFilters.school = newFilters.search;
    }
    onChange(newFilters);
  };

  return (
    <ButtonFiltersMenu
      open={openMenu}
      onChangeOpenMenu={(val) => setOpenMenu(val)}
      trigger="click"
      placement="right"
      id="btn-schools-filters"
      popoverProps={{ className: 'popover-behind' }}>
      <SchoolsFilter
        data={localData}
        districts={districts}
        isLoadingDistricts={isLoadingDistricts}
        onChange={handleOnLocalDataChange}
        isMulti={isMulti}
        disabled={isLoading}
      />
      <div className="d-flex justify-content-end flex-nowrap">
        <Button
          color="light"
          className="btn-input-size"
          onClick={() => {
            setOpenMenu(false);
            onChange(initialSearchData);
            setLocalData(initialSearchData);
          }}
          disabled={isLoading || isEmptyFilter}>
          Clear
        </Button>
        <Button
          className="ml-3 btn-input-size"
          color="primary"
          onClick={() => {
            setOpenMenu(false);
            handleApply();
          }}
          disabled={isLoading || !changedFilters}>
          Apply
        </Button>
      </div>
    </ButtonFiltersMenu>
  );
};

ButtonSchoolFilter.defaultProps = {
  isMulti: false,
  isLoading: false,
};

ButtonSchoolFilter.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  isLoading: PropTypes.bool,
};
