import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import './shared/assets/scss/theme.scss';
import App from './App';
import { default as config } from './config/firebase.json';
import firebase from 'firebase';
import * as serviceWorker from './serviceWorker';

firebase.initializeApp(config);

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
