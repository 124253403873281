import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import InstructionalIcon from '../../../shared/assets/icons/instructional-autonomy.svg';
import { updateIndicator, updateIndicators } from '../my-voice-actions';
import { INITIAL_INSTRUCTIONAL_INDICATORS, INSTRUCTIONAL_INDICATORS } from '../my-voice-models';
import { DriverDetailsForm } from '../components/DriversDetailsForm';

class DriverInstructionalView extends Component {
  constructor(props) {
    super(props);
    const data = this.props.data;
    const indicators = updateIndicators(
      R.clone(this.props.isInitial ? INITIAL_INSTRUCTIONAL_INDICATORS : INSTRUCTIONAL_INDICATORS),
      data,
    );
    this.state = {
      indicators,
    };
  }

  onClickStep = (indicatorId, indicatorValue) => {
    const indicators = updateIndicator(this.state.indicators, indicatorId, indicatorValue);
    this.setState({ indicators });
  };

  onSubmit = () => {
    this.props.onSubmit(this.state.indicators);
  };

  render() {
    const { indicators } = this.state;
    const { submitText } = this.props;
    return (
      <DriverDetailsForm
        icon={InstructionalIcon}
        title="Instructional Autonomy"
        message="Teachers have the flexibility to make decisions about the success of each student
        using multiple metrics. Standards determine the core framework for teachers;
        however, flexibility, innovation, and personalization in instructional design is
        encouraged, supported, and shared."
        indicators={indicators}
        onChangeIndicator={(id, stepNumber) => this.onClickStep(id, stepNumber)}
        submitText={submitText}
        onSubmit={this.onSubmit}
      />
    );
  }
}

DriverInstructionalView.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  isInitial: PropTypes.bool,
};

DriverInstructionalView.defaultProps = {
  submitText: 'Update',
  isInitial: false,
};

export default DriverInstructionalView;
