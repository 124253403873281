import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TableSortable from '../../../components/table/TableSortable';
import { StatusBadge } from '../../../shared/components/badge/StatusBadge';
import Icon from 'react-icons-kit';
import { formatStringToId, truncateString, validateValue } from '../../../shared/utils';
import { TooltipClipboard } from '../../../shared/components/TooltipClipboard';
import { edit as ic_edit } from 'react-icons-kit/fa/edit';
import { USER_TYPE_CLF } from '../../../shared/userTypes';

export const TableUsers = ({ onEdit, rows, ...props }) => {
  const statusColumn = (val) => {
    const active = typeof val === 'boolean' ? val : true;
    return <StatusBadge active={active} />;
  };

  const mappedRows = useMemo(() => {
    return rows.map((user) => ({
      ...user,
      schoolId: user.userType === USER_TYPE_CLF ? null : user.schoolId,
      districtId: user.userType === USER_TYPE_CLF ? null : user.districtId,
    }));
  }, [rows]);

  const columns = [
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: 'Status',
      field: 'active',
      render: statusColumn,
    },
    {
      title: 'School ID',
      field: 'schoolId',
      render: (value, user) => {
        if (user.userType === 'COA') {
          return validateValue('N/A');
        } else if (value && value !== 'N/A') {
          return (
            <TooltipClipboard value={value} id={formatStringToId(`${user.id}${value}`)}>
              {validateValue(truncateString(value))}
            </TooltipClipboard>
          );
        }

        return validateValue(value && truncateString(value));
      },
    },
    {
      title: 'Region ID',
      field: 'regionId',
      render: (value, user) => {
        if (user.userType === 'COA' || user.userType === 'CLF') {
          return validateValue('N/A');
        } else if (value && value !== 'N/A') {
          return (
            <TooltipClipboard value={value} id={formatStringToId(`${user.id}${value}`)}>
              {validateValue(truncateString(value))}
            </TooltipClipboard>
          );
        }

        return validateValue(value && truncateString(value));
      },
    },
    {
      title: 'District ID',
      field: 'districtId',
      render: (value, user) => {
        const validDistrictId = !(user.districtIds || []).length;
        if (validDistrictId) {
          if (value && value !== 'N/A') {
            return (
              <TooltipClipboard value={value} id={formatStringToId(`${user.id}${value}`)}>
                {validateValue(truncateString(value))}
              </TooltipClipboard>
            );
          }
        }
        return validateValue(validDistrictId ? value && truncateString(value) : null);
      },
    },
    {
      title: 'Role',
      field: 'userType',
    },
    {
      title: 'Last Log In',
      field: 'lastLoggedIn',
      render: (value) => value || <span style={{ color: 'lightgrey' }}>N/A</span>,
    },
    {
      title: 'Origin',
      field: 'userGUID',
      render: (value) => (value ? 'Cognia' : 'MyVoice'),
    },
    {
      sort: false,
      onClick: (user) => {
        const isCogniaUser = Boolean(user.userGUID);
        if (!isCogniaUser) onEdit(user);
      },
      render: function renderButton(_, user) {
        const isCogniaUser = Boolean(user.userGUID);
        if (isCogniaUser) return null;
        return (
          <span className="table-button-edit">
            <Icon icon={ic_edit} size="25px" />
          </span>
        );
      },
    },
  ];

  return <TableSortable columns={columns} rows={mappedRows} {...props} />;
};

TableUsers.propTypes = {
  onEdit: PropTypes.func.isRequired,
};
