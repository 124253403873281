import React from 'react';
import ManageSchoolModal from './ManageSchoolModal';
import PropTypes from 'prop-types';

/**
 * Add School Modal Component.
 *
 * @param {boolean} isOpen - Define if modal is open or close.
 * @param {Function} onToggle - Function to trigger on toggle.
 * @param {Function} onSubmit - Function to trigger on submit.
 * @param {Array} regions - Array of available regions to select.
 * @param {boolean} loading - Is component loading.
 * @returns {*} - ManageSchoolModal Component.
 * @class
 */
const AddSchoolModal = ({ isOpen, onToggle, onSubmit, regions, loading = false }) => {
  return (
    <ManageSchoolModal
      regions={regions}
      onSubmit={onSubmit}
      onToggle={onToggle}
      isOpen={isOpen}
      loading={loading}
    />
  );
};

AddSchoolModal.defaultProps = {
  loading: false,
};

AddSchoolModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  regions: PropTypes.array.isRequired,
  loading: PropTypes.bool,
};

export default AddSchoolModal;
