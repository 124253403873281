import React from 'react';
import Button from 'reactstrap/es/Button';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { ic_save } from 'react-icons-kit/md/ic_save';
import { theme } from '../../../shared/theme';

const iconStyle = {
  transform: 'translate(0px, -2px)',
};

const downloadButtonStyle = {
  backgroundColor: theme.palette.primary,
  borderRadius: '50%',
  textCenter: 'center',
  height: 40,
  width: 40,
  padding: 0,
};

/**
 * Save Report Button.
 *
 * @param {Function} onClick - Function triggered on click.
 * @returns {*} - Export Report Button Component.
 * @class
 */
const DownloadReportButton = ({ url }) => {
  const handleClick = () => {
    window.open(url);
  };

  return (
    <Button style={downloadButtonStyle} onClick={handleClick}>
      <Icon style={iconStyle} size={18} icon={ic_save} />
    </Button>
  );
};

DownloadReportButton.propTypes = {
  url: PropTypes.string.isRequired,
};

export default DownloadReportButton;
