import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Row } from 'reactstrap';
import ActiveSelect from './ActiveSelect';
import { MinimumMaximumInput } from './MinimumMaximumInput';

export const DistrictsFilter = ({ data, onChange, disabled }) => {
  const handleOnChange = (values) => {
    const auxData = { ...data };
    Object.assign(auxData, values);
    onChange(auxData);
  };

  return (
    <FormGroup>
      <Row>
        <Col xs={12}>
          <label>Status</label>
          <ActiveSelect
            onChange={(onlyActives) => {
              handleOnChange({ onlyActives });
            }}
            value={data.onlyActives}
            disabled={disabled}
          />
        </Col>
        <Col xs={12}>
          <label style={{ marginTop: '1rem' }}>Teachers</label>
          <MinimumMaximumInput
            maximumInput={{
              onChange: (teachersMaximumQty) => handleOnChange({ teachersMaximumQty }),
              value: data.teachersMaximumQty,
            }}
            minimumInput={{
              onChange: (teachersMinimumQty) => handleOnChange({ teachersMinimumQty }),
              value: data.teachersMinimumQty,
            }}
            disabled={disabled}
          />
        </Col>
        <Col xs={12}>
          <label style={{ marginTop: '1rem' }}>Leaders</label>
          <MinimumMaximumInput
            maximumInput={{
              onChange: (leadersQtyMaximumQty) => handleOnChange({ leadersQtyMaximumQty }),
              value: data.leadersQtyMaximumQty,
            }}
            minimumInput={{
              onChange: (leadersQtyMinimumQty) => handleOnChange({ leadersQtyMinimumQty }),
              value: data.leadersQtyMinimumQty,
            }}
            disabled={disabled}
          />
        </Col>
        <Col xs={12}>
          <label style={{ marginTop: '1rem' }}>Schools</label>
          <MinimumMaximumInput
            maximumInput={{
              onChange: (schoolsQtyMaximum) => handleOnChange({ schoolsQtyMaximum }),
              value: data.schoolsQtyMaximum,
            }}
            minimumInput={{
              onChange: (schoolsQtyMinimum) => handleOnChange({ schoolsQtyMinimum }),
              value: data.schoolsQtyMinimum,
            }}
            disabled={disabled}
          />
        </Col>
      </Row>
    </FormGroup>
  );
};

DistrictsFilter.defaultProps = {
  fixedValues: {},
  isMulti: false,
  disabled: false,
};

DistrictsFilter.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
