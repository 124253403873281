import styled from 'styled-components';
import { theme } from '../../../../shared/theme';

export const Card = styled.div`
  width: 400px;
  min-height: 640px;
  background-color: #ffff !important;
  color: ${theme.palette.primary};
  border: 1px solid transparent;
  border-radius: 0.8rem !important;
  transition: box-shadow ease 0.5s;
  padding: 30px;
  margin: 0 10px;
  box-shadow: 1px 1px 5px 1px #0000002e;
  &:hover {
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.24);
  }
  opacity: ${(props) => (props.active ? '1' : '0.3')};
`;

export const BoxInfo = styled.div`
  width: 100%;
  color: ${theme.palette.primary};
  padding: 5px;
`;
