import React from 'react';
import View from '@cobuildlab/react-flux-state';
// import firebase from 'firebase';
// import Moment from 'react-moment';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import schoolDefaultImg from '../../shared/assets/images/school-default.png';
// import { authStore, UPDATE_USER_EVENT, USER_EVENT } from '../auth/auth-store';
import { fetchHome } from './home-actions';
import { HOME_ERROR, HOME_EVENT, homeStore } from './home-store';
import { NavigationBar } from '../../shared/layouts/NavigationBar';
import { onErrorMixin } from '../../shared/mixins';
// import { isValidString } from '../../shared/strings';
// import { capitalize } from 'lodash';
import styled from 'styled-components';
import { MessageCard } from './components/MessageCard';
import { ClipLoader } from '../../shared/components/progress/ClipLoader';
import LoadMoreButton from '../super-admin/components/LoadMoreButton';
import { getUserDistrictMessages } from '../my-district/my-district-actions';
import {
  GET_MESSAGES_ERROR,
  GET_MESSAGES_EVENT,
  myDistrictStore,
} from '../my-district/my-district-store';
import { customToast } from '../../shared/toast';

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
`;

class HomeView extends View {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      lastDoc: null,
      limit: 10,
      isMore: false,
      loadingMessages: false,
      loadingMoreMessages: false,
    };
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(homeStore, HOME_ERROR, this.onError);
    this.subscribe(homeStore, HOME_EVENT, (state) => {
      const {
        districtData: { message },
        messagePrincipal: schoolMessage,
      } = state;
      this.setState({ message, messagePrincipal: schoolMessage });
    });
    this.subscribe(myDistrictStore, GET_MESSAGES_EVENT, (data) => {
      this.setState({
        loadingMessages: false,
        loadingMoreMessages: false,
        messages: this.state.messages.concat(...data.messages),
        isMore: data.isMore,
        lastDoc: data.lastDoc,
      });
    });
    this.subscribe(myDistrictStore, GET_MESSAGES_ERROR, (err) => {
      this.setState({
        loadingMessages: false,
        loadingMoreMessages: false,
      });
      customToast.error(err);
    });
    setTimeout(() => {
      fetchHome();
      this.getMessages();
    }, 1000);

    // const test = async () => {
    //   const DB = firebase.firestore();
    //   const avg = await DB.collection('monthlySchoolAverages').where('updated', '==', false).get();
    //   const coll = DB.collection('monthlySchoolAverages');
    //   const promises = [];
    //   avg.forEach(doc => {
    //     const id = doc.id;
    //     const message = doc.data();
    //     const newMonth = message.month === 0 ? 11 : message.month - 1;
    //     const newYear = message.month === 0 ? message.year - 1 : message.year;
    //     console.log(id, message.month, message.year, newMonth, newYear, message.updated);
    //     promises.push(coll.doc(id).set({ month: newMonth, year: newYear, updated: true }, { merge: true }));
    //   });
    //   await Promise.all(promises);
    //   return;
    // };
    // test()
    //   .then((r) => console.log('DEBUG:test:', r))
    //   .catch(console.error);
  }

  getMessages(loadMore = false) {
    const { limit, lastDoc } = this.state;
    if (loadMore) this.setState({ loadingMoreMessages: true });
    else {
      this.setState({
        loadingMessages: true,
        messages: [],
        isMore: false,
        lastDoc: null,
      });
    }
    getUserDistrictMessages(limit, lastDoc);
  }

  render() {
    // const user = { ...authStore.getState(USER_EVENT), ...authStore.getState(UPDATE_USER_EVENT) };

    const { messages, isMore, loadingMessages, loadingMoreMessages } = this.state;
    // const firstName = isValidString(user.firstName) ? user.firstName : '';
    // const lastName = isValidString(user.lastName) ? user.lastName : '';
    // const name = `${capitalize(firstName)} ${capitalize(lastName)}`;
    // const { message, messagePrincipal } = this.state;
    // const logo = isValidString(user.school.logoUrl) ? user.school.logoUrl : schoolDefaultImg;

    let content = null;

    if (loadingMessages) {
      content = (
        <div className="d-flex justify-content-center">
          <ClipLoader size={150} />
        </div>
      );
    }
    if (messages.length) {
      content = messages.map((msg) => (
        <div key={msg.id}>
          <MessageCard data={msg} />
          <br />
        </div>
      ));
    }

    return (
      <NavigationBar currentRoute={'/home'}>
        <Container fluid className="pt-4">
          {/* <Row className="mt-2"> */}
          <Title className="text-center mb-5">
            MyVoice: Activating the Power of School Culture Through Teacher Voice and Agency
          </Title>
          <div style={{ width: 750, margin: 'auto' }}>
            <div style={{ height: 350 }}>
              <img width="100%" height="100%" src={schoolDefaultImg} alt="School Logo" />
            </div>
            <br />
            {content}
            {isMore ? (
              <div className="d-flex justify-content-center mt-3">
                <LoadMoreButton
                  onClick={() => this.getMessages(true)}
                  loading={loadingMoreMessages}
                />
              </div>
            ) : null}
          </div>
        </Container>
      </NavigationBar>
    );
  }
}

export default withRouter(HomeView);
