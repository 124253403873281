import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { ButtonFiltersMenu } from '../../../shared/components/menus/ButtonFiltersMenu';
import { INITIAL_FILTERS } from '../DistrictsManagementView';
import { DistrictsFilter } from '../../../shared/components/inputs/DistrictsFilter';

export const ButtonFilterDistrict = ({ data, onChange, isLoading }) => {
  const [localData, setLocalData] = useState({ ...INITIAL_FILTERS });
  const [openMenu, setOpenMenu] = useState(false);

  const handleOnLocalDataChange = (newValues) => {
    const auxLocalData = { ...localData };
    Object.assign(auxLocalData, newValues);
    setLocalData({ ...auxLocalData });
  };

  const changedFilters = JSON.stringify(localData) !== JSON.stringify(data);
  const isEmptyFilter = JSON.stringify(localData) === JSON.stringify(INITIAL_FILTERS);

  return (
    <ButtonFiltersMenu
      open={openMenu}
      onChangeOpenMenu={(val) => setOpenMenu(val)}
      trigger="click"
      placement="right"
      id="btn-districts-filters"
      popoverProps={{ className: 'popover-behind' }}>
      <DistrictsFilter data={localData} onChange={handleOnLocalDataChange} disabled={isLoading} />
      <div className="d-flex justify-content-end flex-nowrap">
        <Button
          color="light"
          className="btn-input-size"
          onClick={() => {
            setOpenMenu(false);
            onChange(INITIAL_FILTERS);
            setLocalData(INITIAL_FILTERS);
          }}
          disabled={isLoading || isEmptyFilter}>
          Clear
        </Button>
        <Button
          className="ml-3 btn-input-size"
          color="primary"
          onClick={() => {
            setOpenMenu(false);
            onChange(localData);
          }}
          disabled={isLoading || !changedFilters}>
          Apply
        </Button>
      </div>
    </ButtonFiltersMenu>
  );
};

ButtonFilterDistrict.defaultProps = {
  isMulti: false,
  isLoading: false,
};

ButtonFilterDistrict.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
