import React from 'react';
import { ClipLoader as ReactClipLoader } from 'react-spinners';
import { useTheme } from '../../theme';

export const ClipLoader = ({ color, ...props }) => {
  const theme = useTheme();
  return <ReactClipLoader {...props} color={theme.palette.primary} />;
};

ClipLoader.defaultProps = {
  sizeUnit: 'px',
  size: 120,
  loading: true,
};
