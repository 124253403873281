import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Stepper from 'react-js-stepper';
import { Row, Button } from 'reactstrap';
import { Icon } from 'react-icons-kit';
import { close as closeIcon } from 'react-icons-kit/fa/close';
import { arrowLeft as backArrowIcon } from 'react-icons-kit/fa/arrowLeft';
import styled from 'styled-components';
import { customToast } from '../../../../shared/toast';
import { SOLUTIONS_STAGES } from '../../solutions-models';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import LoadingButton from '../../../../components/button/LoadingButton';

const Steppers = styled.div`
  display: flex;
  justify-content: space-between;
  margin: auto;
`;

const BackToGrid = styled(Button)`
  text-decoration: none !important;
  display: flex !important;
  &:hover {
    text-decoration: none !important;
  }
`;

const BackToGridIcon = styled(Icon)`
  margin-top: -2px;
  margin-right: 5px;
`;

const solutionInitialData = {
  name: '',
  stage: '',
  driverDomain: '',
  relatedIndicators: [],
  needsIdentified: '',
  desiredOutcomes: '',
  summary: '',
  goalDesired: '',
  implementationPlan: '',
  implementationCompletion: '',
  errors: {},
  status: 'inProgress',
  completionDate: '',
};

const SolutionForm = ({ editable, onSubmit, solutionData, saving, saved, onClose, onDelete }) => {
  const [step, setStep] = useState(1);
  const [solution, setSolution] = useState(solutionInitialData);
  const solutionActualStage = SOLUTIONS_STAGES.find((stg) => stg.id === solution.stage);

  useEffect(() => {
    if (solutionData) {
      solutionData.errors = {};
      const solutionStage = SOLUTIONS_STAGES.find((stg) => stg.id === solutionData.stage);
      setStep(solutionStage.number);
      setSolution(solutionData);
    } else {
      setStep(1);
      setSolution(solutionInitialData);
    }
  }, [solutionData]);

  useEffect(() => {
    if (!saving && saved) {
      if (solutionData) {
        const newStep = step === 3 ? step : step + 1;
        setStep(newStep);
      } else setSolution(solutionInitialData);
    }
  }, [saving, saved]);

  const handleOnChange = (keys, values) => {
    const auxSolution = { ...solution };
    if (Array.isArray(keys) && Array.isArray(values)) {
      keys.forEach((key, index) => (auxSolution[key] = values[index]));
    } else {
      auxSolution[keys] = values;
    }
    setSolution(auxSolution);
  };

  const handleOnSubmit = () => {
    if (handleValidation()) {
      const newStage = SOLUTIONS_STAGES.find((stg) => stg.number === step);
      const auxSolution = { ...solution };

      if (
        !solution.stage.length ||
        (solutionActualStage ? solutionActualStage.number : 0) <= newStage.number
      ) {
        auxSolution.stage = newStage.id;
      }
      onSubmit(auxSolution);
    } else {
      customToast.error('All fields are required');
    }
  };

  const handleValidation = () => {
    let fields = solution;
    let errors = {};
    let formIsValid = true;

    console.log(fields.name);
    if (
      (step === 1 && typeof solutionActualStage == 'undefined') ||
      (step === 1 && solutionActualStage.number === 1) ||
      (step === 2 && solutionActualStage.number === 2) ||
      (step === 3 && solutionActualStage.number === 2) ||
      (step === 3 && solutionActualStage.number === 3)
    ) {
      //relatedIndicators
      if (solution.relatedIndicators.length < 1) {
        formIsValid = false;
        errors.relatedIndicators = 'You must select at least one indicator';
      }

      //name
      if (fields.name === '') {
        formIsValid = false;
        errors.name = 'Required field';
      }

      //needsIdentified
      if (fields.needsIdentified === '') {
        formIsValid = false;
        errors.needsIdentified = 'Required field';
      }

      //desiredOutcomes
      if (fields.desiredOutcomes === '') {
        formIsValid = false;
        errors.desiredOutcomes = 'Required field';
      }
    }

    if (
      (step === 2 && solutionActualStage.number === 1) ||
      (step === 2 && solutionActualStage.number === 2) ||
      (step === 3 && solutionActualStage.number === 2) ||
      (step === 3 && solutionActualStage.number === 3)
    ) {
      //summary
      if (fields.summary === '') {
        formIsValid = false;
        errors.summary = 'Required field';
      }

      //goalDesired
      if (fields.goalDesired === '') {
        formIsValid = false;
        errors.goalDesired = 'Required field';
      }
    }

    if (
      (step === 3 && solutionActualStage.number === 2) ||
      (step === 3 && solutionActualStage.number === 3)
    ) {
      //implementationPlan
      if (fields.implementationPlan === '') {
        formIsValid = false;
        errors.implementationPlan = 'Required field';
      }

      //implementationCompletion
      if (fields.implementationCompletion === '') {
        formIsValid = false;
        errors.implementationCompletion = 'Required field';
      }
    }

    handleOnChange('errors', errors);
    return formIsValid;
  };

  const handleErrors = (field) => Object.keys(solution.errors).length > 0 && solution.errors[field];

  const isStepCompleted = (number) => {
    return solution.stage.length
      ? SOLUTIONS_STAGES.find((s) => s.id === solution.stage).number >= number
      : false;
  };

  const handleOnChangeStep = (newStep) => {
    if (
      editable &&
      isStepCompleted(newStep > 1 ? newStep - 1 : newStep) &&
      newStep >= (solutionActualStage ? solutionActualStage.number : 0)
    ) {
      setStep(newStep);
    }
  };

  const submitButtonText =
    (solutionActualStage ? solutionActualStage.number : 0) >= 1
      ? saving
        ? 'Updating'
        : 'Update'
      : saving
        ? 'Saving'
        : 'Save';

  const stepperSteps = SOLUTIONS_STAGES.map((s) => ({ title: s.name }));

  return (
    <>
      <Row>
        <BackToGrid color="link" onClick={onClose}>
          <BackToGridIcon icon={backArrowIcon} />
          Solutions Dashboard
        </BackToGrid>
        {solution.id && editable && (
          <BackToGrid color="link" onClick={onDelete}>
            <BackToGridIcon icon={closeIcon} />
            Delete solution
          </BackToGrid>
        )}
        <div className="ml-auto">
          {editable ? (
            <LoadingButton loading={saving} onClick={handleOnSubmit} color="secondary">
              {submitButtonText}
            </LoadingButton>
          ) : null}
        </div>
      </Row>
      <Row className="mb-4">
        <Stepper
          onSelect={handleOnChangeStep}
          showNumber={true}
          steps={stepperSteps}
          activeStep={step}
        />
      </Row>
      <Row>
        <Steppers>
          <Step1
            active={step >= 1}
            solution={solution}
            editable={editable}
            onChange={handleOnChange}
            loading={saving}
            onError={handleErrors}
          />
          <Step2
            active={step >= 2}
            solution={solution}
            editable={editable}
            onChange={handleOnChange}
            loading={saving}
            onError={handleErrors}
          />
          <Step3
            active={step === 3}
            solution={solution}
            editable={editable}
            onChange={handleOnChange}
            loading={saving}
            onError={handleErrors}
          />
        </Steppers>
      </Row>
    </>
  );
};

SolutionForm.defaultProps = {
  solutionData: null,
};

SolutionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  solutionData: PropTypes.object,
  saving: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default SolutionForm;
