import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import CollaborationIcon from '../../../shared/assets/icons/collaboration.svg';
import { updateIndicator, updateIndicators } from '../my-voice-actions';
import { COLLABORATION_INDICATORS, INITIAL_COLLABORATION_INDICATORS } from '../my-voice-models';
import { DriverDetailsForm } from '../components/DriversDetailsForm';

class DriverCollaborationView extends Component {
  constructor(props) {
    super(props);
    const data = this.props.data;
    const indicators = updateIndicators(
      R.clone(this.props.isInitial ? INITIAL_COLLABORATION_INDICATORS : COLLABORATION_INDICATORS),
      data,
    );
    this.state = {
      indicators,
    };
  }

  onClickStep = (indicatorId, indicatorValue) => {
    const indicators = updateIndicator(this.state.indicators, indicatorId, indicatorValue);
    this.setState({ indicators });
  };

  onSubmit = () => {
    this.props.onSubmit(this.state.indicators);
  };

  render() {
    const { indicators } = this.state;
    const { submitText } = this.props;
    return (
      <DriverDetailsForm
        icon={CollaborationIcon}
        title="Collaboration"
        message="Collaborative planning is required to develop and share instructional resources, and
        to embed the professional learning needed for expanding and improving teacher
        practices. New instructional designs emanate from shared expertise and support in
        using new instructional designs, and then evaluating their effectiveness."
        indicators={indicators}
        onChangeIndicator={(id, stepNumber) => this.onClickStep(id, stepNumber)}
        submitText={submitText}
        onSubmit={this.onSubmit}
      />
    );
  }
}

DriverCollaborationView.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  isInitial: PropTypes.bool,
};

DriverCollaborationView.defaultProps = {
  submitText: 'Update',
  isInitial: false,
};

export default DriverCollaborationView;
