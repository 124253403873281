import React from 'react';
import PropTypes from 'prop-types';
import View from '@cobuildlab/react-flux-state';
import firebase from 'firebase';
import { fetchUser, logOutAction } from '../modules/auth/auth-actions';
import { authStore, USER_EVENT } from '../modules/auth/auth-store';
import { withRouter } from 'react-router-dom';
import { onErrorMixin } from '../shared/mixins';
import {
  USER_TYPE_CLF,
  USER_TYPE_COA,
  USER_TYPE_LEADER,
  USER_TYPE_TEACHER,
} from '../shared/userTypes';
import { LoadingPage } from '../shared/components/progress/LoadingPage';

/**
 * User Session component.
 */
class Session extends View {
  constructor(props) {
    super(props);
    this.state = {
      firebaseAcquired: false,
    };
    this.onError = onErrorMixin.bind(this);
  }

  async componentDidMount() {
    this.subscribe(authStore, USER_EVENT, (user) => {
      this.setState({ firebaseAcquired: true }, () => {
        if (user.needsPasswordReset || !user.active) {
          logOutAction().then(() => this.props.history.push('/login'));
        } else {
          let redirectTo = '';

          if (user.userType === USER_TYPE_COA)
            redirectTo = user.needsProfile ? 'initial-assessment' : 'my-district';
          else if (user.userType === USER_TYPE_CLF) redirectTo = 'my-district';
          else if (user.needsProfile) redirectTo = 'edit-profile';
          else if (user.userType === USER_TYPE_TEACHER) redirectTo = 'home';
          else if (user.userType === USER_TYPE_LEADER) redirectTo = 'home';

          this.props.history.push(`/${redirectTo}`);
        }
      });
    });

    const that = this;
    firebase.auth().onAuthStateChanged(async function(firebaseUser) {
      if (firebaseUser) {
        fetchUser(firebaseUser.email).catch(() => that.setState({ firebaseAcquired: true }));
      } else that.setState({ firebaseAcquired: true });
    });
  }

  render() {
    const { firebaseAcquired } = this.state;

    if (firebaseAcquired === false) return <LoadingPage />;

    return this.props.children;
  }
}

Session.propTypes = {
  client: PropTypes.any,
  logOut: PropTypes.func,
  children: PropTypes.any,
};

export default withRouter(Session);
