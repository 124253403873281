import React, { useMemo } from 'react';
import { Select } from './Select';
import PropTypes from 'prop-types';

const statusOptions = [
  { label: 'Active', value: 'active' },
  { label: 'Disabled', value: 'inactive' },
];

const ActiveSelect = ({ onChange, value, disabled, ...props }) => {
  const inputValue = useMemo(() => {
    if (value === null) return null;
    return value === 'active' ? statusOptions[0] : statusOptions[1];
  }, [value]);

  return (
    <Select
      {...props}
      disabled={disabled}
      style={{ width: '100%' }}
      placeholder="Select status"
      options={statusOptions}
      isClearable
      onChange={(status, evt) => {
        if (evt.action === 'select-option') {
          onChange(status.value);
        } else if (evt.action === 'clear') {
          onChange(null);
        }
      }}
      value={inputValue}
    />
  );
};

ActiveSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default ActiveSelect;
