import Flux from '@cobuildlab/flux-state';
import firebase from 'firebase';
import {
  ROSTER_MANAGER_ADD_USER_ERROR_EVENT,
  ROSTER_MANAGER_ADD_USER_EVENT,
  ROSTER_MANAGER_ALL_SCHOOLS_EVENT,
  ROSTER_MANAGER_UPDATE_USER_ERROR_EVENT,
  ROSTER_MANAGER_UPDATE_USER_EVENT,
  ROSTER_MANAGER_USERS_EVENT,
} from './RosterManagerStore';
import { authStore, USER_EVENT } from '../auth/auth-store';
import {
  createUser,
  editUserFirebase,
  filterQueryLike,
  setLastLoginToUsers,
  compareStartWith,
} from '../super-admin/super-admin-actions';
import { USER_TYPE_CLF, USER_TYPE_COA } from '../../shared/userTypes';
import { findIndex } from 'lodash';

/**
 * @param filters
 * @param limit
 * @param startAfterUser
 * @returns {Promise<*>}
 */
export const getUsersAction = async (filters = {}, limit = 50, startAfterUser) => {
  // GET LOGGED IN USER
  const user = authStore.getState(USER_EVENT);

  // USER LIST
  let users;
  let isMore;
  let startAfter;
  // VALIDATE USER TYPE
  if (user.userType !== USER_TYPE_COA) {
    filters.schoolId = user.schoolId;
  }
  ({ users, isMore, startAfter } = await searchDistrictUsers(
    user.districtId,
    filters,
    limit,
    startAfterUser,
  ));

  Flux.dispatchEvent(ROSTER_MANAGER_USERS_EVENT, {
    users,
    isMore,
    startAfter,
    clear: !startAfterUser,
  });
  return {
    users,
    isMore,
    startAfter,
    clear: !startAfterUser,
  };
};

/**
 * @param filters
 * @param limit
 * @param startAfterEmail
 * @returns {Promise<*>}
 */
export const getAllSchoolsAction = async () => {
  const DB = firebase.firestore();
  const schoolsCollection = DB.collection('schools');

  // GET LOGGED IN USER
  const user = authStore.getState(USER_EVENT);

  let schools;
  if (user.userType === USER_TYPE_COA) {
    ({ data: schools } = await getSchoolsByDistrictId(user.districtId));
  } else {
    const schoolDoc = await schoolsCollection.doc(user.schoolId).get();
    const school = { id: schoolDoc.id, ...schoolDoc.data() };
    schools = [school];
  }

  Flux.dispatchEvent(ROSTER_MANAGER_ALL_SCHOOLS_EVENT, schools);
  return schools;
};

/**
 * @param {number} districtId - District Id.
 * @param {object} filters - Filters.
 * @param {number} limit - Limit number of results.
 * @param {object | null} startAfterUser - User Object with field and value to use startAfter.
 * @returns {Promise<{startAfter, isMore: boolean, users: {id: *}[]}>} - Users Firebase references.
 */
async function searchDistrictUsers(districtId, filters, limit, startAfterUser) {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');

  // Get users in Schools
  let usersByDistrictQuery = usersCollection;

  if (filters.schoolId) {
    usersByDistrictQuery = usersByDistrictQuery.where('schoolId', '==', filters.schoolId);
  } else {
    usersByDistrictQuery = usersByDistrictQuery.where('districtId', '==', districtId);
  }

  const { docs: usersDocs, isMore } = await getFilterUsers(
    usersByDistrictQuery,
    filters,
    limit,
    startAfterUser,
  );

  // Filter User Types in Schools Locally
  const users = usersDocs.map((doc) => ({ id: doc.id, ...doc.data() }));

  await setLastLoginToUsers(users);

  const lastUser = users.lastItem;

  return {
    users,
    isMore,
    startAfter: lastUser,
  };
}

/**
 * Get Users Filtered.
 *
 * @param {firebase.firestore.Query} query - Query to be filtered.
 * @param {object} filters - Filters.
 * @param {number} limit - Limit.
 * @param {object | null} startAfter - Start after object.
 * @param {Array<any>} lastDocs - Last docs.
 * @param {boolean} filterCLF - Remove clf users.
 * @returns {Promise<{docs: firebase.firestore.QueryDocumentSnapshot[], isMore: boolean}>} - Returns User Docs.
 */
export async function getFilterUsers(
  query,
  filters,
  limit,
  startAfter,
  lastDocs = [],
  filterCLF = false,
) {
  const lastDocsMap = {};
  for (const doc of lastDocs) {
    if (lastDocsMap[doc.id]) continue;
    lastDocsMap[doc.id] = doc;
  }

  if (
    filters.generalSearch ||
    filters.email ||
    filters.firstName ||
    filters.lastName ||
    filters.schoolId ||
    filters.districtId ||
    filters.role ||
    filters.onlyActives ||
    (filters.containsDistrictIds && filters.containsDistrictIds.length) ||
    (filters.containsSchoolIds && filters.containsSchoolIds.length)
  ) {
    let isMore = false;
    let userDocsMap = {};
    let queriesResults = [];
    let lastEmailResult = startAfter ? startAfter.email : null;

    const defaultFilterQuery = async (func, fields, value, reverChart, operator) =>
      func(query, fields, value, limit, lastEmailResult, reverChart, operator);

    const pushFilterQuery = async (func, fields, value, reverChart, operator) => {
      const data = await defaultFilterQuery(func, fields, value, reverChart, operator);
      queriesResults.push(...data.result.docs);
      isMore = isMore || data.isMore;
    };

    const maxRepeatInteracion = 3;
    let repeatedInteraction = 0;
    let lastResultCount = 0;
    do {
      if (filters.firstName || filters.generalSearch) {
        const filterValue = filters.firstName || filters.generalSearch;
        await pushFilterQuery(filterQueryLike, '_firstName_lower', filterValue.toLowerCase());
      }

      if (filters.lastName || filters.generalSearch) {
        const filterValue = filters.lastName || filters.generalSearch;
        await pushFilterQuery(filterQueryLike, '_lastName_lower', filterValue.toLowerCase());
      }

      if (filters.email || filters.generalSearch) {
        const filterValue = filters.lastName || filters.generalSearch;
        const emailFields = { normal: 'email', reversed: 'emailReversed' };
        const data = await defaultFilterQuery(
          filterQueryLike,
          emailFields,
          filterValue.toLowerCase(),
          '@',
        );

        if (data.result.size) {
          lastEmailResult = data.result.docs.lastItem.data().email;
          isMore = isMore || data.isMore;
        } else {
          isMore = false;
        }
        queriesResults.push(...data.result.docs);
      }

      if (filters.schoolId) {
        await pushFilterQuery(filterQuery, 'schoolId', filters.schoolId);
      } else if (filters.containsSchoolIds && filters.containsSchoolIds.length) {
        await pushFilterQuery(
          filterQuery,
          'schoolIds',
          filters.containsSchoolIds,
          'array-contains-any',
        );
      } else if (filters.districtId) {
        await pushFilterQuery(filterQuery, 'districtId', filters.districtId);
      } else if (filters.containsDistrictIds && filters.containsDistrictIds.length) {
        await pushFilterQuery(
          filterQuery,
          'districtIds',
          filters.containsDistrictIds,
          'array-contains-any',
        );
      }

      if (filters.role) await pushFilterQuery(filterQuery, 'userType', filters.role);

      if (filters.onlyActives) {
        if (typeof filters.onlyActives === 'boolean') {
          await pushFilterQuery(filterQuery, 'active', filters.onlyActives);
        } else {
          await pushFilterQuery(
            filterQuery,
            'active',
            filters.onlyActives === 'active' ? true : false,
          );
        }
      }

      let filtersOnlyActives = null;
      if (typeof filters.onlyActives === 'boolean') {
        filtersOnlyActives = filters.onlyActives;
      }
      if (typeof filters.onlyActives === 'string') {
        filtersOnlyActives = filters.onlyActives === 'active';
      }

      const resultMap = {};

      for (const ref of queriesResults) {
        const doc = ref.data();
        const alreadyExistDoc =
          Boolean(lastDocsMap[ref.id]) ||
          Boolean(userDocsMap[ref.id]) ||
          Boolean(resultMap[ref.id]);
        const isNotFistnameStartWith =
          filters.generalSearch && doc.firstName
            ? !compareStartWith(doc.firstName, filters.generalSearch)
            : true;
        const isNotIdStartWith =
          filters.generalSearch && !compareStartWith(ref.id, filters.generalSearch);
        const isNotStartWith = isNotIdStartWith && isNotFistnameStartWith;
        const isNotActive = filtersOnlyActives && !doc.active;
        const isActive = filtersOnlyActives === false && doc.active;
        const isNotRole = filters.role && doc.userType !== filters.role;
        const isCLF = filterCLF && doc.userType === USER_TYPE_CLF;
        const isNotSchoolId = filters.schoolId && doc.schoolId !== filters.schoolId;
        const isNotDistrictId = filters.districtId && doc.districtId !== filters.districtId;
        let isNotContainsSchoolIds = false;
        let isNotContainsDistrictIds = false;

        // VERIFY IF NOT CONTAIN FILTER SCHOOL IDS
        if (filters.containsSchoolIds && filters.containsSchoolIds.length) {
          const strUserScholIds = doc.schoolIds ? doc.schoolIds.join() : '';
          isNotContainsSchoolIds = true;
          for (const schoolId of filters.containsSchoolIds) {
            if (strUserScholIds.includes(schoolId)) {
              isNotContainsSchoolIds = false;
              break;
            }
          }

          // VERIFY IF NOT CONTAIN FILTER DISTRICT IDS
        } else if (filters.containsDistrictIds && filters.containsDistrictIds.length) {
          const strUserDistrictIds = doc.districtIds ? doc.districtIds.join() : '';
          isNotContainsDistrictIds = true;
          for (const districtId of filters.containsDistrictIds) {
            if (strUserDistrictIds.includes(districtId)) {
              isNotContainsDistrictIds = false;
              break;
            }
          }
        }

        if (
          alreadyExistDoc ||
          isNotStartWith ||
          isNotActive ||
          isNotRole ||
          isNotSchoolId ||
          isNotContainsSchoolIds ||
          isNotDistrictId ||
          isNotContainsDistrictIds ||
          isActive ||
          isCLF
        )
          continue;
        resultMap[ref.id] = ref.id;
        userDocsMap[ref.id] = ref;
      }

      const intersectionResult = Object.keys(resultMap);

      // const intersectionResult = intersectionBy(...queriesResults, (ref) => ref.id);
      // if(!intersectionResult.length && queriesResults.length)
      //   intersectionResult.push(queriesResults[0]);

      // VALID IF IS REPEAT INTERACTION RESULT
      if (
        maxRepeatInteracion === repeatedInteraction &&
        intersectionResult.length === lastResultCount
      ) {
        isMore = false;
        break;
      }

      if (intersectionResult.length === lastResultCount) {
        repeatedInteraction += 1;
      }

      if (
        !filters.email &&
        filters.firstName &&
        filters.lastName &&
        !intersectionResult.length &&
        isMore
      ) {
        lastEmailResult = true;
      }

      if (intersectionResult.length && isMore) lastEmailResult = intersectionResult.lastItem;

      lastResultCount = intersectionResult.length;
    } while (Object.keys(userDocsMap).length < limit && isMore);

    let userDocs = Object.values(userDocsMap);

    if (startAfter) {
      const index = findIndex(userDocs, (user) => user.id === startAfter.email);
      if (index >= 0) {
        const endIndex = index + limit + 1;
        isMore = isMore || endIndex < userDocs.length;
        userDocs = userDocs.slice(index + 1, endIndex);
      }
    }
    userDocs = userDocs.splice(0, limit);
    return { docs: userDocs, isMore };
  } else {
    if (startAfter) {
      query = query.orderBy('email').startAfter(startAfter.email);
    }
    const ref = await query.limit(limit).get();
    return { docs: ref.docs, isMore: ref.size === limit };
  }
}

export const getSchoolsByDistrictId = async (districtId) => {
  const getSchoolsByDistrictId = firebase
    .functions()
    .httpsCallable('getDistrictSchoolsByDistrictId');
  return await getSchoolsByDistrictId(districtId);
};

export const createUserRosterManager = async (user) => {
  try {
    const createdUser = await createUser(user);
    Flux.dispatchEvent(ROSTER_MANAGER_ADD_USER_EVENT, createdUser);
    return user;
  } catch (e) {
    Flux.dispatchEvent(ROSTER_MANAGER_ADD_USER_ERROR_EVENT, e.message);
    console.error(e);
  }
};

/**
 * Updates a User of the Platform.
 *
 * @param user - A User data.
 * @returns {Promise<object|void>} The data of the User.
 */
export async function updateUserRosterManager(user) {
  let newUserData = null;
  try {
    newUserData = await editUserFirebase(user);
  } catch (e) {
    Flux.dispatchEvent(ROSTER_MANAGER_UPDATE_USER_ERROR_EVENT, e.message);
    return;
  }

  Flux.dispatchEvent(ROSTER_MANAGER_UPDATE_USER_EVENT, newUserData);
  return newUserData;
}

/**
 *
 * @param {firebase.firestore.Query} query - Firebase Query.
 * @param {string} field - Specific fields.
 * @param {*} value - Value of the field to query by.
 * @param {number} limit - Limit Result.
 * @param {*} startAfterValue - Start Doc.
 * @param {string} operator - Operator.
 * @returns {Promise<{result: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>, isMore: boolean}>} - Firebase Query.
 */
export async function filterQuery(
  query,
  field,
  value,
  limit,
  startAfterValue = null,
  operator = '==',
) {
  query = query
    .where(field, operator, value)
    .orderBy(firebase.firestore.FieldPath.documentId())
    .limit(limit);

  if (startAfterValue) {
    query = query.startAfter(startAfterValue);
  }

  const result = await query.get();

  const isMore = result.size === limit;

  return { result, isMore };
}
