import { Col } from 'reactstrap';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { generateMonthsData } from '../my-dashboard-utils';
import PropTypes from 'prop-types';
import { MONTHS } from '../../../shared/date';

const GraphDriveTrend = ({ schoolAvgData, driverId, schoolAvgMonthlyData }) => {
  const dataTodayAverage = [];

  // Today's Average
  if (schoolAvgData !== null) MONTHS.forEach(() => dataTodayAverage.push(schoolAvgData[driverId]));

  const [categories, valueMap] = generateMonthsData(schoolAvgMonthlyData);

  const values = [];
  for (let i = 0, j = categories.length; i < j; i++) {
    const value = valueMap[categories[i]];
    if (value && value[driverId]) {
      values.push(parseFloat(value[driverId]).toFixed(2));
    } else {
      values.push(0);
    }
  }

  const exportFilename = `driver-trend-${driverId}`;

  const dataTodayAverageText = dataTodayAverage[0]
    ? "Today's Average: " + dataTodayAverage[0]
    : 'Loading';

  const state = {
    options: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          export: {
            csv: {
              filename: exportFilename,
            },
            svg: {
              filename: exportFilename,
            },
            png: {
              filename: exportFilename,
            },
          },
        },
      },
      annotations: {
        position: 'back',
        yaxis: [
          {
            y: dataTodayAverage[0],
            y2: null,
            strokeDashArray: 1,
            yAxisIndex: 0,
            label: {
              borderColor: '#ffffff',
              borderWidth: 1,
              text: dataTodayAverageText,
              position: 'right',
              style: {
                background: '#00e396',
                color: '#ffffff',
                fontSize: '12px',
                padding: {
                  left: 5,
                  right: 5,
                  top: 5,
                  bottom: 5,
                },
              },
            },
          },
        ],
      },
      stroke: {
        width: [5, 3],
        dashArray: [0, 8],
      },
      fill: {
        type: 'solid',
      },
      markers: {
        size: [5, 0],
      },
      tooltip: {
        shared: false,
        intersect: true,
      },
      legend: {
        show: true,
      },
      xaxis: {
        type: 'category',
        categories: categories,
      },
      yaxis: {
        type: 'numeric',
        min: 0,
        max: 5,
        tickAmount: 5,
      },
    },
    series: [
      {
        name: 'Monthly Snapshot',
        type: 'line',
        data: values,
      },
      {
        name: "Today's Average",
        type: 'line',
        data: dataTodayAverage,
      },
    ],
  };

  return (
    <Col md={12}>
      <div id="chart_1">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="line"
          height="300"
          width="100%"
        />
      </div>
    </Col>
  );
};

// Specifies the default values for props:
GraphDriveTrend.defaultProps = {
  schoolAvgData: {},
};

GraphDriveTrend.propTypes = {
  schoolAvgData: PropTypes.object,
  driverId: PropTypes.string.isRequired,
  schoolAvgMonthlyData: PropTypes.array.isRequired,
};

export { GraphDriveTrend };
