import React from 'react';
import View from '@cobuildlab/react-flux-state';
import { PropTypes } from 'prop-types';

import SchoolPicker from '../../my-district/components/SchoolPicker';
import { SubNavigationBar } from '../../../shared/components/menus/SubNavigationBar';
import { CultureAndCovidCollapsibleLists } from '../../../shared/components/menus/CultureAndCovidCollapsibleLists';

class DriversCoaNavigationBar extends View {
  render() {
    const {
      schools,
      school,
      dropSelect,
      onAllSchool,
      schoolDriversAverage,
      domainsAverage,
    } = this.props;

    return (
      <SubNavigationBar style={{ height: 'calc(100vh - 115px)' }}>
        <div className="school-select-container">
          School:
          <div className="d-inline-block ml-2">
            <SchoolPicker
              school={school}
              schools={schools}
              onChange={dropSelect}
              onAllSchool={onAllSchool}
            />
          </div>
        </div>
        <CultureAndCovidCollapsibleLists
          pathBase="/my-district"
          driversAvg={schoolDriversAverage || {}}
          domainsAvg={domainsAverage}
        />
      </SubNavigationBar>
    );
  }
}

DriversCoaNavigationBar.propTypes = {
  avg: PropTypes.object.isRequired,
};

export { DriversCoaNavigationBar };
