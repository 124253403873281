import React from 'react';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import * as R from 'ramda';
// import { ClipLoader } from 'react-spinners';
// import { toast } from 'react-toastify';

import { onChangeInputMixin, onErrorMixin } from '../../shared/mixins';
import { LoginModel } from './auth-models';
import View from '@cobuildlab/react-flux-state';
import { getUserByCogniaToken, loginAction } from './auth-actions';
import {
  authStore,
  LOGIN_ERROR_EVENT,
  LOGIN_EVENT,
  USER_ERROR,
  USER_BY_TOKEN_EVENT,
} from './auth-store';
import { TextField } from '../../shared/components/inputs/TextField';
import { ClipLoader } from '../../shared/components/progress/ClipLoader';
import { AuthLayout, AuthContent } from '../../shared/layouts/AuthLayout';
import { customToast } from '../../shared/toast';

class LoginView extends View {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingCogniaUser: false,
      data: R.clone(LoginModel),
    };
    this.onChange = onChangeInputMixin.bind(this);
    this.onError = onErrorMixin.bind(this);
  }

  async componentDidMount() {
    this.subscribe(authStore, LOGIN_ERROR_EVENT, this.onError);
    this.subscribe(authStore, LOGIN_EVENT, () => {
      this.props.history.push('/home');
      const { rememberMe, email } = this.state.data;
      if (rememberMe && email) {
        this.setRememberUser();
      }
    });
    this.subscribe(authStore, USER_BY_TOKEN_EVENT, (data) => {
      this.setState(
        {
          loadingCogniaUser: false,
          data: {
            ...LoginModel,
            email: data.email,
            customToken: data.customToken,
          },
        },
        () => {
          this.onSubmit();
        },
      );
    });
    this.subscribe(authStore, USER_ERROR, (err) => {
      this.setState({ loadingCogniaUser: false });
      customToast.error(err);
    });

    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const cogniaToken = queryParams.get('token');

    if (cogniaToken) {
      getUserByCogniaToken(cogniaToken);
      this.setState({
        loadingCogniaUser: true,
      });
    } else {
      const email = await this.getUserRemember();

      this.setState({
        data: {
          email: email || '',
          rememberMe: email ? true : false,
        },
      });
    }
  }

  onSubmit = async (e) => {
    if (e) e.preventDefault();

    const data = R.clone(this.state.data);

    if (!data.email && data.rememberMe) {
      customToast.error('Enter valid credentials');
      return;
    }

    if (!data.customToken && !data.password) {
      customToast.error('Enter valid credentials');
      return;
    }

    const email = this.state.data.email;
    const dataNew = { ...R.clone(this.state.data), email };
    this.setState({ loading: true }, () => {
      loginAction(dataNew);
    });
  };

  onCheckboxChange = () => {
    const { data } = this.state;
    data.rememberMe && this.forgetUser();
    data.rememberMe = !data.rememberMe;
    this.setState({ data });
  };

  setRememberUser = async () => {
    const { data } = this.state;
    try {
      await localStorage.setItem('email', data.email);
    } catch (error) {
      // Error saving data
      console.log(error);
    }
  };

  getUserRemember = async () => {
    try {
      const email = await localStorage.getItem('email');
      return email;
    } catch (error) {
      console.log(error);
    }
  };

  forgetUser = async () => {
    try {
      await localStorage.removeItem('email');
    } catch (error) {
      // Error removing
      console.log(error);
    }
  };

  render() {
    const { loading, loadingCogniaUser, data } = this.state;
    const { email, password, rememberMe } = data;

    const isLoading = loadingCogniaUser || loading;

    const buttonLogin = isLoading ? (
      <ClipLoader />
    ) : (
      <Button
        style={{ width: 200 }}
        type="submit"
        color="secondary"
        className=" mx-auto pl-4 pr-4"
        onClick={this.onSubmit}
        disabled={isLoading}>
        Login
      </Button>
    );

    return (
      <AuthLayout>
        <AuthContent>
          <h1 className="text-center font-weight-bold">Welcome!</h1>
          <h4 className="text-center mb-5">Please login to your account</h4>
          <Form onSubmit={this.onSubmit}>
            <TextField
              className="mb-5"
              label="Email"
              onChange={this.onChange}
              name="email"
              value={email}
              type="text"
              required
              disabled={isLoading}
            />
            <TextField
              className="mb-4"
              label="Password"
              onChange={this.onChange}
              name="password"
              value={password}
              type="password"
              required
              disabled={isLoading}
            />
            <FormGroup className="position-relative form-group">
              <Row>
                <Col xs={7}>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      checked={rememberMe}
                      className="form-check-input"
                      id="exampleCheck1"
                      onChange={this.onCheckboxChange}
                    />
                    <span style={{ fontSize: 18 }}>Remember Me</span>
                  </div>
                </Col>
                <Col xs={4}>
                  <div
                    style={{ width: 130, textDecoration: 'underline' }}
                    className="ml-auto text-center">
                    <Link to="/forgot-password" style={{ fontSize: 18 }}>
                      Create/Change Password
                    </Link>
                  </div>
                </Col>
              </Row>
            </FormGroup>
            <div className="d-flex text-center justify-content-center mt-5">{buttonLogin}</div>
          </Form>
        </AuthContent>
      </AuthLayout>
    );
  }
}

LoginView.propTypes = {
  history: PropTypes.object,
};

export default withRouter(LoginView);
