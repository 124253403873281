import React from 'react';
import { customToast } from '../../shared/toast';
import View from '@cobuildlab/react-flux-state';

import { NavigationBar } from '../../shared/layouts/NavigationBar';
import { DriversNavigationBar } from './components/DriversNavigationBar';
import { fetchMySchoolAverage, fetchMyVoiceAction, updateMyVoiceAction } from './my-voice-actions';
import {
  getTeacherCustomDomains,
  calculateDomainAverages,
  updateTeacherCustomDomains,
  getSchoolCustomDomainAverages,
} from '../covid-19-domains/covid-19-domains-actions';
import {
  MY_VOICE_ERROR_EVENT,
  MY_VOICE_EVENT,
  MY_VOICE_UPDATE_EVENT,
  myVoiceStore,
  SCHOOL_AVERAGE_EVENT,
} from './my-voice-store';
import covid19DomainsStore, {
  COVID_19_TEACHER_CUSTOM_DOMAINS_EVENT,
  UPDATE_COVID_19_TEACHER_CUSTOM_DOMAINS_EVENT,
  COVID_19_SCHOOL_CUSTOM_DOMAINS_AVERAGE_EVENT,
} from '../covid-19-domains/covid-19-domains-store';
import { onErrorMixin } from '../../shared/mixins';
import ProtectedRoute from '../../routes/ProtectedRoute';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MyVoiceGraph } from './MyVoiceGraph';
import { DRIVERS } from './my-voice-models';
import { CUSTOM_DOMAINS } from '../covid-19-domains/covid-19-domains-models';
import CustomDomainSteppers from '../covid-19-domains/components/CustomDomainSteppers';
import { authStore, USER_EVENT } from '../auth/auth-store';
import { ClipLoader } from '../../shared/components/progress/ClipLoader';
import { SubNavigationLayout } from '../../shared/layouts/SubNavigationLayout';

class MyVoiceView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      customDomainId: null,
      customDomainsData: {},
      schoolAvgData: {},
      customDomainAvg: {},
      schoolCustomDomainAvg: {},
      loading: true,
    };
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(myVoiceStore, MY_VOICE_ERROR_EVENT, this.onError);
    this.subscribe(myVoiceStore, SCHOOL_AVERAGE_EVENT, (state) => {
      this.setState({ loading: false, schoolAvgData: state.data.results });
    });
    this.subscribe(myVoiceStore, MY_VOICE_EVENT, (state) => {
      const { driversData } = state;
      this.setState({ data: driversData });
      fetchMySchoolAverage();
    });
    this.subscribe(myVoiceStore, MY_VOICE_UPDATE_EVENT, (state) => {
      const { data } = this.state;
      const driverId = Object.keys(state)[0];
      let driverData = data[driverId];
      // When a User comes here for the First Time, this.state.data
      // does not contains all the Drivers Key, so this my be undefined
      if (driverData === undefined) {
        data[driverId] = {};
        driverData = data[driverId];
      }
      const indicatorsId = Object.keys(state[driverId]);
      indicatorsId.forEach((indicatorId) => {
        driverData[indicatorId] = state[driverId][indicatorId];
      });
      this.setState({ data, loading: false });
      customToast.success('Driver successfully Updated!');
      this.setState({ loading: true });
      fetchMySchoolAverage();
    });
    this.subscribe(covid19DomainsStore, COVID_19_TEACHER_CUSTOM_DOMAINS_EVENT, (data) => {
      if (data) {
        const { id, domains } = data;
        this.setState({
          customDomainId: id,
          customDomainsData: domains,
          customDomainAvg: calculateDomainAverages(domains),
        });
      }
    });
    this.subscribe(covid19DomainsStore, UPDATE_COVID_19_TEACHER_CUSTOM_DOMAINS_EVENT, (data) => {
      this.setState({
        loading: false,
        customDomainsData: data,
        customDomainAvg: calculateDomainAverages(data),
      });
      getTeacherCustomDomains();
    });
    this.subscribe(covid19DomainsStore, COVID_19_SCHOOL_CUSTOM_DOMAINS_AVERAGE_EVENT, (data) => {
      this.setState({
        schoolCustomDomainAvg: data,
      });
    });
    fetchMyVoiceAction();
    getTeacherCustomDomains();
    getSchoolCustomDomainAverages();
  }

  onSubmit = (driverId, indicatorsList) => {
    this.setState({ loading: true }, () => {
      const indicators = indicatorsList.filter((indicator) => indicator.data !== 0);
      const indicatorsObj = {};
      indicators.forEach(({ id, data }) => {
        indicatorsObj[id] = data;
      });
      updateMyVoiceAction(driverId, indicatorsObj);
    });
  };

  onSubmitCustomDomains = (domainId, indicatorsData) => {
    const ID = this.state.customDomainId;
    const customDomainsData = { ...this.state.customDomainsData };
    if (!customDomainsData[domainId]) customDomainsData[domainId] = {};
    for (const indicator of indicatorsData) {
      customDomainsData[domainId][indicator.id] = indicator.data;
    }
    this.setState({ loading: true });
    updateTeacherCustomDomains(ID, customDomainsData);
  };

  render() {
    const {
      data,
      loading,
      schoolAvgData,
      customDomainsData,
      customDomainAvg,
      schoolCustomDomainAvg,
    } = this.state;
    const driversAvg = {};
    let content = (
      <div className="align-items-center justify-content-center h-100 d-flex">
        <ClipLoader />
      </div>
    );
    if (!loading) {
      const user = authStore.getState(USER_EVENT);
      const driversIds = Object.keys(data);
      // Calculating Averages
      driversIds.forEach((driverId) => {
        const indicators = data[driverId];
        const indicatorsIds = Object.keys(indicators);
        const indicatorsValue = [];
        indicatorsIds.forEach((indicatorId) => indicatorsValue.push(indicators[indicatorId]));
        driversAvg[driverId] = (
          indicatorsValue.reduce((acc, curr) => acc + curr, 0) / indicatorsIds.length
        ).toFixed(2);
      });

      content = (
        <Switch>
          {DRIVERS.map((driver) => {
            const { component: Component } = driver;
            const component = (
              <Component
                data={data[driver.id]}
                onSubmit={(indicators) => this.onSubmit(driver.id, indicators)}
              />
            );
            return (
              <ProtectedRoute key={driver.id} path={driver.href} component={() => component} />
            );
          })}
          {CUSTOM_DOMAINS.map((domain, index) => {
            const domainIndicators = this.state.customDomainsData[domain.id];
            const indicators = domain.indicators.map((indicator) => {
              indicator.data = 0;
              if (domainIndicators && domainIndicators[indicator.id]) {
                indicator.data = domainIndicators[indicator.id];
              }
              return indicator;
            });

            const RouteComponent = () => (
              <CustomDomainSteppers
                domain={domain.name}
                message={domain.message}
                indicators={indicators}
                onSubmit={(indicatorsData) => this.onSubmitCustomDomains(domain.id, indicatorsData)}
              />
            );

            return <ProtectedRoute key={index} path={domain.href} component={RouteComponent} />;
          })}
          <ProtectedRoute
            path={'/my-voice'}
            render={() => (
              <MyVoiceGraph
                avg={driversAvg}
                domainsAvg={customDomainAvg}
                schoolAvg={schoolAvgData}
                schoolDomainsAvg={schoolCustomDomainAvg}
                user={user}
              />
            )}
          />
          {/*  Default*/}
          <Route render={() => <Redirect to={'/my-voice'} />} />
        </Switch>
      );
    }

    return (
      <NavigationBar currentRoute={'/my-voice'}>
        <SubNavigationLayout
          sidebar={
            <DriversNavigationBar
              avg={driversAvg}
              customDomainsData={customDomainsData}
              customDomainAvg={customDomainAvg}
            />
          }>
          {content}
        </SubNavigationLayout>
      </NavigationBar>
    );
  }
}

export default MyVoiceView;
