import { isValidEmail } from '@cobuildlab/validation-utils';
import { isArray } from 'lodash';
import { isObject } from 'lodash';

/**
 * Validate if string is a valid email.
 *
 * @param {string} email - Email to validate.
 * @returns {boolean} - Is a valid email?.
 */
export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const transformDomainsEmail = (domains) => {
  return !isArray(domains) || domains.length === 0
    ? []
    : domains.map((item) =>
      item.charAt(0) !== '@' ? `@${item.toLowerCase()}` : item.toLowerCase(),
    );
};

export const validatorEmailDomain = (domainsData) => {
  if (!isArray(domainsData) || domainsData.length === 0) return false;
  for (const domain of domainsData) {
    const email = `x${domain}`;
    if (!isValidEmail(email)) {
      return false;
    }
  }
  return true;
};

/**
 * @param {Array} data - Object with data.
 * @param {Array} columns - Array defining the tables columns.
 * @returns {Array} - Array with data.
 */
export function transformDataReport(data, columns) {
  return data.map((item) => {
    let customData = {};
    columns.map((column) => {
      const { title, field, transform } = column;
      if (transform) {
        return (customData[title] = transform(item[field], item));
      }
      return (customData[title] = item[field]);
    });
    return customData;
  });
}

export const concatFilters = (filters) => {
  let filterJoin = '';
  Object.entries(filters).forEach(([key, val]) => {
    if (!val) return;
    let value = val;
    if (Array.isArray(val) === true && typeof val.map === 'function')
      value = val.map((item) => item.id).join(', ');
    if (isObject(value)) return;
    if (value.toLowerCase() === 'inactive') value = 'disabled';
    if (value !== '') filterJoin += `${key}: ${value}, `;
  });
  return filterJoin.slice(0, -2).concat('.');
};

export const columnsUserReport = [
  {
    title: 'Email',
    field: 'email',
  },
  {
    title: 'Status',
    field: 'active',
    transform: (value) => (value ? 'Active' : 'Disabled'),
  },
  {
    title: 'School ID',
    field: 'schoolId',
    transform: (value, user) => {
      if (user.userType === 'COA') {
        return 'N/A';
      }
      return value || 'N/A';
    },
  },
  {
    title: 'Region ID',
    field: 'regionId',
    transform: (value, user) => {
      if (user.userType === 'COA') {
        return 'N/A';
      }
      return value || 'N/A';
    },
  },
  {
    title: 'District ID',
    field: 'districtId',
    transform: (value) => {
      return value || 'N/A';
    },
  },
  {
    title: 'Role',
    field: 'userType',
  },
  {
    title: 'Last Log In',
    field: 'lastLoggedIn',
    transform: (value) => (value ? value : 'N/A'),
  },
  {
    title: 'Origin',
    field: 'userGUID',
    transform: (value) => (value ? 'Cognia' : 'MyVoice'),
  },
];

export const columnsSchoolReport = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Status',
    field: 'active',
    transform: (value) => {
      const status = typeof value === 'boolean' ? value : true;
      return status ? 'Active' : 'Disabled';
    },
  },
  {
    title: 'School ID',
    field: 'id',
    transform: (value) => {
      return value || 'N/A';
    },
  },
  {
    title: 'Region ID',
    field: 'regionId',
    transform: (value) => {
      return value || 'N/A';
    },
  },
  {
    title: 'District ID',
    field: 'regionDistrictId',
    transform: (value) => {
      return value || 'N/A';
    },
  },
  {
    title: 'School Type',
    field: 'schoolType',
  },
  {
    title: 'Address',
    field: 'address1',
  },
  {
    title: 'Teachers',
    field: 'teachersNumbers',
  },
  {
    title: 'Origin',
    field: 'organizationGUID',
    transform: (value) => (value ? 'Cognia' : 'MyVoice'),
  },
];

export const columnsDistrictReport = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'ID',
    field: 'id',
  },
  {
    title: 'Status',
    field: 'active',
    transform: (value) => (value ? 'Active' : 'Disabled'),
  },
  {
    title: 'Schools',
    field: 'schools',
    transform: (value = 0) => value,
  },
  {
    title: 'Leaders',
    field: 'leaders',
    transform: (value = 0) => value,
  },
  {
    title: 'Teachers',
    field: 'teachers',
    transform: (value = 0) => value,
  },
  {
    title: 'Regions',
    field: 'regions',
    transform: (value = []) => value.length,
  },
];

export const columnsCLFReport = [
  {
    title: 'Email',
    field: 'email',
  },
  {
    title: 'Name',
    field: 'firstName',
    transform: (val, user) => `${val} ${user.lastName}`,
  },
  {
    title: 'Status',
    field: 'active',
    transform: (value) => (value ? 'Active' : 'Disabled'),
  },
  {
    title: 'Districts',
    field: 'districts',
    transform: (value = []) => value.map((district) => district.name).join(', '),
  },
  {
    title: 'Schools',
    field: 'schools',
    transform: (value = []) => value.map((school) => school.name).join(', '),
  },
  {
    title: 'Last Log In',
    field: 'lastLoggedIn',
    transform: (value) => value || 'N/A',
  },
];

export const splitArray = (array, each = 10) => {
  const copyArray = [...array];
  const result = [];
  while (copyArray.length) {
    result.push(copyArray.splice(0, each));
  }
  return result;
};
