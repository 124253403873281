import React from 'react';
import { NavigationBar } from '../../shared/layouts/NavigationBar';
import { bulkUpdateMyVoiceAction, bulkUpdateMyVoiceInitialAction } from './my-voice-actions';
import View from '@cobuildlab/react-flux-state';
import { MY_VOICE_ERROR_EVENT, MY_VOICE_UPDATE_EVENT, myVoiceStore } from './my-voice-store';
import { onErrorMixin } from '../../shared/mixins';
import { DRIVERS } from './my-voice-models';
import { CUSTOM_DOMAINS } from '../covid-19-domains/covid-19-domains-models';
import {
  updateTeacherCustomDomains,
  getTeacherCustomDomains,
} from '../covid-19-domains/covid-19-domains-actions';
import covid19DomainsStore, {
  COVID_19_TEACHER_CUSTOM_DOMAINS_EVENT,
} from '../covid-19-domains/covid-19-domains-store';
import { customToast } from '../../shared/toast';
import { Col, Alert, Progress, Container } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import { authStore, USER_EVENT } from '../auth/auth-store';
import CustomDomainSteppers from '../covid-19-domains/components/CustomDomainSteppers';
import userTypes from '../../shared/userTypes';

const SECTIONS = [...DRIVERS, ...CUSTOM_DOMAINS];

class InitialAssessmentView extends View {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sectionIndex: 0,
    };
    this.onError = onErrorMixin.bind(this);
    this.driversData = {};
    this.domainsId = null;
    this.domainsData = {};
  }

  componentDidMount() {
    this.setState({ sectionIndex: 0 });
    this.subscribe(myVoiceStore, MY_VOICE_ERROR_EVENT, this.onError);
    this.subscribe(myVoiceStore, MY_VOICE_UPDATE_EVENT, () => {
      const user = authStore.getState(USER_EVENT);
      let redirectTo = 'my-voice';
      if (user.userType === userTypes.LEADER) redirectTo = 'my-school';
      customToast.success('Drivers successfully Updated!');
      this.props.history.push(`/${redirectTo}`);
    });
    this.subscribe(covid19DomainsStore, COVID_19_TEACHER_CUSTOM_DOMAINS_EVENT, (data) => {
      if (data) {
        const { id, domains } = data;
        this.setState({
          domainsId: id,
          domainsData: domains,
        });
      }
    });
    getTeacherCustomDomains();
  }

  onSubmit = (sectionId, indicatorsList) => {
    const { sectionIndex } = this.state;
    const indicators0 = indicatorsList.filter((indicator) => indicator.data === 0);
    if (indicators0.length > 0) return customToast.error('You Must fill all the indicators');
    const indicatorsObj = {};
    indicatorsList.forEach(({ id, data }) => {
      indicatorsObj[id] = data;
    });

    if (sectionIndex < DRIVERS.length) {
      this.driversData[sectionId] = indicatorsObj;
    } else {
      this.domainsData[sectionId] = indicatorsObj;
    }

    if (sectionIndex === SECTIONS.length - 1) {
      bulkUpdateMyVoiceAction(this.driversData);
      bulkUpdateMyVoiceInitialAction(this.driversData);
      updateTeacherCustomDomains(this.state.domainsId, this.domainsData);
    } else {
      this.setState({ sectionIndex: this.state.sectionIndex + 1 });
    }
  };

  render() {
    const { loading, sectionIndex } = this.state;
    let content = (
      <Col
        md={12}
        className="content-sub-bar align-items-center justify-content-center h-100vh d-flex">
        <ClipLoader sizeUnit={'px'} size={150} color={'#123abc'} loading={true} />
      </Col>
    );

    if (!loading) {
      const submitText = sectionIndex === SECTIONS.length - 1 ? 'Submit' : 'Next';
      const user = authStore.getState(USER_EVENT);

      if (sectionIndex < DRIVERS.length) {
        const { component: Component, id } = SECTIONS[sectionIndex];
        content = (
          <Component
            user={user}
            isInitial={true}
            submitText={submitText}
            bodyClass={'driver-sub-bar-full'}
            data={undefined}
            onSubmit={(indicators) => this.onSubmit(id, indicators)}
          />
        );
      } else {
        const domain = SECTIONS[sectionIndex];
        content = (
          <CustomDomainSteppers
            domain={domain.name}
            message={domain.message}
            icon={domain.icon}
            indicators={domain.indicators}
            submitText={submitText}
            bodyClass={'driver-sub-bar-full'}
            onSubmit={(indicatorsData) => this.onSubmit(domain.id, indicatorsData)}
          />
        );
      }
    }

    const progress = Math.ceil((sectionIndex * 100) / SECTIONS.length);
    return (
      <NavigationBar currentRoute={'/initial-assessment'}>
        <Container fluid>
          <Alert color="primary">
            Please fill all the indicators for each section.
            <div className="text-center">{`${progress} %`}</div>
            <Progress value={progress} />
            <br />
          </Alert>
          {content}
        </Container>
      </NavigationBar>
    );
  }
}

export default InitialAssessmentView;
