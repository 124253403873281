import React from 'react';
import View from '@cobuildlab/react-flux-state';
import {
  SUPER_ADMIN_ADD_SCHOOL_ERROR,
  SUPER_ADMIN_ADD_SCHOOL_EVENT,
  SUPER_ADMIN_REGIONS,
  superAdminStore,
} from '../super-admin-store';
import { customToast } from '../../../shared/toast';
import AddSchoolModal from '../../../components/school/AddSchoolModal';
import { createSchoolAction, getRegions } from '../super-admin-actions';
import PropTypes from 'prop-types';

/**
 * A Container Component to display a Modal to Add a School.
 *
 * @param {boolean} isOpen - Define if modal is open or close.
 * @param school
 * @param logoImage
 * @param {Function} onToggle - Function to trigger on toggle.
 */
class AddSchoolModalContainer extends View {
  constructor(props) {
    super(props);
    this.state = {
      regions: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.subscribe(superAdminStore, SUPER_ADMIN_REGIONS, (regions) => {
      this.setState({ regions });
    });
    this.subscribe(superAdminStore, SUPER_ADMIN_ADD_SCHOOL_ERROR, (error) => {
      customToast.error(error);
      this.setState({ loading: false });
    });
    this.subscribe(superAdminStore, SUPER_ADMIN_ADD_SCHOOL_EVENT, () => {
      this.setState({ loading: false });
      this.props.onToggle();
    });
    getRegions();
  }

  handleSubmit = (school, logoImage) => {
    this.setState({ loading: true });
    createSchoolAction(school, logoImage);
  };

  render() {
    const { regions, loading } = this.state;
    const { isOpen, onToggle } = this.props;

    return (
      <AddSchoolModal
        loading={loading}
        regions={regions}
        onSubmit={this.handleSubmit}
        onToggle={onToggle}
        isOpen={isOpen}
      />
    );
  }
}

AddSchoolModalContainer.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

export default AddSchoolModalContainer;
