import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { plus as ic_plus } from 'react-icons-kit/fa/plus';
import { ic_search } from 'react-icons-kit/md/ic_search';
import { useSubscription } from '@cobuildlab/react-flux-state';
import { InputWithIcon } from '../../shared/components/inputs/InputWithIcon';
import { TableUsersClf } from './components/TableUsersClf';
import { UserClfModalContainer } from './components/UserClfModalContainer';
import ConfirmModal from '../../components/modal/ConfirmModal';
import { download2 as ic_download } from 'react-icons-kit/icomoon/download2';
import useFetchPage from '../../shared/useFetchPage';
import { TABLE_LIMIT } from '../../components/ManagementView';
import { queryClient } from '../../shared/utils';
import { useDebounce } from '../../shared/hooks';
import {
  superAdminStore,
  SUPER_ADMIN_DELETE_USER_ERROR,
  SUPER_ADMIN_DELETE_USER_EVENT,
  SUPER_ADMIN_EXCEL_REPORTS_ERROR,
  SUPER_ADMIN_EXCEL_REPORTS_EVENT,
} from './super-admin-store';
import {
  searchUsersCLF,
  formatUserWithoutDistrictsData,
  deleteUser,
  createAndDownloadSuperAdminReport,
} from './super-admin-actions';
import { customToast } from '../../shared/toast';
import { USER_TYPE_CLF } from '../../shared/userTypes';
import { ButtonUsersFilter } from './components/ButtonFilterUsers';
import { ButtomActionsTabs } from './components/ButtomActionsTabs';

const initialFilter = {
  generalSearch: '',
  district: [],
  school: [],
  role: USER_TYPE_CLF,
  onlyActives: 'active',
};

const fetchKey = 'fetchUsersCLF';

export const ClfManagementView = () => {
  const [usersFilter, setUsersFilter] = useState(initialFilter);
  const [search, setSearch] = useState('');
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loadingReport, setLoadingReport] = useState(false);

  const { isLoading, data, fetchNextPage, isFetchingNextPage: isLoadingMore } = useFetchPage(
    [fetchKey, usersFilter],
    ({ queryKey, pageParam = null }) => {
      const [, filters] = queryKey;
      return searchUsersCLF(filters, TABLE_LIMIT, pageParam);
    },
    {
      onError: (err) => {
        customToast.error(err);
      },
    },
  );

  useDebounce(() => {
    setUsersFilter({ ...usersFilter, generalSearch: search });
  }, search);

  const handleCreateReport = () => {
    setLoadingReport(true);
    createAndDownloadSuperAdminReport(usersFilter, 'CLF', 'CLF Report');
  };

  const onChangeUsersFilter = (values) => {
    setUsersFilter(Object.assign({ ...usersFilter }, values));
  };

  useSubscription(superAdminStore, SUPER_ADMIN_DELETE_USER_EVENT, () => {
    customToast.success('User deleted successfully');
    queryClient.invalidateQueries([fetchKey]);
  });

  useSubscription(superAdminStore, SUPER_ADMIN_DELETE_USER_ERROR, (err) => {
    customToast.success(err.message);
  });

  useSubscription(superAdminStore, SUPER_ADMIN_EXCEL_REPORTS_ERROR, (err) => {
    setLoadingReport(false);
    customToast.error(err);
  });

  useSubscription(superAdminStore, SUPER_ADMIN_EXCEL_REPORTS_EVENT, () => {
    setLoadingReport(false);
  });

  const countText = (
    <span className="result-count">
      {data.count}
      {data.haveMoreToLoad ? '+' : ''} Results
    </span>
  );

  const loading = isLoading || isLoadingMore;
  return (
    <div>
      <h5 className="my-3">Search {countText}</h5>
      <Row className="justify-content-between align-items-center">
        <Col xs={6} md={3}>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <InputWithIcon
                icon={ic_search}
                value={search}
                onChange={(evt) => setSearch(evt.target.value)}
                type="text"
                placeholder="Search"
                disabled={loading}
              />
            </div>
            <div className="ml-2">
              <ButtonUsersFilter
                data={usersFilter}
                onChange={onChangeUsersFilter}
                isMulti
                fixedValues={{
                  role: USER_TYPE_CLF,
                }}
                isLoading={loading}
              />
            </div>
          </div>
        </Col>
        <Col md xs className="d-flex justify-content-end">
          <ButtomActionsTabs
            actions={[
              {
                onClick: handleCreateReport,
                title: 'Download File',
                icon: ic_download,
                loading: loadingReport,
              },
              {
                onClick: () => setIsOpenUserModal(true),
                title: 'Add User',
                icon: ic_plus,
              },
            ]}
          />
        </Col>
      </Row>

      <UserClfModalContainer
        edit={Boolean(selectedUser)}
        user={selectedUser && formatUserWithoutDistrictsData(selectedUser)}
        isOpen={isOpenUserModal}
        onToggle={() => {
          setIsOpenUserModal(false);
          setSelectedUser(null);
        }}
        submitCallback={() => {
          queryClient.invalidateQueries([fetchKey, usersFilter]);
          queryClient.invalidateQueries([fetchKey]);
        }}
      />

      <br />

      <TableUsersClf
        isLoading={loading}
        isMoreToLoad={data.haveMoreToLoad}
        onLoadMore={() => {
          fetchNextPage({ pageParam: data.startAfter });
        }}
        rows={data.users || []}
        onEdit={(user) => {
          setSelectedUser(user);
          setIsOpenUserModal(true);
        }}
        onDelete={(user) => {
          setSelectedUser(user);
          setIsOpenConfirmModal(true);
        }}
      />

      <ConfirmModal
        active={isOpenConfirmModal}
        toggle={() => setIsOpenConfirmModal(false)}
        title="Are you sure?"
        body="This will permanently delete user"
        onResponse={(response) => {
          if (response && selectedUser) {
            deleteUser(selectedUser.email);
          } else {
            setSelectedUser(null);
          }
        }}
      />
    </div>
  );
};
