import React from 'react';
import { PropTypes } from 'prop-types';

// MODELS
import { DRIVERS } from '../../../modules/my-voice/my-voice-models';
import { DOMAINS } from '../../../modules/covid-19-domains/covid-19-domains-models';

// COMPONENTS
import { CollapsibleSubNavigationList } from './SubNavigationBar';
// ICONS
import gridInterfaceIcon from '../../../shared/assets/icons/grid-interface.svg';

const collapsibleProps = {
  avg: PropTypes.object.isRequired,
  pathBase: PropTypes.string.isRequired,
};

const itemAllEnv = ({ href, title }) => ({
  icon: (
    <img src={gridInterfaceIcon} alt="all env icon" className="ml-1 mr-1" height={15} width={15} />
  ),
  href: href,
  title,
  className: 'font-weight-bold',
  style: { fontSize: '1.1rem' },
});

export const CultureCollapsible = ({ avg, pathBase }) => (
  <CollapsibleSubNavigationList
    className="mb-4"
    defaultOpen={true}
    title="Culture"
    items={[
      itemAllEnv({ href: pathBase, title: 'All Drivers' }),
      ...DRIVERS.map((item) => {
        item.data = avg[item.id] || 0;
        return {
          icon: <img src={item.icon} alt="driver icon" height={24} width={24} />,
          href: `${pathBase}/${item.path}`,
          title: item.name,
          value: item.data,
        };
      }),
    ]}
  />
);

CultureCollapsible.propTypes = collapsibleProps;

export const Covid19Collapsible = ({ avg, pathBase }) => (
  <CollapsibleSubNavigationList
    title="Covid-19"
    defaultOpen={true}
    items={[
      itemAllEnv({ href: pathBase, title: 'All Drivers' }),
      ...DOMAINS.map((item) => {
        return {
          // icon: item.icon ? <img src={item.icon} alt="domain icon" height={24} width={24} /> : null,
          href: `${pathBase}/${item.path}`,
          title: item.name,
          value: avg[item.id] || 0,
        };
      }),
    ]}
  />
);

Covid19Collapsible.propTypes = collapsibleProps;

export const buildPathBaseCulture = (pathBase) => `${pathBase}/culture`;
export const buildPathBaseCovid19 = (pathBase) => `${pathBase}/covid-19`;

export const CultureAndCovidCollapsibleLists = ({ driversAvg, domainsAvg, pathBase }) => (
  <>
    <CultureCollapsible avg={driversAvg} pathBase={buildPathBaseCulture(pathBase)} />
    {/* <Covid19Collapsible avg={domainsAvg} pathBase={buildPathBaseCovid19(pathBase)} /> */}
  </>
);

CultureAndCovidCollapsibleLists.propTypes = {
  driversAvg: PropTypes.object.isRequired,
  domainsAvg: PropTypes.object.isRequired,
  pathBase: PropTypes.string.isRequired,
};
