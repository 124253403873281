import React from 'react';
import { Row, Col } from 'reactstrap';
import { ic_search } from 'react-icons-kit/md/ic_search';
import { InputWithIcon } from '../shared/components/inputs/InputWithIcon';
import PropTypes from 'prop-types';
import { actionsPropsType } from '../modules/super-admin/super-admin-types';
import { ButtomActionsTabs } from '../modules/super-admin/components/ButtomActionsTabs';

export const TABLE_LIMIT = 50;

export const ManagementView = ({
  count,
  haveMoreToLoad,
  isLoading,
  searchInput: { value, onChange },
  openFilterButton,
  buttomActions = [],
  children,
}) => {
  const countText = (
    <span className="result-count">
      {count}
      {haveMoreToLoad ? '+' : ''} Results
    </span>
  );

  return (
    <div>
      <h5 className="my-3">Search {countText}</h5>
      <Row className="justify-content-between align-items-center">
        <Col xs={3} md={3}>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <InputWithIcon
                icon={ic_search}
                value={value}
                onChange={(evt) => onChange(evt.target.value)}
                type="text"
                placeholder="Search"
                disabled={isLoading}
              />
            </div>
            <div className="ml-2">{openFilterButton}</div>
          </div>
        </Col>
        <Col md xs className="d-flex justify-content-end">
          <ButtomActionsTabs actions={buttomActions} />
        </Col>
      </Row>
      <br />
      {children}
    </div>
  );
};

ManagementView.propTypes = {
  count: PropTypes.number.isRequired,
  haveMoreToLoad: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  searchInput: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  openFilterButton: PropTypes.node.isRequired,
  buttomActions: actionsPropsType,
  children: PropTypes.node.isRequired,
};

ManagementView.defaultProps = {
  buttomActions: [],
};
