import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { BAR_GRAPH_OPTIONS_LEADER, DRIVERS_LEADER } from '../my-voice-models';
import * as R from 'ramda';
import PropTypes from 'prop-types';

/**
 * My Voice View Leader.
 *
 * @param avg
 * @param schoolAvg
 * @param schoolInitialAvg
 * @returns {*}
 * @class
 */
const MyVoiceLeaderGraph = ({ avg, schoolAvg = {}, user, schoolInitialAvg = {} }) => {
  const order = [
    'clearUnifiedDirection',
    'professionalEngagement',
    'instructionalAutonomy',
    'collaboration',
    'empowerment',
    'feedbackReflection',
    'resourcePriorities',
    'supportCare',
    'senseOfBelonging',
    'advancement',
  ];
  const labels = [];
  const data = [];
  const schoolInitialAvgData = [];
  const schoolAvgData = [];
  const schoolName = user.school ? user.school.name : '';
  const exportFilename = `${schoolName}-drivers-average`;

  if (schoolAvg) {
    DRIVERS_LEADER.forEach((driver) => {
      labels.push(driver.name);
      data.push(avg[driver.id] || 0);
      schoolAvgData.push(schoolAvg[driver.id] || 0);
    });
  }
  // Validate if data initial drivers avg is not null for create array with avgs
  if (schoolInitialAvg) {
    const driversId = Object.keys(schoolInitialAvg);
    const objsOrdenados = order.map((order) => {
      return driversId.find((driversId) => driversId === order);
    });
    objsOrdenados.forEach((driverId) => {
      schoolInitialAvgData.push(schoolInitialAvg[driverId] || 0);
    });
  }

  const options = R.clone(BAR_GRAPH_OPTIONS_LEADER);
  options.xaxis.categories = labels;
  options.chart.toolbar.export.svg.filename = exportFilename;
  options.chart.toolbar.export.png.filename = exportFilename;
  options.chart.toolbar.export.csv.filename = exportFilename;
  const series = [
    {
      name: 'Baseline',
      data: schoolInitialAvgData,
      type: 'bar',
    },
    {
      name: 'Realtime',
      data: schoolAvgData,
      type: 'bar',
    },
    {
      name: 'Your Score',
      data: data,
      type: 'line',
    },
  ];

  return (
    <div>
      <h2 className="my-5" style={{ fontFamily: 'Zilla Slab' }}>
        {schoolName}
      </h2>
      <ReactApexChart options={options} series={series} type="line" height="500" width="80%" />
    </div>
  );
};

MyVoiceLeaderGraph.propTypes = {
  avg: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  schoolAvg: PropTypes.object.isRequired,
  schoolInitialAvg: PropTypes.object.isRequired,
};

export { MyVoiceLeaderGraph };
