import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const options = [
  { id: 0, value: 'clearUnifiedDirection', name: '1. Clear & Unified Direction' },
  { id: 1, value: 'professionalEngagement', name: '2. Professional Engagement' },
  { id: 2, value: 'instructionalAutonomy', name: '3. Instructional Autonomy' },
  { id: 3, value: 'collaboration', name: '4. Collaboration' },
  { id: 4, value: 'empowerment', name: '5. Empowerment' },
  { id: 5, value: 'feedbackReflection', name: '6. Feedback & Reflection' },
  { id: 6, value: 'resourcePriorities', name: '7. Resource Priorities' },
  { id: 8, value: 'supportCare', name: '8. Support & Care' },
  { id: 7, value: 'senseOfBelonging', name: '9. Sense of Belonging' },
  { id: 9, value: 'advancement', name: '10. Teacher Advancement' },
];

export default class DropDownDrive extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      dropDownValue: '1. Clear & Unified Direction',
    };
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  changeValue = (e) => {
    this.setState({ dropDownValue: e.name });
    this.props.dropSelect(e.value);
  };

  render() {
    return (
      <Dropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        onChange={this.handleChange}
        size="sm">
        <DropdownToggle caret>{this.state.dropDownValue}</DropdownToggle>
        <DropdownMenu>
          {options.map((e) => {
            return (
              <DropdownItem id={e.id} key={e.id} onClick={() => this.changeValue(e)}>
                {e.name}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

DropDownDrive.propTypes = {
  dropSelect: PropTypes.func.isRequired,
};
