import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Row, Input } from 'reactstrap';
import { Select } from './Select';
import userTypes from '../../userTypes';
import ActiveSelect from './ActiveSelect';

export const roleOptions = [
  { label: 'Teacher', value: userTypes.TEACHER },
  { label: 'Leader', value: userTypes.LEADER },
  { label: 'COA', value: userTypes.COA },
  // { label: 'CLF', value: userTypes.CLF },
];

export const UsersFilter = ({
  data,
  isLoadingDistricts,
  isLoadingSchools,
  schools,
  districts,
  onChange,
  fixedValues,
  isMulti,
  disabled,
}) => {
  const handleOnChange = (values) => {
    const auxData = { ...data };
    Object.assign(auxData, values);
    onChange(auxData);
  };

  let districtInput = null;

  if (fixedValues.districtId) {
    districtInput = <Input value={fixedValues.districtId} disabled />;
  } else if (disabled) {
    let val = '';
    if (data.district) {
      if (!isMulti) val = data.district.name;
      else val = data.district.map((d) => d.name || d.id).join(', ');
    }
    districtInput = <Input value={val} disabled />;
  } else {
    districtInput = (
      <Select
        placeholder="Search District"
        getOptionValue={(opt) => opt.id}
        getOptionLabel={(opt) => opt.name || opt.id}
        options={districts}
        isSearchable
        isClearable
        isMulti={isMulti}
        isLoading={isLoadingDistricts}
        onChange={(district, evt) => {
          if (evt.action === 'select-option' || evt.action === 'remove-value') {
            handleOnChange({ district });
          } else if (evt.action === 'clear') {
            handleOnChange({
              district: isMulti ? [] : null,
            });
          }
        }}
        value={data.district}
        disabled={disabled}
      />
    );
  }

  let districtIdInput = null;

  if (fixedValues.districtId) {
    districtIdInput = <Input value={fixedValues.districtId} disabled />;
  } else if (disabled) {
    let val = '';
    if (data.district) {
      if (!isMulti) val = data.district.id;
      else val = data.district.map((d) => d.id).join(', ');
    }
    districtIdInput = <Input value={val} disabled />;
  } else {
    districtIdInput = (
      <Select
        placeholder="Search District Id"
        getOptionValue={(opt) => opt.id}
        getOptionLabel={(opt) => opt.id}
        options={districts}
        isSearchable
        isClearable
        isMulti={isMulti}
        isLoading={isLoadingDistricts}
        onChange={(district, evt) => {
          if (evt.action === 'select-option' || evt.action === 'remove-value') {
            handleOnChange({ district });
          } else if (evt.action === 'clear') {
            handleOnChange({
              district: isMulti ? [] : null,
            });
          }
        }}
        value={data.district}
        disabled={disabled}
      />
    );
  }

  let schoolInput = null;
  if (fixedValues.schoolId) {
    schoolInput = <Input value={fixedValues.schoolId} disabled />;
  } else if (disabled) {
    let val = '';
    if (data.district) {
      if (isMulti) val = data.school.name || data.school.id;
      else val = data.schools.map((s) => s.name || s.id).join(', ');
    }
    schoolInput = <Input value={val} disabled />;
  } else {
    schoolInput = (
      <Select
        placeholder="Search School"
        getOptionValue={(opt) => opt.id}
        getOptionLabel={(opt) => opt.name || opt.id}
        options={schools}
        isSearchable
        isClearable
        isMulti={isMulti}
        isLoading={isLoadingSchools}
        onChange={(school, evt) => {
          if (evt.action === 'select-option' || evt.action === 'remove-value') {
            handleOnChange({ school });
          } else if (evt.action === 'clear') {
            handleOnChange({
              school: isMulti ? [] : null,
            });
          }
        }}
        value={data.school}
      />
    );
  }

  let schoolIdInput = null;

  if (fixedValues.schoolId) {
    schoolIdInput = <Input value={fixedValues.schoolId} disabled />;
  } else if (disabled) {
    let val = '';
    if (data.district) {
      if (isMulti) val = data.school.id;
      else val = data.schools.map((s) => s.id).join(', ');
    }
    schoolIdInput = <Input value={val} disabled />;
  } else {
    schoolIdInput = (
      <Select
        placeholder="Search School Id"
        getOptionValue={(opt) => opt.id}
        getOptionLabel={(opt) => opt.id}
        options={schools}
        isSearchable
        isClearable
        isMulti={isMulti}
        isLoading={isLoadingSchools}
        onChange={(school, evt) => {
          if (evt.action === 'select-option' || evt.action === 'remove-value') {
            handleOnChange({ school });
          } else if (evt.action === 'clear') {
            handleOnChange({
              school: isMulti ? [] : null,
            });
          }
        }}
        value={data.school}
      />
    );
  }

  let roleInput = null;
  if (fixedValues.role || disabled) {
    roleInput = <Input value={fixedValues.role} disabled />;
  } else if (disabled) {
    roleInput = <Input value={data.role ? data.role.value : ''} disabled />;
  } else {
    roleInput = (
      <Select
        placeholder="Select Role"
        options={roleOptions}
        isClearable
        onChange={(role, evt) => {
          if (evt.action === 'select-option') {
            handleOnChange({ role });
          } else if (evt.action === 'clear') {
            handleOnChange({ role: null });
          }
        }}
        value={data.role}
      />
    );
  }

  return (
    <FormGroup>
      <Row>
        <Col xs={12} className="my-2">
          <label>District</label>
          {districtInput}
        </Col>
        <Col xs={12} className="my-2">
          <label>District Id</label>
          {districtIdInput}
        </Col>
        <Col xs={12} className="mb-2">
          <label>School</label>
          {schoolInput}
        </Col>
        <Col xs={12} className="mb-2">
          <label>School Id</label>
          {schoolIdInput}
        </Col>
        <Col xs={12} className="mb-2">
          <label>Role</label>
          {roleInput}
        </Col>
        <Col xs={12}>
          <label>Status</label>
          <ActiveSelect
            onChange={(onlyActives) => {
              handleOnChange({ onlyActives });
            }}
            value={data.onlyActives}
            disabled={disabled}
          />
        </Col>
      </Row>
    </FormGroup>
  );
};

UsersFilter.defaultProps = {
  fixedValues: {},
  isMulti: false,
  disabled: false,
};

UsersFilter.propTypes = {
  data: PropTypes.object.isRequired,
  isLoadingDistricts: PropTypes.bool.isRequired,
  isLoadingSchools: PropTypes.bool.isRequired,
  schools: PropTypes.array.isRequired,
  districts: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  fixedValues: PropTypes.object,
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
};
