import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useTheme } from '../../theme';

const highlightAnimation = keyframes`
  from {
    background: $primary;
  }
  to {
    width: 0;
    background: transparent;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  font-size: 16px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid ${(props) => props.palette.primary};
  &:focus {
    outline: none;
    & + span {
      animation: ${highlightAnimation} 0.3s ease;
    }
    & + span + span {
      &:before,
      &:after {
        width: 50%;
      }
    }
  }
  &:-webkit-autofill ~ label,
  &:focus ~ label,
  &:valid ~ label {
    top: -25px;
    font-size: 16px;
    color: ${(props) => props.palette.primary};
  }
`;

const Label = styled.label`
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 0px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
`;

const Highlight = styled.span`
  position: absolute;
  height: 60%;
  width: 100%;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
`;

const Bar = styled.span`
  position: relative;
  display: block;
  width: 100%;
  &:after,
  &:before {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: ${(props) => props.palette.primary};
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  &:before {
    left: 50%;
  }
  &:after {
    right: 50%;
  }
`;

export const TextField = ({ onChange, value, type, name, required, disabled, label, ...rest }) => {
  const theme = useTheme();
  const props = { ...theme, ...rest };
  return (
    <Wrapper {...props}>
      <Input
        {...theme}
        name={name}
        type={type}
        required={required}
        disabled={disabled}
        onChange={onChange}
        value={value}
      />
      <Highlight {...theme} />
      <Bar {...theme} />
      <Label {...theme}>{label}</Label>
    </Wrapper>
  );
};
