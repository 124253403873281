import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

const SchoolPicker = ({ schools, school, onChange, onAllSchool }) => {
  const [isOpen, setOpen] = useState(false);
  const [isAllSchool, setIsAllSchool] = useState(false);
  const [selectedSchool, setSchool] = useState(school);

  useEffect(() => {
    // sort schools by name
    schools.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }, []);

  const toggleDropDown = () => setOpen(!isOpen);

  const handleSelect = (item) => {
    setSchool(item);
    setIsAllSchool(false);
    onChange(item);
  };
  const setAllSchool = () => {
    setIsAllSchool(true);
    onAllSchool();
  };

  if (school !== selectedSchool) {
    setSchool(school);
  }

  const allSchools = 'All Schools';
  const displayValue = selectedSchool.name ? selectedSchool.name : '- Select a School -';

  const allSchoolOption = onAllSchool ? (
    <DropdownItem onClick={() => setAllSchool()}>{allSchools}</DropdownItem>
  ) : null;

  return (
    <Dropdown isOpen={isOpen} toggle={toggleDropDown} size="sm">
      <DropdownToggle caret color="light">
        {isAllSchool ? allSchools : displayValue}
      </DropdownToggle>
      <DropdownMenu>
        {allSchoolOption}
        {schools.map((item) => {
          if (item.active === false) return <></>;
          return (
            <DropdownItem id={item.id} key={item.id} onClick={() => handleSelect(item)}>
              {item.name}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

SchoolPicker.propTypes = {
  schools: PropTypes.array.isRequired,
  school: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onAllSchool: PropTypes.func.isRequired,
};

export default SchoolPicker;
