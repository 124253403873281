import firebase from 'firebase';
import Flux from '@cobuildlab/flux-state';
//import { log } from 'pure-logger';
import { authStore, USER_EVENT } from '../auth/auth-store';
import { HOME_ERROR, HOME_EVENT } from './home-store';
import { isValidString } from '../../shared/strings';

/**
 * Fetches Home Data.
 *
 * @returns {Promise<{districtData: {[p: string]: any}, regionData: {[p: string]: any}}|void>}
 */
export const fetchHome = async () => {
  const { school } = authStore.getState(USER_EVENT);
  const { regionId, message } = school;
  const messagePrincipal = message;
  let query;
  if (!isValidString(regionId))
    return Flux.dispatchEvent(
      HOME_ERROR,
      new Error(
        'The Teacher does not belong to any School or the School does not have a Region associated',
      ),
    );

  const DB = firebase.firestore();
  // Regions
  const regionsCollection = DB.collection('regions');
  const regionRef = regionsCollection.doc(regionId);

  try {
    query = await regionRef.get();
  } catch (e) {
    return Flux.dispatchEvent(
      HOME_ERROR,
      new Error(`It seems that this Region does not exists: ${regionId}`),
    );
  }
  let regionData;
  if (!query.exists)
    return Flux.dispatchEvent(
      HOME_ERROR,
      new Error(`It seems that this Region does not exists: ${regionId}`),
    );
  regionData = query.data();
  const { districtId } = regionData;
  if (!isValidString(districtId))
    return Flux.dispatchEvent(HOME_ERROR, new Error('The Region does not have a valid districtId'));

  // District
  const districtsCollection = DB.collection('districts');
  const districtRef = districtsCollection.doc(districtId);
  try {
    query = await districtRef.get();
  } catch (e) {
    return Flux.dispatchEvent(
      HOME_ERROR,
      new Error(`It seems that this Districts does not exists: ${districtId}`),
    );
  }
  let districtData;
  if (!query.exists)
    return Flux.dispatchEvent(
      HOME_ERROR,
      new Error(`It seems that this Districts does not exists: ${districtId}`),
    );
  districtData = query.data();

  Flux.dispatchEvent(HOME_EVENT, { regionData, districtData, messagePrincipal });
  return { regionData, districtData };
};
