import React from 'react';
import View from '@cobuildlab/react-flux-state';
import { NavigationBar } from '../../shared/layouts/NavigationBar';
import { allUserMyDashboardAction, getMySchoolMonthlyAveragesAction } from './my-dashboard-actions';
import {
  MY_DASHBOARD_ERROR_EVENT,
  MY_DASHBOARD_TEACHERS_EVENT,
  myDashboardStore,
  SCHOOL_MONTHLY_AVERAGE_EVENT,
} from './my-dashboard-store';
import { onErrorMixin } from '../../shared/mixins';
import ProtectedRoute from '../../routes/ProtectedRoute';
import { Redirect, Route, Switch } from 'react-router-dom';
import { GraphTeacherActivity } from './components/GraphTeacherActivity';
import { GraphDriveTrend } from './components/GraphDriveTrend';
import { customToast } from '../../shared/toast';
import { Col, Row } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import { authStore, USER_EVENT } from '../auth/auth-store';
import DropDownDrive from './components/DropDownDrive';
import { fetchMySchoolAverage } from '../my-voice/my-voice-actions';
import { myVoiceStore, SCHOOL_AVERAGE_EVENT } from '../my-voice/my-voice-store';

class MyDashboardView extends View {
  constructor(props) {
    super(props);
    this.state = {
      schoolAvgData: null,
      numUpdatedDrivers: 0,
      schoolAvgMonthlyData: [],
      typeGraph: 'clearUnifiedDirection',
      loading: true,

      numTeachers: 0,
      numTeachersInitial: 0,
      haveLoggedIn: 0,
      haveLogged30: 0,
    };
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(myDashboardStore, MY_DASHBOARD_TEACHERS_EVENT, (data) => {
      const {
        numTeachers,
        haveLoggedIn,
        haveLogged30,
        numTeachersInitial,
        numUpdatedDrivers,
      } = data;
      this.setState({
        loading: false,
        numTeachers,
        numTeachersInitial,
        haveLoggedIn,
        haveLogged30,
        numUpdatedDrivers,
      });
    });
    this.subscribe(myDashboardStore, MY_DASHBOARD_ERROR_EVENT, () => {
      this.setState({ loading: false });
      customToast.error('Error From Data Base');
    });
    this.subscribe(myVoiceStore, SCHOOL_AVERAGE_EVENT, (state) => {
      this.setState({ schoolAvgData: state.data.results });
    });
    this.subscribe(myDashboardStore, SCHOOL_MONTHLY_AVERAGE_EVENT, (state) => {
      this.setState({
        schoolAvgMonthlyData: state,
      });
    });

    allUserMyDashboardAction();
    fetchMySchoolAverage();
    getMySchoolMonthlyAveragesAction();
  }

  dropSelect = (type) => {
    this.setState({
      typeGraph: type,
    });
  };

  render() {
    const {
      haveLoggedIn,
      haveLogged30,
      schoolAvgData,
      numUpdatedDrivers,
      schoolAvgMonthlyData,
      numTeachers,
      numTeachersInitial,
      typeGraph,
      loading,
    } = this.state;

    let contentTeacherActivity = (
      <Col md={12} className="content-sub-bar justify-content-center h-100vh d-flex">
        <ClipLoader sizeUnit={'px'} size={150} color={'#123abc'} loading={true} />
      </Col>
    );

    let contentDriveTrend = (
      <Col md={12} className="content-sub-bar justify-content-center h-100vh d-flex">
        <ClipLoader sizeUnit={'px'} size={150} color={'#123abc'} loading={true} />
      </Col>
    );

    if (!loading) {
      const user = authStore.getState(USER_EVENT);

      contentTeacherActivity = (
        <Switch>
          <ProtectedRoute
            path="/my-dashboard"
            render={() => (
              <GraphTeacherActivity
                haveLoggedIn={haveLoggedIn}
                haveLogged30={haveLogged30}
                numTeachers={numTeachers}
                numTeachersInitial={numTeachersInitial}
                schoolUpdateData={numUpdatedDrivers}
              />
            )}
          />
          <Route render={() => <Redirect to={'/my-dashboard'} />} />
        </Switch>
      );

      contentDriveTrend = (
        <Switch>
          <ProtectedRoute
            path="/my-dashboard"
            render={() => (
              <GraphDriveTrend
                user={user}
                schoolAvgData={schoolAvgData}
                driverId={typeGraph}
                schoolAvgMonthlyData={schoolAvgMonthlyData}
              />
            )}
          />
          <Route render={() => <Redirect to={'/my-dashboard'} />} />
        </Switch>
      );
    }

    return (
      <NavigationBar currentRoute={'/my-dashboard'}>
        <div className="m-4 ">
          <div />
          <h6 className="d-inline" style={{ color: 'darkgray', paddingLeft: '2px' }}>
            {`Total Teachers: ` + numTeachers}
          </h6>
          <Row className="m-5" style={{ paddingTop: 0 + 'px !important' }}>
            {contentTeacherActivity}
          </Row>
          <div style={{ display: 'flex' }}>
            <h4 className="d-inline">{`Driver Trend`} </h4>
            <div style={{ marginLeft: '10px' }}>
              <DropDownDrive dropSelect={this.dropSelect} />
            </div>
          </div>
          <Row className="m-5" style={{ paddingTop: 0 + 'px !important' }}>
            {contentDriveTrend}
          </Row>
        </div>
      </NavigationBar>
    );
  }
}

export default MyDashboardView;
