import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { arrowUpThick } from 'react-icons-kit/typicons/arrowUpThick';
import LoadingButton from '../../../components/button/LoadingButton';

const iconStyle = {
  transform: 'translate(0px, -2px)',
  marginRight: 5,
};

const exportButtonStyle = {
  paddingRight: 15,
};

/**
 * Export Report Button.
 *
 * @param {Function} onClick - Function triggered on click.
 * @returns {*} - Export Report Button Component.
 * @class
 */
const ExportReportButton = ({ onClick, loading }) => {
  return (
    <LoadingButton loading={loading} size="sm" style={exportButtonStyle} onClick={onClick}>
      <Icon style={iconStyle} size={18} icon={arrowUpThick} />
      Export
    </LoadingButton>
  );
};

ExportReportButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ExportReportButton;
