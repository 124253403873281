import React from 'react';
import {
  COLOR_STRONGLY_AGREE,
  COLOR_AGREE,
  COLOR_NEUTRAL,
  COLOR_DISAGREE,
  COLOR_STRONGLY_DISAGREE,
} from '../../my-voice-models';
import PropTypes from 'prop-types';
//icon
import DriverIcon from '../../../../components/DriverIcon';
import styled from 'styled-components';

const StatusIndicator = styled.div`
  width: 150px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  background-color: ${(props) => props.color};
  padding: 5px 15px;
`;

const Title = styled.h5`
  flex-grow: 1;
  font-family: Zilla Slab;
`;

/**
 * My Voice Title Questions Leader View.
 *
 * @param numberTeachers
 * @param numberTeachersResponded
 * @param icon
 * @param name
 * @param message
 * @class TitleQuestions
 */

const TitleQuestions = ({ numberTeachers, numberTeachersResponded, icon, name, message }) => {
  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        {icon ? <DriverIcon icon={icon} /> : null}
        <Title className="ml-3 mb-0 flex-grow-1">{name}</Title>
      </div>

      <p className="mb-3">{message}</p>

      <div style={{ fontWeight: 'bold' }} className="mb-3">
        {numberTeachersResponded} out of {numberTeachers} Teachers Have Responded
      </div>

      <div className="d-flex">
        <StatusIndicator color={COLOR_STRONGLY_DISAGREE}>Strongly Disagree</StatusIndicator>
        <StatusIndicator color={COLOR_DISAGREE}>Disagree</StatusIndicator>
        <StatusIndicator color={COLOR_NEUTRAL}>Neutral</StatusIndicator>
        <StatusIndicator style={{ color: '#ffff' }} color={COLOR_AGREE}>
          Agree
        </StatusIndicator>
        <StatusIndicator style={{ color: '#ffff' }} color={COLOR_STRONGLY_AGREE}>
          Strongly Agree
        </StatusIndicator>
      </div>
    </div>
  );
};

TitleQuestions.propTypes = {
  numberTeachers: PropTypes.number.isRequired,
  numberTeachersResponded: PropTypes.number.isRequired,
  icon: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default TitleQuestions;
