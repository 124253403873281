import React from 'react';
import TitleQuestions from '../../my-voice/leader/drivers/TitleQuestions';
import IndicatorGraphCard from './IndicatorGraphCard';
import PropTypes from 'prop-types';

const DomainDetails = ({
  domain,
  message,
  indicators,
  indicatorsData,
  numberTeachers,
  numberTeachersResponded,
}) => {
  const indicatorsResponses = {};
  for (const indicators of indicatorsData) {
    for (const indicator of Object.keys(indicators)) {
      if (!indicatorsResponses[indicator]) indicatorsResponses[indicator] = [];
      const indicatorValue = indicators[indicator];
      indicatorsResponses[indicator].push(indicatorValue);
    }
  }

  const indicatorGraphCards = indicators.map((indicator, index) => {
    const indicatorResponses = indicatorsResponses[indicator.id];
    return (
      <div key={`indicator-${index}`} style={{ padding: 8, marginTop: 10 }}>
        <IndicatorGraphCard indicator={indicator.name} responses={indicatorResponses} />
      </div>
    );
  });

  return (
    <div>
      <TitleQuestions
        name={domain}
        message={message}
        numberTeachers={numberTeachers}
        numberTeachersResponded={numberTeachersResponded}
      />
      <div className="d-flex flex-wrap" style={{ marginLeft: -8 }}>
        {indicatorGraphCards}
      </div>
    </div>
  );
};

DomainDetails.propTypes = {
  domain: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  indicators: PropTypes.array.isRequired,
  indicatorsData: PropTypes.array.isRequired,
  numberTeachers: PropTypes.number.isRequired,
  numberTeachersResponded: PropTypes.number.isRequired,
};

export default DomainDetails;
