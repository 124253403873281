import React, { useState } from 'react';
import { useSubscription } from '@cobuildlab/react-flux-state';
import { clearInventorySchoolsOfDistrict } from '../super-admin-actions';
import {
  superAdminStore,
  CLEAR_DISTRICT_INVENTORY_ERROR_EVENT,
  CLEAR_DISTRICT_INVENTORY_EVENT,
} from '../super-admin-store';
import { customToast } from '../../../shared/toast';
import LoadingButton from '../../../components/button/LoadingButton';
import ConfirmModal from '../../../components/modal/ConfirmModal';

export const ClearDistrictInventoryButton = ({ districtId }) => {
  const [isLoading, setLoading] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const toggle = () => setConfirmModalOpen(!isConfirmModalOpen);

  const handleResetInventory = async () => {
    setLoading(true);
    await clearInventorySchoolsOfDistrict(districtId);
  };

  useSubscription(superAdminStore, CLEAR_DISTRICT_INVENTORY_EVENT, (msg) => {
    customToast.success(msg);
    setLoading(false);
  });

  useSubscription(superAdminStore, CLEAR_DISTRICT_INVENTORY_ERROR_EVENT, (err) => {
    customToast.error(err);
    setLoading(false);
  });

  return (
    <>
      <LoadingButton
        size="sm"
        disabled={isLoading}
        loading={isLoading}
        onClick={() => setConfirmModalOpen(true)}>
        Clear Inventory
      </LoadingButton>

      <ConfirmModal
        active={isConfirmModalOpen}
        toggle={toggle}
        onResponse={handleResetInventory}
        title="Please Confirm"
        body={`Are you sure you want to require again the Initial Profile and Initial Drivers for the schools with the district ID ${districtId}?`}
      />
    </>
  );
};
