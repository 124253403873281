import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '../../../shared/theme';

const SwitchContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 38px;
  height: 18px;
  margin-left: 10px;
  & input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + span {
      background-color: ${theme.palette.secondary};
      &:before {
        background-color: #3b458e;
        transform: translateX(15px);
      }
    }
    &:focus + span {
      box-shadow: 0 0 1px ${theme.palette.secondary};
    }
  }
  & span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 35px;
    &:before {
      position: absolute;
      content: '';
      height: 23px;
      width: 23px;
      left: 0px;
      top: -2.5px;
      background-color: #8e8e93;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
`;

const SwitchCheckbox = ({ onChange, checked = false, name, disabled = false }) => {
  const checkbox = useRef();

  const handleChange = () => {
    checkbox.current.click();
  };

  return (
    <SwitchContainer>
      <input
        type="checkbox"
        disabled={disabled}
        onChange={onChange}
        ref={checkbox}
        checked={checked}
        name={name}
      />
      <span onClick={handleChange}></span>
    </SwitchContainer>
  );
};

SwitchCheckbox.defaultProps = {
  checked: false,
  disabled: false,
};

SwitchCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default SwitchCheckbox;
