import Flux from '@cobuildlab/flux-state';

export const SOLUTIONS_EVENT = 'SOLUTIONS_EVENT';
export const SOLUTIONS_ERROR = 'SOLUTIONS_ERROR';

export const CREATE_UPDATE_SOLUTION_EVENT = 'CREATE_UPDATE_SOLUTION_EVENT';
export const CREATE_UPDATE_SOLUTION_ERROR = 'CREATE_UPDATE_SOLUTION_ERROR';

export const DRIVERS_AND_AVERAGES = 'DRIVERS_AND_AVERAGES';
export const DRIVERS_AND_AVERAGES_ERROR = 'DRIVERS_AND_AVERAGES_ERROR';

export const DELETE_SOLUTION_EVENT = 'DELETE_SOLUTION_EVENT';
export const DELETE_SOLUTION_ERROR = 'DELETE_SOLUTION_ERROR';

export const NOTIFICATIONS_SOLUTIONS_NEW_EVENT = 'NOTIFICATIONS_SOLUTIONS_NEW_EVENT';
export const NOTIFICATIONS_SOLUTIONS_NEW_ERROR = 'NOTIFICATIONS_SOLUTIONS_NEW_ERROR';

export const UPDATE_NOTIFICATION_SOLUTION_NEW_EVENT = 'UPDATE_NOTIFICATION_SOLUTION_NEW_EVENT';

export const UPDATE_NOTIFICATION_SOLUTION_NEW_ERROR = 'UPDATE_NOTIFICATION_SOLUTION_NEW_ERROR';

class SolutionsStore extends Flux.Store {
  constructor() {
    super();
    this.addEvent(SOLUTIONS_EVENT);
    this.addEvent(SOLUTIONS_ERROR);
    this.addEvent(CREATE_UPDATE_SOLUTION_EVENT);
    this.addEvent(CREATE_UPDATE_SOLUTION_ERROR);
    this.addEvent(DRIVERS_AND_AVERAGES);
    this.addEvent(DRIVERS_AND_AVERAGES_ERROR);
    this.addEvent(DELETE_SOLUTION_EVENT);
    this.addEvent(DELETE_SOLUTION_ERROR);
    this.addEvent(NOTIFICATIONS_SOLUTIONS_NEW_EVENT);
    this.addEvent(NOTIFICATIONS_SOLUTIONS_NEW_ERROR);
    this.addEvent(UPDATE_NOTIFICATION_SOLUTION_NEW_EVENT);
    this.addEvent(UPDATE_NOTIFICATION_SOLUTION_NEW_ERROR);
  }
}

export default new SolutionsStore();
