import React, { useEffect, useState } from 'react';
import { useSubscription } from '@cobuildlab/react-flux-state';
import CustomBadge from '../CustomBadge';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Input } from 'reactstrap';
import { DRIVERS_AND_DOMAINS } from './../../solutions-models';
import solutionsStore, { DRIVERS_AND_AVERAGES } from '../../solutions-store';
import { getSchoolDriversAndDomainsAverage } from '../../solutions-actions';
import PropTypes from 'prop-types';

const ModalIndicators = ({
  isOpen,
  toggle,
  onSubmit,
  selectedIndicators,
  selectedDriverDomain,
}) => {
  const [driverDomain, setDriverDomain] = useState('');
  const [indicators, setIndicators] = useState([]);
  const [driversDomainsAvg, setDriversDomainsAvg] = useState({});

  useEffect(() => {
    getSchoolDriversAndDomainsAverage();
  }, []);

  useSubscription(solutionsStore, DRIVERS_AND_AVERAGES, (data) => {
    setDriversDomainsAvg(data);
  });

  const driverDomainItem = DRIVERS_AND_DOMAINS.find((item) => item.id === driverDomain);

  useEffect(() => {
    if (isOpen && selectedIndicators && selectedDriverDomain) {
      setDriverDomain(selectedDriverDomain);
      setIndicators(selectedIndicators);
    } else {
      setIndicators([]);
      setDriverDomain('');
    }
  }, [isOpen, selectedDriverDomain, selectedIndicators]);

  useEffect(() => {
    if (driverDomain !== selectedDriverDomain) {
      setIndicators([]);
    } else {
      setIndicators(selectedIndicators);
    }
  }, [driverDomain]);

  const handleOnChangeIndicator = (indicatorId, value) => {
    let auxIndicators = [...indicators];
    if (!value) {
      auxIndicators = auxIndicators.filter((i) => i !== indicatorId);
    } else {
      auxIndicators.push(indicatorId);
    }
    setIndicators(auxIndicators);
  };

  const handleClose = () => {
    toggle();
    setDriverDomain('');
    setIndicators([]);
  };

  const handleOnSubmit = () => {
    onSubmit(driverDomain, indicators);
    setDriverDomain('');
    setIndicators([]);
  };

  let driverDomainIndicators = null;
  let domainAverage = null;

  if (driverDomain.length) {
    driverDomainIndicators = driverDomainItem.indicators.map((indicator) => {
      const isSelected = indicators.find((i) => i === indicator.id) ? true : false;
      const driverDoaminAvg = driversDomainsAvg[driverDomain];
      const indicatorAvg = driverDoaminAvg ? driverDoaminAvg.indicators[indicator.id] || 0 : 0;
      let badgeColor = 'danger';
      if (parseFloat(indicatorAvg) > 3.49) badgeColor = 'success';
      else if (parseFloat(indicatorAvg) > 2.49) badgeColor = 'warning';
      return (
        <div className="ml-4 mt-4 mb-2" key={indicator.id}>
          <Input
            type="checkbox"
            checked={isSelected}
            onChange={({ target: { checked } }) => handleOnChangeIndicator(indicator.id, checked)}
          />
          <CustomBadge color={badgeColor} className="mr-2">
            {indicatorAvg}
          </CustomBadge>
          {indicator.name}
        </div>
      );
    });
    domainAverage = (
      <CustomBadge>
        {driversDomainsAvg[driverDomain] ? driversDomainsAvg[driverDomain].avg : 0}
      </CustomBadge>
    );
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
      <ModalHeader toggle={toggle}>Select Indicators</ModalHeader>
      <ModalBody>
        <div className="d-flex flex-nowrap">
          <Input
            type="select"
            className="col-10 col-md-6"
            onChange={(e) => setDriverDomain(e.target.value)}
            value={driverDomain}>
            <option value="">Select Driver</option>
            {DRIVERS_AND_DOMAINS.map((driver) => {
              return (
                <option value={driver.id} key={driver.id}>
                  {driver.name}
                </option>
              );
            })}
          </Input>
          <span className="ml-3 d-inline-flex justify-content-center align-items-center">
            {domainAverage}
          </span>
        </div>
        <Col>{driverDomainIndicators}</Col>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <Button onClick={handleClose} className="btn btn-light">
          Cancel
        </Button>
        <Button color="secondary" onClick={handleOnSubmit}>
          Add Selected
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ModalIndicators.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedIndicators: PropTypes.array.isRequired,
  selectedDriverDomain: PropTypes.string.isRequired,
};

export default ModalIndicators;
