export const LoginModel = {
  email: '',
  password: '',
  rememberMe: false,
};

export const UserModel = {
  email: '',
  firstName: '',
  lastName: '',
  id: null,
  schoolId: null,
  needsProfile: true,
  needsPasswordReset: true,
  active: true,
  picture: null,
  anonymityId: null,
  isAdmin: false,
};
