import { Row } from 'reactstrap';
import React from 'react';
import {
  INSTRUCTIONAL_INDICATORS,
  INSTRUCTIONAL_MESSAGE,
  INSTRUCTIONAL_QUESTIONS,
} from '../../my-voice-models';
import TitleQuestions from './TitleQuestions';
import PropTypes from 'prop-types';
import IndicatorDetailCards from './indicatorsCards/IndicatorDetailCards';
import Icon from '../../../../shared/assets/icons/instructional-autonomy.svg';
import { getSeries } from '../../../../shared/driversGraphs';

/**
 * My Voice Leader Questions instructionalAutonomy View.
 *
 * @param questionsTeacher
 * @param numberTeachers
 * @returns {*}
 */
const DriverInstructionalLeaderView = ({
  questionsTeacher,
  numberTeachers,
  numberTeachersResponded,
  name,
  type,
  height,
}) => {
  const seriesGeneral = getSeries(
    INSTRUCTIONAL_QUESTIONS,
    questionsTeacher['instructionalAutonomy'],
  );

  const content = (
    <IndicatorDetailCards
      seriesGeneral={seriesGeneral}
      questions={INSTRUCTIONAL_INDICATORS}
      type={type}
      height={height}
    />
  );
  return (
    <div>
      <TitleQuestions
        numberTeachers={numberTeachers}
        numberTeachersResponded={numberTeachersResponded}
        message={INSTRUCTIONAL_MESSAGE}
        icon={Icon}
        name={name}
      />
      <Row className="p-4 ">{content}</Row>
    </div>
  );
};

DriverInstructionalLeaderView.propTypes = {
  questionsTeacher: PropTypes.object.isRequired,
  numberTeachers: PropTypes.number.isRequired,
  numberTeachersResponded: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
};

export default DriverInstructionalLeaderView;
