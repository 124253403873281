/**
 * Helper to check valid Strings: not null, not undefined, not blank.
 *
 * @param {string} str - String to test.
 * @returns {boolean} - True if the string is Valid, otherwise false.
 */
export const isValidString = (str) => {
  if (str === undefined) return false;
  if (str === null) return false;
  return str !== '';
};

/**
 * Capitalize a String.
 *
 * @param {string} s - String to capitalize.
 * @returns {string} - Capitalized string.
 */
export function capitalize(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}
