import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useTheme } from '../../theme';
import cogniaC from '../../assets/images/cognia_c.png';

const rotateKeyframe = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${(props) => props.palette.grey};
`;

const SpinImg = styled.img`
  animation: ${rotateKeyframe} 1.5s infinite linear;
`;

export const LoadingPage = ({ text }) => {
  const theme = useTheme();
  return (
    <Container {...theme}>
      <SpinImg src={cogniaC} alt="cognia logo" />
      <p className="font-weight-bold mt-3">{text}</p>
    </Container>
  );
};

LoadingPage.defaultProps = {
  text: 'Loading, Please wait...',
};
