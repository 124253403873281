import React, { useEffect, useState } from 'react';
import { useSubscription } from '@cobuildlab/react-flux-state';
import { Container, Row, Input } from 'reactstrap';
import styled from 'styled-components';
import { customToast } from '../../shared/toast';
import solutionsStore, {
  SOLUTIONS_EVENT,
  SOLUTIONS_ERROR,
  CREATE_UPDATE_SOLUTION_EVENT,
  CREATE_UPDATE_SOLUTION_ERROR,
  DELETE_SOLUTION_EVENT,
  DELETE_SOLUTION_ERROR,
  NOTIFICATIONS_SOLUTIONS_NEW_EVENT,
  UPDATE_NOTIFICATION_SOLUTION_NEW_EVENT,
} from './solutions-store';
import { DRIVERS_AND_DOMAINS } from './solutions-models';
import {
  getSolutions,
  createUpdateSolution,
  deleteSolution,
  getUserNotificationsSolutionsNew,
  updateUserNotification,
  getUserClfSolutions,
} from './solutions-actions';
import {
  myDistrictStore,
  MY_DISTRICT_ERROR_EVENT,
  SCHOOLS_BY_DISTRCIT_EVENT,
} from '../my-district/my-district-store';
import { getSchoolsByDistrict } from '../my-district/my-district-actions';
import { NavigationBar } from '../../shared/layouts/NavigationBar';
import SolutionsCardsGroup from './components/SolutionsCardsGroup';
import SolutionForm from './components/stepper/SolutionForm';
import {
  authStore,
  USER_CLF_DISTRICTS,
  USER_CLF_DISTRICTS_ERROR,
  USER_EVENT,
} from '../auth/auth-store';
import ConfirmModal from './../../components/modal/ConfirmModal';
import { ClipLoader } from '../../shared/components/progress/ClipLoader';
import { USER_TYPE_COA, USER_TYPE_LEADER, USER_TYPE_CLF } from '../../shared/userTypes';
import { getUserClfDistrictsData } from '../auth/auth-actions';

const MainContainer = styled(Container)`
  height: 100%;
`;

const MainRow = styled(Row)`
  height: 100%;
  flex-wrap: nowrap !important;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: calc(100% - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardsContainer = styled.div`
  width: ${(props) => (props.oneColumn ? '400px' : '100%')};
  min-width: 400px;
  height: 100%;
  overflow: auto;
  padding: 15px;
  padding-top: 1.5rem;
`;

const SolutionContainer = styled.div`
  flex-grow: 1;
  padding: 1.5rem 30px;
  height: 100%;
  overflow: auto;
`;

const TopInputSelect = styled(Input)`
  width: 350px !important;
  display: inline-block !important;
`;

const SolutionsView = () => {
  const [districts, setDistricts] = useState([]);
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState('allSchools');
  const [selectedDistrict, setSelectedDistrict] = useState('allDistricts');
  const [solutions, setSolutions] = useState([]);
  const [solutionSelected, setSolutionSelected] = useState(-1);
  const [solution, setSolution] = useState(null);
  const [selectedDriverDomain, setSelectedDriverDomain] = useState('allDriversDomains');
  const [creatingSolution, setCreatingSolution] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [notificationsSolutionsNew, setNotificationsSolutionsNew] = useState([]);

  const showStepperSolution = creatingSolution || solutionSelected > -1;
  const { userType, districtId } = authStore.getState(USER_EVENT);
  const isLeader = userType === USER_TYPE_LEADER;
  const isCOA = userType === USER_TYPE_COA;
  const isCLF = userType === USER_TYPE_CLF;

  const loadSolutions = () => {
    setSolutionSelected(-1);
    const driverDomainQuery =
      selectedDriverDomain === 'allDriversDomains' ? undefined : selectedDriverDomain;
    if (isCOA) {
      if (selectedSchool !== 'allSchools') {
        getSolutions(driverDomainQuery, selectedSchool);
      } else {
        getSolutions(driverDomainQuery, undefined, districtId);
      }
    } else if (isCLF) {
      getUserClfSolutions(
        selectedDriverDomain !== 'allDriversDomains' ? selectedDriverDomain : null,
        selectedSchool !== 'allSchools' ? selectedSchool : null,
        selectedDistrict !== 'allDistricts' ? selectedDistrict : null,
      );
    } else getSolutions(driverDomainQuery);
    setLoading(true);
  };

  useEffect(() => {
    if (isCOA) {
      getSchoolsByDistrict();
    } else if (isCLF) {
      getUserClfDistrictsData();
    }
  }, []);

  useEffect(() => {
    loadSolutions();
  }, [selectedDriverDomain, selectedSchool, selectedDistrict]);

  useEffect(() => {
    if (solutionSelected > -1) {
      setSolution(solutions[solutionSelected]);
    } else setSolution(null);
  }, [solutionSelected]);

  useSubscription(myDistrictStore, SCHOOLS_BY_DISTRCIT_EVENT, ({ data }) => {
    setSchools(data);
  });

  useSubscription(myDistrictStore, MY_DISTRICT_ERROR_EVENT, (err) => {
    customToast.error('Get schools error');
  });

  useSubscription(solutionsStore, SOLUTIONS_EVENT, (data) => {
    setSolutions(data);
    setLoading(false);
  });

  useSubscription(solutionsStore, SOLUTIONS_ERROR, () => {
    customToast.error('Get solutions error');
    setLoading(false);
  });

  useSubscription(solutionsStore, CREATE_UPDATE_SOLUTION_EVENT, (data) => {
    if (solutionSelected > -1) {
      const auxSolutions = [...solutions];
      auxSolutions[solutionSelected] = data;
      customToast.success('solution updated!');
      setSolutions(auxSolutions);
    } else {
      customToast.success('solution created!');
      setCreatingSolution(false);
      loadSolutions();
    }

    setSaving(false);
    setSaved(true);
  });

  useSubscription(solutionsStore, CREATE_UPDATE_SOLUTION_ERROR, (err) => {
    setSaving(false);
    customToast.error(err);
  });

  useSubscription(solutionsStore, DELETE_SOLUTION_EVENT, (data) => {
    setSolutionSelected(-1);
    loadSolutions();
    customToast.success('solution delete!');
  });

  useSubscription(solutionsStore, DELETE_SOLUTION_ERROR, (err) => {
    customToast.error(err);
  });

  useSubscription(solutionsStore, NOTIFICATIONS_SOLUTIONS_NEW_EVENT, (data) => {
    setNotificationsSolutionsNew(data);
  });

  useSubscription(solutionsStore, UPDATE_NOTIFICATION_SOLUTION_NEW_EVENT, () => {
    getUserNotificationsSolutionsNew();
  });

  useSubscription(authStore, USER_CLF_DISTRICTS, (data) => {
    setDistricts(data.districts);
    setSchools(data.schools);
  });

  useSubscription(authStore, USER_CLF_DISTRICTS_ERROR, (err) => {
    customToast.error(err.message);
  });

  const handleModalToggle = () => setIsOpenConfirmation(!isOpenConfirmation);

  const handleOnNewSolution = isLeader
    ? () => {
      setSolutionSelected(-1);
      setCreatingSolution(!creatingSolution);
    }
    : undefined;

  const handleOnSelectSolution = (selected) => {
    setCreatingSolution(false);
    if (solutionSelected === selected) {
      setSolutionSelected(-1);
    } else setSolutionSelected(selected);

    // if the solution had not been seen by the user update notification in sight
    const solutionData = solutions[selected];
    const notificationSolutionNew = notificationsSolutionsNew.find(
      (n) => n.solutionId === solutionData.id,
    );
    if (notificationSolutionNew && !notificationsSolutionsNew.viewed) {
      const { id } = notificationSolutionNew;
      updateUserNotification(id, { viewed: true });
    }
  };

  const onCloseSolutionForm = () => {
    setCreatingSolution(false);
    setSolutionSelected(-1);
  };

  const handleOnSubmitSolution = (data) => {
    createUpdateSolution(data, data.id);
    setSaving(true);
    setSaved(false);
  };

  const handleOnDeleteSolution = (solutionDeleted) => {
    if (solutionDeleted) deleteSolution(solution.id);
    else handleModalToggle();
  };

  const districtsInputSelect = isCLF ? (
    <TopInputSelect
      type="select"
      value={selectedDistrict}
      onChange={({ target: { value } }) => {
        if (value === 'allDistricts') {
          const allSchools = [];
          districts.forEach((d) => allSchools.push(...d.schools));
          setSchools(allSchools);
        } else {
          const district = districts.find((d) => d.id === value);
          setSchools(district.schools);
        }
        setSelectedSchool('allSchools');
        setSelectedDistrict(value);
      }}
      className={`mb-3 ${!showStepperSolution ? 'mr-3' : ''}`}>
      <option value="allDistricts">All Districts</option>
      {districts.map((district) => {
        return (
          <option key={district.id} value={district.id}>
            {district.name}
          </option>
        );
      })}
    </TopInputSelect>
  ) : null;

  const schoolsInputSelect =
    isCOA || isCLF ? (
      <TopInputSelect
        type="select"
        value={selectedSchool}
        onChange={({ target: { value } }) => setSelectedSchool(value)}
        className={`mb-3 ${!showStepperSolution ? 'mr-3' : ''}`}>
        <option value="allSchools">All Schools</option>
        {schools.map((school) => {
          return (
            <option key={school.id} value={school.id}>
              {school.name}
            </option>
          );
        })}
      </TopInputSelect>
    ) : null;

  const driversDomainInputSelect = (
    <TopInputSelect
      type="select"
      value={selectedDriverDomain}
      onChange={({ target: { value } }) => setSelectedDriverDomain(value)}>
      <option value="allDriversDomains">All Drivers</option>
      {DRIVERS_AND_DOMAINS.map((item) => {
        return (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        );
      })}
    </TopInputSelect>
  );

  let content = (
    <LoaderContainer>
      <ClipLoader />
    </LoaderContainer>
  );

  if (!loading) {
    content = (
      <SolutionsCardsGroup
        oneColumn={showStepperSolution}
        solutions={solutions}
        selected={solutionSelected}
        onSelect={handleOnSelectSolution}
        creatingSolution={creatingSolution}
        onNewSolution={handleOnNewSolution}
        notificationsSolutionsNew={notificationsSolutionsNew}
        showSchool={isCOA}
      />
    );
  }

  return (
    <NavigationBar currentRoute={'/solutions'}>
      <MainContainer fluid>
        <MainRow>
          <ConfirmModal
            active={isOpenConfirmation}
            toggle={handleModalToggle}
            title={'Are you sure?'}
            body={'This will permanently delete solution'}
            onResponse={handleOnDeleteSolution}
          />
          <CardsContainer oneColumn={showStepperSolution}>
            {districtsInputSelect}
            {schoolsInputSelect}
            {driversDomainInputSelect}
            {content}
          </CardsContainer>
          <SolutionContainer hidden={!showStepperSolution}>
            <SolutionForm
              editable={isLeader}
              solutionData={solution}
              onSubmit={handleOnSubmitSolution}
              saving={saving}
              saved={saved}
              onClose={onCloseSolutionForm}
              onDelete={handleModalToggle}
            />
          </SolutionContainer>
        </MainRow>
      </MainContainer>
    </NavigationBar>
  );
};

export default SolutionsView;
