import React from 'react';
import { Col, Row, Input } from 'reactstrap';
import PropTypes from 'prop-types';

export const MinimumMaximumInput = ({ minimumInput, maximumInput, disabled }) => {
  return (
    <Row className="justify-content-between">
      <Col sm={6}>
        <label>Minimum</label>
        <Input
          type="number"
          placeholder="min"
          min={0}
          value={minimumInput.value}
          onChange={(e) => minimumInput.onChange(e.target.value)}
          disabled={disabled}
        />
      </Col>
      <Col sm={6}>
        <label>Maximum</label>
        <Input
          type="number"
          placeholder="max"
          min={0}
          value={maximumInput.value}
          onChange={(e) => maximumInput.onChange(e.target.value)}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
};

MinimumMaximumInput.defaultProps = {
  disabled: false,
};

MinimumMaximumInput.propTypes = {
  minimumInput: PropTypes.object.isRequired,
  maximumInput: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};
