import React from 'react';
import Moment from 'react-moment';
import { Card as ReactCard, CardBody, CardText, Media, Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DropdownActions } from '../../../shared/components/inputs/DropdownActions';

const Card = styled(ReactCard)`
  border: 0;
  border-top: 1px solid #e8e9ed;
`;

export const MessageCard = ({ data, editable, onEdit, onDelete }) => (
  <Card>
    <CardBody>
      <Media style={{ position: 'relative' }} heading className="mb-3 font-weight-bold">
        {data.title}
        {editable ? (
          <div style={{ position: 'absolute', right: 0, top: -5 }}>
            <DropdownActions onEdit={() => onEdit(data)} onDelete={() => onDelete(data)} />
          </div>
        ) : null}
      </Media>
      <div className="d-flex justify-content-between">
        <div>
          {data.user ? (
            <>
              <span style={{ fontSize: 16 }} className="font-weight-bold">
                {data.user.firstName} {data.user.lastName}
              </span>
              <br />
              <span style={{ fontSize: 14, color: '#525252' }}>{data.user.userType}</span>
            </>
          ) : null}
        </div>
        <div>
          <Badge color="secondary" className="p-2">
            <Moment format="LL">{data.createdAt}</Moment>
          </Badge>
        </div>
      </div>
      <br />
      <CardText style={{ fontSize: 14 }} className="font-weight-bold mb-0">
        Description
      </CardText>
      <br />
      <CardText style={{ fontSize: 14 }}>{data.message}</CardText>
    </CardBody>
  </Card>
);

MessageCard.defaultProps = {
  editable: false,
  onEdit: undefined,
  onDelete: undefined,
};

MessageCard.propTypes = {
  data: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};
