export const QUESTIONS = [
  {
    options: [
      {
        title: 'Gender:',
        id: 'gender',
        values: [
          { name: 'Female', checked: false },
          { name: 'Male', checked: false },
          { name: 'I choose not to share', checked: false },
        ],
      },
      {
        title: 'Race:',
        id: 'race',
        values: [
          { name: 'African-American', checked: false },
          { name: 'American indian/Alaska Native', checked: false },
          { name: 'Asian', checked: false },
          { name: 'Hispanic', checked: false },
          { name: 'White', checked: false },
          { name: 'Other..', checked: false },
          { name: 'I choose not to share', checked: false },
        ],
      },
      {
        title: 'Age:',
        id: 'age',
        values: [
          { name: 'Under 30', checked: false },
          { name: '31 to 39', checked: false },
          { name: '40 to 49', checked: false },
          { name: '50 or higher', checked: false },
          { name: 'I choose not to share', checked: false },
        ],
      },
      {
        title: 'Number of years in education:',
        id: 'yearsInEducation',
        values: [
          { name: '0 to 5', checked: false },
          { name: '6 to 10', checked: false },
          { name: '11 to 15', checked: false },
          { name: '16 or higher', checked: false },
        ],
      },
      {
        title: 'Number of years in current school:',
        id: 'yearsInCurrentSchool',
        values: [
          { name: '0 to 5', checked: false },
          { name: '6 to 10', checked: false },
          { name: '11 to 15', checked: false },
          { name: '16 or higher', checked: false },
        ],
      },
    ],
  },
  {
    options: [
      {
        title: 'School Level:',
        id: 'schoolLevel',
        values: [
          { name: 'Elementary School', checked: false },
          { name: 'Middle School', checked: false },
          { name: 'High School', checked: false },
        ],
      },
      {
        title: 'Number of years I have worked with the principal in this school:',
        id: 'yearsWithPrincipal',
        values: [
          { name: '0 to 2', checked: false },
          { name: '3 to 4', checked: false },
          { name: '5 to 6', checked: false },
          { name: '7 or more', checked: false },
        ],
      },
      {
        title: 'How many principals have you worked with during your tenure in this building?',
        id: 'howManyPrincipals',
        values: [
          { name: '1 to 2', checked: false },
          { name: '3', checked: false },
          { name: '4 or more', checked: false },
        ],
      },
      {
        title: 'How many schools have you worked in during your career?',
        id: 'howManySchools',
        values: [
          { name: '1 to 2', checked: false },
          { name: '3', checked: false },
          { name: '4 or more', checked: false },
        ],
      },
    ],
  },
  {
    options: [
      {
        title: 'Highest degree attained:',
        id: 'highestDegree',
        values: [
          { name: 'Bachelor’s Degree', checked: false },
          { name: 'Master’s Degree', checked: false },
          { name: 'Specialist in Education Degree', checked: false },
          { name: 'Doctoral Degree', checked: false },
          { name: 'Other', checked: false },
        ],
      },
      {
        title: 'Certification type:',
        id: 'certificationType',
        values: [
          { name: 'Traditional Certification', checked: false },
          { name: 'Alternative Certification', checked: false },
          { name: 'Non-certified', checked: false },
        ],
      },
      {
        title: 'Are you considering leaving your current school?',
        id: 'consideringLivingSchool',
        values: [
          { name: 'Yes', checked: false },
          { name: 'No', checked: false },
          { name: 'I choose not to share', checked: false },
        ],
      },
      {
        title:
          'Are you considering leaving the teaching profession for reasons other than retirement?',
        id: 'consideringLivingProfession',
        dropdown: false,
        values: [
          { name: 'Yes', checked: false },
          { name: 'No', checked: false },
          { name: 'I choose not to share', checked: false },
        ],
      },
      {
        title:
          'Do you have an interest in a leadership-type position? [e.g., principal, assistant principal, instructional coach, department chair, lead teacher, etc.]?',
        id: 'interestedOnLeadership',
        dropdown: false,
        values: [
          { name: 'Yes', checked: false },
          { name: 'No', checked: false },
        ],
      },
    ],
  },
];

export const QUESTIONS_LEADER = [
  {
    options: [
      {
        title: 'Current Administrative Position:',
        id: 'currentPosition',
        values: [
          { name: 'Principal', checked: false },
          { name: 'Assistant or Associate Principal', checked: false },
          {
            name:
              'Other Building Level Administrator  (Headmaster, Assistant Headmaster, Dean of Students, Athletic Director)',
            checked: false,
          },
        ],
      },
      {
        title: 'School Level:',
        id: 'schoolLevel',
        values: [
          { name: 'Elementary School', checked: false },
          { name: 'Middle School', checked: false },
          { name: 'High School', checked: false },
        ],
      },
      {
        title: 'Gender:',
        id: 'gender',
        values: [
          { name: 'Female', checked: false },
          { name: 'Male', checked: false },
          { name: 'I choose not to share', checked: false },
        ],
      },
      {
        title: 'Race:',
        id: 'race',
        values: [
          { name: 'African-American', checked: false },
          { name: 'American indian/Alaska Native', checked: false },
          { name: 'Asian', checked: false },
          { name: 'Hispanic', checked: false },
          { name: 'White', checked: false },
          { name: 'Other', checked: false },
        ],
      },
      {
        title: 'Age:',
        id: 'age',
        values: [
          { name: 'Under 30', checked: false },
          { name: '31 to 39', checked: false },
          { name: '40 to 49', checked: false },
          { name: '50 or higher', checked: false },
        ],
      },
      {
        title: 'Number of years in Education:',
        id: 'yearsInEducation',
        values: [
          { name: '0 to 5', checked: false },
          { name: '6 to 10', checked: false },
          { name: '11 to 15', checked: false },
          { name: '16 or higher', checked: false },
        ],
      },
      {
        title: 'Certification type:',
        id: 'certificationType',
        values: [
          { name: 'Traditional Certification', checked: false },
          { name: 'Alternative Certification', checked: false },
        ],
      },
    ],
  },
  {
    options: [
      {
        title: 'The number of years as a building administrator:',
        id: 'buildingAdministrator',
        values: [
          { name: '0 to 2', checked: false },
          { name: '3 to 4', checked: false },
          { name: '5 to 6', checked: false },
          { name: '7 or more', checked: false },
        ],
      },
      {
        title: 'Number of Years as an administrator in this building:',
        id: 'numberAdministrator',
        values: [
          { name: '0 to 2', checked: false },
          { name: '3 to 4', checked: false },
          { name: '5 to 6', checked: false },
          { name: '7 or more', checked: false },
        ],
      },
      {
        title: 'If a principal, how many years as an assistant principal:',
        id: 'timeAssistant',
        values: [
          { name: '0 to 2', checked: false },
          { name: '3 to 4', checked: false },
          { name: '5 to 6', checked: false },
          { name: '7 or more', checked: false },
          { name: 'Not applicable', checked: false },
        ],
      },
      {
        title:
          'If an assistant principal, the number of years I have worked with the principal in this school:',
        id: 'howManyTimePrincipal',
        values: [
          { name: '0 to 2', checked: false },
          { name: '3 to 4', checked: false },
          { name: '5 to 6', checked: false },
          { name: '7 or more', checked: false },
          { name: 'Not applicable', checked: false },
        ],
      },
      {
        title: 'Immediate role before becoming a building administrator:',
        id: 'roleBefore',
        values: [
          { name: 'Classroom Teacher', checked: false },
          { name: 'Certified position other than teacher', checked: false },
          { name: 'District Administrator', checked: false },
          { name: 'Leader position outside of education', checked: false },
          { name: 'Other', checked: false },
        ],
      },
    ],
  },
  {
    options: [
      {
        title:
          'If an assistant principal, how many principals have you worked with during your tenure in this building?',
        id: 'howManyWorked',
        values: [
          { name: '1', checked: false },
          { name: '2', checked: false },
          { name: '3', checked: false },
          { name: '4 or more', checked: false },
          { name: 'Not applicable', checked: false },
        ],
      },
      {
        title:
          'How many schools have you worked in during your career as a building administrator?',
        id: 'consideringLivingSchool',
        values: [
          { name: '1 to 2', checked: false },
          { name: '3 to 4', checked: false },
          { name: '5 or more', checked: false },
        ],
      },
      {
        title: 'Highest Degree Attained?',
        id: 'highestDegree',
        values: [
          { name: 'Bachelor’s Degree', checked: false },
          { name: 'Master’s Degree', checked: false },
          { name: 'Specialist in Education Degree', checked: false },
          { name: 'Doctoral Degree', checked: false },
        ],
      },
      {
        title: 'Are you considering leaving your current school?',
        id: 'interestedOnLeadership',
        dropdown: false,
        values: [
          { name: 'Yes', checked: false },
          { name: 'No', checked: false },
        ],
      },
      {
        title:
          'Are you considering leaving the administration profession for reasons other than retirement?',
        id: 'leavingProfession',
        dropdown: false,
        values: [
          { name: 'Yes', checked: false },
          { name: 'No', checked: false },
        ],
      },
      {
        title:
          'Do you have an interest in a district leadership-type position [e.g., Director, Associate Superintendent, Superintendent, etc.]?',
        id: 'leadership',
        values: [
          { name: 'Yes', checked: false },
          { name: 'No', checked: false },
        ],
      },
    ],
  },
];

export const ProfileModel = {
  gender: null,
  race: null,
  age: null,
  yearsInEducation: null,
  schoolLevel: null,
  yearsInCurrentSchool: null,
  yearsWithPrincipal: null,
  howManyPrincipals: null,
  howManySchools: null,
  certificationType: null,
  consideringLivingSchool: null,
  consideringLivingProfession: null,
  interestedOnLeadership: null,
  highestDegree: null,
};

export const ProfileModelLeader = {
  currentPosition: null,
  schoolLevel: null,
  gender: null,
  race: null,
  age: null,
  yearsInEducation: null,
  certificationType: null,
  buildingAdministrator: null,
  numberAdministrator: null,
  timeAssistant: null,
  howManyTimePrincipal: null,
  roleBefore: null,
  howManyWorked: null,
  consideringLivingSchool: null,
  highestDegree: null,
  interestedOnLeadership: null,
  leavingProfession: null,
  leadership: null,
};
