import React, { useRef } from 'react';
import styled from 'styled-components';
import { theme } from '../../shared/theme';

const CheckboxContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: ${({ active, disabled }) =>
    !active && disabled ? '1px solid #CED4DA' : '1px solid #1A3044'};
  border-radius: 3px;
  height: 15px;
  width: 15px;
  padding: 2.8px;
  ${({ active, disabled }) => {
    if (active) return `background-color: ${theme.palette.primary};`;
    if (disabled) return `background-color: ${theme.palette.disabled};`;
  }}
  &:after {
    content: "";
    display: ${({ active }) => (active ? 'block' : 'none')}
    width: 5px;
    height: 10px;
    border: 1px solid #fff;
    border-width: 0 1px 1px 0;
    transform: rotate(35deg);
  }
`;

const Checkbox = ({ checked, children, onChange, ...rest }) => {
  const checkbox = useRef();

  const handleChange = () => {
    checkbox.current.click();
  };

  return (
    <CheckboxContainer {...rest} active={checked} onClick={handleChange}>
      {children}
      <input type="checkbox" ref={checkbox} onChange={onChange} disabled={rest.disabled} hidden />
    </CheckboxContainer>
  );
};

export default Checkbox;
