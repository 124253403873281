import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import LoadingButton from '../button/LoadingButton';

const ConfirmModal = ({ active, toggle, title, body, onResponse }) => {
  const handleResponse = (response) => {
    onResponse(response);
    toggle();
  };
  // className={className}
  return (
    <Modal isOpen={active} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        <Button color="light" onClick={() => handleResponse(false)}>
          Cancel
        </Button>
        <LoadingButton color="secondary" onClick={() => handleResponse(true)}>
          Confirm
        </LoadingButton>{' '}
      </ModalFooter>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  active: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  onResponse: PropTypes.func.isRequired,
};

export default ConfirmModal;
