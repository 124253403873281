import firebase from 'firebase';
import Flux from '@cobuildlab/flux-state';
import {
  SCHOOLS_BY_DISTRCIT_EVENT,
  MY_DISTRICT_ERROR_EVENT,
  SCHOOL_INITIAL_AVERAGE_EVENT,
  SCHOOL_AVERAGE_SCHOOLID_EVENT,
  TEACHERS_BY_SCHOOLS_EVENT,
  SEND_MESSAGE_EVENT,
  SEND_MESSAGE_ERROR,
  GET_MESSAGES_EVENT,
  GET_MESSAGES_ERROR,
  UPDATE_MESSAGE_EVENT,
  UPDATE_MESSAGE_ERROR,
  DELETE_MESSAGE_EVENT,
  DELETE_MESSAGE_ERROR,
} from './my-district-store';
import { authStore, USER_EVENT } from './../auth/auth-store';
import { USER_TYPE_TEACHER } from '../../shared/userTypes';

/**
 * Return the Schools by district of user.
 *
 *  @param districtId - District ID.
 * @returns {Promise<>}
 */
export const getSchoolsByDistrict = async (districtId) => {
  const user = authStore.getState(USER_EVENT);
  const getSchoolsByDistrict = firebase.functions().httpsCallable('getDistrictSchoolsByDistrictId');
  let result;
  try {
    result = await getSchoolsByDistrict(user.districtId);
  } catch (err) {
    return Flux.dispatchEvent(MY_DISTRICT_ERROR_EVENT, err);
  }

  Flux.dispatchEvent(SCHOOLS_BY_DISTRCIT_EVENT, result);
  return result;
};

/**
 * Get avg of initialDriversTeachers.
 *
 * @param schoolId
 * @param {object}
 * @returns {object} Avg of initials drivers of teachers.
 */
export const getSchoolTeacherInitialDriversAvg = async (schoolId) => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');

  let teachers;
  if (schoolId) {
    teachers = await usersCollection
      .where('schoolId', '==', schoolId)
      .where('active', '==', true)
      .get();
  } else {
    teachers = await getCoaSchoolsUsers();
  }

  const emailsTeachers = [];
  teachers.forEach((doc) => {
    emailsTeachers.push(doc.id);
  });
  const promises = emailsTeachers.map((emailTeacher, i) =>
    getTeacherInitialDriversAvg(emailTeacher, i),
  );
  const results = await Promise.all(promises);
  const filteredResults = results.filter((result) => result !== null);
  const avgMap = {};

  filteredResults.forEach((result) => {
    const driverAvgs = result[Object.keys(result)[0]];
    const driversIds = Object.keys(driverAvgs);
    driversIds.forEach((driverId) => {
      const indicatorAvg = driverAvgs[driverId];
      if (avgMap[driverId] === undefined) {
        avgMap[driverId] = [];
      }
      avgMap[driverId].push(parseFloat(indicatorAvg));
    });
  });

  // Reducing
  const driversIds = Object.keys(avgMap);
  driversIds.forEach((driverId) => {
    const valueList = avgMap[driverId];
    const size = valueList.length;
    avgMap[driverId] = (avgMap[driverId].reduce((total, num) => total + num) / size).toFixed(2);
  });

  Flux.dispatchEvent(SCHOOL_INITIAL_AVERAGE_EVENT, avgMap);
  return avgMap;
};

/**
 * Get a Teacher Drivers Avg From the initial Values.
 *
 * @param emailTeacher
 * @param i
 * @returns {Promise<{}|null>}
 */
const getTeacherInitialDriversAvg = async (emailTeacher, i) => {
  if (emailTeacher === null || emailTeacher === undefined) return null;
  const DB = firebase.firestore();
  const driversCollection = DB.collection('initialDriversTeacher');
  const queryRef = await driversCollection.where('email', '==', emailTeacher);
  // get teacher with the most recent date
  const descDate = await queryRef
    .orderBy('dateCompleted', 'desc')
    .limit(1)
    .get();
  let driversData = null;
  //get drivers of the teacher
  descDate.forEach((doc) => {
    const { drivers } = doc.data();
    driversData = drivers;
  });

  if (driversData === null || driversData === undefined) return null;

  //get property names like sections [ advancement, clearUnifiedDirection, ... ]
  const driversIds = Object.keys(driversData);
  const teacherAvgs = {};
  driversIds.forEach((driverId) => {
    //get object with questions and answers by section { expertise :5 , leaderOpportunities : 5 , ...}
    const indicators = driversData[driverId];
    //get the names of the answers
    const indicatorsIds = Object.keys(indicators);
    let acc = 0,
      i = 0,
      driverAvg = 0;
    //sum all answers for each section
    indicatorsIds.forEach((indicatorId) => {
      acc += indicators[indicatorId];
      i++;
    });
    if (i !== 0)
      //get avg driver by section
      driverAvg = (acc / i).toFixed(2);
    //add the avg by section in object
    teacherAvgs[driverId] = driverAvg;
  });
  return { [emailTeacher]: teacherAvgs };
};

/**
 * Gets the Teachers School Average of an specific school.
 *
 * @param schoolId
 * @returns {Promise<void>}
 */
export const getSchoolTeachersDriversAvg = async (schoolId) => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');

  let users;
  if (schoolId) {
    users = await usersCollection
      .where('schoolId', '==', schoolId)
      .where('active', '==', true)
      .get();
  } else {
    users = await getCoaSchoolsUsers();
  }

  const userIDs = users.docs.map((doc) => doc.id);
  const promises = userIDs.map((email, i) => getTeacherDriversAvg(email, i));
  const results = await Promise.all(promises);
  const filteredResults = results.filter((result) => result !== null);

  // Mapping
  const avgMap = {};
  filteredResults.forEach((driverAvgs) => {
    const indicatorsIds = Object.keys(driverAvgs);
    indicatorsIds.forEach((indicatorId) => {
      const indicatorAvg = driverAvgs[indicatorId];
      if (avgMap[indicatorId] === undefined) {
        avgMap[indicatorId] = [];
      }
      avgMap[indicatorId].push(parseFloat(indicatorAvg));
    });
  });

  // Reducing
  const indicatorsIds = Object.keys(avgMap);
  indicatorsIds.forEach((indicatorId) => {
    const valueList = avgMap[indicatorId];
    const size = valueList.length;
    avgMap[indicatorId] = (avgMap[indicatorId].reduce((total, num) => total + num) / size).toFixed(
      2,
    );
  });

  Flux.dispatchEvent(SCHOOL_AVERAGE_SCHOOLID_EVENT, avgMap);
  return avgMap;
};

/**
 * Get a Teacher Drivers Avg.
 *
 * @param userEmail
 * @param i
 * @returns {Promise<{}|null>}
 */
const getTeacherDriversAvg = async (userEmail, i) => {
  const DB = firebase.firestore();
  const driversCollection = DB.collection('driversTeacher');
  const queryRef = driversCollection.doc(userEmail);
  let driversData;
  try {
    const ref = await queryRef.get();
    driversData = ref.data();
  } catch (e) {
    console.error('User email not in Drivers', i, e.message);
    return null;
  }
  if (driversData === null || driversData === undefined) return null;
  const driversIds = Object.keys(driversData);
  const userAvgs = {};
  driversIds.forEach((driverId) => {
    const indicators = driversData[driverId];
    const indicatorsIds = Object.keys(indicators);
    let acc = 0,
      i = 0,
      driverAvg = 0;
    indicatorsIds.forEach((indicatorId) => {
      acc += indicators[indicatorId];
      i++;
    });
    if (i !== 0) driverAvg = (acc / i).toFixed(2);
    userAvgs[driverId] = driverAvg;
  });
  return userAvgs;
};

/**
 * Get a Teacher.
 *
 * @returns {Promise<{}|null>}
 * @param schools
 */
export const getTeacherForSchool = async (schools) => {
  const DB = firebase.firestore();

  let schoolsCollection = [];
  schools.forEach((school) => {
    schoolsCollection.push(
      DB.collection('users')
        .where('schoolId', '==', school.id)
        .where('userType', '==', USER_TYPE_TEACHER)
        .where('active', '==', true)
        .get(),
    );
  });

  let schoolsQuery = null;
  try {
    schoolsQuery = await Promise.all(schoolsCollection);
  } catch (err) {
    return Flux.dispatchEvent(MY_DISTRICT_ERROR_EVENT, err);
  }

  let teachersList = [];
  schoolsQuery.forEach((doc) => {
    if (doc.docs) {
      doc.docs.forEach((document) => {
        const teacher = document.data();
        teachersList.push(teacher);
      });
    }
  });

  schools.forEach((doc) => {
    const numTeacher = teachersList.filter((teacher) => teacher.schoolId === doc.id);
    doc.numTeacher = numTeacher.length;
  });

  Flux.dispatchEvent(TEACHERS_BY_SCHOOLS_EVENT, schools);
  return schools;
};

export const getCoaSchoolsUsers = async () => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');

  const user = authStore.getState(USER_EVENT);

  const users = await usersCollection
    .where('districtId', '==', user.districtId)
    .where('active', '==', true)
    .get();

  return users;
};

export const sendMessageCOA = async (messageData) => {
  const DB = firebase.firestore();
  const collection = DB.collection('messages');
  const user = authStore.getState(USER_EVENT);

  try {
    const result = await collection.add({
      ...messageData,
      sender: user.email,
      districtId: user.districtId,
      createdAt: new Date(),
    });
    Flux.dispatchEvent(SEND_MESSAGE_EVENT, result.id);
  } catch (err) {
    console.error(err);
    Flux.dispatchEvent(SEND_MESSAGE_ERROR, err.message);
  }
};

/**
 *
 * @param {[object]} messages - Messages.
 * @returns {[object]} - Messages with user data.
 */
export const getMessagesUsers = async (messages) => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');

  const userEmails = {};

  messages.forEach((msg) => {
    if (!userEmails[msg.sender]) {
      userEmails[msg.sender] = msg.sender;
    }
  });

  const promises = Object.keys(userEmails).map(async (email) => {
    const doc = await usersCollection.doc(email).get();
    return { ...doc.data(), id: doc.id };
  });

  const users = await Promise.all(promises);

  const messagesWithUserData = messages.map((msg) => {
    const user = users.find((user) => user.email === msg.sender);
    return {
      ...msg,
      user,
    };
  });

  return messagesWithUserData;
};

/**
 *
 * @param {string} districtId - District Id.
 * @param {number} limit - Result Count Limit.
 * @param {firebase.firestore.DocumentSnapshot} startAfter - Last Doc to start.
 * @returns {object} -
 */
export const getMessages = async (districtId, limit = 10, startAfter = null) => {
  const DB = firebase.firestore();
  const collection = DB.collection('messages');

  let query = collection
    .where('districtId', '==', districtId)
    .orderBy('createdAt', 'desc')
    .limit(limit);

  if (startAfter) query = query.startAfter(startAfter.createdAt);

  const result = await query.get();

  const messages = await getMessagesUsers(
    result.docs.map((doc) => {
      const data = { ...doc.data(), id: doc.id };
      data.createdAt = data.createdAt.toDate();
      return data;
    }),
  );

  let lastDoc = result.docs[result.docs.length - 1];
  if (lastDoc) {
    lastDoc = { ...lastDoc.data(), id: lastDoc.id };
    if (lastDoc.createdAt) lastDoc.createdAt = lastDoc.createdAt.toDate();
  }
  const isMore = limit === result.size;

  return {
    messages,
    lastDoc,
    messagesCount: messages.length,
    isMore,
  };
};

export const getUserDistrictMessages = async (limit, startAfter) => {
  const user = authStore.getState(USER_EVENT);

  try {
    const data = await getMessages(user.districtId, limit, startAfter);
    Flux.dispatchEvent(GET_MESSAGES_EVENT, data);
  } catch (err) {
    console.error(err);
    Flux.dispatchEvent(GET_MESSAGES_ERROR, err.message);
  }
};

/**
 *
 * @param {object} messageData - Message to update.
 */
export const updateMessage = async (messageData) => {
  const DB = firebase.firestore();
  const collection = DB.collection('messages');
  const user = authStore.getState(USER_EVENT);

  try {
    await collection.doc(messageData.id).set(
      {
        ...messageData,
        updatedBy: user.email,
        updatedAt: new Date(),
      },
      { merge: true },
    );
    Flux.dispatchEvent(UPDATE_MESSAGE_EVENT);
  } catch (err) {
    console.error(err);
    Flux.dispatchEvent(UPDATE_MESSAGE_ERROR, err.message);
  }
};

/**
 *
 * @param {object} messageData - Message to delete.
 */
export const deleteMessage = async (messageData) => {
  const DB = firebase.firestore();
  const collection = DB.collection('messages');

  try {
    await collection.doc(messageData.id).delete();
    Flux.dispatchEvent(DELETE_MESSAGE_EVENT);
  } catch (err) {
    console.error(err);
    Flux.dispatchEvent(DELETE_MESSAGE_ERROR, err.message);
  }
};
