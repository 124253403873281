import React from 'react';
import View from '@cobuildlab/react-flux-state';
import { getSchoolsByDistrict } from '../../my-district/my-district-actions';
import { myDistrictStore, SCHOOLS_BY_DISTRCIT_EVENT } from '../../my-district/my-district-store';
import AddUserModal from '../../../components/user/AddUserModal';
import { createUserRosterManager } from '../roster-manager-actions';
import {
  ROSTER_MANAGER_ADD_USER_ERROR_EVENT,
  ROSTER_MANAGER_ADD_USER_EVENT,
  rosterManagerStore,
} from '../RosterManagerStore';
import {
  SUPER_ADMIN_ALL_DISTRICTS_EVENT,
  superAdminStore,
} from '../../super-admin/super-admin-store';
import { getAllDistricts } from '../../super-admin/super-admin-actions';

class AddUserModalContainer extends View {
  state = {
    schools: [],
    districts: [],
    loading: false,
  };

  handleSubmit = (user) => {
    this.setState({ loading: true });
    createUserRosterManager(user);
  };

  componentDidMount() {
    // EVENT TO CREATE USER
    this.subscribe(rosterManagerStore, ROSTER_MANAGER_ADD_USER_EVENT, () => {
      this.props.toggle();
      this.setState({ loading: false });
    });
    this.subscribe(rosterManagerStore, ROSTER_MANAGER_ADD_USER_ERROR_EVENT, () => {
      this.setState({ loading: false });
    });
    // EVENT TO GET SCHOOLS
    this.subscribe(myDistrictStore, SCHOOLS_BY_DISTRCIT_EVENT, (data) => {
      const schoolsByDistrict = data.data;
      this.setState({
        schools: schoolsByDistrict,
      });
    });
    this.subscribe(superAdminStore, SUPER_ADMIN_ALL_DISTRICTS_EVENT, (districts) => {
      this.setState({ districts });
    });

    getSchoolsByDistrict();
    getAllDistricts();
  }

  render() {
    const { schools, districts, loading } = this.state;
    const { modal, toggle } = this.props;
    return (
      <AddUserModal
        onSubmit={this.handleSubmit}
        loading={loading}
        schools={schools}
        districts={districts}
        onToggle={toggle}
        isOpen={modal}
      />
    );
  }
}

export default AddUserModalContainer;
