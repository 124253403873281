import React, { useState } from 'react';
import { initialOtherSearch } from './components/UserSearchForm';
import { plus as ic_plus } from 'react-icons-kit/fa/plus';
import { download2 as ic_download } from 'react-icons-kit/icomoon/download2';
import {
  createAndDownloadSuperAdminReport,
  fetchAllSchools,
  getAllDistricts,
  searchUsersSuperAdmin,
  updateUser,
} from './super-admin-actions';
import { useDebounce } from '../../shared/hooks';
import { ManagementView, TABLE_LIMIT } from '../../components/ManagementView';
import { ButtonUsersFilter } from './components/ButtonFilterUsers';
import AddUserModalContainer from './components/AddUserModalContainer';
import {
  ADD_USER_EVENT,
  superAdminStore,
  SUPER_ADMIN_EXCEL_REPORTS_ERROR,
  SUPER_ADMIN_EXCEL_REPORTS_EVENT,
  UPDATE_USER_ERROR_EVENT,
  UPDATE_USER_EVENT,
} from './super-admin-store';
import { customToast } from '../../shared/toast';
import { useSubscription } from '@cobuildlab/react-flux-state';
import useFetchPage from '../../shared/useFetchPage';
import { TableUsers } from './components/TableUsers';
import { queryClient } from '../../shared/utils';
import { useQuery } from 'react-query';
import EditUserModal from '../../components/user/EditUserModal';

const fetchKey = 'fetchUsersManagement';
const fetchSchoolKey = 'fetchSchoolKey';
const fetchDistrictKey = 'fetchDistrictKey';

export const UsersManagementView = () => {
  const [userFilters, setUserFilters] = useState(initialOtherSearch);
  const [search, setSearch] = useState('');
  const [loadingReport, setLoadingReport] = useState(false);
  const [isModalOpen, setModalState] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loadingEdit, setLoadingEdit] = useState(false);

  const toggleModal = () => setModalState(!isModalOpen);

  const handleCreateReport = () => {
    setLoadingReport(true);
    createAndDownloadSuperAdminReport(userFilters, 'users', 'Users Report');
  };

  useDebounce(() => {
    setUserFilters({ ...userFilters, generalSearch: search });
  }, search);

  const { isLoading, data, fetchNextPage, isFetchingNextPage: isLoadingMore } = useFetchPage(
    [fetchKey, userFilters],
    ({ queryKey, pageParam = null }) => {
      const [, rawFilters] = queryKey;
      const filters = { ...rawFilters };
      return searchUsersSuperAdmin(filters, TABLE_LIMIT, pageParam);
    },
    {
      onError: (err) => {
        customToast.error(err);
      },
    },
  );

  const { data: schools } = useQuery(
    [fetchSchoolKey],
    () => {
      return fetchAllSchools();
    },
    {
      onError: (err) => {
        customToast.error(err);
      },
    },
  );

  const { data: districts } = useQuery(
    [fetchDistrictKey],
    () => {
      return getAllDistricts();
    },
    {
      onError: (err) => {
        customToast.error(err);
      },
    },
  );

  useSubscription(superAdminStore, SUPER_ADMIN_EXCEL_REPORTS_ERROR, (err) => {
    setLoadingReport(false);
    customToast.error(err);
  });

  useSubscription(superAdminStore, SUPER_ADMIN_EXCEL_REPORTS_EVENT, () => {
    setLoadingReport(false);
  });

  useSubscription(superAdminStore, UPDATE_USER_EVENT, () => {
    queryClient.invalidateQueries([fetchKey]);
    customToast.success('User updated successfully');
    toggleModal();
    setLoadingEdit(false);
  });

  useSubscription(superAdminStore, UPDATE_USER_ERROR_EVENT, (error) => {
    customToast.error(error);
    setLoadingEdit(false);
  });

  useSubscription(superAdminStore, ADD_USER_EVENT, () => {
    queryClient.invalidateQueries([fetchKey]);
  });

  const handleEdit = (userDetail) => {
    setSelectedUser(userDetail);
    toggleModal();
  };

  const handleUserUpdate = (user) => {
    setLoadingEdit(true);
    updateUser(user);
  };

  const loading = isLoading || isLoadingMore;
  return (
    <>
      <ManagementView
        openFilterButton={
          <ButtonUsersFilter
            data={userFilters}
            onChange={(val) => setUserFilters(val)}
            isLoading={loading}
          />
        }
        count={data.count}
        haveMoreToLoad={data.haveMoreToLoad}
        searchInput={{
          value: search,
          onChange: (value) => setSearch(value),
        }}
        isLoading={loading}
        buttomActions={[
          {
            onClick: handleCreateReport,
            title: 'Download File',
            icon: ic_download,
            loading: loadingReport,
          },
          {
            onClick: () => {
              setSelectedUser(null);
              toggleModal();
            },
            title: 'Add User',
            icon: ic_plus,
          },
        ]}>
        <TableUsers
          isLoading={loading}
          isMoreToLoad={data.haveMoreToLoad}
          onLoadMore={() => fetchNextPage({ pageParam: data.startAfter })}
          rows={data.users || []}
          onEdit={handleEdit}
        />
      </ManagementView>
      <AddUserModalContainer toggle={toggleModal} modal={isModalOpen} />
      {selectedUser && (
        <EditUserModal
          admin
          loading={loadingEdit}
          user={selectedUser}
          schools={schools}
          districts={districts}
          onSubmit={handleUserUpdate}
          onToggle={toggleModal}
          isOpen={isModalOpen}
        />
      )}
    </>
  );
};
