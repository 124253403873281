import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useSubscription } from '@cobuildlab/react-flux-state';

// FLUX STATE
import { authStore, USER_CLF_DISTRICTS, USER_CLF_DISTRICTS_ERROR } from '../../auth/auth-store';
import { getUserClfDistrictsData } from '../../auth/auth-actions';

// COMPONENTS
import { customToast } from '../../../shared/toast';
import { SubNavigationBar } from '../../../shared/components/menus/SubNavigationBar';
import { DropdownPicker } from '../../../shared/components/inputs/DropdownPicker';
import { CultureAndCovidCollapsibleLists } from '../../../shared/components/menus/CultureAndCovidCollapsibleLists';

export const DriversClfNavigationBar = ({
  avg,
  domainsAvg,
  onSelectDistricts,
  onSelectSchools,
}) => {
  const [districts, setDistricts] = useState([]);
  const [schools, setSchools] = useState([]);

  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [isAllDistricts, setIsAllDistricts] = useState(false);

  const [selectedSchool, setSelectedSchool] = useState(null);
  const [isAllSchools, setIsAllSchools] = useState(false);

  const filteredSchools = selectedDistrict ? selectedDistrict.schools : schools;

  const handleSelectDistrict = (value, isAll = false) => {
    setSelectedSchool(null);
    setIsAllSchools(false);
    setSelectedDistrict(value);
    setIsAllDistricts(isAll);
    if (isAll) onSelectDistricts(districts);
    else onSelectDistricts([value]);
  };

  const handleSelectSchool = (value, isAll = false) => {
    setSelectedSchool(value);
    setIsAllSchools(isAll);

    if (isAll && isAllDistricts) onSelectSchools(schools);
    else if (isAll) onSelectSchools(filteredSchools);
    else onSelectSchools([value]);
  };

  useEffect(() => {
    getUserClfDistrictsData();
  }, []);

  useSubscription(authStore, USER_CLF_DISTRICTS, (data) => {
    setDistricts(data.districts);
    setSchools(data.schools);
  });

  useSubscription(authStore, USER_CLF_DISTRICTS_ERROR, (err) => {
    customToast.error(err.message);
  });

  return (
    <SubNavigationBar>
      <div className="d-flex justify-content-between px-3 my-3">
        <div>
          <label className="mb-2">District: </label>
          <br />
          <DropdownPicker
            options={districts}
            value={selectedDistrict}
            onChange={(item) => handleSelectDistrict(item)}
            onSelectAll={() => handleSelectDistrict(null, true)}
            defaultSelectLabel="- Select a District -"
            allOptionsLabel="All Districts"
            isAllOptions={isAllDistricts}
          />
        </div>
        <div>
          <label className="mb-2">School: </label>
          <br />
          <DropdownPicker
            options={filteredSchools}
            value={selectedSchool}
            onChange={(item) => handleSelectSchool(item)}
            onSelectAll={() => handleSelectSchool(null, true)}
            defaultSelectLabel="- Select a School -"
            allOptionsLabel="All Schools"
            isAllOptions={isAllSchools}
          />
        </div>
      </div>

      <CultureAndCovidCollapsibleLists
        pathBase="/my-district"
        driversAvg={avg}
        domainsAvg={domainsAvg}
      />
    </SubNavigationBar>
  );
};

DriversClfNavigationBar.propTypes = {
  avg: PropTypes.object.isRequired,
  domainsAvg: PropTypes.object.isRequired,
  onSelectDistricts: PropTypes.func.isRequired,
  onSelectSchools: PropTypes.func.isRequired,
};
