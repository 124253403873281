import React from 'react';
import {
  availableDistrictFields,
  availableRegionFields,
  availableSchoolFields,
  availableUserFields,
} from '../data-import-actions';
import { Button } from 'reactstrap';
import { Icon } from 'react-icons-kit';
import { ic_file_download } from 'react-icons-kit/md/ic_file_download';

const ListGroupItem = ({ data, title }) => {
  return (
    <>
      <h6 className="mt-4 mb-4">{title}</h6>
      {data && (
        <div className="list-group mb-5">
          {data.map((item) => (
            <li key={item} className="item-list list-group-item-instruction">
              {item}
            </li>
          ))}
        </div>
      )}
    </>
  );
};

export const FirstStepContent = () => {
  return (
    <>
      <ListGroupItem
        title="Set the books names as the names of the collections available on the system: "
        data={['Users', 'Schools', 'Regions', 'Districts']}
      />
      <div>
        <h6 className="mt-4 mb-2">Download excel file template here:</h6>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={'./files/MyVoice - Upload Data Sheet.xlsx'}>
          <Button className="mb-4" size={'sm'} color="primary">
            <Icon icon={ic_file_download} size={16} /> Download Template
          </Button>
        </a>
      </div>
    </>
  );
};

export const SecondStepContent = () => {
  return <ListGroupItem title="The First Row will be used as field names" />;
};

export const ThirdStepContent = () => {
  return (
    <>
      <ListGroupItem title="Available Fields:" />
      <ListGroupItem title="Users: " data={availableUserFields} />
      <ListGroupItem title="Schools: " data={availableSchoolFields} />
      <ListGroupItem title="Regions: " data={availableRegionFields} />
      <ListGroupItem title="Districts: " data={availableDistrictFields} />
    </>
  );
};
