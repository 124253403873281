export const USER_TYPE_TEACHER = 'TEACHER';
export const USER_TYPE_LEADER = 'LEADER';
export const USER_TYPE_COA = 'COA';
export const USER_TYPE_CLF = 'CLF';

export default {
  TEACHER: USER_TYPE_TEACHER,
  LEADER: USER_TYPE_LEADER,
  COA: USER_TYPE_COA,
  CLF: USER_TYPE_CLF,
};
