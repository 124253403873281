import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, Form, Input, Label, Row, Col, Button, Spinner } from 'reactstrap';
import { Icon } from 'react-icons-kit';
import { ic_content_copy } from 'react-icons-kit/md';
import styled from 'styled-components';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import ModalHeader from '../../../components/modal/ModalHeader';
import { isActiveOpenRegistration } from '../super-admin-actions';
import Check from '../../../components/form/Checkbox';
import { CreatableInput } from '../../../shared/components/inputs/Select';
import { transformDomainsEmail, validatorEmailDomain } from '../super-admin-utils';

const StyledLabel = styled(Label)`
  position: inherit;
  top: 0;
  left: 0;
  color: #1a3044;
`;

const InputURL = styled(Input)`
  padding-right: 30px;
`;

const Checkbox = styled(Check)`
  margin-right: 10px;
`;

const CopyButton = styled.span`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 6px;
  right: 10px;
`;

const ActiveRegistrationModal = ({
  isOpen,
  onToggle,
  onChange,
  onSubmit,
  onExtend,
  isExtending,
  isSaving,
  isLoading,
  school,
  openRegistration,
}) => {
  const [urlCopied, setUrlCopied] = useState(false);
  const [isValidEmailDomain, setIsValidEmailDomain] = useState(
    validatorEmailDomain(openRegistration.emailDomain),
  );
  const opRegIsAlreadyExist = openRegistration.id ? true : false;
  const modalTitle = `${school.name} Open Registration`;
  const isActiveOpReg = isActiveOpenRegistration(openRegistration.expireDate);
  const formatedExpireDate = moment(openRegistration.expireDate).format('MM-DD-YYYY');
  const registrationUrl = opRegIsAlreadyExist
    ? `${window.location.origin}/registration/${openRegistration.token}`
    : '';

  const hasExpired = moment(openRegistration.expireDate).isBefore(moment());

  const isEnabledSubmit =
    (!isSaving && !isLoading && isValidEmailDomain) ||
    opRegIsAlreadyExist ||
    openRegistration.allowAllDomains;

  useEffect(() => {
    if (!isOpen) {
      setIsValidEmailDomain(false);
    }
  }, [isOpen]);

  const handleOnChange = (newOpenRegistration) => {
    if (newOpenRegistration.emailDomain) {
      const isValid = validatorEmailDomain(newOpenRegistration.emailDomain);
      setIsValidEmailDomain(isValid);
    }
    onChange(newOpenRegistration);
  };

  const copyUrl = () => {
    const textField = document.createElement('textarea');
    textField.innerText = registrationUrl;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    setUrlCopied(true);
    setTimeout(() => {
      setUrlCopied(false);
    }, 1000);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (isEnabledSubmit) onSubmit();
  };

  let butttonSubmitText = opRegIsAlreadyExist ? 'Stop Open Registration' : 'Open Registration';
  if (isSaving) {
    butttonSubmitText = (
      <>
        <Spinner size="sm" color="primary" />
        {opRegIsAlreadyExist ? 'Disabling URL' : 'Creating URL'}
      </>
    );
  }

  let buttonExtend = null;
  if (opRegIsAlreadyExist) {
    let butttonExtendText = 'Extend 30 Days';
    if (isExtending) {
      butttonExtendText = (
        <>
          <Spinner size="sm" color="danger" />
          Extending
        </>
      );
    }

    buttonExtend = (
      <Button
        type="button"
        color="danger"
        outline
        onClick={onExtend}
        className="float-right btn-input-h mr-2"
        disabled={isExtending || isSaving || isLoading}>
        {butttonExtendText}
      </Button>
    );
  }

  let copyUrlButton = null;
  if (opRegIsAlreadyExist) {
    const copiedTextStyle = { opacity: urlCopied ? '1' : '0' };
    copyUrlButton = (
      <CopyButton>
        <Icon style={{ cursor: 'pointer' }} onClick={copyUrl} icon={ic_content_copy} />
        <small style={copiedTextStyle} className="mt-1">
          Copied!
        </small>
      </CopyButton>
    );
  }

  let expireDateText = null;
  if (registrationUrl) {
    expireDateText = (
      <p className="mt-2">
        {hasExpired ? (
          <i>This link has expired on {formatedExpireDate}</i>
        ) : (
          <i>This link will expire on {formatedExpireDate} </i>
        )}
      </p>
    );
  }

  let content = (
    <div className="d-flex justify-content-center my-5">
      <ClipLoader sizeUnit="px" size={150} color="#123abc" loading />
    </div>
  );

  if (!isLoading) {
    content = (
      <Form onSubmit={handleOnSubmit}>
        <Row>
          <Col md={12}>
            <StyledLabel className="ml-2">Email domain accepted for registrations</StyledLabel>
            <CreatableInput
              placeholder={
                !openRegistration.allowAllDomains
                  ? '@school-email-domain.com'
                  : 'All domains are allowed'
              }
              value={openRegistration.emailDomain}
              onChange={(val) => handleOnChange({ emailDomain: val })}
              disabled={opRegIsAlreadyExist || openRegistration.allowAllDomains}
              onTransformValues={transformDomainsEmail}
              textInvalidError={'Invalid email domain'}
              textVoidError={'Email domain is required'}
            />
          </Col>
          <Col md={12}>
            <InputURL value={registrationUrl} readOnly />
            {copyUrlButton}
            <Checkbox
              checked={openRegistration.allowAllDomains}
              disabled={opRegIsAlreadyExist}
              onChange={() => {
                const newAllowAllDomains = !openRegistration.allowAllDomains;
                let newOpenRegistration = {
                  allowAllDomains: newAllowAllDomains,
                };
                if (newAllowAllDomains === true) {
                  newOpenRegistration = {
                    ...newOpenRegistration,
                    emailDomain: [],
                  };
                }
                handleOnChange(newOpenRegistration);
              }}
            />
            <Label check>Allow all email domain</Label>
          </Col>
          <Col md={12}>{expireDateText}</Col>
        </Row>

        <div className="mt-5">
          <Button
            type="submit"
            color={opRegIsAlreadyExist && isActiveOpReg ? 'danger' : 'secondary'}
            className={`${
              opRegIsAlreadyExist && isActiveOpReg ? '' : 'btn-blue'
            } btn-input-h float-right`}
            disabled={!isEnabledSubmit}>
            {butttonSubmitText}
          </Button>
          {buttonExtend}
        </div>
      </Form>
    );
  }

  return (
    <Modal isOpen={isOpen} toggle={onToggle} backdrop="static" centered>
      <ModalHeader title={modalTitle} onToggle={onToggle} />
      <ModalBody>{content}</ModalBody>
    </Modal>
  );
};

ActiveRegistrationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onExtend: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isExtending: PropTypes.bool.isRequired,
  school: PropTypes.array.isRequired,
  openRegistration: PropTypes.object.isRequired,
};

export default ActiveRegistrationModal;
