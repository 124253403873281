import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import SenseOfBelongingIcon from '../../../shared/assets/icons/sense-of-belonging.svg';
import { updateIndicator, updateIndicators } from '../my-voice-actions';
import { INITIAL_SENSE_INDICATORS, SENSE_INDICATORS } from '../my-voice-models';
import { DriverDetailsForm } from '../components/DriversDetailsForm';

class DriverSenseOfBelongingView extends Component {
  constructor(props) {
    super(props);
    const data = this.props.data;
    const indicators = updateIndicators(
      R.clone(this.props.isInitial ? INITIAL_SENSE_INDICATORS : SENSE_INDICATORS),
      data,
    );
    this.state = {
      indicators,
    };
  }

  onClickStep = (indicatorId, indicatorValue) => {
    const indicators = updateIndicator(this.state.indicators, indicatorId, indicatorValue);
    this.setState({ indicators });
  };

  onSubmit = () => {
    this.props.onSubmit(this.state.indicators);
  };

  render() {
    const { indicators } = this.state;
    const { submitText } = this.props;
    return (
      <DriverDetailsForm
        icon={SenseOfBelongingIcon}
        title="Sense of Belonging"
        message="The feeling of being valued and part of the school brings meaning and importance to
        teachers’ work. Teachers avoid working in isolation when they feel a sense of
        belonging. Diversity is valued, and programs are in place to make teachers feel like
        they belong."
        indicators={indicators}
        onChangeIndicator={(id, stepNumber) => this.onClickStep(id, stepNumber)}
        submitText={submitText}
        onSubmit={this.onSubmit}
      />
    );
  }
}

DriverSenseOfBelongingView.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  isInitial: PropTypes.bool,
};

DriverSenseOfBelongingView.defaultProps = {
  submitText: 'Update',
  isInitial: false,
};

export default DriverSenseOfBelongingView;
