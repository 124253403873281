import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ALL_DOMAIN_GRAPH_OPTIONS, DOMAINS } from '../covid-19-domains-models';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as R from 'ramda';
import { theme } from '../../../shared/theme';

const StyledReactApexChart = styled(ReactApexChart)`
  height: 135px !important;
  max-height: 135px !important;
  min-height: 135px !important;
`;

const DomainsGraph = ({ domainAverages, myDomainsAverages, schoolName }) => {
  const GRAPH_OPTIONS = R.clone(ALL_DOMAIN_GRAPH_OPTIONS);

  const exportFilename = `${schoolName}-domains-average`;

  GRAPH_OPTIONS.chart.toolbar.export.svg.filename = exportFilename;
  GRAPH_OPTIONS.chart.toolbar.export.png.filename = exportFilename;
  GRAPH_OPTIONS.chart.toolbar.export.csv.filename = exportFilename;

  const domainsData = [];
  for (const domain of DOMAINS) {
    const data = domainAverages[domain.id];
    domainsData.push(data || 0);
  }

  const series = [
    {
      type: myDomainsAverages ? 'line' : 'bar',
      name: 'School COVID Domains Score',
      data: domainsData,
    },
  ];

  if (myDomainsAverages) {
    const myDomainsData = [];
    for (const domain of DOMAINS) {
      const data = myDomainsAverages[domain.id];
      myDomainsData.push(data || 0);
    }
    series.unshift({
      type: 'bar',
      name: 'My COVID Domains Score',
      data: myDomainsData,
    });

    GRAPH_OPTIONS.colors.unshift(theme.palette.secondary);
    GRAPH_OPTIONS.fill.colors.unshift(theme.palette.secondary);
  }

  return (
    <div>
      <h2 className="my-5" style={{ fontFamily: 'Zilla Slab' }}>
        {schoolName}
      </h2>
      <StyledReactApexChart
        type="line"
        height="500"
        width="80%"
        options={GRAPH_OPTIONS}
        series={series}
      />
    </div>
  );
};

DomainsGraph.defaultProps = {
  myDomainsAverages: null,
};

DomainsGraph.propTypes = {
  schoolName: PropTypes.string.isRequired,
  myDomainsAverages: PropTypes.object,
  domainAverages: PropTypes.object.isRequired,
};

export default DomainsGraph;
