import React from 'react';
import { Button, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

const LoadingButton = ({ children, loading = false, ...rest }) => (
  <Button {...rest}>
    <span className="span-style">{children}</span>

    {loading && (
      <Spinner className="button-style position-relative" style={{ marginLeft: 10 }} size="sm" />
    )}
  </Button>
);

LoadingButton.defaultProps = {
  loading: false,
};

LoadingButton.propTypes = {
  children: PropTypes.any.isRequired,
  loading: PropTypes.bool,
};

export default LoadingButton;
