import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import Creatable from 'react-select/creatable';
import styled from 'styled-components';
import { theme } from '../../theme';
import { ErrorInputMsg } from '../typography/ErrorInputMsg';
import { FormGroup } from 'reactstrap';
import { validatorEmailDomain } from '../../../modules/super-admin/super-admin-utils';

const StyledSelect = styled(ReactSelect)`
  & > div {
    box-shadow: none;
    border-color: #ced4da !important;
    &:hover {
      border-color: ${theme.palette.secondary} !important;
    }
  }
`;

export const Select = ({ options, ...props }) => {
  const sortedOptions = useMemo(() => {
    if (options) {
      return options.sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        if (a.label && b.label) {
          return a.label.localeCompare(b.label);
        }
        if (props.getOptionLabel) {
          const labelA = props.getOptionLabel(a);
          const labelB = props.getOptionLabel(b);
          return labelA.localeCompare(labelB);
        }
        return 0;
      });
    }
    return [];
  }, [options]);
  return <StyledSelect {...props} options={sortedOptions} />;
};
export const CreatableSelect = styled(Creatable)`
  & > div {
    box-shadow: none;
    border-color: ${({ error }) => (error ? theme.palette.danger : '#ced4da')} !important;
    &:hover {
      border-color: ${// eslint-disable-next-line indent
      ({ error }) => (error ? theme.palette.dangerDark : theme.palette.secondary)} !important;
    }
  }
`;

export const CreatableInput = ({
  disabled = false,
  value = [],
  onChange,
  onBlur,
  onTransformValues,
  textVoidError = 'Please enter a value',
  textInvalidError = 'Invalid value',
  ...rest
}) => {
  const [error, setError] = useState(false);
  const [textError, setTextError] = useState('');
  const [inputValue, setInputValue] = useState('');
  const optionValues = value.map((label) => ({ label, value: label }));
  const handleInputChange = (inputValue) => {
    const data = onTransformValues ? onTransformValues(inputValue) : inputValue;
    handleInputError(data);
    onChange(data);
  };
  const handleInputError = (inputValue) => {
    if (inputValue.length === 0) {
      setTextError(textVoidError);
      setError(true);
      return;
    } else {
      setError(false);
    }
    const isValid = validatorEmailDomain(inputValue);
    if (!isValid) {
      setTextError(textInvalidError);
      setError(true);
    } else {
      setError(false);
    }
  };
  return (
    <FormGroup>
      <CreatableSelect
        components={{
          DropdownIndicator: null,
        }}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onBlur={() => onBlur(value)}
        onChange={(valueEvent) => {
          const newValue = valueEvent.map((item) => item.value);
          handleInputChange(newValue);
        }}
        onInputChange={(newValue) => {
          setInputValue(newValue);
        }}
        onKeyDown={(event) => {
          if (!inputValue || inputValue === '') return;
          if (event.key === 'Enter' || event.key === 'Tab') {
            const newValue = [...value, inputValue];
            handleInputChange(newValue);
            setInputValue('');
            event.preventDefault();
          }
        }}
        value={optionValues}
        isDisabled={disabled}
        error={error}
        {...rest}
      />
      {error && <ErrorInputMsg>{textError}</ErrorInputMsg>}
    </FormGroup>
  );
};
CreatableInput.defaultProps = {
  onBlur: () => {},
  onTransformValues: () => {},
};

CreatableInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};
