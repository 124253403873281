import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSubscription } from '@cobuildlab/react-flux-state';
import { withRouter } from 'react-router-dom';

// COMPONENTS
import { Col, Alert, Progress, Container } from 'reactstrap';
import { NavigationBar } from '../../shared/layouts/NavigationBar';
import { ClipLoader } from 'react-spinners';
import { customToast } from '../../shared/toast';

// FLUX STATE
import { MY_VOICE_ERROR_EVENT, MY_VOICE_UPDATE_EVENT, myVoiceStore } from './my-voice-store';
import { DRIVERS } from './my-voice-models';
import { bulkUpdateMyVoiceActionCoa, bulkUpdateMyVoiceInitialActionCoa } from './my-voice-actions';
import { authStore, USER_EVENT } from '../auth/auth-store';

const InitialAssessmentCoa = (props) => {
  const [driverData, setDriverData] = useState({});
  const [driverIndex, setDriverIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const progress = Math.ceil((driverIndex * 100) / DRIVERS.length);

  useEffect(() => {
    setDriverIndex(0);
  }, []);

  const onSubmit = (driverId, indicatorsList) => {
    const indicators0 = indicatorsList.filter((indicator) => indicator.data === 0);
    if (indicators0.length > 0) return customToast.error('You Must fill all the indicators');
    const indicatorsObj = {};
    indicatorsList.forEach(({ id, data }) => {
      indicatorsObj[id] = data;
    });

    const auxDriverData = { ...driverData };
    auxDriverData[driverId] = indicatorsObj;
    setDriverData(auxDriverData);

    if (driverIndex === DRIVERS.length - 1) {
      bulkUpdateMyVoiceActionCoa(auxDriverData);
      bulkUpdateMyVoiceInitialActionCoa(auxDriverData);
      // updateTeacherCustomDomains()
    } else {
      setDriverIndex(driverIndex + 1);
    }
  };

  useSubscription(myVoiceStore, MY_VOICE_ERROR_EVENT, (err) => {
    customToast.error(err.message);
    setLoading(false);
  });

  useSubscription(myVoiceStore, MY_VOICE_UPDATE_EVENT, () => {
    customToast.success('Drivers successfully Updated!');
    props.history.push('/my-district');
  });

  let content = (
    <Col
      md={12}
      className="content-sub-bar align-items-center justify-content-center h-100vh d-flex">
      <ClipLoader sizeUnit={'px'} size={150} color={'#123abc'} loading />
    </Col>
  );

  if (!loading) {
    const submitText = driverIndex === DRIVERS.length - 1 ? 'Submit' : 'Next';
    const user = authStore.getState(USER_EVENT);

    const { component: Component, id } = DRIVERS[driverIndex];
    content = (
      <Component
        user={user}
        isInitial={true}
        submitText={submitText}
        bodyClass={'driver-sub-bar-full'}
        data={undefined}
        onSubmit={(indicators) => onSubmit(id, indicators)}
      />
    );
  }

  return (
    <NavigationBar currentRoute={'/initial-assessment'}>
      <Container fluid>
        <Alert color="primary">
          Please fill all the indicators for each Driver.
          <div className="text-center">{`${progress} %`}</div>
          <Progress value={progress} />
          <br />
        </Alert>
        {content}
      </Container>
    </NavigationBar>
  );
};

InitialAssessmentCoa.propTypes = {
  history: PropTypes.object.isRequired,
};

export const InitialAssessmentCoaView = withRouter(InitialAssessmentCoa);
