import React from 'react';
import PropTypes from 'prop-types';

const WhiteLink = ({ href, text }) => {
  return (
    <a className={'white'} href={href} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );
};

WhiteLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default WhiteLink;
