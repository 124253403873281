import firebase from 'firebase';
import Flux from '@cobuildlab/flux-state';
import { log, error } from 'pure-logger';
// import {UserModel} from './auth-models';
// import * as R from 'ramda';
import { PROFILE_ERROR_EVENT, PROFILE_EVENT, UPDATE_PROFILE_EVENT } from './profile-store';
import { profileValidator } from './profile-validators';
import { authStore, USER_EVENT } from '../auth/auth-store';

/**
 *
 * @param {ProfileModel} profileData
 * @returns {Promise<ProfileModel>}
 */
export const updateProfileAction = async (profileData) => {
  log('updateProfileAction', profileData);
  const user = authStore.getState(USER_EVENT);
  try {
    profileValidator(profileData, user);
  } catch (e) {
    error('updateProfileAction', e);
    return Flux.dispatchEvent(PROFILE_ERROR_EVENT, e);
  }

  const DB = firebase.firestore();
  const profilesCollection = DB.collection('profilesTeacher');
  log('updateProfileAction:user', user);
  const profileRef = profilesCollection.doc(user.email);
  // logProfile(user.email, profileData, new Date().getTime());
  // initialProfilesTeacher(user.email, profileData, new Date().getTime());
  await profileRef.set(profileData, { merge: true });
  Flux.dispatchEvent(UPDATE_PROFILE_EVENT, profileData);
  return profileData;
};

//leader

export const updateProfileActionLeader = async (profileData) => {
  try {
    const user = authStore.getState(USER_EVENT);
    profileValidator(profileData, user);
  } catch (e) {
    error('updateProfileAction', e);
    return Flux.dispatchEvent(PROFILE_ERROR_EVENT, e);
  }
  const DB = firebase.firestore();
  const profilesCollection = DB.collection('profilesLeader');
  const user = authStore.getState(USER_EVENT);
  const profileRef = profilesCollection.doc(user.email);
  await profileRef.set(profileData, { merge: true });
  Flux.dispatchEvent(UPDATE_PROFILE_EVENT, profileData);
  return profileData;
};

/**
 *
 * @param {ProfileModel} profileData
 * @returns {Promise<ProfileModel>}
 */
export const initialProfiles = async (profileData) => {
  const DB = firebase.firestore();
  const profilesCollection = DB.collection('initialProfilesTeacher');
  const user = authStore.getState(USER_EVENT);
  // const profileRef = profilesCollection.doc(user.email);
  //logInitialProfile(user.email, profileData, new Date().getTime());
  const dateCompleted = new Date().getTime();
  await profilesCollection.add({ profileData, dateCompleted, email: user.email });
  return profileData;
};

export const initialProfilesLeader = async (profileData) => {
  const DB = firebase.firestore();
  const profilesCollection = DB.collection('initialProfilesLeader');
  const user = authStore.getState(USER_EVENT);
  const dateCompleted = new Date().getTime();
  await profilesCollection.add({ profileData, dateCompleted, email: user.email });
  return profileData;
};

/**
 *
 * @param email
 * @param profileData
 * @param timestamp
 * @returns {Promise<void>}
 */
// const logProfile = async (email, profileData, timestamp) => {
//   const DB = firebase.firestore();
//   const profilesLogsCollection = DB.collection('profilesLogs');
//   const profileLogRef = profilesLogsCollection.doc(`${email}:${timestamp}`);
//   await profileLogRef.set(profileData);
// };

// const logInitialProfile = async (email, profileData, timestamp) => {
//   const DB = firebase.firestore();
//   const profilesLogsCollection = DB.collection('initialProfilesLogs');
//  // const profileLogRef = profilesLogsCollection.doc(`${email}:${timestamp}`);
//   await profilesLogsCollection.add({profileData, _dateCompleted : timestamp, email });
// };

/**
 * Fetches a User Profile Teacher.
 *
 * @param email - The email of the User.
 * @returns {Promise<ProfileModel>}
 */
export const fetchProfileAction = async (email = null) => {
  const DB = firebase.firestore();
  const profilesCollection = DB.collection('profilesTeacher');
  if (email === null) {
    const user = authStore.getState(USER_EVENT);
    email = user.email;
  }
  const profileRef = profilesCollection.doc(email.toLowerCase());
  const query = await profileRef.get();
  let profileData = {};
  if (query.exists) {
    profileData = query.data();
  }
  Flux.dispatchEvent(PROFILE_EVENT, profileData);
  return profileData;
};

//Leader Profile

export const fetchProfileActionLeader = async (email = null) => {
  const DB = firebase.firestore();
  const profilesCollection = DB.collection('profilesLeader');
  if (email === null) {
    const user = authStore.getState(USER_EVENT);
    email = user.email;
  }
  const profileRef = profilesCollection.doc(email.toLowerCase());
  const query = await profileRef.get();
  let profileData = {};
  if (query.exists) {
    profileData = query.data();
  }
  Flux.dispatchEvent(PROFILE_EVENT, profileData);
  return profileData;
};
