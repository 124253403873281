import * as Excel from 'exceljs';
import * as xlsx from 'xlsx';

/**
 * Export data as a XLSX file.
 *
 * @param {Array} columns - Array defining the tables columns.
 * @param {Array} rows - Object containing an Array of Arrays.
 * @returns {Promise<Buffer>} - Excel File.
 */
export async function createExcelFile(columns, rows) {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('MyReports');

  worksheet.addTable({
    name: 'MyReports',
    ref: 'A1',
    headerRow: true,
    style: {
      theme: 'TableStyleMedium2',
      showRowStripes: true,
    },
    columns,
    rows,
  });

  columns.forEach((column, index) => {
    if (column.width) {
      worksheet.getColumn(index + 1).width = column.width;
    }
  });

  const buffer = await workbook.xlsx.writeBuffer();
  return buffer;
}

/**
 * @param {Buffer} body - Buffer Array.
 * @param {string} fileName - File name.
 */
export function downloadBuffer(body, fileName) {
  const blob = new Blob([body]);
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

/**
 * Export data as a XLSX file.
 *
 * @param {Array} rows - Object containing an Array of Arrays.
 * @param {string} fileName - File name.
 * @param {Array} preHeaderRow - Array of strings.
 */
export function createXlsxFile(rows, fileName = 'MyReports', preHeaderRow = []) {
  const name = fileName.concat('.xlsx');
  const rowNumberHeader = preHeaderRow.length > 0 ? preHeaderRow.length + 2 : 1;
  const startTable = `A${rowNumberHeader}`;
  const workbook = xlsx.utils.book_new();
  const worksheet = xlsx.utils.json_to_sheet(rows, { origin: startTable });
  if (preHeaderRow.length > 0) {
    xlsx.utils.sheet_add_aoa(worksheet, preHeaderRow, { origin: 'A1' });
  }
  xlsx.utils.book_append_sheet(workbook, worksheet, fileName);
  xlsx.writeFile(workbook, name);
}

/**
 *
 * @param {Array} columns - Array defining the tables columns.
 * @param {Array} rows - Object containing an Array of Arrays with data.
 * @param {string} fileName - File name.
 * @param {Array} headerRow - Array with header row.
 */
export async function createAndDownloadExcel(columns, rows, fileName) {
  let fileBuffer;
  try {
    fileBuffer = await createExcelFile(columns, rows);
  } catch (error) {
    console.log('Error creating an Excel file.', error.message);
    throw error;
  }

  try {
    await downloadBuffer(fileBuffer, fileName);
  } catch (error) {
    console.log('Error downloading the Excel file.', error.message);
    throw error;
  }
}
