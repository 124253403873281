import React from 'react';
import { PropTypes } from 'prop-types';
import { Container } from 'reactstrap';

export const SubNavigationLayout = ({ sidebar, children }) => (
  <div className="d-flex h-100">
    {sidebar}
    <div className="flex-grow-1 overflow-auto">
      <Container className="h-100 pt-3">{children}</Container>
    </div>
  </div>
);

SubNavigationLayout.propTypes = {
  sidebar: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};
