import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import SupportingIcon from '../../../shared/assets/icons/support-and-care.svg';
import { updateIndicator, updateIndicators } from '../my-voice-actions';
import { INITIAL_SUPPORT_INDICATORS, SUPPORT_INDICATORS } from '../my-voice-models';
import { DriverDetailsForm } from '../components/DriversDetailsForm';

class DriverSupportView extends Component {
  constructor(props) {
    super(props);
    const data = this.props.data;
    const indicators = updateIndicators(
      R.clone(this.props.isInitial ? INITIAL_SUPPORT_INDICATORS : SUPPORT_INDICATORS),
      data,
    );
    this.state = {
      indicators,
    };
  }

  onClickStep = (indicatorId, indicatorValue) => {
    const indicators = updateIndicator(this.state.indicators, indicatorId, indicatorValue);
    this.setState({ indicators });
  };

  onSubmit = () => {
    this.props.onSubmit(this.state.indicators);
  };

  render() {
    const { indicators } = this.state;
    const { submitText } = this.props;
    return (
      <DriverDetailsForm
        icon={SupportingIcon}
        title="Support & Care"
        message="Experienced teachers are valuable in providing support and mentoring to their
        colleagues. Meeting teacher’s personal and professional needs through focused
        professional learning with follow-up are essential for professional growth."
        indicators={indicators}
        onChangeIndicator={(id, stepNumber) => this.onClickStep(id, stepNumber)}
        submitText={submitText}
        onSubmit={this.onSubmit}
      />
    );
  }
}

DriverSupportView.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  isInitial: PropTypes.bool,
};

DriverSupportView.defaultProps = {
  submitText: 'Update',
  isInitial: false,
};

export default DriverSupportView;
