import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSubscription } from '@cobuildlab/react-flux-state';
import { Button } from 'reactstrap';
import { ButtonFiltersMenu } from '../../../shared/components/menus/ButtonFiltersMenu';
import { roleOptions, UsersFilter } from '../../../shared/components/inputs/UsersFilter';
import {
  superAdminStore,
  SUPER_ADMIN_ALL_SCHOOLS_EVENT,
  SUPER_ADMIN_ALL_DISTRICTS_EVENT,
} from '../super-admin-store';
import { fetchAllSchools, getAllDistricts } from '../super-admin-actions';

const initialSearchData = {
  school: null,
  district: null,
  role: '',
  onlyActives: 'active',
};

export const ButtonUsersFilter = ({ data, onChange, fixedValues, isMulti, isLoading }) => {
  const [openMenu, setOpenMenu] = useState(false);

  const [localData, setLocalData] = useState({ ...initialSearchData });
  const [schools, setSchools] = useState([]);
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [isLoadingSchools, setIsLoadingSchools] = useState(false);

  const [districts, setDistricts] = useState([]);
  const [isLoadingDistricts, setIsLoadingDistricts] = useState(false);

  useEffect(() => {
    getAllDistricts();
    setIsLoadingDistricts(true);
    fetchAllSchools();
    setIsLoadingSchools(true);
  }, []);

  useEffect(() => {
    let newFilteredSchools = [...schools];
    const districtIdToCompare =
      (localData.district && localData.district.id) || fixedValues.districtId;
    if (districtIdToCompare) {
      if (!isMulti)
        newFilteredSchools = schools.filter((school) => {
          const schoolDistrictId = school.regionDistrictId;
          return districtIdToCompare === schoolDistrictId;
        });
      else if (localData.district.length)
        newFilteredSchools = schools.filter((school) => {
          const schoolDistrictId = school.regionDistrictId;
          return Boolean(localData.district.find((district) => district.id === schoolDistrictId));
        });
    }
    setFilteredSchools(newFilteredSchools);
  }, [localData.district, schools, isMulti]);

  useEffect(() => {
    if (data) {
      const rawData = { ...data };
      if (typeof rawData.school === 'string')
        rawData.school = schools.find((school) => school.id === rawData.schoolId);

      if (typeof rawData.role === 'string')
        rawData.role = roleOptions.find((role) => role.value === rawData.role);
      setLocalData(rawData);
    }
  }, [data]);

  useSubscription(superAdminStore, SUPER_ADMIN_ALL_SCHOOLS_EVENT, (allSchools) => {
    setIsLoadingSchools(false);
    setSchools(allSchools);
  });

  useSubscription(superAdminStore, SUPER_ADMIN_ALL_DISTRICTS_EVENT, (allDistricts) => {
    setIsLoadingDistricts(false);
    setDistricts(allDistricts);
  });

  const onChangeLocalData = (newValues) => {
    const hasMultiDistrictChanged =
      isMulti && JSON.stringify(newValues.district) !== JSON.stringify(localData.district);
    const hasDistrictChanged =
      newValues.district && newValues.district.id !== (localData.district && localData.district.id);

    if (hasMultiDistrictChanged || hasDistrictChanged) {
      newValues.school = isMulti ? [] : null;
    }
    const auxLocalData = { ...localData };
    const newObject = Object.assign(auxLocalData, newValues);
    setLocalData(newObject);
  };

  const handleApply = () => {
    const newFilters = { ...localData };
    if (newFilters.district) {
      newFilters.districtId = newFilters.district.id;
    } else {
      newFilters.districtId = '';
    }
    if (newFilters.school || localData.school) {
      newFilters.schoolId = localData.school.id;
      if (!isMulti) newFilters.school = localData.school.name;
    } else {
      newFilters.schoolId = '';
    }
    if (newFilters.role) {
      newFilters.role = newFilters.role.value;
    } else {
      newFilters.role = '';
    }
    if (newFilters.search) {
      newFilters.email = newFilters.search;
      newFilters.generalSearch = newFilters.search;
    } else {
      newFilters.generalSearch = '';
      newFilters.email = newFilters.search;
    }
    onChange(newFilters);
  };

  const changedFilters = JSON.stringify(localData) !== JSON.stringify(data);
  const isEmptyFilter = JSON.stringify(localData) === JSON.stringify(initialSearchData);

  return (
    <ButtonFiltersMenu
      open={openMenu}
      onChangeOpenMenu={(val) => !isLoading && setOpenMenu(val)}
      trigger="click"
      placement="right"
      id="btn-users-filters"
      popoverProps={{ className: 'popover-behind' }}>
      <UsersFilter
        data={localData}
        absoluteValues={{}}
        districts={districts}
        schools={filteredSchools}
        isLoadingSchools={isLoadingSchools}
        isLoadingDistricts={isLoadingDistricts}
        onChange={onChangeLocalData}
        isMulti={isMulti}
        fixedValues={fixedValues}
        disabled={isLoading}
      />
      <div className="d-flex justify-content-end flex-nowrap">
        <Button
          type="button"
          color="light"
          className="btn-input-size"
          onClick={() => {
            const auxData = { ...initialSearchData };
            if (isMulti) {
              auxData.district = [];
              auxData.school = [];
            }
            setOpenMenu(false);
            onChange({ ...auxData });
          }}
          disabled={isLoading || isEmptyFilter}>
          Clear
        </Button>
        <Button
          type="button"
          className="ml-3 btn-input-size"
          color="primary"
          onClick={() => {
            setOpenMenu(false);
            handleApply();
          }}
          disabled={isLoading || !changedFilters}>
          Apply
        </Button>
      </div>
    </ButtonFiltersMenu>
  );
};

ButtonUsersFilter.defaultProps = {
  fixedValues: {},
  isMulti: false,
  isLoading: false,
};

ButtonUsersFilter.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  fixedValues: PropTypes.object,
  isMulti: PropTypes.bool,
  isLoading: PropTypes.bool,
};
