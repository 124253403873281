import Flux from '@cobuildlab/flux-state';

/**
 * Super admin Events.
 *
 * @type {string}
 */

export const ROSTER_MANAGER_USERS_EVENT = 'ROSTER_MANAGER_USERS_EVENT';
export const ROSTER_MANAGER_USERS_ERROR = 'ROSTER_MANAGER_USERS_ERROR';
export const ROSTER_MANAGER_ALL_SCHOOLS_EVENT = 'ROSTER_MANAGER_ALL_SCHOOLS_EVENT';
export const ROSTER_MANAGER_ADD_USER_EVENT = 'ROSTER_MANAGER_ADD_USER_EVENT';
export const ROSTER_MANAGER_ADD_USER_ERROR_EVENT = 'ROSTER_MANAGER_ADD_USER_ERROR_EVENT';
export const ROSTER_MANAGER_UPDATE_USER_EVENT = 'ROSTER_MANAGER_UPDATE_USER_EVENT';
export const ROSTER_MANAGER_UPDATE_USER_ERROR_EVENT = 'ROSTER_MANAGER_UPDATE_USER_ERROR_EVENT';

class RosterManagerStore extends Flux.Store {
  constructor() {
    super();

    this.addEvent(ROSTER_MANAGER_USERS_EVENT);
    this.addEvent(ROSTER_MANAGER_USERS_ERROR);
    this.addEvent(ROSTER_MANAGER_ALL_SCHOOLS_EVENT);
    this.addEvent(ROSTER_MANAGER_ADD_USER_EVENT);
    this.addEvent(ROSTER_MANAGER_ADD_USER_ERROR_EVENT);
    this.addEvent(ROSTER_MANAGER_UPDATE_USER_EVENT);
    this.addEvent(ROSTER_MANAGER_UPDATE_USER_ERROR_EVENT);
  }
}

const rosterManagerStore = new RosterManagerStore();
export { rosterManagerStore };
